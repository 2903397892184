import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormPR, TablePR} from '../../memo-template';
import {MasterItemDropdown} from '../../../../../../master-item/master-item.model';
import {MasterItemService} from '../../../../../../master-item/master-item.service';

@Component({
  selector: 'app-table-template-pr',
  templateUrl: './table-template-pr.component.html',
  styleUrls: ['./table-template-pr.component.scss']
})
export class TableTemplatePrComponent implements OnInit {
  @Input() numberLabel;
  // tslint:disable-next-line:no-input-rename
  @Input('formPRData') formPRData: FormPR;
  @Output() formPRDataChange = new EventEmitter();

  constructor(private masterService: MasterItemService) {
  }

  async ngOnInit() {
    this.formPRData.details.forEach((detail) => {
      if (detail.item && !detail.item_dropdown) {
        // selected item_dropdown will empty, when edit but item was locked, so I will clear item id
        detail.item = null;
      }
    });
  }

  onAddRowToLineItems() {
    this.formPRData.details.push({
      sequence: (this.formPRData.details || []).length + 1,
      id: null,
      description: null,
      quantity: 0,
      unit: null,
      unit_price: 0,
      amount: 0,
      item: null,
      item_dropdown: null,
    });
  }

  onRemoveRowFromLineItems(index) {
    this.formPRData.details.splice(index, 1);
    this.reOrderSequence();
    this.calSumAmount();
  }

  reOrderSequence() {
    (this.formPRData.details || []).forEach((d, index) => {
      d.sequence = index + 1;
    });
  }

  calSumAmount() {
    const sumAmount = this.formPRData.details
      .reduce((sum, cur) => sum + cur.amount, 0);
    this.formPRData.sum_amount = this.limitTwoDecimal(sumAmount);
  }

  limitTwoDecimal(num: number, mode: 'round' | 'floor' = 'round'): number {
    return mode === 'round'
      ? Math.round(num * 100) / 100
      : Math.floor(num * 100) / 100;
  }

  updateTableLineItem(lineItem, key: string, val: string) {
    if (key === 'quantity') {
      lineItem[key] = Math.floor(+val);
    } else {
      lineItem[key] = this.limitTwoDecimal(+val, 'round');
    }
    lineItem.amount = this.limitTwoDecimal(lineItem.quantity * lineItem.unit_price);
    this.calSumAmount();
  }

  onSelectMasterItem(lineItem: TablePR, masterItem: MasterItemDropdown) {
    lineItem.item_dropdown = masterItem;
    lineItem.item = masterItem.value;
  }
}
