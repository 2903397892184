import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExecutiveListComponent } from './executive-list/executive-list.component';

export const executiveRoutes: Routes = [
  {
    path: 'list/:memo',
    component: ExecutiveListComponent,
  },
  {
    path: 'list',
    component: ExecutiveListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(executiveRoutes)],
  exports: [RouterModule],
})
export class ExecutiveRoutingModule {}
