import {Injectable} from '@angular/core';
import * as FastAverageColor from 'fast-average-color';


@Injectable()
export class SignatureService {

  private fastAverageColor = new FastAverageColor();

  async checkOnlyWhiteBackground(blob_signature: Blob) {
    const imageSource = window.URL.createObjectURL(blob_signature);
    const ignoreWhiteColors: IFastAverageColorRgb[] = [];
    for (let i = 0; i <= 255; i++) {
      ignoreWhiteColors.push([255, 255, 255, i]);
    }
    try {
      const result: IFastAverageColorResult = await this.fastAverageColor.getColorAsync(
        imageSource,
        {ignoredColor: ignoreWhiteColors}
      );
      const sumRgba = result.value.reduce((sum, i) => sum + i, 0);
      if (sumRgba === 0) {
        return true;
      }
    } catch (err) {
      return true;
    }
    return false;
  }

}
