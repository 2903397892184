import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserProfileService} from '../user-profile.service';
import {Router} from '@angular/router';
import {ProfileService} from '../../../shared/services/profile-service';
import {MessageService} from 'primeng/components/common/messageservice';
import {Location} from '@angular/common';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TitleService} from '../../../shared/services/title-service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './user-profile-edit.component.html',
  styleUrls: ['./user-profile-edit.component.scss']
})
export class UserProfileEditComponent implements OnInit, OnDestroy {
  profile: {
    username: string;
    first_name: string;
    last_name: string;
    first_name_en: string;
    last_name_en: string;
    job_position: string;
    role_name: string;
    department_name: string;
    email: string;
    id: string | number;
    photo: string;
    phone_number_1?: string;
    phone_number_2?: string;
    nickname?: string;
  };

  isLoading = false;
  lang_short_code = 'en';
  firstName = '';
  lastName = '';
  profilePic: File;

  newPhoto = undefined;

  subscriptions = new Subscription();

  constructor(
    private userProfileService: UserProfileService,
    private messageService: MessageService,
    private location: Location,
    private swal: SweetAlertService,
    public profileService: ProfileService,
    private router: Router,
    private translate: TranslateService,
    private titleService: TitleService
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle(this.translate.instant('PROFILE.EDIT-PROFILE'));
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.titleService.setTitle(this.translate.instant('PROFILE.EDIT-PROFILE'));
    });
    this.subscriptions.add(translateSub);
    this.loadInputData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadInputData() {
    const id = localStorage.getItem('profile_id');
    this.userProfileService.getProfileDataById(id).subscribe((data: any) => {
      this.profile = data;
    });
  }

  editProfile() {
    const fd = new FormData();
    if (this.profilePic) {
      fd.append('photo', this.profilePic);
    }
    if (!this.profile.first_name) {
      this.swal.toastNotification({type: 'warning', content: 'Need to add First Name'});
    } else if (!this.profile.last_name) {
      this.swal.toastNotification({type: 'warning', content: 'Need to add Last Name'});
    } else {
      this.isLoading = true;
      // fd.append('last_name_en', this.profile.last_name_en || '');
      // fd.append('first_name_en', this.profile.first_name_en || '');
      fd.append('first_name', this.profile.first_name || '');
      fd.append('last_name', this.profile.last_name || '');
      fd.append('nickname', this.profile.nickname || '');
      fd.append('phone_number_1', this.profile.phone_number_1 || '');
      fd.append('user_email', this.profile.email || '');
      this.userProfileService.updateProfile(this.profile.id, fd).subscribe(
        data => {
          // this.userProfileService.setUserProfileNameEng(data.first_name_en, data.last_name_en);
          // this.userProfileService.setUserProfileNameThai(data.first_name, data.last_name);
          this.lang_short_code = localStorage.getItem('lang');
          // this.userProfileService.switchUserProfileNameLang(this.lang_short_code);
          this.userProfileService.setUserName(data.first_name);
          this.userProfileService.setUserFullName(data.full_name);
          this.userProfileService.setUserJobPosition(data.job_position);
          this.userProfileService.setUserProfileImage(data.photo);
          this.profileService.loadInputData();
          this.router.navigate(['/userProfile', 'detail']);
          this.isLoading = false;
        },
        response => {
          if (response.error && response.error.en) {
            this.swal.toastNotification({type: 'error', content: response.error[this.translate.currentLang].join('\n')});
          } else {
            this.swal.toastNotification({type: 'error', content: 'Unable to update profile'});
          }
          this.isLoading = false;
        }
      );
    }
  }

  getImage(image) {
    this.profilePic = image;
  }

  uploadProfile(fileInput: any) {
    this.profilePic = fileInput.target.files[0];
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.newPhoto = e.target.result;
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  back() {
    this.location.back();
  }
}
