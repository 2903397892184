import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SetThemeDirective} from './directive/set-theme.directive';
import {NumberFormatPipe} from './pipes/number-format.pipe';


@NgModule({
  declarations: [
    SetThemeDirective,
    NumberFormatPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SetThemeDirective,
    NumberFormatPipe,
  ]
})
export class SharedModule {
}
