import { environment } from '../../../environments/environment';

const HOST_URL = environment.host_url;
export const BASE_URL = HOST_URL + '/memo';
const HR_URL = HOST_URL + '/hr';

export const GATEWAY_URL = {
  user: HOST_URL + '/auth/user/',
  token: HOST_URL + '/auth/token/',
  refresh_token: HOST_URL + '/memo/api/token-refresh/'
};

export const ADFS_URL = {
  login: HOST_URL + '/oauth2/login?next=/adfs-callback/',
  logout: HOST_URL + '/oauth2/logout'
};

// The URL should be sorted alphabetically
export const API_URL = {
  approve_memo: BASE_URL + '/api/approval-memo/',
  guest_approve_memo: BASE_URL + '/api/guest-approval-memo/',
  blog: BASE_URL + '/api/blog/',
  blog_attachment: BASE_URL + '/api/blog-attachments/',
  blog_category: BASE_URL + '/api/blog-categories/',
  blog_content: BASE_URL + '/api/blog-contents/',
  budget_wbs: BASE_URL + '/api/wbs/',
  convert_token: BASE_URL + '/auth/custom-convert-token/',
  cost_centre: BASE_URL + '/api/cost-centre/',
  departments: BASE_URL + '/api/departments/',
  department_members: BASE_URL + '/api/department-members/',
  department_by_group: BASE_URL + '/api/department-by-group/',
  download_template: BASE_URL + '/api/members-template/',
  exports_meeting: BASE_URL + '/api/exports-meeting/',
  groups: BASE_URL + '/api/groups/',
  reset_password: BASE_URL + '/api/password_reset/',
  reset_password_validate: BASE_URL + '/api/password_reset/validate_token/',
  reset_password_confirm: BASE_URL + '/api/password_reset/confirm/',
  import: BASE_URL + '/api/members-import/',
  import_logs: BASE_URL + '/api/members-import-logs/',
  job_member: BASE_URL + '/api/job-members/',
  job_position: BASE_URL + '/api/job-positions/',
  loa_infos: BASE_URL + '/api/loa-infos/',
  login: BASE_URL + '/api/token-auth/',
  login_from_email_approval: BASE_URL + '/api/token-auth-from-email/',
  login_via_fa: BASE_URL + '/api/login-via-fa/',
  me: BASE_URL + '/api/me/',
  me_set_password: BASE_URL + '/api/me/set_password/',
  meeting: BASE_URL + '/api/meetings/',
  meetings_agenda: BASE_URL + '/api/meetings-agenda/',
  meetings_agenda_action: BASE_URL + '/api/meetings-agenda-action/',
  meetings_agenda_question: BASE_URL + '/api/meetings-agenda-question/',
  meetings_agenda_question_attachment: BASE_URL + '/api/meetings-agenda-question-attachment/',
  meetings_agenda_question_answer: BASE_URL + '/api/meetings-agenda-question-answer/',
  meetings_agenda_question_image: BASE_URL + '/api/meetings-agenda-question-image/',
  meetings_attachment: BASE_URL + '/api/meetings-attachment/',
  meetings_document: BASE_URL + '/api/meetings-document/',
  meetings_guest: BASE_URL + '/api/meetings-guest/',
  meeting_info: BASE_URL + '/api/meeting-info/',
  meeting_members: BASE_URL + '/api/meetings-member/',
  meeting_members_send_email: BASE_URL + '/api/meetings-member/send_email/',
  meeting_preview: BASE_URL + '/api/preview-meetings/',
  meeting_summary: BASE_URL + '/api/meetings-summary/',
  memo_attachment: BASE_URL + '/api/memos-attachment/',
  memo_bulk_attachment: BASE_URL + '/api/memos-bulk-attachments/',
  memo_comment: BASE_URL + '/api/memos-comment/',
  memo_mentions: BASE_URL + '/api/memos-comment/get_mention_profiles/',
  memo_draft: BASE_URL + '/api/memos-draft/',
  memo_list_members: BASE_URL + '/api/list-memo-numbers/',
  memo_logo: BASE_URL + '/api/memos-logo/',
  memo_number_verify: BASE_URL + '/api/memos-verify-memo-number/',
  memo_organization: BASE_URL + '/api/memos-organization/',
  memo_preview: BASE_URL + '/api/memos-preview/',
  memo_publish: BASE_URL + '/api/memos-publish/',
  memo_read: BASE_URL + '/api/memos-read/',
  memo_real: BASE_URL + '/api/memos-real/',
  preview_memo: BASE_URL + '/api/render_pdf/',
  memo_send_email: BASE_URL + '/api/memos-send-email/',
  memo_resend_email: BASE_URL + '/api/memo-resend-email/',
  memo_template: BASE_URL + '/api/memos-template/',
  memo_link: BASE_URL + '/api/memos-link/',
  memos_history: BASE_URL + '/api/memos-history/',
  memo_pdf2image: BASE_URL + '/api/pdf2image/',
  memo_special_type: BASE_URL + '/api/memos-special-type/',
  member_status: BASE_URL + '/api/members-status/',
  members: BASE_URL + '/api/members/',
  payments: BASE_URL + '/api/members-payment/',
  profiles: BASE_URL + '/api/profiles/',
  transfer_profile: BASE_URL + '/api/transfer-profile/',
  profile_user_active: BASE_URL + '/api/profile-user-activate/',
  profiles_name: BASE_URL + '/api/profiles/all/',
  profiles_files: BASE_URL + '/api/profiles-files/',
  profiles_guest: BASE_URL + '/api/profiles-guest/',
  project_draft_template: BASE_URL + '/api/projects-template/',
  project_draft_template_item: BASE_URL + '/api/projects-template-item/',
  project_mgmt: BASE_URL + '/api/project-mgmt/',
  project_mgmt_activity: BASE_URL + '/api/project-mgmt-activity/',
  project_mgmt_activity_stream: BASE_URL + '/api/project-mgmt-activity-stream/',
  project_mgmt_task: BASE_URL + '/api/project-mgmt-task/',
  project_mgmt_task_dashboard: BASE_URL + '/api/project-mgmt-task-dashboard/',
  project_mgmt_task_read: BASE_URL + '/api/project-mgmt-task-read/',
  project_mgmt_tasks: BASE_URL + '/api/project-mgmt-tasks/',
  project_mgmt_group: BASE_URL + '/api/project-mgmt-group/',
  project_mgmt_task_comment: BASE_URL + '/api/project-mgmt-task-comment/',
  project_mgmt_task_subcomment: BASE_URL + '/api/project-mgmt-task-subcomment/',
  project_mgmt_task_attachment: BASE_URL + '/api/project-mgmt-task-attachment/',
  project_mgmt_task_attachments: BASE_URL + '/api/project-mgmt-task-attachments/',
  project_mgmts: BASE_URL + '/api/project-mgmts/',
  projects_template_item_tree: BASE_URL + '/api/projects-template-item-tree/',
  register_new: BASE_URL + '/api/registers/',
  register_gmail: BASE_URL + '/api/registers/google/',
  registered_user_export: BASE_URL + '/api/registered_user_export/',
  render_pdf: BASE_URL + '/api/render_pdf/',
  reply: BASE_URL + '/guest/retrieve_training_register_info/',
  training: BASE_URL + '/api/training/',
  training_logo: BASE_URL + '/api/training_logo/',
  training_file: BASE_URL + '/api/training_material/',
  training_guest: BASE_URL + '/api/training_guest/',
  training_register: BASE_URL + '/api/training_register/',
  training_register_attachment: BASE_URL + '/api/training_register_attachment/',
  training_register_admin: BASE_URL + '/api/training_register_admin/',
  training_register_payment: BASE_URL + '/api/training_register_payment/',
  training_register_accounting: BASE_URL + '/api/training_register_accounting/',
  training_templates: BASE_URL + '/api/training_templates/',
  training_form: BASE_URL + '/api/training-form/',
  training_app: BASE_URL + '/api/training-applicant/',
  training_app_detail: BASE_URL + '/api/training-applicant-detail/',
  training_event_fa: BASE_URL + '/api/training-applicant-event/fa/',
  training_event_applicant: BASE_URL + '/api/training-applicant-event/applicant/',
  training_event_list: BASE_URL + '/api/training-applicant/events/',
  training_app_name_list: BASE_URL + '/api/training-applicant/approved_namelist/',
  training_upload: BASE_URL + '/api/training-upload-document/',
  training_upload_type: BASE_URL + '/api/training-upload-document-type/',
  training_type: BASE_URL + '/api/training-type/',
  user_department: BASE_URL + '/api/departments/',
  companies: BASE_URL + '/api/companies/',
  calendar_dashboard: BASE_URL + '/api/dashboard-calendar/',
  calendar_dashboard_event: BASE_URL + '/api/dashboard-calendar-event/',
  calendar_dashboard_booking: BASE_URL + '/api/dashboard-calendar-booking/',
  calendar_dashboard_affair: BASE_URL + '/api/dashboard-calendar-affair/',
  training_public: BASE_URL + '/api/training-public/',
  theme_color_list: BASE_URL + '/api/theme-config/',
  theme_active: BASE_URL + '/api/theme-config/active/',
  configuration: BASE_URL + '/api/configurations/',
  bulk_update: BASE_URL + '/api/configurations/bulk-update/',
  dashboard_storage: BASE_URL + '/api/storage-report/',
  open_otp: BASE_URL + '/api/open-otp/',
  offices: BASE_URL + '/api/offices/',
  close_otp: BASE_URL + '/api/close-otp/',
  verify_otp: BASE_URL + '/api/verify-otp/',
  news_feed: BASE_URL + '/api/news-feed/',
  news_feed_comment: BASE_URL + '/api/news-feed-comment/',
  news_feed_post: BASE_URL + '/api/news-feed-post/',
  news_all_post: BASE_URL + '/api/news-feed-post/ ',
  news_related_post: BASE_URL + '/api/news-feed-post/related-post/ ',
  news_feed_get_sticky_note: BASE_URL + '/api/get-notes/',
  news_feed_update_sticky_note: BASE_URL + '/api/update-notes/',
  notification: BASE_URL + '/api/notifications/',
  announcement_get: BASE_URL + '/api/announcement/',
  link_line_codium: BASE_URL + '/api/line/codium/',
  link_line_user: BASE_URL + '/api/line/user/',
  hide_announcement: BASE_URL + '/api/hide-announcement/',
  operation_log: BASE_URL + '/api/operation-log/',
  master_item: BASE_URL + '/api/master-data-item-detail/',
  master_gl: BASE_URL + '/api/master-data-gl-setting/',
  setting_email: BASE_URL + '/api/mail-notifications/',
  delegate_permission: BASE_URL + '/api/delegate-permission/',
  wbs_year: BASE_URL + '/api/wbs-years/',
  wbs_budget_dashboard: BASE_URL + '/api/wbs-budget-dashboard/',
  is_memo_number_exist: BASE_URL + '/api/is-memonumber-exist/',
  search_employee: BASE_URL + '/api/search-employee/',
  central_department: BASE_URL + '/api/central-department/',
  central_department_choice: BASE_URL + '/api/central-department/choice/',
};


export const API_CONSTANT = {
  login: HR_URL + '/api-token-auth/',
  login_via_fa: HR_URL + '/api/login-via-fa/',
  register: HR_URL + '/api/register/',
  work_types: HR_URL + '/api/work_types/',
  users: HR_URL + '/api/user_profile/',
  add_text_field: HR_URL + '/api/add_text_field/',
  request_lists: HR_URL + '/api/request_lists/',
  request_types: HR_URL + '/api/request_types/',
  requests: HR_URL + '/api/requests/',
  timesheet_lists: HR_URL + '/api/timesheet_lists/',
  timesheet_lists_by_department: HR_URL + '/api/timesheet_lists_by_department/',
  timesheet_logs: HR_URL + '/api/timesheet_logs/',
  holidays: HR_URL + '/api/holidays/',
  holiday_lists: HR_URL + '/api/holiday_table/',
  overtime: HR_URL + '/api/overtime/',
  overtime_lists: HR_URL + '/api/overtime_lists/',
  leave: HR_URL + '/api/leaves/',
  me: HR_URL + '/api/me/',
  pending_request: HR_URL + '/api/latest_requests/',
  latest_activity: HR_URL + '/api/latest_events/',
  fiscal_year: HR_URL + '/api/fiscal_year/',
  business_types: HR_URL + '/api/business_types/',
  gps_location: HR_URL + '/api/gps_locations/',
  job_position: HR_URL + '/api/job_positions/',
  announcement: HR_URL + '/api/announcements/',
  set_password: HR_URL + '/api/set_password/',
  department: HR_URL + '/api/departments/',
  set_permission: HR_URL + '/api/set_permission/'
};
