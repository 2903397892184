import {ActivatedRoute} from '@angular/router';
import {Component, OnInit, Output, EventEmitter, Input, OnDestroy} from '@angular/core';
import {LoaService} from '../loa.service';
import {NotificationService} from '../../../../shared/services/notification.service';
import {HttpParams} from '@angular/common/http';
import {MemoService} from 'src/app/modules/memos/memo/memo.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import * as _ from 'lodash';
import {ThemeService} from '../../../../shared/services/theme.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TitleService} from '../../../../shared/services/title-service';

@Component({
  selector: 'app-general-list',
  templateUrl: './general-list.component.html',
  styleUrls: ['./general-list.component.scss']
})
export class GeneralListComponent implements OnInit, OnDestroy {
  option = 1;
  loaList = [];
  isOpenList: { is_open: boolean; name: string; row_index: number }[] = [
    {is_open: false, name: 'expenses claim', row_index: 0}, // EXPENSES_CLAIM
    {is_open: false, name: 'budget request', row_index: 5}, // BUDGET_TRANSFER
    {is_open: false, name: 'budget transfer', row_index: 8}, // BUDGET_REQUEST
    {is_open: false, name: 'budget asset', row_index: 13}, // BUDGET_ASSET
    {is_open: false, name: 'expenses claim (BGP)', row_index: 16}, // EXPENSES_CLAIM BGP
  ];
  dict_set = {};
  showResults = false;
  currentPage = 1;
  count = 0;
  isList = true;
  isEdit = false;
  isCreate = false;
  selectedLOA;
  departmentList = [];
  with_loa = true;
  rowGroupMetadata: any;
  checkIsSmall: boolean;
  companyList: { id: number; code_name: string }[] = [];
  onSelected = '';
  departmentsModeList: { label: string; value: string }[] = [
    {label: 'By Department', value: 'by-department'},
    {label: 'By Budget', value: 'by-budget'}
  ];
  styleScrollLoa = 'hidden';
  selectedDepartmentsMode: string = this.departmentsModeList[0].value;
  isLoading = true;
  loaWBSList = [];

  debouncer = new Subject<string>();

  subscriptions = new Subscription();

  @Output() closed = new EventEmitter();
  @Output() backPage = new EventEmitter();
  @Input() type: string;
  @Input() detail;
  @Input() selectedCompanyId: number;

  constructor(
    private loaService: LoaService,
    private activatedRoute: ActivatedRoute,
    private notification: NotificationService,
    private swal: SweetAlertService,
    private apiService: MemoService,
    private theme: ThemeService,
    private translate: TranslateService,
    private titleService: TitleService
  ) {
    this.debouncer.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe((event) => {
    });
  }

  ngOnInit() {
    this.titleService.setTitle(this.translate.instant('LOA.MANAGE-LINE'));
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.titleService.setTitle(this.translate.instant('LOA.MANAGE-LINE'));
    });
    this.subscriptions.add(translateSub);
    this.checkIsSmall = this.theme.isMobile();
    this.checkType();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  closeToggle() {
    this.closed.emit();
  }

  selectOption(option) {
    this.option = option;
  }

  loadLOAList() {
    this.isLoading = true;
    this.showResults = false;
    const params = new HttpParams()
      .set('group_id', (this.detail.id || '').toString());
    this.apiService.getDepartmentByGroupList(params).subscribe(
      (data: any) => {
        this.loaList = [];
        _.forEach(data, (obj) => {
          this.loaList.push({
            name: obj.name,
            value: obj.id,
            loa: obj.loa,
            group_info: obj.group_info,
            // id: obj.id
          });
        });
        this.checkSameDepartment();
        this.count = data.count;
        // this.loaList = data.results;
        this.updateRowGroupMetaData();
        this.showResults = true;
        this.isLoading = false;
      },
      error => this.swal.toastNotification({type: 'error', content: 'Unable to load loa list'})
    );
  }

  setDefault() {
    this.loaList = [
      // {value: 0, name: '0-3000', is_open: false, loa: []},
      // {value: 1, name: '3000-10000', is_open: false, loa: []},
      // {value: 2, name: '10000-100000', is_open: false, loa: []},
      // {value: 3, name: '100000-200000', is_open: false, loa: []},
      // {value: 4, name: '>200000', is_open: false, loa: []},
      // {value: 5, name: '0-3000000', is_open: false, loa: []},
      // {value: 6, name: '3000000-5000000', is_open: false, loa: []},
      // {value: 7, name: '5000000-10000000', is_open: false, loa: []},
      // {value: 8, name: '0-50000', is_open: false, loa: []},
      // {value: 9, name: '50000-100000', is_open: false, loa: []},
      // {value: 10, name: '100000-500000', is_open: false, loa: []},
      // {value: 11, name: '500000-1000000', is_open: false, loa: []},
      // {value: 12, name: '>1000000', is_open: false, loa: []},
      // {value: 13, name: '0-500000', is_open: false, loa: []},
      // {value: 14, name: '500000-5000000', is_open: false, loa: []},
      // {value: 15, name: '>5000000', is_open: false, loa: []},
      // {value: 16, name: '0-5000', is_open: false, loa: []},
      // {value: 17, name: '5000-10000', is_open: false, loa: []},
      // {value: 18, name: '10000-50000', is_open: false, loa: []},
      // {value: 19, name: '>50000', is_open: false, loa: []},
    ];
  }
  loadCostcentreLOAList() {
    this.dict_set = {};
    this.isLoading = true;
    this.showResults = false;
    this.setDefault();
    const params = new HttpParams()
      .set('costcentre_id', (this.detail.id || '').toString());
    this.apiService.getCostCentreList(params).subscribe(
      (data: any) => {
        let i = 0;
        data.results[0].loa.forEach(item => {
          this.loaList[i] = {
            value: i,
            name: item.name,
            is_open: false,
            loa: []
          };

          if (!(item.budget_type in this.dict_set)) {
            item.budget_type = (item.budget_type || '').toLowerCase();
            this.dict_set[item.budget_type] = 1;
            const index = (Object.keys(this.dict_set).length) - 1;
            try {
              this.isOpenList[index]['name'] = item.budget_type;
            } catch (e) {}
          } else {
            this.dict_set[item.budget_type] += 1;
          }
          this.loaList[i].loa.push(item);
          i += 1;
        });

        let j = 1;
        let total = 0;
        for (const [key, value] of Object.entries(this.dict_set)) {
          total += Number(value);
          try {
            this.isOpenList[j]['row_index'] = total;
          } catch (e) {}
          j += 1;
        }

        this.showResults = true;
        this.isLoading = false;
      },
      error => this.swal.toastNotification({type: 'error', content: 'Unable to load loa list'})
    );
  }

  checkSameDepartment() {
    const same_department = [];
    // find department same
    this.loaList.forEach(item => {
      const count = this.loaList.filter(value => value.name === item.name).length;
      if (count > 1) {
        same_department.push(item.name);
      }
    });

    // get unique department
    const same_department_list = same_department.filter((value, index, self) => self.indexOf(value) === index);

    // filter check department same with same_department_list or not
    this.loaList = this.loaList.map(item => {
      if (same_department_list.some(value => value === item.name)) {
        item.name += item.group_info ? ` (${item.group_info.name})` : '';
      }
      return item;
    });
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.loaList) {
      for (let i = 0; i < this.loaList.length; i++) {
        const rowData = this.loaList[i];
        const value = rowData.value;
        if (i === 0) {
          this.rowGroupMetadata[value] = {index: 0, size: 1};
        } else {
          const previousRowData = this.loaList[i - 1];
          const previousRowGroup = previousRowData.value;
          if (value === previousRowGroup) {
            this.rowGroupMetadata[value].size++;
          } else {
            this.rowGroupMetadata[value] = {index: i, size: 1};
          }
        }
      }
    }
  }

  openDetail(index: number) {
    this.isOpenList[index].is_open = !this.isOpenList[index].is_open;
    this.loaList = this.loaList.map(item => {
      if (item.value >= 0 && item.value <= this.isOpenList[1]['row_index'] - 1) {
        item.is_open = this.isOpenList[0].is_open;
      } else if (item.value >= this.isOpenList[1]['row_index'] && item.value <= this.isOpenList[2]['row_index'] - 1) {
        item.is_open = this.isOpenList[1].is_open;
      } else if (item.value >= this.isOpenList[2]['row_index'] && item.value <= this.isOpenList[3]['row_index'] - 1) {
        item.is_open = this.isOpenList[2].is_open;
      } else if (item.value >= this.isOpenList[3]['row_index'] && item.value <= this.isOpenList[4]['row_index'] - 1) {
        item.is_open = this.isOpenList[3].is_open;
      } else if (item.value >= this.isOpenList[4]['row_index'] && item.value <= this.isOpenList[5]['row_index'] - 1) {
        item.is_open = this.isOpenList[4].is_open;
      }
      return item;
    });
  }

  createLOA(loa) {
    this.styleScrollLoa = 'hidden';
    this.isList = true;
    this.isCreate = false;
    this.checkType();
  }

  changePage() {
    this.loadLOAList();
  }

  deleteLOA(e) {
    this.loaService
      .removeLOA(e.data.id)
      .subscribe(
        () => this.checkType(),
        error => this.swal.toastNotification({type: 'error', content: 'Unable to load loa list'})
      );
  }

  clickLOA(loa) {
    this.styleScrollLoa = 'scroll';
    this.selectedLOA = null;
    setTimeout(() => {
      this.selectedLOA = loa;
      this.isEdit = true;
      this.isList = false;
      this.isCreate = false;
    }, 10);
  }

  createNew(type: string) {
    this.isLoading = false;
    this.styleScrollLoa = 'scroll';
    this.isEdit = false;
    this.isList = false;
    this.isCreate = true;
  }

  updateLOA(loa) {
    this.styleScrollLoa = 'hidden';
    const loaIndex = this.loaList.findIndex(obj => obj.id === loa.id);
    this.loaList[loaIndex] = loa;
    this.closeLOA();
  }

  closeLOA(event?) {
    this.styleScrollLoa = 'hidden';
    this.isList = true;
    this.isEdit = false;
    this.isCreate = false;
    this.isOpenList.map(item => {
      item.is_open = false;
      }
    );
    this.checkType();
  }

  filterCompany(event) {
    this.checkType();
  }

  onSelectMode(event) {
    this.onSelected = event.value;
    this.checkType();
  }

  // Check type is department or budget (wbs)
  checkType() {
    if (this.type === 'by-department') {
      this.loadLOAList();
    } else if (this.type === 'by-budget') {
      this.loadCostcentreLOAList();
    }
  }

  back() {
    this.backPage.emit();
  }
}
