import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import * as _ from 'lodash';
import {Router} from '@angular/router';

@Injectable()
export class HttpClientService {

  headers;

  constructor(private http: HttpClient,
              private router: Router) {
    this.setHeader();
  }

  checkError(apiFunction: Observable<any>, data): any {
    return new Observable((observer) => {
      apiFunction.subscribe((res) => {
        observer.next(res);
        observer.complete();
      }, (err) => {
        if (
          _.get(err, 'error.detail') === 'Signature has expired.' ||
          _.get(err, 'error.detail') === 'Error decoding signature.' ||
          _.get(err, 'error.detail') === 'Invalid signature.' ||
          _.get(err, 'error.detail') === 'User account is disabled.' ||
          _.get(err, 'error.detail') === 'บัญชีผู้ใช้ถูกระงับ'
        ) {
          localStorage.clear();
          this.router.navigate(['/login']);
        }

        const trackJs = window['trackJs'];
        if (trackJs != null && err != null && err.error != null) {
          trackJs.track({
            'response': err.error,
            'requestBody': data
          });
        }

        observer.error(err);
      });
    });
  }

  createDownloadFile(blob, filename) {
    // for IE10+
    if (typeof navigator !== 'undefined' && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const element = document.createElement('a');
      document.body.appendChild(element);

      const url = window.URL.createObjectURL(blob);
      element.setAttribute('href', url);
      element.setAttribute('download', filename);

      element.style.display = 'none';
      element.click();

      document.body.removeChild(element);
    }
  }

  setHeader() {
    if (localStorage.getItem('currentUser')) {
      this.headers = new HttpHeaders()
        .set('Authorization', 'Bearer ' + localStorage.getItem('currentUser'));
    }
  }

  get(url, params?) {
    return this.checkError(this.http.get(url, {params: params, headers: this.headers}), null);
  }

  getBlob(url, params?) {
    return this.checkError(this.http.get(url, {params: params, headers: this.headers, responseType: 'blob'}), null);
  }

  post(url, data) {
    return this.checkError(this.http.post(url, data, {headers: this.headers}), data);
  }

  pdfPost(url, data) {
    return this.checkError(this.http.post(url, data, {headers: this.headers, responseType: 'arraybuffer'}), data);
  }

  put(url, data, params?) {
    return this.checkError(this.http.put(url, data, {params: params, headers: this.headers}), data);
  }

  patch(url, data) {
    return this.checkError(this.http.patch(url, data, {headers: this.headers}), data);
  }

  delete(url) {
    return this.checkError(this.http.delete(url, {headers: this.headers}), null);
  }

  deleteWithPayload(url, payload) {
    return this.checkError(this.http.request('delete', url, {headers: this.headers, body: payload}), payload);
  }

  download(url, params?) {
    return this.checkError(this.http.get(url, {params: params, headers: this.headers, responseType: 'blob', observe: 'response'}), null);
  }

  printFile(url, params?) {
    return this.checkError(this.http.get(url, {params: params, headers: this.headers, responseType: 'blob', observe: 'response'}), null);
  }

  // Check Mobile Operating system
  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/.test(userAgent)) {
      return 'Android';
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return null;
  }

  isMobileOperationSystem() {
    return this.getMobileOperatingSystem() != null;
  }

  isPDFUrl(path) {
    if (path == null) {
      return false;
    }
    if (typeof path === 'object') {
      path = path.filename;
    }
    const parts = path.split('.');
    if (parts == null || parts.length <= 1) {
      return false;
    }

    const extension = parts[1];
    return extension != null && extension.toLowerCase() === 'pdf';
  }
}
