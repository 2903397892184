import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-preview-template',
  templateUrl: './preview-template.component.html',
  styleUrls: ['./preview-template.component.scss']
})
export class PreviewTemplateComponent implements OnInit {

  // mainLogo = '../../../assets/images/logo-cloudhm.png';
  mainLogo = '/assets/images/LOGO_template.png';
  @Input() image: any;
  constructor() { }
  ngOnInit() {
  }
}
