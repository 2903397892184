import {Component, OnInit} from '@angular/core';
import {ConfigurationService} from '../../../shared/services/configuration.service';
import {NotificationService} from '../../../shared/services/notification.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  variables;
  loading = true;

  constructor(private configurationService: ConfigurationService, private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.loadConfigurations();
  }

  loadConfigurations() {
    this.configurationService.fetch().subscribe((result: any) => {
      this.variables = result.results;
      this.loading = false;
    });
  }

  saveConfigurations() {
    const configurations = [];
    this.variables.forEach((item) => {
      item.variables.forEach((itemVariable) => {
        configurations.push({id: itemVariable.id, value: itemVariable.value});
      });
    });
    this.configurationService.save(configurations).subscribe((result: any) => {
      this.notificationService.showSuccess();
    }, (error) => {
      this.notificationService.showSpecificError('Something went wrong. Please try again.');
    });
  }

}
