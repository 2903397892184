import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

export class NgbThaiDateParserFormatter extends NgbDateParserFormatter {
  constructor() {
      super();
  }
  format(date: NgbDateStruct, forSendingToBackend= false): string {
      if (date == null) {
          return '';
      }
      const d = moment({ year: date.year,
                       month: date.month - 1,
                       date: date.day });

      if (forSendingToBackend) {
        return d.isValid() ? d.format('YYYY-MM-DD') : '';
      } else {
        return d.isValid() ? d.format('DD/MM/YYYY') : '';
      }
  }

  parse(value: string): NgbDateStruct {
      if (!value) {
          return null;
      }

      let d = null;

      if (value.includes('-')) {
        d = moment(value, 'YYYY-MM-DD');
      } else {
        d = moment(value, 'DD/MM/YYYY');
      }

      return d.isValid() ? { year: d.year(),
                             month: d.month() + 1,
                             day: d.date() } : null;
  }
}
