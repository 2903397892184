import {Component, OnInit} from '@angular/core';
import {ThemeService} from '../../../../shared/services/theme.service';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {NotificationService} from '../../../../shared/services/notification.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';

@Component({
  selector: 'app-theme-list',
  templateUrl: './theme-list.component.html',
  styleUrls: ['./theme-list.component.scss']
})
export class ThemeListComponent implements OnInit {
  themeList = [];
  isCreate = false;
  isList = true;
  modal: NgbModalRef;
  idTheme: number;
  isLoading = false;
  themePage = 'themeList';
  themeDetail = {};
  public themes;

  constructor(private themeService: ThemeService,
              private sanitizer: DomSanitizer,
              private notificationService: NotificationService,
              private modalService: NgbModal,
              private swal: SweetAlertService
  ) {
  }

  ngOnInit() {
    this.fetchData();
  }

  public fetchData() {
    this.isLoading = true;
    this.themeService.fetchData().subscribe((response) => {
      this.themeList = response.results;
      this.isLoading = false;
    });
  }

  sanitizeStyle(unsafeStyle: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(unsafeStyle);
  }

  setActive(id: number) {
    const theme = JSON.parse(JSON.stringify(this.themes.find((response) => response.id === id)));
    theme.active = true;
    delete theme.small_logo;
    delete theme.long_logo;
    delete theme.login_background;
    this.themeService.updateTheme(theme, theme.id).subscribe(
      (response) => this.themeService.resetStyle(document), (error) => console.log(error));
  }

  deleteTheme(id) {
    this.themeService.deleteTheme(id.data).subscribe((response) => {
        this.swal.toastNotification({type: 'success'});
        this.fetchData();
      }, error => {
        this.swal.toastNotification({type: 'error'});
      });
  }

  changePage(page, theme?) {
    this.themePage = page;
    this.themeDetail = theme;
  }

  refreshPage(event, page) {
    this.themePage = page;
    this.fetchData();
    if (event) {
      if (event.active) {
        this.getTheme();
      }
    }
  }

  openModalApply(content, idTheme) {
    this.idTheme = idTheme;
    this.modal = this.modalService.open(content, idTheme);
  }

  applyTheme(activeTheme, idTheme) {
    const data = {
      active: activeTheme
    };
    this.themeService.updateTheme(data, idTheme).subscribe(value => {
      this.modal.close();
      this.swal.toastNotification({type: 'success'});
      this.getTheme();
      this.fetchData();
    }, error => {
      this.swal.toastNotification({type: 'error'});
    });
  }

  getTheme() {
    this.themeService.getTheme().subscribe(theme => {
      this.themeService.setThemeList(theme);
    });
  }


}
