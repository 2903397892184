import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UserService, SearchEmployeeResponse, EmployeeProfile} from '../../user/user.service';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {MemoService} from 'src/app/modules/memos/memo/memo.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TitleService} from '../../../../shared/services/title-service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import {Observable, Subscription} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {ThemeList, ThemeService} from '../../../../shared/services/theme.service';
import {map} from 'rxjs/operators';

export interface AddUserSetting {
  role: number;
  is_view_all_memo: boolean;
  is_view_all_budget: boolean;
  is_view_all_company: boolean;
  company: number;
  office: number;
  group: number;
  department: number;
}

@Component({
  selector: 'app-profile-create',
  templateUrl: './profile-create.component.html',
  styleUrls: ['./profile-create.component.scss']
})
export class ProfileCreateComponent implements OnInit, OnDestroy {
  searchFirstName = '';
  searchLastName = '';
  // firstNameEN = '';
  // lastNameEN = '';
  // nameTitle = '';
  // nameTitleEN = '';
  jobPosition = '';
  department = [];
  username = '';
  role = 1;
  searchEmail = '';
  password1 = '';
  password2 = '';
  strength = 0;
  departmentLabel = '';
  accessible_until = '';
  phoneNumberOne = '';
  departmentList = [];
  keepdepartment: string;
  savesign: any;
  viewAllMemo = [{
    name: 'View all memo',
    value: true
  },
    {
      name: 'View only selected department',
      value: false
    }];
  is_view_all_memo = false;
  roleList = [
    {
      name: 'General',
      id: 1
    },
    {
      name: 'IT',
      id: 4
    },
  ];
  loadimg = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  file_input: any = '';
  blob_output: any = '';
  errorMsg: any = '';
  currentSignature: any;
  signature: any;
  private notification: any;
  modal: NgbModalRef;

  checkLowerCase = false;
  checkUpperCase = false;
  checkNumber = false;
  checkSymbols = false;
  checkLength = false;
  isMasterAdmin = localStorage.getItem('role_name') === 'Master Admin';
  themeList: ThemeList;

  subscriptions = new Subscription();

  searchEmployeeResults: EmployeeProfile[] = [];
  isLoading = false;
  isPendingAddUser = false;
  selectedEmployee: EmployeeProfile;
  addUserSetting: AddUserSetting;

  viewAllBudget = [
    {
      name: 'View all budget dashboard',
      value: true
    },
    {
      name: 'Not view budget dashboard',
      value: false
    }
  ];
  viewAllCompany = [
    {
      name: 'View all company budget dashboard',
      value: true
    },
    {
      name: 'Not view all company budget dashboard',
      value: false
    }
  ];
  companies = [];
  offices = [];
  groups = [];
  departments = [];

  @Output() closeAddUser = new EventEmitter<boolean>();

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private swal: SweetAlertService,
    private memoService: MemoService,
    private router: Router,
    private translate: TranslateService,
    private titleService: TitleService,
    private modalService: NgbModal,
    private location: Location,
    private themeService: ThemeService,
  ) {
  }

  ngOnInit() {
    this.addUserSetting = {
      role: 1,
      is_view_all_memo: false,
      is_view_all_budget: false,
      is_view_all_company: false,
      company: null,
      office: null,
      group: null,
      department: null
    };
    this.getCompany();
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translatePage();
    });

    const themeSub = this.themeService.data.subscribe(theme => {
      this.themeList = theme;
    });

    this.subscriptions.add(translateSub).add(themeSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  searchEmployee() {
    this.isLoading = true;
    const params = new HttpParams()
      .set('first_name', this.searchFirstName)
      .set('last_name', this.searchLastName)
      .set('email', this.searchEmail);
    this.userService.searchEmployee(params).subscribe(
      (res: SearchEmployeeResponse) => {
        this.searchEmployeeResults = res.result;
        this.isLoading = false;
      },
      error => {
        const res: SearchEmployeeResponse = error.error;
        this.swal.toastNotification({ type: 'error', content: res.error });
        this.isLoading = false;
      }
    );
  }

  saveSignature() {
    if (this.file_input && this.blob_output) {
      const filename =
        this.file_input.name.substring(0, this.file_input.name.lastIndexOf('.')) + '.png';
      this.signature = new File([this.blob_output], filename, {type: this.blob_output.type});
      this.savesign = this.croppedImage;
      // this.updateSignature();
    }
    this.modalService.dismissAll();
  }

  back() {
    this.closeAddUser.emit(true);
  }

  backToSearchPage() {
    this.clearAddUserData();
  }

  onSelectedEmployee(emp: EmployeeProfile) {
    this.selectedEmployee = emp;
  }

  translatePage() {
    this.departmentLabel = this.translate.instant('CREATE-USER.CHOOSE');
    this.viewAllMemo = [
      {name: this.translate.instant('CREATE-USER.VIEW-ALL-MEMO'), value: true},
      {name: this.translate.instant('CREATE-USER.VIEW-ONLY'), value: false}
    ];
    this.departmentLabel = this.translate.instant('CREATE-USER.CHOOSE');
    this.roleList = [
      {name: this.translate.instant('CREATE-USER.GENERAL'), id: 1},
      {name: this.translate.instant('CREATE-USER.IT'), id: 4}
    ];
    this.viewAllBudget = [
      {name: this.translate.instant('PROFILE.VIEW-ALL-BUDGET'), value: true},
      {name: this.translate.instant('PROFILE.NOT-VIEW-ALL-BUDGET'), value: false},
    ];
    if (this.isMasterAdmin) {
      this.roleList.unshift({name: this.translate.instant('CREATE-USER.ADMIN'), id: 0});
    }
  }

  getCompany() {
    const params = new HttpParams().set('type', 'company');
    this.memoService.getDropDown(params)
      .pipe(map((dropDown: { [key: string]: any[] }) => {
        return (dropDown.company || []).map(company => {
          return {name: company.context.code_name, id: company.value};
        });
      }))
      .subscribe((companies: { name: string; id: string }[]) => {
        this.companies = companies;
        this.addUserSetting.company = this.companies[0].id;
        this.getOffice();
      });
  }

  onSelectedCompany() {
    if (!this.addUserSetting.company) {
      this.addUserSetting.office = null;
      this.addUserSetting.group = null;
      this.addUserSetting.department = null;
      return;
    }
    this.getOffice();
  }

  getOffice() {
    const params = new HttpParams()
      .set('type', 'office')
      .set('company', this.addUserSetting.company.toString());
    this.memoService.getDropDown(params)
      .pipe(map((dropDown: { [key: string]: any[] }) => {
        return (dropDown.office || []).map(office => {
          return office.context.is_central
            ? {
              name: `central department - ${office.label ? office.label : ''}`,
              id: office.value
            }
            : {
              name: `${office.context.code_number} - ${office.label ? office.label : ''}`,
              id: office.value
            };
        });
      }))
      .subscribe((offices: { name: string; id: string }[]) => {
        this.offices = offices;
      });
  }

  onSelectedOffice() {
    if (!this.addUserSetting.office) {
      this.addUserSetting.group = null;
      this.addUserSetting.department = null;
      return;
    }
    this.getGroup();
  }

  getGroup() {
    const params = new HttpParams()
      .set('type', 'group')
      .set('office', this.addUserSetting.office.toString());
    this.memoService.getDropDown(params)
      .pipe(map((dropDown: { [key: string]: any[] }) => {
        return (dropDown.group || []).map(group => {
          return group.context.is_central
            ? {
              name: `central department - ${group.label ? group.label : ''}`,
              id: group.value
            }
            : {
              name: `${group.context.code_number} - ${group.label ? group.label : ''}`,
              id: group.value
            };
        });
      }))
      .subscribe((groups: { name: string; id: string }[]) => {
        this.groups = groups;
      });
  }

  onSelectedGroup() {
    if (!this.addUserSetting.group) {
      this.addUserSetting.department = null;
      return;
    }
    this.getDepartment();
  }

  getDepartment() {
    const params = new HttpParams()
      .set('type', 'department')
      .set('group', this.addUserSetting.group.toString());
    this.memoService.getDropDown(params)
      .pipe(map((dropDown: { [key: string]: any[] }) => {
        return (dropDown.department || []).map(department => {
          return  department.context.is_central
            ? {
              name: `central department - ${department.label ? department.label : ''}`,
              id: department.value
            }
            : {
            name: `${department.context.code_number} - ${department.label ? department.label : ''}`,
            id: department.value
          };
        });
      }))
      .subscribe((departments: { name: string; id: string }[]) => {
        this.departments = departments;
      });
  }

  confirmAddUser() {
    if (!this.validateUserData()) {
      return;
    }
    this.isPendingAddUser = true;
    const data = {
      codcomp: this.selectedEmployee.codcomp,
      company: this.addUserSetting.company,
      level: this.selectedEmployee.level,
      user_email: this.selectedEmployee.email,
      email: this.selectedEmployee.email,
      first_name: this.selectedEmployee.first_name,
      first_name_en: this.selectedEmployee.first_name_en,
      last_name: this.selectedEmployee.last_name,
      last_name_en: this.selectedEmployee.last_name_en,
      job_position: this.selectedEmployee.job_position,
      phone_number_1: this.selectedEmployee.phone_number,
      is_approve: true,
      is_sign_up: true,
      role: this.addUserSetting.role,
      is_view_all_memo: this.addUserSetting.is_view_all_memo,
      is_view_all_budget: this.addUserSetting.is_view_all_budget,
      is_view_all_company: this.addUserSetting.is_view_all_company,
      is_add_by_admin: true,
      username: this.selectedEmployee.email,
      department: [this.addUserSetting.department],
    };
    this.userService.createUser(data).subscribe(
      profile => {
        this.swal.toastNotification({type: 'success', content: this.translate.instant('CREATE-USER.CREATE-SUCCESS')});
        this.isPendingAddUser = false;
        this.clearAddUserData();
        this.router.navigate(['/permission', 'profile', 'list']);
      },
      error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error', content: this.translate.instant('CREATE-USER.CANT-CREATE')});
        }
        this.isPendingAddUser = false;
      }
    );
  }

  validateUserData(): boolean {
    if (!this.isEmployeeHasRealEmail()) {
      this.swal.toastNotification({type: 'error', content: this.translate.instant('VALIDATION.NOT-USE-REAL-EMAIL')});
      return false;
    }
    if (this.hasEmptyField()) {
      this.swal.toastNotification({type: 'error', content: this.translate.instant('VALIDATION.FILL-ALL-DATA')});
      return false;
    }
    return true;
  }

  isEmployeeHasRealEmail() {
    const email: string = this.selectedEmployee.email;
    return email
      && email !== 'null'
      && !email.toLowerCase().includes('dummy')
      && /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
  }

  hasEmptyField() {
    return !(
      this.addUserSetting.company
      && this.addUserSetting.office
      && this.addUserSetting.group
      && this.addUserSetting.department
    );
  }

  clearAddUserData() {
    this.addUserSetting = {
      role: 1,
      is_view_all_memo: false,
      is_view_all_budget: false,
      is_view_all_company: false,
      company: null,
      office: null,
      group: null,
      department: null
    };
    this.selectedEmployee = null;
  }
}
