import {Injectable} from '@angular/core';
import {HttpClientService} from './http-client.service';
import {API_URL} from '../constants/api.constant';
import {BehaviorSubject, Subject} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';

export interface ThemeList {
  id: number;
  name: string;
  display_email: string;
  display_pdf: string;
  display_login: string;
  bg_login_color: string;
  bg_login_second_color: string;
  box_login_color: string;
  text_login_color: string;
  text_box_login_color: string;
  display_sidebar: string;
  bg_color_primary: string;
  bg_color_secondary: string;
  text_color: string;
  hovered_text_color: string;
  header_table_color: string;
  text_header_table_color: string;
  active: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public currentTheme;
  private style;
  public small_logo = new Subject();
  public long_logo = new Subject();
  public isSidebarOpen = false;
  themeList: ThemeList;
  private setTheme = new BehaviorSubject<ThemeList>(this.themeList);
  data = this.setTheme.asObservable();

  constructor(
    private http: HttpClientService,
    private breakpointObserver: BreakpointObserver
  ) { }

  public fetchData() {
    return this.http.get(API_URL.theme_color_list);
  }

  public loadTheme(id: number) {
    return this.http.get(API_URL.theme_color_list + id + '/');
  }

  public updateTheme(theme: any, id) {
    return this.http.patch(API_URL.theme_color_list + id + '/', theme);
  }

  public createTheme(theme: any) {
    return this.http.post(API_URL.theme_color_list, theme);
  }

  public deleteTheme(id) {
    return this.http.delete(API_URL.theme_color_list + id + '/');
  }

  public getTheme() {
    return this.http.get(API_URL.theme_active);
  }

  private getStyle() {
    return ` ` +
      `.sidebar, .content-header, span.item-name, a.hamburgur.l-50, a.hamburgur.l-200,
      button.btn.btn-primary, button.btn.btn-outline-secondary.btn-sm.active
      { background-color: #${this.currentTheme.navbar_color} !important; }
      h5.font-weight-bold.m-0.pd-6, h5.f-18.m-b-15, .login-title, .text-primary,
      .table thead th, p.m-b-0.m-t-20.p-l-10, span.san-serif, .theme-title
      { color: #${this.currentTheme.navbar_color} !important; }
      perfect-scrollbar, .user, .memo
      { background-color: #${this.currentTheme.panel_color} !important; }
      .nav-link.text-green, button.btn.btn-outline-secondary.btn-sm.selected, span.tag.green, button.btn.btn-primary
      { background-color: #${this.currentTheme.button_color} !important; }
      .main-content.p-l-50, .bg, .listbg, .content-frame.px-4
      { background-color: #${this.currentTheme.background_color} !important; }
      svg { fill: #${this.currentTheme.main_text_color} !important; }
      h3, i.fas.fa-chevron-left, p.memo-name.text-truncate, li.sidebar-item, li.sidebar-item span, [data-toggle="collapse"]:after
      { color: #${this.currentTheme.main_text_color} !important; }
      span.line
      { background-color: #${this.currentTheme.main_text_color} !important; }
      li.sidebar-item:hover svg, .active-li svg
      { fill: #${this.currentTheme.sub_text_color} !important; }
      li:hover span, .active-li a, label-sm, a.text-muted, a.text-muted.logout-button, li:hover span
      { color: #${this.currentTheme.sub_text_color} !important; }
      .login-bg
      { background: url("${this.currentTheme.login_background}"); }
      h5, .content-frame h5, p.memo-name.text-truncate,
      span.text-white, span.f-13.text-muted, p.user-name.m-0, p.label-sm, button.btn.btn-outline-secondary.btn-sm, .memo-date span
      { color: #${this.currentTheme.header_text_color} !important }
      .subtext-table td, .no-data h5, h5.font-weight-bold.m-0, td.text-center,
      h5.font-weight-bold.m-0.pd-6.memo-name, span.f-10, p.m-0.text-muted.p-l-6
      { color: #${this.currentTheme.background_text_color} !important }`;
  }

  public attachStyle(document: HTMLDocument) {
    this.style = document.createElement('style');
    this.style.innerHTML = this.getStyle();
    document.getElementsByTagName('head')[0].appendChild(this.style);
  }

  public resetStyle(document: HTMLDocument) {
    this.fetchData().subscribe((response) => {
      this.currentTheme = response.find((item) => item.active === true);
      this.style.innerHTML = this.getStyle();
      this.small_logo.next(this.currentTheme.small_logo);
      this.long_logo.next(this.currentTheme.long_logo);
    });
  }

  isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 768px)');
  }

  isMobileSmallScreen() {
    return this.breakpointObserver.isMatched('(max-width: 576px)');
  }

  isTabletOrMobile() {
    return this.breakpointObserver.isMatched(['(max-width: 1024px)']);
  }

  isTabletOrMobilePortrait() {
    return this.breakpointObserver.isMatched(['(max-width: 1024px)', '(orientation: portrait)']);
  }

  setThemeList(theme: ThemeList) {
    this.themeList = theme;
    this.setTheme.next(this.themeList);
  }

  themeSetting() {
    this.setThemeList(this.themeList);
  }
}
