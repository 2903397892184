import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  imageChangedEvent: any = '';
  modal: NgbModalRef;
  croppedImage: any = '';
  file_input: any = '';
  blob_output: any = '';
  errorMsg: any = '';
  currentImage: any;
  image: any;

  @Input() imageFile: FormControl;
  @Output() imageFileChange = new EventEmitter();
  @Output() imagePreview = new EventEmitter();
  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
  }

  imageLoaded() {
    // show cropperx
  }

  openUploadSign(cropImg) {
    this.blob_output = '';
    this.modal = this.modalService.open(cropImg, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

  imageCropped(output) {
    this.croppedImage = output.base64;
    this.blob_output = output.file;
  }


  fileChangeEvent(file: any): void {
    this.errorMsg = '';
    this.imageChangedEvent = file;
    this.file_input = file.target.files[0];
  }

  loadImageFailed() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.errorMsg = 'ไม่สามารถประมวลผลภาพได้ กรุณาอัพโหลดภาพใหม่อีกครั้ง';
    // show message
  }

  saveSignature() {
    if (this.file_input && this.blob_output) {
      const filename =
        this.file_input.name.substring(0, this.file_input.name.lastIndexOf('.')) + '.png';
      this.image = new File([this.blob_output], filename, {type: this.blob_output.type});
      this.imageFile.setValue(this.image);
      this.imagePreview.emit(this.image);
      // this.updateSignature();
    }
    this.modalService.dismissAll();
  }
}
