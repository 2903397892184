import {
  Component,
  ElementRef,
  EventEmitter, OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {Router} from '@angular/router';
import {NotificationService} from '../../shared/services/notification.service';
import {ProfileService} from '../../shared/services/profile-service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ThemeList, ThemeService} from '../../shared/services/theme.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {UserProfileService} from '../../modules/user-profile/user-profile.service';
import {SidebarService} from './sidebar.service';
import {ADFS_URL} from '../../shared/constants/api.constant';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  // isAdmin = false;
  isAccounting = false;
  // showSidebar = false;

  currentToggle = '';

  photo: string;
  name: string;
  profile: any;
  modal: NgbModalRef;

  // mainLogoFull = '/assets/images/Cloudhm-white.png';
  mainLogoFull = '/assets/images/LOGO_template_white.png';
  // mainLogoShort = '../../../assets/images/white-frame.png';
  @Output() resized = new EventEmitter();
  @ViewChild('loadingHRModal', {static: true}) loadingHRModal: ElementRef;

  public config: PerfectScrollbarConfigInterface = {};
  language = '';
  pending = '';
  manage_user = '';
  memo = '';
  manage_approval = '';
  add_department = '';
  manage_organization = '';
  dashboard = '';
  budget_dashboard = '';
  operationLog = '';
  logoutPage = '';
  cancel = '';
  content = '';
  yes = '';
  feed = '';
  isDesktop: boolean;
  flatIconPath = '../../../assets/images/flaticons/';
  icons = {
    notes: this.flatIconPath + 'notes.svg',
    profits: this.flatIconPath + 'profits.svg',
    hierarchy: this.flatIconPath + 'hierarchy.svg',
    user: this.flatIconPath + 'user.svg',
    teamwork: this.flatIconPath + 'teamwork.svg',
    megaphone: this.flatIconPath + 'megaphone.svg',
    edit: this.flatIconPath + 'edit.svg',
    list: this.flatIconPath + 'list.svg',
    gear: this.flatIconPath + 'gear.svg',
    db: this.flatIconPath + 'db.svg',
  };
  configuration = '';
  themeList: ThemeList;

  hoverList = {
    'new_feed': false,
    'department': false,
    'approval': false,
    'memo_list': false,
    'manage_user': false,
    'setting_loa': false,
    'dashboard': false,
    'budget_dashboard': false,
    'operation_log': false,
    'configuration': false,
    'logout': false,
    'master_item': false,
  };

  subscriptions = new Subscription();

  constructor(
    public profileService: ProfileService,
    public themeService: ThemeService,
    private modalService: NgbModal,
    private notification: NotificationService,
    private authService: AuthenticationService,
    private router: Router,
    private translate: TranslateService,
    public userProfileService: UserProfileService,
    private sbService: SidebarService // private memoService: MemoService,
  ) {
    this.language = translate.currentLang;
    const themeSub = this.themeService.data.subscribe(theme => {
      this.themeList = theme;
    });
    this.subscriptions.add(themeSub);
  }

  ngOnInit() {
    this.isDesktop = !this.themeService.isTabletOrMobile(); // Check if the client is Desktop
    this.themeService.isSidebarOpen = this.isDesktop;
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translatePage();
    });
    this.subscriptions.add(translateSub);
    this.translatePage();
    this.isAccounting = localStorage.getItem('role_name') === 'Accounting';
    this.photo = this.userProfileService.userProfileImageStorage;
    this.name = localStorage.getItem('name');
    this.notification.updateNotificationCount();
    this.userProfileService.refreshUserProfileImage();
    this.userProfileService.refreshUserProfileName();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  translatePage() {
    this.pending = this.translate.instant('SIDEBAR.PENDING');
    this.memo = this.translate.instant('SIDEBAR.MEMOS');
    this.manage_user = this.translate.instant('SIDEBAR.MANAGE-USERS');
    this.manage_approval = this.translate.instant('SIDEBAR.MANAGE-APPROVAL');
    this.add_department = this.translate.instant('SIDEBAR.ADD-DEPARTMENT');
    this.manage_organization = this.translate.instant('SIDEBAR.MANAGE-ORGANIZATION');
    this.dashboard = this.translate.instant('SIDEBAR.DASHBOARD');
    this.budget_dashboard = this.translate.instant('SIDEBAR.BUDGET-DASHBOARD');
    this.logoutPage = this.translate.instant('SIDEBAR.LOGOUT');
    this.cancel = this.translate.instant('SIDEBAR.CLOSE');
    this.content = this.translate.instant('SIDEBAR.CONTENT');
    this.yes = this.translate.instant('SIDEBAR.LOGOUT');
    this.feed = this.translate.instant('FEED.NEWS-FEED');
    this.operationLog = this.translate.instant('SIDEBAR.OPERATIONLOG');
    this.configuration = this.translate.instant('SIDEBAR.CONFIGURATION');
  }

  open(content) {
    this.modal = this.modalService.open(content, {size: 'sm'});
  }

  toggle(section) {
    this.currentToggle = section === this.currentToggle ? 'NONE' : section;
  }

  resizeSidebar() {
    this.resized.emit();
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  isViewBudgetDashboard() {
    //  Check level user more than 8 and is_view_all_budget is true only
    return (localStorage.level >= 8 && localStorage.is_view_all_budget === 'true') ||
      (localStorage.level < 8 && localStorage.is_view_all_budget === 'true');
  }

  isIt() {
    return this.authService.isIt();
  }

  onResize(event) {
    const pageWidth = event.target.innerWidth;
    this.isDesktop = pageWidth > 1024;
    this.themeService.isSidebarOpen = this.isDesktop;
  }

  forceChangePath(url: Array<string>) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(url);
    });
  }

  homePage(url) {
    this.router.navigateByUrl(url, {skipLocationChange: false}).then(() => {
      this.authService.checkNavigateRoute(localStorage.role_name);
    });
  }

  close() {
    this.sbService.setActiveSidebar(false);
  }

  isGeneral() {
    return this.authService.isGeneral();
  }

  changeLanguage(lang: string) {
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    } else if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  openModalLogout(content) {
    this.modal = this.modalService.open(content, {size: 'md'});
  }

  submitLogout() {
    const isLoginFromBGBGCInstance = JSON.parse(localStorage.getItem('from_bgbgc_instance')) || false;
    if (isLoginFromBGBGCInstance) {
      this.authService.clearStorage();
      window.location.href = environment.bgbgc_frontend_url;
      return;
    }

    const isADFSLogin = JSON.parse(localStorage.getItem('adfs')) || false;
    this.authService.clearStorage();
    this.modal.close();
    if (isADFSLogin) {
      window.open(
        ADFS_URL.logout,
        '_blank',
        'location=yes,height=400,width=600,scrollbars=yes,status=yes'
      );
    }
    this.router.navigate(['/login']);
  }

  hoverText(type, value) {
    this.hoverList[type] = value;
  }
}
