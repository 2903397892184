import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThemeRoutingModule} from './theme-routing.module';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppModule} from '../../../app.module';
import {SafeStylePipe} from './safe-style.pipe';
import {LaddaModule} from 'angular2-ladda';
import {TranslateModule} from '@ngx-translate/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ColorPickerModule} from 'primeng/colorpicker';
import {CoreModule} from '../../../core/core.module';
import {ThemeListComponent} from './theme-list/theme-list.component';
import {ThemeAddComponent} from './theme-add/theme-add.component';
import {ThemeEditComponent} from './theme-edit/theme-edit.component';
import {DetailSetupComponent} from './detail-setup/detail-setup.component';
import {UploadFileComponent} from './upload-file/upload-file.component';
import {PreviewThemeComponent} from './preview-theme/preview-theme.component';
import { PreviewLoginComponent } from './preview-login/preview-login.component';
import { PreviewTemplateComponent } from './preview-template/preview-template.component';
import { PreviewEmailTemplateComponent } from './preview-email-template/preview-email-template.component';
import {CheckboxModule} from 'primeng/checkbox';

// import {ColorPickerModule} from 'narik-angular-color-picker';

@NgModule({
  declarations: [ThemeListComponent,
    ThemeAddComponent,
    ThemeEditComponent,
    SafeStylePipe,
    DetailSetupComponent,
    UploadFileComponent,
    PreviewThemeComponent,
    PreviewLoginComponent,
    PreviewTemplateComponent,
    PreviewEmailTemplateComponent],
  exports: [
    DetailSetupComponent,
    ThemeListComponent
  ],
  imports: [
    CommonModule,
    ThemeRoutingModule,
    AngularSvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    LaddaModule,
    TranslateModule,
    CoreModule,
    ImageCropperModule,
    ColorPickerModule,
    CheckboxModule
    // ColorPickerModule
  ]
})
export class ThemeModule {
}
