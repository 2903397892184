import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import {HttpParams} from '@angular/common/http';
import {LoaService} from '../../../loa/loa.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {MessageService} from 'primeng/components/common/messageservice';
import {GeneralService} from '../../../../setting/general/general.service';
import {NotificationService} from '../../../../../shared/services/notification.service';
import {MemoService} from '../../memo.service';
import {UserService} from '../../../../permission/user/user.service';
import {NgbThaiDateParserFormatter} from 'src/app/core/NgDateParser';

declare const $: any;

// @Pipe({ name: 'reverse' })
// export class ReversePipe implements PipeTransform {
//   transform(value) {
//     return value.reverse();
//   }
// }

@Component({
  selector: 'app-memo-template',
  templateUrl: './memo-template.component.html',
  styleUrls: ['./memo-template.component.scss']
})
export class MemoTemplateComponent implements OnInit {
  isLoading = false;
  loaList = [];
  loa: any;
  isToggleTemplate = false;
  isAuto = true;
  documentFile: File;
  errorMessage = false;
  isLoadingPublished = false;
  correctMessage = false;

  inputOptions = {
    placeholderText: 'ไม่มีข้อมูล',
    charCounterCount: false,
    tabSpaces: 4,
    pastePlain: true,
    key: 'bA2B8A8A5cD3F3C2C1G3A4C4B6D2C3vmmyfnyC-13sA-9pD1yeD-16==',
    events: {
      'froalaEditor.contentChanged': (e, editor) => {
        // this.autoSaved();
      }
    },
    toolbarButtons: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      'align',
      'highlight',
      'clearFormatting'
    ]
  };

  textareaOptions = {
    placeholderText: 'ไม่มีข้อมูล',
    // height: 930,
    charCounterCount: false,
    tabSpaces: 4,
    key: 'bA2B8A8A5cD3F3C2C1G3A4C4B6D2C3vmmyfnyC-13sA-9pD1yeD-16==',
    pastePlain: true,
    editorClass: 'justify-text',
    events: {
      'froalaEditor.contentChanged': (e, editor) => {
        this.autoSaved();
        // this.countLines(e, editor);
      }
    },
    toolbarButtons: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      'align',
      'formatOL',
      'formatUL',
      'insertTable',
      'clearFormatting',
      'highlight'
    ]
  };
  content = '';
  logo = {
    id: null,
    image: null
  };
  address: any;
  error: any = {};

  currentDay = moment().format('YYYY-MM-DD');
  profileList = [];
  profile: any;
  departmentList = [];
  logoList = [];
  addressList = [];
  addressENList = [];
  sequenceList = [];
  department: any;
  thAddress: any;
  enAddress: any;
  remark: string;
  documentData: any;
  showPreview = false;
  trainingID = undefined;
  oldFileList = [];
  modal: NgbModalRef;
  memoData: any;

  // tslint:disable-next-line:no-input-rename
  @Input('memoData') _memoData;
  @Output() savedMemo = new EventEmitter();
  @Output() editSuccess = new EventEmitter();
  @Output() togglePreview = new EventEmitter();

  constructor(
    private messageService: MessageService,
    private generalService: GeneralService,
    private loaService: LoaService,
    private memoService: MemoService,
    private notification: NotificationService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private date: NgbThaiDateParserFormatter,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.memoData = _.cloneDeep(this._memoData);

    $.FroalaEditor.DefineIcon('highlight', {NAME: 'star'});
    $.FroalaEditor.RegisterCommand('highlight', {
      title: 'Highlight',
      focus: true,
      undo: true,
      refreshAfterCallback: false,
      callback: function () {
        this.colors.background('#ffff00');
        setTimeout(() => {
          this.selection.clear();
        }, 700);
        setTimeout(() => {
          this.commands.clearFormatting();
        }, 1000);
      }
    });
    this.memoData.memo_number = this.memoData.is_thai_number ?
      this.converts_numbers_to_thai(this.memoData.memo_number)
      : this.memoData.memo_number || '';
    this.memoData.memo_number_year = this.memoData.is_thai_number ?
      this.converts_numbers_to_thai(this.memoData.memo_number_year) : this.memoData.memo_number_year;

    // this.memoData.memo_number = this.memoData.memo_thai_number || this.memoData.memo_number;
    // this.memoData.memo_number_year =
    //   this.memoData.memo_thai_number_year || this.memoData.memo_number_year;

    this.getProfileList();
    this.loadLogo();
    this.loadAddress();
    this.loadDepartmentType();
    this.checkURLParameters();
    this.loadFileList();
    this.loadLOAList();
    if (this.memoData['created_date']) {
      this.memoData['created_date'] = this.date.parse(
        this.memoData['created_date'] || moment().format('YYYY-MM-DD')
      );
    }

    if (this.memoData.due_date) {
      this.memoData.due_date = this.date.parse(this.memoData.due_date);
    }
  }

  autoSaved() {
    let reversedNewLOA = null;
    if (this.memoData.loa) {
      const newLOA = _.cloneDeep(this.memoData.loa);
      reversedNewLOA = newLOA;
    }

    const data = {
      // logo: _.get(this.logo, 'logo.id') || this.memoData.logo_id,
      // logo_id: _.get(this.logo, 'logo.id') || this.memoData.logo_id,
      subject: this.memoData.subject,
      to: this.memoData.to,
      detail: this.reformatContent(),
      department: this.memoData.department,
      approver: this.memoData.approver,
      mailing_address_th: this.memoData.mailing_address_th,
      mailing_address_en: this.memoData.mailing_address_en,
      attachments: [],
      type: this.memoData.type,
      name: this.memoData.name,
      remark: this.memoData.remark,
      loa: reversedNewLOA
    };
    this.memoService.updateMemoReal(this.memoData.id, data).subscribe(() => {
      // this.notification.showSuccess();
    });
  }

  checkURLParameters() {
    this.activatedRoute.params.subscribe(params => {
      if (!_.isEmpty(params.training)) {
        this.trainingID = params.training;
      }
    });
    if (!this.memoData.approver) {
      this.memoData.approver = [
        {
          text: 'ขอแสดงความนับถือ',
          approver: null,
          position: '',
          company: 'ในพระบรมราชูปถัมภ์'
        }
      ];
    }
    this.currentDay = moment(this.memoData.created_at).format('YYYY-MM-DD');
    this.checkDepartment(this.memoData.department);
  }

  loadLOAList() {
    this.loaService.getLOAList().subscribe(data => {
      this.loaList = data['results'];
      if (this.memoData.loa) {
        this.loaList.forEach(obj => {
          if (this.memoData.loa[0].LOA_group === obj.id) {
            this.loa = obj.name;
          }
        });
      }
    });
  }

  getProfileList() {
    const params = new HttpParams().set('none_pagination', 'True');
    this.userService.getProfileList(params).subscribe((profiles: any) => {
      this.profileList = profiles.filter(profile => profile.email);
    });
  }

  loadFileList() {
    const params = new HttpParams().set('memo', this.memoData.id);
    this.memoService.getMemoAttachment(params).subscribe((fileList: any) => {
      this.oldFileList = fileList.results;
    });
  }

  loadDepartmentType() {
    this.memoService.getDepartmentList().subscribe((departments: any) => {
      this.departmentList = departments.results;
    });
  }

  checkDepartment(departmentID) {
    this.memoService.getDepartmentList().subscribe((departments: any) => {
      const filteredDepartment = _.filter(departments.results, department => {
        return department.id === Number(departmentID);
      });
      this.memoData.department = _.get(filteredDepartment[0], 'id');
    });
  }

  // filterText() {
  //   let combinedText = '';
  //   _.forEach(this.memoData.detail, content => {
  //     combinedText = combinedText + content.text;
  //   });
  //   const filteredText = combinedText.match(/(^[^@$].*(background-color).*|^background-color)/gm);
  //   return _.isNil(filteredText);
  // }

  checkDuplidateMemoNumber() {
    return !this.errorMessage;
  }

  submit() {
    if (!this.checkDuplidateMemoNumber()) {
      this.messageService.add({
        severity: 'error',
        summary: 'ผิดพลาด',
        detail: 'เลขที่เอกสารซ้ำ'
      });
      return;
    }

    // if (!this.filterText()) {
    //   this.messageService.add({
    //     severity: 'error',
    //     summary: 'ผิดพลาด',
    //     detail: 'ต้องแก้ข้อความที่โดนไฮไลท์ให้หมด'
    //   });
    //   return;
    // }

    if (!this.validateMemoNumber()) {
      this.messageService.add({
        severity: 'error',
        summary: 'ผิดพลาด',
        detail: 'เลขที่เอกสารไม่ถูกต้อง'
      });
      this.error['memo_number'] = this.memoData.memo_number
        ? !Number.isInteger(Number(this.memoData.memo_number))
        : false;
      this.error['memo_number_year'] = !Number.isInteger(Number(this.memoData.memo_number_year));
      return;
    }

    if (this.memoData.upload_type !== 'offline') {
      this.saveEditedMemo();
    } else {
      this.saveOfflineMemo();
    }
  }

  saveEditedMemo() {
    let reversedNewLOA = null;
    if (this.memoData.loa) {
      reversedNewLOA = _.cloneDeep(this.memoData.loa);
    }

    this.isLoading = true;
    const data = {
      subject: this.memoData.subject,
      to: this.memoData.to,
      detail: this.reformatContent(),
      department: this.memoData.department,
      approver: this.memoData.approver,
      mailing_address_th: this.memoData.mailing_address_th,
      mailing_address_en: this.memoData.mailing_address_en,
      attachments: [],
      type: this.memoData.type,
      name: this.memoData.name,
      remark: this.memoData.remark,
      loa: reversedNewLOA,
      loa_type: this.memoData.loa_type,
      loa_title: this.memoData.loa_title,
      loa_purpose: this.memoData.loa_purpose,
      created_date: this.memoData.created_date
        ? this.date.format(this.memoData.created_date, true)
        : moment().format('YYYY-MM-DD')
    };

    if (data.approver.length) {
      data['approver'][0]['approve_status'] = 0;
    }

    if (this.memoData.due_date) {
      data['due_date'] = this.date.format(this.memoData.due_date, true);
    }

    if (_.get(this.logo, 'logo.id') || _.get(this.memoData, 'logo_id')) {
      data['logo'] = _.get(this.logo, 'logo.id') || this.memoData.logo_id;
      data['logo_id'] = _.get(this.logo, 'logo.id') || this.memoData.logo_id;
    }

    this.memoService.updateMemoReal(this.memoData.id, data).subscribe(
      memo => {
        this.messageService.add({severity: 'success', summary: 'บันทึกการเปลี่ยนแปลง'});
        this.isLoading = false;
        this.editSuccess.emit(memo);
      },
      error => {
        this.isLoading = false;
        this.notification.showNotification(error.status);
      }
    );
  }

  saveOfflineMemo() {
    this.isLoading = true;
    const fd = new FormData();
    fd.append('name', this.memoData.name || '');
    const memo_number_arabic = this.convert_numbers_to_arabic(this.memoData.memo_number);
    const memo_year_arabic = this.convert_numbers_to_arabic(this.memoData.memo_number_year);
    fd.append('memo_number', memo_number_arabic);
    fd.append('memo_number_year', memo_year_arabic);
    fd.append('is_thai_number', (memo_number_arabic !== this.memoData.memo_number
      || memo_year_arabic !== this.memoData.memo_number_year).toString());
    if (this.memoData.department) {
      fd.append('department', this.memoData.department.toString());
    }
    fd.append(
      'created_date',
      this.memoData.created_date
        ? this.date.format(this.memoData.created_date, true)
        : moment().format('YYYY-MM-DD')
    );
    if (this.documentFile) {
      fd.append('signed_document', this.documentFile);
    }

    if (
      this.memoData.due_date &&
      (typeof this.memoData.due_date === 'string' || this.memoData.due_date instanceof String)
    ) {
      this.memoData.due_date = this.date.parse(this.memoData.due_date);
    }

    this.memoService.updateMemoReal(this.memoData.id, fd).subscribe(
      memo => {
        this.messageService.add({severity: 'success', summary: 'บันทึกการเปลี่ยนแปลง'});
        this.isLoading = false;
        this.editSuccess.emit(memo);
      },
      error => {
        this.isLoading = false;
        this.notification.showNotification(error.status);
      }
    );
  }

  reformatContent() {
    return this.memoData.detail;
  }

  preview() {
    let reversedNewLOA = null;
    if (this.memoData.loa) {
      const newLOA = _.cloneDeep(this.memoData.loa);
      reversedNewLOA = newLOA;
    }

    this.documentData = {
      logo: _.get(this.logo, 'logo.image') || this.memoData.logo,
      subject: this.memoData.subject,
      to: this.memoData.to,
      detail: this.memoData.detail,
      department: this.memoData.department,
      approver: this.memoData.approver,
      preview: true,
      is_thai_number: this.memoData.memo_number ? '๑๒๓๔๕๖๗๘๙๐'.indexOf(this.memoData.memo_number[0]) >= 0 : null,
      memo_number_en: this.memoData.memo_number_en,
      memo_number_th: this.memoData.memo_number_th,
      mailing_address_th: this.memoData.mailing_address_th,
      mailing_address_en: this.memoData.mailing_address_en,
      attachments: [],
      type: this.memoData.type,
      loa: reversedNewLOA || null,
      loa_type: this.memoData.loa_type,
      loa_title: this.memoData.loa_title,
      loa_purpose: this.memoData.loa_purpose,
      created_date: this.memoData.created_date
        ? this.date.format(this.memoData.created_date, true)
        : moment().format('YYYY-MM-DD')
    };
    this.showPreview = !this.showPreview;
  }

  closePreview(e) {
    this.showPreview = !this.showPreview;
  }

  loadLogo() {
    this.generalService.getLogo().subscribe((logos: any) => (this.logoList = logos.results));
  }

  loadAddress() {
    this.generalService
      .getAddress(new HttpParams().set('lang', 'th'))
      .subscribe((addresses: any) => {
        this.addressList = addresses.results;
        this.addressList.forEach(obj => {
          if (
            this.memoData.mailing_address_th !== null &&
            this.memoData.mailing_address_th.startsWith(
              `<p>${obj.address}<br>โทรศัพท์:${obj.phone} โทรสาร:${obj.fax}<br>อีเมล: ${
                obj.email
              }</p>`
            )
          ) {
            this.thAddress = obj;
          }
        });
      });
    this.generalService
      .getAddress(new HttpParams().set('lang', 'en'))
      .subscribe((addresses: any) => {
        this.addressENList = addresses.results;
        this.addressENList.forEach(obj => {
          if (
            this.memoData.mailing_address_en !== null &&
            this.memoData.mailing_address_en.startsWith(
              `<p>${obj.address}<br>Tel: ${obj.phone} Fax: ${obj.fax}<br>Email: ${obj.email}</p>`
            )
          ) {
            this.enAddress = obj;
          }
        });
      });
  }

  selectTHAddress() {
    this.memoData['mailing_address_th'] =
      '<p>' +
      this.thAddress.address +
      '<br>' +
      'โทรศัพท์:' +
      this.thAddress.phone +
      ' โทรสาร:' +
      this.thAddress.fax +
      '<br>อีเมล: ' +
      this.thAddress.email +
      '</p>';
  }

  selectENAddress() {
    this.memoData['mailing_address_en'] =
      '<p>' +
      this.enAddress.address +
      '<br>' +
      'Tel: ' +
      this.enAddress.phone +
      ' Fax: ' +
      this.enAddress.fax +
      '<br>Email: ' +
      this.enAddress.email +
      '</p>';
  }

  selectLogo(logo) {
    this.logo = logo;
  }

  addNewPage() {
    this.memoData.detail.push({
      text: ''
    });
  }

  removePage(index) {
    this.memoData.detail.splice(index, 1);
  }

  selectApprover(selectedApprover?) {
    if (selectedApprover) {
      if (this.memoData.type === 3) {
        this.memoData.approver[0]['approver'] = selectedApprover;
        this.memoData.approver[0]['position'] = selectedApprover.job_position;
        this.memoData.approver[0]['association'] = 'The Football Association of Thailand';
        this.memoData.approver[0]['company'] = '';
        this.memoData.approver[0]['approver']['full_name'] =
          selectedApprover.first_name_en + ' ' + selectedApprover.last_name_en;
      } else {
        if (selectedApprover.job_position_name && selectedApprover.department_name) {
          this.memoData.approver[0]['approver'] = selectedApprover;
          this.memoData.approver[0]['position'] = selectedApprover.job_position;
          this.memoData.approver[0]['company'] = 'ในพระบรมราชูปถัมภ์';
          this.memoData.approver[0]['association'] = 'สมาคมกีฬาฟุตบอลแห่งประเทศไทย';
          this.memoData.approver[0]['approver']['full_name'] = selectedApprover.full_name;
        } else {
          this.memoData.approver[0]['approver'] = selectedApprover;
          this.memoData.approver[0]['position'] = selectedApprover.job_position;
          this.memoData.approver[0]['company'] = 'ในพระบรมราชูปถัมภ์';
          this.memoData.approver[0]['association'] = 'สมาคมกีฬาฟุตบอลแห่งประเทศไทย';
          this.memoData.approver[0]['approver']['full_name'] = selectedApprover.full_name;
        }
      }
    }
  }

  selectLOA(data) {
    if (data) {
      _.map(data.LOA_member, obj => {
        return (
          (obj['approve_status'] = 0),
            (obj['association'] = 'สมาคมกีฬาฟุตบอลแห่งประเทศไทย'),
            (obj['company'] = 'ในพระบรมราชูปถัมภ์')
        );
      });
      this.setDefaultLoaTitle(data);
      this.memoData.loa = data.LOA_member.reverse();
    } else {
      this.memoData.loa = null;
    }
  }

  setDefaultLoaTitle(memo) {
    this.memoData.loa_title =
      memo.LOA_member[0].job_position_name || memo.LOA_member[0].job_position || '';
  }

  selectTemplate(type) {
    if (
      confirm(
        'การเปลี่ยนฟอร์แมตเอกสารระบบจะทำการสร้างรูปแบบของเอกสารใหม่ขึ้นมา โดยที่ข้อมูลเดิมจะหายไป คุณต้องการที่จะทำการเปลี่ยนแปลงหรือไม่'
      )
    ) {
      this.memoData.type = type;
      this.memoData.detail = [];
      switch (type) {
        case 1:
          this.memoData.detail[0] = {
            text:
              '<p>เรื่อง &nbsp; ชื่อการตั้งเรื่อง</p>' +
              '<p>เรียน &nbsp; ชื่อผู้รับ</p><p>สิ่งที่ส่งมาด้วย &nbsp; ' +
              '1. เอกสารแนบฉบับที่หนึ่ง</p><p>&nbsp; &nbsp; ' +
              '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' +
              '&nbsp; &nbsp; &nbsp;' +
              '2. เอกสารแนบฉบับที่สอง</p><p><br></p><p>' +
              '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' +
              '&nbsp; &nbsp; Lorem ipsum dolor sit amet, ' +
              'consectetur adipiscing elit. Aliquam semper ' +
              'mattis porta. Etiam laoreet, libero eget ' +
              'ultricies rutrum, urna tortor consequat diam, ' +
              'ac placerat est augue at orci. Donec mollis nulla est, ' +
              'vitae pharetra mauris rhoncus a. Nullam porta leo a ' +
              'nibh rhoncus facilisis. Sed vel rutrum lacus. ' +
              'Suspendisse ac luctus diam. Sed est magna, ' +
              'sollicitudin euismod ultrices vel, ' +
              'ornare pellentesque nunc.</p>'
          };
          this.memoData.approver.text = 'ขอแสดงความนับถือ';
          break;
        case 2:
          this.memoData.detail[0] = {
            text:
              '<p>เรื่อง &nbsp; ชื่อการตั้งเรื่อง</p>' +
              '<p>เรียน &nbsp; ชื่อผู้รับ</p><p>สิ่งที่ส่งมาด้วย &nbsp; ' +
              '1. เอกสารแนบฉบับที่หนึ่ง</p><p>&nbsp; &nbsp; ' +
              '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' +
              '&nbsp; &nbsp; &nbsp;' +
              '2. เอกสารแนบฉบับที่สอง</p><p><br></p><p>' +
              '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' +
              '&nbsp; &nbsp; Lorem ipsum dolor sit amet, ' +
              'consectetur adipiscing elit. Aliquam semper ' +
              'mattis porta. Etiam laoreet, libero eget ' +
              'ultricies rutrum, urna tortor consequat diam, ' +
              'ac placerat est augue at orci. Donec mollis nulla est, ' +
              'vitae pharetra mauris rhoncus a. Nullam porta leo a ' +
              'nibh rhoncus facilisis. Sed vel rutrum lacus. ' +
              'Suspendisse ac luctus diam. Sed est magna, ' +
              'sollicitudin euismod ultrices vel, ' +
              'ornare pellentesque nunc.</p>'
          };
          this.memoData.approver.text = 'ขอแสดงความนับถือ';
          break;
        case 3:
          this.memoData.detail[0] = {
            text:
              '<p>' +
              '<strong>H.E Honorary Consul</strong>' +
              '</p><p><strong>Royal Thai Honorary Consulate ' +
              'in Tashkent, Uzbekistan</strong></p><p>' +
              '<br></p><p><strong>Subject: &nbsp; ' +
              'Lorem ipsum dolor sit amet</strong>' +
              '</p><p><br></p><p>Dear Sir,</p><p>Warm ' +
              'greetings from the Football Association of Thailand.' +
              '</p><p><br></p><p>Lorem ipsum dolor sit amet, ' +
              'consectetur adipiscing elit. Aliquam semper ' +
              'mattis porta. Etiam laoreet, libero eget ultricies ' +
              'rutrum, urna tortor consequat diam, ac placerat est ' +
              'augue at orci. Donec mollis nulla est, vitae ' +
              'pharetra mauris rhoncus a. Nullam porta leo a ' +
              'nibh rhoncus facilisis. Sed vel rutrum lacus. ' +
              'Suspendisse ac luctus diam. Sed est magna, ' +
              'sollicitudin euismod ultrices vel, ornare ' +
              'pellentesque nunc.</p>'
          };
          this.memoData.approver.text = 'Yours sincerely,';
          this.memoData.department = _.filter(this.departmentList, {name: 'ฝ่ายต่างประเทศ'})[0][
            'id'
            ];
          this.memoData.approver.association = 'Football Association of Thailand';
          break;
        case 4:
          this.memoData.detail[0] = {
            text:
              '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' +
              '&nbsp; &nbsp; Lorem ipsum dolor sit amet, ' +
              'consectetur adipiscing elit. Aliquam semper ' +
              'mattis porta. Etiam laoreet, libero eget ' +
              'ultricies rutrum, urna tortor consequat diam, ' +
              'ac placerat est augue at orci. Donec mollis nulla est, ' +
              'vitae pharetra mauris rhoncus a. Nullam porta leo a ' +
              'nibh rhoncus facilisis. Sed vel rutrum lacus. ' +
              'Suspendisse ac luctus diam. Sed est magna, ' +
              'sollicitudin euismod ultrices vel, ' +
              'ornare pellentesque nunc.</p>'
          };
          this.memoData.approver.text = 'ประกาศ ณ วันที่';
          this.memoData.subject = 'ประกาศ สมาคมกีฬาฟุตบอลแห่งประเทศไทย';
          this.memoData.to = 'เรื่อง แต่งตั้งผู้รับผิดชอบ';
          this.memoData.department = _.filter(this.departmentList, {name: 'เลขาลงนาม'})[0]['id'];
          break;
        case 5:
          this.memoData.detail[0] = {
            text:
              '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' +
              '&nbsp; &nbsp; Lorem ipsum dolor sit amet, ' +
              'consectetur adipiscing elit. Aliquam semper ' +
              'mattis porta. Etiam laoreet, libero eget ' +
              'ultricies rutrum, urna tortor consequat diam, ' +
              'ac placerat est augue at orci. Donec mollis nulla est, ' +
              'vitae pharetra mauris rhoncus a. Nullam porta leo a ' +
              'nibh rhoncus facilisis. Sed vel rutrum lacus. ' +
              'Suspendisse ac luctus diam. Sed est magna, ' +
              'sollicitudin euismod ultrices vel, ' +
              'ornare pellentesque nunc.</p>'
          };
          this.memoData.approver.text = 'ประกาศ ณ วันที่';
          this.memoData.subject = 'ประกาศ สมาคมกีฬาฟุตบอลแห่งประเทศไทย';
          this.memoData.to = 'เรื่อง แต่งตั้งผู้รับผิดชอบ';
          this.memoData.department = _.filter(this.departmentList, {name: 'เลขาลงนาม'})[0]['id'];
          break;
        default:
          this.memoData.detail[0] = {
            text:
              '<p>เรื่อง &nbsp; ชื่อการตั้งเรื่อง</p>' +
              '<p>เรียน &nbsp; ชื่อผู้รับ</p><p>สิ่งที่ส่งมาด้วย &nbsp; ' +
              '1. เอกสารแนบฉบับที่หนึ่ง</p><p>&nbsp; &nbsp; ' +
              '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' +
              '&nbsp; &nbsp; &nbsp;' +
              '2. เอกสารแนบฉบับที่สอง</p><p><br></p><p>' +
              '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' +
              '&nbsp; &nbsp; Lorem ipsum dolor sit amet, ' +
              'consectetur adipiscing elit. Aliquam semper ' +
              'mattis porta. Etiam laoreet, libero eget ' +
              'ultricies rutrum, urna tortor consequat diam, ' +
              'ac placerat est augue at orci. Donec mollis nulla est, ' +
              'vitae pharetra mauris rhoncus a. Nullam porta leo a ' +
              'nibh rhoncus facilisis. Sed vel rutrum lacus. ' +
              'Suspendisse ac luctus diam. Sed est magna, ' +
              'sollicitudin euismod ultrices vel, ' +
              'ornare pellentesque nunc.</p>'
          };
          this.memoData.approver.text = 'ขอแสดงความนับถือ';
          break;
      }
    }
  }

  toggleTemplate() {
    this.isToggleTemplate = !this.isToggleTemplate;
  }

  back() {
    // if user click back, return original variable.
    this.editSuccess.emit(this._memoData);
  }

  toggleMemoNumber() {
    this.isAuto = !this.isAuto;
  }

  changeMemoNumber() {
    this.errorMessage = false;
    this.correctMessage = false;
    if (!this.validateMemoNumber()) {
      this.messageService.add({
        severity: 'error',
        summary: 'ผิดพลาด',
        detail: 'เลขที่เอกสารไม่ถูกต้อง'
      });
      this.error['memo_number'] = this.memoData.memo_number
        ? !Number.isInteger(Number(this.memoData.memo_number))
        : false;
      this.error['memo_number_year'] = !Number.isInteger(Number(this.memoData.memo_number_year));
      return;
    }

    if (this.memoData.memo_number && this.memoData.memo_number_year) {
      this.correctMessage = this.errorMessage = false;
      const params = new HttpParams()
        .set('department', this.memoData.department.toString())
        .set('memo_id', this.memoData.id.toString())
        .set('memo_number', this.memoData.memo_number && this.memoData.memo_number.toString())
        .set('year', this.memoData.memo_number_year);
      this.memoService.verifyMemoNumber(params).subscribe(
        verify => (this.correctMessage = true),
        error => {
          this.errorMessage = true;
        }
      );
    }
  }

  getFile(file) {
    this.documentFile = file;
  }

  validateMemoNumber() {
    this.error = {};
    if (this.memoData.memo_number) {
      const number0To9InThaiOrEng = /([0-9\u0E50-\u0E59]+)/gmu;
      return number0To9InThaiOrEng.test(this.memoData.memo_number);
    } else {
      return true;
    }
  }

  publishMemoAndSendEmail() {
    let reversedNewLOA = null;
    if (this.memoData.loa) {
      const newLOA = _.cloneDeep(this.memoData.loa);
      reversedNewLOA = newLOA;
    }

    const memo_number_arabic = this.convert_numbers_to_arabic(this.memoData.memo_number);
    const memo_year_arabic = this.convert_numbers_to_arabic(this.memoData.memo_number_year);

    this.isLoading = true;
    const data = {
      subject: this.memoData.subject,
      to: this.memoData.to,
      detail: this.reformatContent(),
      department: this.memoData.department,
      approver: this.memoData.approver,
      mailing_address_th: this.memoData.mailing_address_th,
      mailing_address_en: this.memoData.mailing_address_en,
      attachments: [],
      memo_number: memo_number_arabic,
      memo_number_year: memo_year_arabic,
      is_thai_number: (memo_number_arabic !== this.memoData.memo_number || memo_year_arabic !== this.memoData.memo_number_year).toString(),
      type: this.memoData.type,
      name: this.memoData.name,
      remark: this.memoData.remark,
      loa: reversedNewLOA,
      loa_type: this.memoData.loa_type,
      loa_title: this.memoData.loa_title,
      loa_purpose: this.memoData.loa_purpose,
      created_date: this.memoData.created_date
        ? this.date.format(this.memoData.created_date, true)
        : moment().format('YYYY-MM-DD')
    };

    if (data.approver.length) {
      data['approver'][0]['approve_status'] = 0;
    }

    if (this.memoData.due_date) {
      data['due_date'] = this.date.format(this.memoData.due_date, true);
    }

    if (_.get(this.logo, 'logo.id') || _.get(this.memoData, 'logo_id')) {
      data['logo'] = _.get(this.logo, 'logo.id') || this.memoData.logo_id;
      data['logo_id'] = _.get(this.logo, 'logo.id') || this.memoData.logo_id;
    }

    this.memoService.updateMemoReal(this.memoData.id, data).subscribe(
      memo => {
        this.isLoading = false;
        this.isLoadingPublished = true;
        const publishedData = {
          memo_id: this.memoData.id
        };
        this.memoService.publishMemoWithoutSendingEmail(publishedData).subscribe(
          () => {
            this.getMemoDetail(this.memoData.id);
            this.memoService.sendEmail(publishedData).subscribe(
              () => {
                this.isLoadingPublished = false;
                this.getMemoDetail(this.memoData.id);
              },
              error => {
                this.isLoadingPublished = false;
                this.messageService.add({
                  severity: 'error',
                  summary: 'ผิดพลาด',
                  detail: 'ไม่พบอีเมล์ของผู้อนุมัติ'
                });
              }
            );
          },
          error => {
            this.isLoadingPublished = false;
            this.messageService.add({
              severity: 'error',
              summary: 'ผิดพลาด',
              detail: 'ไม่สามารถสร้างเลขที่เอกสารได้'
            });
          }
        );
      },
      error => {
        this.isLoading = false;
        this.notification.showNotification(error.status);
      }
    );
  }

  getMemoDetail(id) {
    this.memoService.getMemoRealDetail(id).subscribe(
      memo => {
        this.modal.close();
        this.editSuccess.emit(memo);
      },
      error => {
        this.modal.close();
        this.notification.showNotification(error.status);
      }
    );
  }

  checkIfCanPublish() {
    return this.memoData.upload_type === 'draft' || this.memoData.status === 'rejected';
  }

  convert_numbers_to_arabic(number_str) {
    if (!number_str) {
      return '';
    }
    const digit_str = '0123456789๐๑๒๓๔๕๖๗๘๙';
    let str_ = '';
    for (let i = 0; i < number_str.length; i++) {
      str_ += digit_str[digit_str.indexOf(number_str[i]) % 10];
    }
    return str_;
  }

  converts_numbers_to_thai(number_str) {
    if (!number_str) {
      return '';
    }
    const digit_str = '๐๑๒๓๔๕๖๗๘๙0123456789';
    let str_ = '';
    for (let i = 0; i < number_str.length; i++) {
      str_ += digit_str[digit_str.indexOf(number_str[i]) % 10];
    }
    return str_;
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  checkIfRemovable() {
    return _.toNumber(localStorage.getItem('user_id')) === _.get(this.memoData, 'created_user', '');
  }

  checkIfSpecialType(memo) {
    return _.isNil(memo.special_type);
  }

  publish(content) {
    if (this.errorMessage) {
      this.messageService.add({
        severity: 'error',
        summary: 'ผิดพลาด',
        detail: 'หมายเลขเอกสารนี้ถูกใช้แล้ว'
      });
    }
    if (this.memoData.approver[0].approver == null) {
      this.messageService.add({
        severity: 'error',
        summary: 'ผิดพลาด',
        detail: 'กรุณาเลือก Approver'
      });
    }
    if (!this.errorMessage && this.memoData.approver[0].approver !== null) {
      this.open(content);
    }
  }

}
