import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileListComponent } from './profile-list/profile-list.component';
import { ProfileCreateComponent } from './profile-create/profile-create.component';
import { ProfileUpdateComponent } from './profile-update/profile-update.component';
import { CoreModule } from '../../../core/core.module';
import { ProfileDetailComponent } from './profile-detail/profile-detail.component';
import {TranslateModule} from '@ngx-translate/core';
import {PasswordStrengthMeterModule} from 'angular-password-strength-meter';
import {MultiSelectModule} from 'primeng/multiselect';
import {CheckboxModule} from 'primeng/checkbox';

@NgModule({
  imports: [
    CommonModule,
    ProfileRoutingModule,
    CoreModule,
    FormsModule,
    ImageCropperModule,
    TranslateModule,
    PasswordStrengthMeterModule,
    MultiSelectModule,
    CheckboxModule
  ],
  declarations: [
    ProfileListComponent,
    ProfileCreateComponent,
    ProfileUpdateComponent,
    ProfileDetailComponent
  ]
})
export class ProfileModule {
}
