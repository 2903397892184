import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { CoreModule } from '../../core/core.module';
import { ExecutiveRoutingModule } from './executive-routing.module';
import { ExecutiveListComponent } from './executive-list/executive-list.component';
import { ExecutiveDetailComponent } from './executive-detail/executive-detail.component';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import {TranslateModule} from '@ngx-translate/core';
import {MemoModule} from '../memos/memo/memo.module';
import {CameraComponent} from '../camera/camera.component';
import {WebcamModule} from 'ngx-webcam';
import {NgOtpInputModule} from 'ng-otp-input';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ImageCropperModule} from 'ngx-image-cropper';
import {SignatureService} from '../../shared/services/signature.service';

@NgModule({
  imports: [
    CommonModule,
    ExecutiveRoutingModule,
    CoreModule,
    FormsModule,
    PdfViewerModule,
    CanvasWhiteboardModule,
    TranslateModule,
    MemoModule,
    WebcamModule,
    NgOtpInputModule,
    ColorPickerModule,
    ImageCropperModule
  ],
  exports: [
    CameraComponent
  ],
  declarations: [
    ExecutiveListComponent,
    ExecutiveDetailComponent,
    CameraComponent
  ],
  providers: [
    SignatureService,
  ]
})
export class ExecutiveModule {}
