import {Injectable} from '@angular/core';
import {NgbThaiDateParserFormatter} from '../../../../../../core/NgDateParser';
import {FormBGPUProductSell, FormPR} from '../memo-template';

@Injectable({
  providedIn: 'root'
})
export class FormTemplateListService {

  constructor(
    private dateFormat: NgbThaiDateParserFormatter
  ) {
  }

  checkFormTemplate(template, memoId) {
    switch (template) {
      case 'asset_form': {
        return this.defaultFormAsset(memoId);
      }
      case 'expenses_claim_form': {
        return this.defaultFormExpensesClaim(memoId);
      }
      case 'internal_form': {
        break;
      }
      case 'budget_request_form': {
        return this.defaultFormBudgetRequest(memoId);
      }
      case 'budget_transfer_form': {
        return this.defaultFormBudgetTransfer(memoId);
      }
      case 'bgpu_product_sell_form': {
        return this.defaultFormBGPUProductSell(memoId);
      }
      case 'pr_form': {
        return this.defaultFormPR(memoId);
      }
    }
  }

  checkTemplateResponse(template, data) {
    switch (template) {
      case 'asset_form': {
        return this.dataFormAsset(data);
      }
      case 'expenses_claim_form': {
        return data;
      }
      case 'internal_form': {
        return data;
      }
      case 'budget_request_form': {
        return data;
      }
      case 'budget_transfer_form': {
        return data;
      }
      case 'bgpu_product_sell_form': {
        return data;
      }
      case 'pr_form': {
        return data;
      }
    }
  }

  checkConvertData(template, data) {
    switch (template) {
      case 'asset_form': {
        return this.convertFormAsset(data);
      }
      case 'expenses_claim_form': {
        return data;
      }
      case 'internal_form': {
        return data;
      }
      case 'budget_request_form': {
        return data;
      }
      case 'budget_transfer_form': {
        return data;
      }
      case 'bgpu_product_sell_form': {
        return data;
      }
      case 'pr_form': {
        return data;
      }
    }
  }

  defaultFormAsset(memoId) {
    return {
      memo: memoId,
      type_request: null,
      type_purpose: null,
      purpose_detail: null,
      asset_table: [
        {
          sequence: 1,
          id: null,
          code: '',
          description: '',
          quantity: 0,
          amount: 0,
          depreciation: 0,
          residual_value: 0,
          get_date: null,
          remark: ''
        }
      ],
    };
  }

  dataFormAsset(formAsset) {
    formAsset.asset_table.map(item => {
      return item.get_date = this.dateFormat.parse(item.get_date);
    });

    return formAsset;
  }

  convertFormAsset(data) {
    data.asset_table.forEach(item => {
      if (item.get_date) {
        item.get_date = this.dateFormat.format(item.get_date, true);
      }
    });
    return data;
  }

  defaultFormExpensesClaim(memoId) {
    return {
      memo: memoId,
      start_details: null,
      end_details: null,
      is_vat_7: false,
      sum_amount: 0,
      wbs: null,
      cost_centre: null,
      expenses_claim_table: [
        {
          sequence: 1,
          id: null,
          description: '',
          quantity: 0,
          unit_price: 0,
          amount: 0
        }
      ],
    };
  }

  defaultFormBudgetRequest(memoId) {
    return {
      memo: memoId,
      budget: 0,
      available: 0,
      po_pr_pending: 0,
      add_budget: 0,
      purpose: null,
      wbs: null,
      cost_centre: null
    };
  }

  defaultFormBudgetTransfer(memoId) {
    return {
      memo: memoId,
      from_budget: 0,
      to_budget: 0,
      from_remaining: 0,
      to_remaining: 0,
      transfer_budget: 0,
      purpose: null,
      from_wbs: null,
      to_wbs: null,
      from_cost_centre: null,
      to_cost_centre: null,
      year_form_wbs: null,
      year_to_wbs: null,
    };
  }

  defaultFormBGPUProductSell(memoId) {
    return {
      memo: memoId,
      start_details: null,
      end_details: null,
      is_vat_7: false,
      sum_amount: 0,
      wbs: null,
      cost_centre: null,
      product_type: null,
      bgpu_product_sell_table: [
        {
          sequence: 1,
          id: null,
          description: null,
          quantity: 0,
          unit: null,
          unit_price: 0,
          amount: 0,
          remark: null,
        }
      ],
    } as FormBGPUProductSell;
  }

  defaultFormPR(memoId) {
    return {
      memo: memoId,
      sum_amount: 0,
      wbs: null,
      cost_centre: null,
      supplier: null,
      tel: null,
      delivery_at: null,
      job_number: null,
      payment_term: null,
      remark: null,
      details: [
        {
          sequence: 1,
          id: null,
          description: null,
          quantity: 0,
          unit_price: 0,
          amount: 0,
          item: null,
        }
      ],
    } as FormPR;
  }

}
