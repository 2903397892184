import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {AuthenticationService} from './shared/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivateChild, CanActivate {

  constructor(private router: Router, private authService: AuthenticationService, ) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      return this.authService.isAdmin() || this.authService.isIt();
    }
    this.router.navigate(['/'], {queryParams: {returnUrl: state.url}});
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      return this.authService.isAdmin() || this.authService.isIt();
    }
    this.router.navigate(['/'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
