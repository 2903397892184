import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-general-loa-modal',
  templateUrl: './general-loa-modal.component.html',
  styleUrls: ['./general-loa-modal.component.scss']
})
export class GeneralLoaModalComponent {

  modal: NgbModalRef;

  @Input() data: any;
  @Output() deleted = new EventEmitter();

  constructor(private modalService: NgbModal) {
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  removeLOA(event) {
    this.deleted.emit({
      id: event.data
    });
    this.modal.close();
  }
}
