import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../../shared/services/sweet-alert.service';
import {FormPR} from '../memo-template';

@Injectable()
export class TemplatePrService {
  constructor(private translate: TranslateService, private swal: SweetAlertService) {
  }

  validateForm(form: FormPR): boolean {
    // validate required
    const requireFields = ['supplier', 'delivery_at', 'job_number'];
    for (const field of requireFields) {
      if (!form[field]) {
        this.swal.toastNotification({
          type: 'error',
          content: this.translate.instant(`pr.err-${field}-require`)
        });
        return false;
      }
    }

    if (!this.validateDetails(form)) {
      return false;
    }

    return true;
  }

  validateDetails(form: FormPR): boolean {
    const details = form.details;
    for (const detail of details) {
      // validate require
      if (!detail.item) {
        this.swal.toastNotification({
          type: 'error',
          content: this.translate.instant('pr.err-item-require')
        });
        return false;
      }

      // validate GL lock
      if (detail.item_dropdown && detail.item_dropdown.context.gl_is_lock) {
        const msg = String(this.translate.instant('pr.err-gl-lock'))
          .replace('{{gl_code}}', detail.item_dropdown.context.gl_code);
        this.swal.toastNotification({
          type: 'error',
          content: msg
        });
        return false;
      }
    }

    // validate same wbs type
    const wbsRequireSet = new Set(
      details.map((d) => d.item_dropdown.context.wbs_required)
    );
    if (wbsRequireSet.size !== 1) {
      this.swal.toastNotification({
        type: 'error',
        content: this.translate.instant('pr.err-same-wbs')
      });
      return false;
    }

    // validate wbs and cost center
    const wbsRequire = wbsRequireSet.values().next().value;
    if (wbsRequire) {
      if (!form.wbs || !form.cost_centre) {
        this.swal.toastNotification({
          type: 'error',
          content: this.translate.instant('pr.err-wbs-require')
        });
        return false;
      }
    } else {
      if (form.wbs || form.cost_centre) {
        this.swal.toastNotification({
          type: 'error',
          content: this.translate.instant('pr.err-wbs-not-require')
        });
        return false;
      }
    }
    return true;
  }
}
