import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {MemoService} from '../../../memos/memo/memo.service';
import {TranslateService} from '@ngx-translate/core';


interface Department {
  id: number;
  sequence: number;
  name: string;
  name_en: null | string;
  code: string;
  company: number;
}


@Component({
  selector: 'app-department-edit',
  templateUrl: './department-edit.component.html',
  styleUrls: ['./department-edit.component.scss']
})
export class DepartmentEditComponent implements OnInit {

  modal: NgbModalRef;
  reason = '';
  name = '';
  code = '';
  company_list = [{
    name: 'Corporate',
    id: 1
  }, {
    name: 'Property',
    id: 2
  }
  ];
  level = null;
  @Input() department: Department;
  @Output() submitted = new EventEmitter();

  constructor(private modalService: NgbModal,
              private swal: SweetAlertService,
              private apiService: MemoService,
              private translate: TranslateService
  ) {
  }


  ngOnInit(): void {
    this.name = this.department.name;
    this.code = this.department.code;
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  submitModal() {
    const msg = [];
    const dataToBeSent = {
      name: this.name,
      code: this.code,
    };
    if (!this.name) {
      msg.push('Department name');
    }
    if (this.code.length < 3) {
      msg.push('Document Code');
    }
    if (msg.length > 0) {
      this.swal.toastNotification({type: 'error', content: ('Please enter ') + msg.join(', ')});
      return;
    } else {
      this.apiService.editDepartment(this.department.id, dataToBeSent).subscribe(
        (department: Department) => {
          this.department.name = department.name;
          this.department.code = department.code;
          this.modal.close();
          this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        }, error => {
          this.swal.toastNotification({
            type: 'error',
            content: 'This department is already in the system. or this document number is already in the system.'
          });
        }
      );
    }
  }

}
