import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {API_URL} from '../../shared/constants/api.constant';
import {HttpClientService} from '../../shared/services/http-client.service';

@Injectable()
export class OperationLogService {

  constructor(private httpLocal: HttpClient, private http: HttpClientService) {
  }

  getOperationLogList(params?) {
    return this.http.get(API_URL.operation_log, params);
  }

  getOperationLogDropDown() {
    return this.http.get(API_URL.operation_log + 'drop_down/');
  }

  getOperationLogCSV(params?) {
    return this.http.getBlob(API_URL.operation_log + 'csv/', params);
  }

}
