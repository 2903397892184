import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ApprovalDetailComponent } from './approval-detail/approval-detail.component';

export const approvalRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':id',
        component: ApprovalDetailComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(approvalRoutes)],
  exports: [RouterModule]
})
export class ApprovalRoutingModule { }
