import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ProfileService} from '../../../../shared/services/profile-service';
import {HttpParams} from '@angular/common/http';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {UserService} from '../../user/user.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {Subscription} from 'rxjs';
import {MemoService} from '../../../memos/memo/memo.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss']
})
export class ProfileDetailComponent implements OnInit, OnDestroy {
  viewType = 0;
  documentList = [];
  isLoading = false;
  extentionFile = '';
  showPreview = false;
  documentData: any;
  count = 0;
  currentPage = 1;
  userID = localStorage.getItem('profile_id');
  isAdmin = false;
  isLoadingTransfer = false;
  eventStatus;
  modal: NgbModalRef;
  budgetView = 'all company';
  companyNameList;
  companyName: any;

  userList = [];
  selectUser = null;
  checkTransfer = false;
  showResult = true;

  // Tooltip
  toolTipDisable = '';
  toolTipDelete = '';

  subscriptions = new Subscription();

  @Input() profile: any;
  @Output() toggleEdit = new EventEmitter();
  @Output() actionChanged = new EventEmitter();
  @Output() saveDelete = new EventEmitter();

  constructor(private profileService: ProfileService,
              private userService: UserService,
              private modalService: NgbModal,
              private swal: SweetAlertService,
              private translate: TranslateService,
              private authenticationService: AuthenticationService,
              private memoService: MemoService,
  ) {
  }

  ngOnInit() {
    this.initialCompanyNameList();
    this.isAdmin = this.authenticationService.isAdmin();
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateContent();
    });
    this.subscriptions.add(translateSub);
    this.translateContent();
    this.checkCompanyname();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initialCompanyNameList() {
    if (environment.web_instance === 'BGC') {
      this.companyNameList = [
        {id: 1, name: 'BGC'},
        {id: 2, name: 'BGCG'},
        {id: 3, name: 'PGI'},
        {id: 4, name: 'AGI'},
        {id: 5, name: 'PTI'},
        {id: 6, name: 'RBI'},
        {id: 7, name: 'AY1'},
        {id: 8, name: 'AY2'},
        {id: 9, name: 'BVP'},
        {id: 10, name: 'PRM'},
        {id: 11, name: 'BGCP'},
      ];
    } else if (environment.web_instance === 'BG') {
      this.companyNameList = [
        {id: 1, name: 'BG'},
        {id: 2, name: 'BGA'},
        {id: 3, name: 'BGPU'},
        {id: 4, name: 'KBI'},
        {id: 6, name: 'BGE'},
      ];
    }
  }

  translateContent() {
    this.toolTipDisable = this.translate.instant('TOOLTIP.DISABLE-USER');
    this.toolTipDelete = this.translate.instant('TOOLTIP.DELETE-USER');
  }

  checkCompanyname() {
    this.companyName = this.companyNameList[this.profile.company - 1]['name'];
  }

  getProfileDocument() {
    this.isLoading = true;
    const params = new HttpParams()
      .set('page', this.currentPage.toString())
      .set('profile', this.profile.id.toString());
    this.profileService.getProfileDocument(params).subscribe(
      (documents: any) => {
        this.documentList = documents.results;
        this.count = documents.count;
        this.isLoading = false;
      },
      error => {
        this.swal.toastNotification({type: 'error', content: 'Unable to load profile document'});
        this.isLoading = false;
      }
    );
  }

  getUserList() {
    this.userList = [];
    this.showResult = false;
    const generalRole = '1';
    const params = new HttpParams()
      .set('type', 'profile')
      .set('role', generalRole);
    this.memoService.getDropDown(params).subscribe(dropDown => {
      this.userList = (dropDown.profile || [])
        .filter(profile => profile.id !== this.profile.id)
        .map(profile => {
          return {name: profile.name, id: profile.id};
        });
      this.showResult = true;
    }, error => {
      this.swal.toastNotification({type: 'error', content: 'Unable to load user list'});
    });
  }

  changePage() {
    this.getProfileDocument();
  }

  selectView(type) {
    this.viewType = type;
  }

  editProfile() {
    this.toggleEdit.emit();
  }

  getFile(file) {
    const fd = new FormData();
    fd.append('file_upload', file.target.files[0]);
    fd.append('profile', this.profile.id);
    this.profileService
      .uploadProfileDocument(fd)
      .subscribe(
        () => this.getProfileDocument(),
        error => this.swal.toastNotification({type: 'error', content: 'Unable to upload profile document'})
      );
  }

  getExtention({file_upload}) {
    return file_upload.substr(file_upload.lastIndexOf('.') + 1);
  }

  preview({file_upload}) {
    this.showPreview = true;
    this.documentData = file_upload;
  }

  closePreview() {
    this.showPreview = false;
  }

  deleteDocument(data) {
    this.profileService
      .deleteProfileDocument(data.data.id)
      .subscribe(
        () => this.getProfileDocument(),
        error => this.swal.toastNotification({type: 'error', content: 'Unable to delete profile document'})
      );
  }

  openUpdate(event, accessible) {
    this.eventStatus = event;
    this.modal = this.modalService.open(accessible);
  }

  closeModal() {
    this.modal.close();
    this.clearData();
  }

  clearData() {
    this.selectUser = null;
    this.checkTransfer = false;
  }

  updateAccessible(key) {
    const data = {
      id: this.profile.id,
      is_active: (key === 'true')
    };
    this.userService.updateUserActive(data).subscribe(
      user => {
        this.profile.is_active = user.is_active;
        this.swal.toastNotification({type: 'success'});
        this.modal.close();
      },
      error => {
        this.swal.toastNotification({type: 'error', content: this.translate.instant('ERROR.CONTACT-DEV')});
        this.modal.close();
      }
    );
  }

  closeOTP() {
    const data = {
      profile: this.profile.id,
    };
    this.profileService.CloseOtp(data).subscribe(
      () => {
        this.profile.otp_enable = false;
        this.swal.toastNotification({type: 'success'});
        this.modal.close();
      },
      error => {
        this.swal.toastNotification({type: 'error', content: this.translate.instant('ERROR.CONTACT-DEV')});
        this.modal.close();
      }
    );
  }

  closeToggle() {
    const toggleSideMenu = true;
    this.actionChanged.emit(toggleSideMenu);
  }

  openDeleteModal(data, deleteModal) {
    this.clearData();
    this.getUserList();
    this.modal = this.modalService.open(deleteModal);
  }

  deleteUser() {
    if (this.checkTransfer) {
      this.selectUser = null;
    }
    const data = {
      from_profile: this.profile.id,
      to_profile: this.selectUser
    };
    this.isLoadingTransfer = true;
    this.profileService.deleteUser(data).subscribe(
      () => {
        this.saveDelete.emit();
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        this.modalService.dismissAll();
        this.isLoadingTransfer = false;
      },
      error => {
        this.swal.toastNotification({type: 'error', content: this.translate.instant('ERROR.CONTACT-DEV')});
        this.isLoadingTransfer = false;
      }
    );

  }

  transferPermission(event) {
    this.checkTransfer = event;
    this.selectUser = null;
  }

  confirmDeleteModal(confirmDeleteModal) {
    const msg = [];
    if (!this.checkTransfer && !this.selectUser) {
      msg.push(this.translate.instant('CREATE-USER.ERROR-NOT-SELECT'));
    }
    if (msg != null && msg.length > 0) {
      this.swal.toastNotification({type: 'error', content: msg.join(', ')});
      return;
    } else {
      this.modal = this.modalService.open(confirmDeleteModal);
    }
  }

}
