import {MessageService} from 'primeng/api';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {UserService} from '../../user/user.service';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {MemoService} from 'src/app/modules/memos/memo/memo.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-profile-update',
  templateUrl: './profile-update.component.html',
  styleUrls: ['./profile-update.component.scss']
})
export class ProfileUpdateComponent implements OnInit, AfterViewInit, OnDestroy {
  userID: any = localStorage.getItem('profile_id');
  showResults = true;
  departmentList = [];
  roleList = [
    {
      name: 'General',
      id: 1
    },
    {
      name: 'IT',
      id: 4
    }
  ];
  isLoading = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  file_input: any = '';
  blob_output: any = '';
  errorMsg: any = '';
  currentSignature: any;
  departmentLabel = '';
  viewAllMemo = [
    {
      name: 'View all memo',
      value: true
    },
    {
      name: 'View only selected department',
      value: false
    }
  ];
  viewAllBudget = [
    {
      name: 'View all budget dashboard',
      value: true
    },
    {
      name: 'Not view budget dashboard',
      value: false
    }
  ];
  viewAllCompany = [
    {
      name: 'View all company budget dashboard',
      value: true
    },
    {
      name: 'Not view all company budget dashboard',
      value: false
    }
  ];
  is_view_all_memo = null;
  savesign: any;
  modal: NgbModalRef;
  isMasterAdmin = localStorage.getItem('role_name') === 'Master Admin';
  subscriptions = new Subscription();
  companyList = [];

  @Input() user: any;
  @Output() saveEdit = new EventEmitter();

  @ViewChild('imageCropper', {static: false}) cropperElement: ElementRef;
  @ViewChild('previewSignature', {static: false}) previewElement: ElementRef;
  private notification: any;

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private swal: SweetAlertService,
    private modalService: NgbModal,
    private memoService: MemoService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translatePage();
    });
    this.getCompany();
    this.subscriptions.add(translateSub);
    this.translatePage();
    this.currentSignature = this.user.signature;
    // this.getDepartmentList();
    this.savesign = this.user.signature;
    // if (this.user.multi_department === null || this.user.multi_department.length === 0) {
    //   this.user.multi_department = [this.user.department];
    // }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getCompany() {
    this.memoService.getCompanies()
      .subscribe((companies) => {
        this.companyList = companies.filter(item => item.id !== this.user.company);
      });
  }

  translatePage() {
    this.departmentLabel = this.translate.instant('CREATE-USER.CHOOSE');
    this.viewAllMemo = [
      {name: this.translate.instant('CREATE-USER.VIEW-ALL-MEMO'), value: true},
      {name: this.translate.instant('CREATE-USER.VIEW-ONLY'), value: false}
    ];
    this.departmentLabel = this.translate.instant('CREATE-USER.CHOOSE');
    this.roleList = [
      {name: this.translate.instant('CREATE-USER.GENERAL'), id: 1},
      {name: this.translate.instant('CREATE-USER.IT'), id: 4}
    ];
    this.viewAllBudget = [
      {name: this.translate.instant('PROFILE.VIEW-ALL-BUDGET'), value: true},
      {name: this.translate.instant('PROFILE.NOT-VIEW-ALL-BUDGET'), value: false},
    ];
    if (this.isMasterAdmin) {
      this.roleList.unshift({name: this.translate.instant('CREATE-USER.ADMIN'), id: 0});
    }
  }


  getDepartmentList() {
    this.memoService.getDepartmentList().subscribe(
      (departments: any) => {
        departments.results.forEach(obj => {
          this.departmentList.push({
            label: obj.name,
            value: obj.id
          });
        });
      },
      error => this.notification.showNotification(error.status)
    );
  }

  ngAfterViewInit() {
  }

  submit() {
    // if (this.user.multi_department[0] === null) {
    //   this.user.multi_department.splice(0, 1);
    // }
    this.isLoading = true;
    const fd = new FormData();

    const msg = [];

    // if (!this.user.first_name || !this.user.last_name) {
    //   msg.push('Name and Lastname cannot be empty');
    // }
    //
    // if (!this.user.email) {
    //   msg.push('Email cannot be empty');
    // }
    // if (!this.user.job_position) {
    //   msg.push('Jobposition cannot be empty');
    // }
    // if (this.user.multi_department === undefined || this.user.multi_department.length === 0) {
    //   msg.push('Department cannot be empty');
    // }


    if (msg.length > 0) {
      this.isLoading = false;
      this.swal.toastNotification({type: 'error', content: msg.join(', ')});
      return;
    }

    const data = {
      first_name: this.user.first_name || '',
      last_name: this.user.last_name || '',
      // first_name_en: this.user.first_name_en || '',
      // last_name_en: this.user.last_name_en || '',
      phone_number_1: this.user.phone_number_1 || '',
      // user_email: this.user.email || '',
      role: this.user.role,
      // name_title: this.user.name_title || '',
      // name_title_en: this.user.name_title_en || '',
      job_position: this.user.job_position || '',
      permission_company: this.user.permission_company || '',
      multi_department: this.user.multi_department,
      is_view_all_memo: this.user.is_view_all_memo,
      is_view_all_budget: this.user.is_view_all_budget,
      is_view_all_company: this.user.is_view_all_company
    };

    this.userService.updateUser(this.user.id, data).subscribe(
      user => {
        // if (this.blob_output) {
        //   fd.append('signature', this.user.signature);
        //   this.userService.updateUser(this.user.id, fd).subscribe((user2) => {
        //     this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        //     this.saveEdit.emit(user2);
        //     this.isLoading = false;
        //   });
        // } else {
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        this.saveEdit.emit(user);
        this.isLoading = false;
        // }

      },
      response => {

        if (response.error && response.error.en) {
          this.swal.toastNotification({type: 'error', content: response.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error', content: 'Unable to update information'});
        }

        this.isLoading = false;
      }
    );
  }

  back() {
    this.saveEdit.emit();
  }

  fileChangeEvent(file: any): void {
    this.errorMsg = '';
    this.imageChangedEvent = file;
    this.file_input = file.target.files[0];
    // this.user.signature = file.target.files[0];
  }

  imageCropped(output) {
    this.croppedImage = output.base64;
    this.blob_output = output.file;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.errorMsg = 'ไม่สามารถประมวลผลภาพได้ กรุณาอัพโหลดภาพใหม่อีกครั้ง';
    // show message
  }

  clearSignature() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.file_input = '';
    this.blob_output = '';
  }

  saveSignature() {
    if (this.file_input && this.blob_output) {
      const filename =
        this.file_input.name.substring(0, this.file_input.name.lastIndexOf('.')) + '.png';
      this.user.signature = new File([this.blob_output], filename, {type: this.blob_output.type});
      this.savesign = this.croppedImage;
    }
    this.modalService.dismissAll();
  }

  openUploadSign(cropImg) {
    this.modal = this.modalService.open(cropImg, {backdrop: 'static', keyboard: false, size: 'lg'});
  }
}
