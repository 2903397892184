const url = window['location']['href'];
const matches = url.match(/^http(s?)?\:\/\/([^\/?#]+)/);

export const environment = {
  production: false,
  hmr: false,
  host_url: matches[0],
  web_instance: 'BGC',
  bgbgc_frontend_url: 'https://dev.bgbgc.memo.heroapp.dev'
};
