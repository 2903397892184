import { HttpParams } from '@angular/common/http';
import { MemoService } from '../memo.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-memo-link-modal',
  templateUrl: './memo-link-modal.component.html',
  styleUrls: ['./memo-link-modal.component.scss']
})
export class MemoLinkModalComponent implements OnInit {
  modal: NgbModalRef;
  memoList = [];
  isShowDetail = [];
  searchInput = '';
  description = '';
  isLoading = false;
  count = 0;
  currentPage = 1;
  errorMessage = false;

  @Input() memo;
  @Output() memoLinked = new EventEmitter();

  constructor(
    private notification: NotificationService,
    private modalService: NgbModal,
    private memoService: MemoService
  ) {}

  ngOnInit() {
    this.getMemoList();
  }

  getMemoList() {
    const params = new HttpParams()
    .set('page', this.currentPage.toString())
    .set('memo_id', this.memo.id)
    .set('search', this.searchInput);
    this.memoService.getAvailableLink(params).subscribe(
      memos => {
        this.memoList = memos['results'];
        this.count = memos['count'];
      },
      error => this.notification.showNotification(error.status)
    );
  }

  changePage() {
    this.getMemoList();
  }

  searchValue(searchInput) {
    this.currentPage = 1;
    this.searchInput = searchInput;
    this.getMemoList();
  }

  open(content) {
    this.getMemoList();
    this.modal = this.modalService.open(content, { size: 'lg' });
  }

  submit(memo) {
    this.errorMessage = false;

    if (!this.description) {
      this.errorMessage = true;
      return;
    }

    this.isLoading = true;
    const data = {
      memo: memo.id,
      description: this.description
    };
    this.memoService.createMemoLink(data).subscribe(
      linkedMemo => {
        const obj = {
          memo_link: this.memo.memo_link.concat([linkedMemo.id])
        };
        this.memoList = this.memoList.filter(m => m.id !== linkedMemo.id);
        this.memoService.updateMemoReal(this.memo.id, obj).subscribe(
          newMemo => {
            this.isLoading = false;
            this.isShowDetail = [];
            this.memo = newMemo;
            this.modal.close();
            this.memoLinked.emit(linkedMemo);
          },
          error => {
            this.notification.showNotification(error.status);
            this.isLoading = false;
          }
        );
      },
      error => {
        this.notification.showNotification(error.status);
        this.isLoading = false;
      }
    );
  }

  clickMemo(index) {
    if (this.isShowDetail[index]) {
      this.isShowDetail[index] = false;
    } else {
      this.description = '';
      this.isShowDetail = [];
      this.isShowDetail[index] = true;
    }
  }
}
