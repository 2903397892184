import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {ThemeService} from '../services/theme.service';

@Directive({
  selector: '[appSetTheme]'
})
export class SetThemeDirective implements OnInit {
  themeList: any;

  @Input() type: string;

  constructor(
    private themeService: ThemeService,
    private element: ElementRef,
    private renderer: Renderer2
  ) {
    this.themeService.data.subscribe(theme => {
      this.themeList = theme;
      if (this.themeList) {
        switch (this.type) {
          case 'headerTable':
            this.setThemeInWeb('header_table_color', 'text_header_table_color');
            break;
          case 'button':
            this.setThemeInWeb('header_table_color', 'text_header_table_color');
            break;
        }
      }
    });
  }

  ngOnInit() {
    this.themeService.themeSetting();
  }

  setThemeInWeb(bg: string, text: string) {
    this.renderer.setStyle(this.element.nativeElement, 'background', this.themeService.themeList[bg]);
    this.renderer.setStyle(this.element.nativeElement, 'color', this.themeService.themeList[text]);
  }

}
