import {MemoService} from '../../memo.service';
import {Component, Input, OnInit} from '@angular/core';
import {NotificationService} from 'src/app/shared/services/notification.service';
import {HttpParams} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {HttpClientService} from '../../../../../shared/services/http-client.service';

@Component({
  selector: 'app-memo-real-history',
  templateUrl: './memo-real-history.component.html',
  styleUrls: ['./memo-real-history.component.scss']
})
export class MemoRealHistoryComponent implements OnInit {
  historyList = [];
  currentPage = 1;
  count = 0;
  showResults = false;
  showDetail = false;
  checkLanguage = false;
  isLoadingExcel = false;
  @Input() memo;

  constructor(private notification: NotificationService,
              private translate: TranslateService,
              private httpClientService: HttpClientService,
              private memoService: MemoService) {
  }

  ngOnInit() {
    this.getMemoHistoryList();
    this.checkLanguage = this.translate.currentLang === 'en';
  }

  getMemoHistoryList() {
    this.showResults = false;
    const params = new HttpParams()
      .set('memo', this.memo.id.toString());
    this.memoService.getMemohistory(params).subscribe(
      (data: any) => {
        this.historyList = data.results.map(history => {
          if (history.status.includes('Transfer Memo')) {
            history.type = 'TRANSFER_MEMO';
            const sp = history.status.replace('Transfer Memo from ', '').split(
              'to'
            );
            history.personFrom = sp[0].trim();
            history.personTo = sp[1].trim();
          } else if (history.status.includes('Delegate Permission')) {
            history.type = 'DELEGATE';
            const sp = history.status.replace('Delegate Permission from ', '').split(
              'to'
            );
            history.personFrom = sp[0].trim();
            history.personTo = sp[1].trim();

          } else if (history.status.includes('Transfer LOA')) {
            history.type = 'TRANSFER_LOA';
            const sp = history.status.replace('Transfer LOA from ', '').split(
              'to'
            );
            history.personFrom = sp[0].trim();
            history.personTo = sp[1].trim();
          } else {
            history.status = 'HISTORY.' + history.status.toUpperCase();
          }

          return history;
        });
        this.showResults = true;
      },
      error => {
        this.notification.showNotification(error.status);
      }
    );
  }

  changePage() {
    this.getMemoHistoryList();
  }

  closeToggle() {
    this.showDetail = false;
  }

  downloadHistory() {
    this.isLoadingExcel = true;
    this.memoService.getHistoryLogCSV(this.memo.id).subscribe(
      res => {
        const filename = 'History Log Memo.csv';
        this.httpClientService.createDownloadFile(res, filename);
        this.isLoadingExcel = false;
        this.getMemoHistoryList();
      },
      err => {
        this.isLoadingExcel = false;
      }
    );
  }

}
