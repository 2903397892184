import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';

import { OperationLogRoutingModule } from './operation-log-routing.module';
import { OperationLogComponent } from './operation-log.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../../core/core.module';
import {OperationLogService} from './operation-log.service';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
  declarations: [
    OperationLogComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    OperationLogRoutingModule,
    CalendarModule,
    FormsModule,
    TranslateModule,
    SharedModule
  ],
  providers: [
    OperationLogService
  ]
})
export class OperationLogModule {
}
