import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GeneralListComponent } from './general-list/general-list.component';
import { LoaListComponent } from './loa-list/loa-list.component';

export const generalRoutes: Routes = [
  {
    path: '',
    component: LoaListComponent,
    children: [{ path: 'list/:tab', component: LoaListComponent }],
  }
];

@NgModule({
  imports: [RouterModule.forChild(generalRoutes)],
  exports: [RouterModule],
})
export class GeneralRoutingModule {}
