import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MessageService} from 'primeng/api';
import {GeneralService} from '../../../../setting/general/general.service';
import {LoaService} from '../../../loa/loa.service';
import {MemoService} from '../../memo.service';
import {NotificationService} from '../../../../../shared/services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NgbThaiDateParserFormatter} from '../../../../../core/NgDateParser';
import {UserService} from '../../../../permission/user/user.service';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {forEach, get} from 'lodash';
import {FileSystemFileEntry, UploadEvent} from 'ngx-file-drop';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {SignaturePosition} from './upload-template-customize/upload-template-cuxtomize.models';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';

interface Level {
  members: Array<any>;
  min_approve_count: number;
  level: number;
}

@Component({
  selector: 'app-template-upload',
  templateUrl: './template-upload.component.html',
  styleUrls: ['./template-upload.component.scss']
})
export class TemplateUploadComponent implements OnInit, OnDestroy {

  PAGE_DIMENSIONS = [];
  PAGE_COUNT = 0;
  PAGE_SIZE_WIDTH = 0;
  PAGE_SIZE_HEIGHT = 0;

  isLoadingPreview: boolean;

  RECT_SIZE_WIDTH = 150;
  RECT_SIZE_HEIGHT = 45;
  allDepartmentList = [];
  CHECKBOX_SIZE_WIDTH = 30;
  CHECKBOX_SIZE_HEIGHT = 30;
  text = '<div>Hello World!</div><div>PrimeNG <b>Editor</b> Rocks</div><div><br></div>';
  signatureClickButtonName: any = null;
  isLoading = false;
  loaList = [];
  loaListAll = [];
  loa: any = [];
  documentFile: File;
  docxFile: File;
  isLoadingData = false;
  selected_page_number = 0;
  preview_url = '';
  job_position = [];
  content = '';
  logo = {
    id: null,
    image: null
  };
  loaSelected = false;
  documentUploaded = false;
  address: any;
  error: any = {};
  profile: any;
  department: any;
  remark: string;
  documentData: any;
  showPreview = false;
  modal: NgbModalRef;
  approvalList = [];
  approvallistLevels = {};
  loa_id: number;
  loaNormalLevels = [];
  loaCustomLevels = [];
  isCustomLOA: boolean;
  countnumber: any;
  files: any[] = [];
  fileUpload: File;
  to: any;
  count_sign = 0;
  count_levels = 0;
  departments = [];
  greenCircleStart = 2;
  signaturePositions: Array<SignaturePosition>;
  signatureBoxColor = ['#E07770',
    '#48FF94',
    '#8D42DA',
    '#70FA4F',
    '#2CC39A',
    '#5BC863',
    '#5CB0E4',
    '#FDEE59',
    '#F5EFBF',
    '#86CDF5',
    '#9CB11E',
    '#442E4E',
    '#67473B',
    '#DD10A2',
    '#7AE910',
    '#E0C1C3',
    '#23DED1',
    '#68F643',
    '#046FF0',
    '#558D43'];
  selectPositionMode = false;
  currentSelectSignature: SignaturePosition;
  fileExists = false;
  insideText = 'Browse or Drop files here';
  contentApprovel = [];
  labelMulti = '';
  memoSpecifycc = '';
  memoSelectdepartment = '';
  departmentList = [];
  toProfileList = [];
  profileListAll = [];
  companyName;
  selectedDepartment;
  company_id;
  memoReference = null;
  memoNumberList = [
    {
      name: 'Auto Generated',
      id: 'Auto Generated'
    },
    {
      name: 'Custom Memo Number',
      id: 'Custom Memo Number'
    }
  ];

  loaSignUserChoices = [
    {value: 'user', label: 'user'},
    {value: 'none user', label: 'non-user'},
  ];
  loaSignNoneUserPlaceHolder = {
    name: 'Contact Name',
    job_position: 'Job Position',
    email: 'Email Address'
  };

  isPermissionOnlyRelated = {value: false, label: 'Only related'};
  // pdf
  pdfPage = 1;
  pdfTotalPages: number;
  pdfIsLoaded = false;
  pdfMemo: File;

  // tooltip
  toolTipSave = '';
  toolTipPreview = '';

  loadTo = false;

  // memoData: any;
  // countEmitOfProfileList = 0;
  // countEmitNormalLoa = 0;
  countEmitFromLoaSelect = 0;

  subscriptions = new Subscription();

  userKeyMemoNumber$ = new Subject<string>();
  userKeyMemoNumber: string;
  memoNumberAlreadyExist = false;

  @ViewChild('pdfImageContainer', {static: false}) pdfImageContainer: ElementRef;

  // tslint:disable-next-line:no-input-rename
  // @Input('memoData') memoData;
  // tslint:disable-next-line:no-input-rename
  @Input('memoData') memoData;
  @Output() savedMemo = new EventEmitter();
  @Output() editSuccess = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() togglePreview = new EventEmitter();

  constructor(
    private messageService: MessageService,
    private generalService: GeneralService,
    private loaService: LoaService,
    private memoService: MemoService,
    private notification: NotificationService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private date: NgbThaiDateParserFormatter,
    private userService: UserService,
    private notificationService: NotificationService,
    private swal: SweetAlertService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.company_id = localStorage.getItem('company_id');
    this.department = this.memoData.department;
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateSelect();
    });
    this.subscriptions.add(translateSub);
    this.getApprovalList('');
    this.loadLOAList();
    this.translateSelect();
    this.loadCompany();
    this.loadDepartment();
    this.getRefMemos();
    this.loadAllGeneralProfile();
    // this.memoData = _.cloneDeep(this._memoData);
    this.loaCustomLevels = this.memoData.loa;
    this.memoData.is_custom_memo_number = true;  /* fix only manual add memo number  */
    this.signaturePositions = this.memoData.signature_position;
    if (this.memoData.word_cloud_pdf) {
      this.fileExists = true;
      this.getPDFFromMemo();
    }
    this.isLoadingData = true;
    if (!this.memoData.customMemoNumberDropDown) {
      this.memoData.customMemoNumberDropDown = this.memoNumberList[0].id;
    }

    this.initialCheckMemoNumberExist();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  translateSelect() {
    this.labelMulti = this.translate.instant('MEMOS.CHOOSE');
    this.memoSpecifycc = this.translate.instant('MEMOS.SPECIFY-CC');
    this.memoSelectdepartment = this.translate.instant('MEMOS.SELECT-DEPARTMENT');
    this.memoNumberList = [
      {
        name: this.translate.instant('MEMOS.AUTO-GENERATED'),
        id: 'Auto Generated'
      },
      {
        name: this.translate.instant('MEMOS.CUSTOM-MEMO-NUMBER'),
        id: 'Custom Memo Number'
      }
    ];
    this.isPermissionOnlyRelated.label = this.translate.instant('MEMOS.ONLY-RELATED');
    this.toolTipSave = this.translate.instant('TOOLTIP.SAVE-MEMO');
    this.toolTipPreview = this.translate.instant('TOOLTIP.PREVIEW');
  }

  loadCompany() {
    const params = new HttpParams()
      .set('type', 'company')
      .set('company', this.company_id);
    this.memoService.getDropDown(params).subscribe((company) => {
      this.companyName = company.company[0].label;
    });
  }

  loadDepartment() {
    this.selectedDepartment = null;
    this.departmentList = [];
    const params = new HttpParams()
      .set('type', 'department')
      .set('department', this.department);
    this.memoService.getDropDown(params).subscribe((department) => {
      this.departmentList = department.department;
    });
  }

  getRefMemos() {
    const params = new HttpParams()
      .set('type', 'memo_number,department')
      .set('company', localStorage.getItem('company_id'));
    this.memoService.getDropDown(params).subscribe(dropDown => {
      this.memoReference = dropDown.memo_number;
      let departmentL = dropDown.department;
      departmentL = departmentL.map(item => {
        return {
          label: (item.context.code_number as string) + ' - ' + (item.label as string),
          value: item.value,
        };
      });
      this.departmentList = _.orderBy(departmentL, [department => department.label], 'asc');
    });
  }

  loadAllGeneralProfile() {
    const generalRole = '1';
    const params = new HttpParams()
      .set('type', 'profile')
      .set('company', localStorage.getItem('company_id'))
      .set('role', generalRole);
    this.memoService.getDropDown(params).subscribe(dropDown => {
      const profiles = dropDown.profile;
      this.profileListAll = _.orderBy(profiles, [profile => profile.name.toLowerCase()], 'asc');
    });
  }

  filterUser(event: { isCustom: boolean; isClear: boolean; data: { name: string; label: number }[] }) {
    this.countEmitFromLoaSelect += 1;
    const isNotSecondEmit = this.countEmitFromLoaSelect > 2;
    this.loadTo = true;
    this.isCustomLOA = event.isCustom;
    this.toProfileList = event.data;
    const isClear = event.isClear;
    this.loaCustomLevels = this.memoData.loa;
    // this.loaNormalLevels = this.memoData.loa;
    if (isNotSecondEmit || isClear) {
      // prevent emit at initail (2 times)
      this.populateSignaturePositions();
    }
    setTimeout(() => {
      this.loadTo = false;
    }, 10);
  }

  updateNormalLoa(event) {
    this.countEmitFromLoaSelect += 1;
    const isNotSecondEmit = this.countEmitFromLoaSelect > 2;
    this.isCustomLOA = event.isCustom;
    this.loaNormalLevels = event.data;
    this.toProfileList = [{
      name: localStorage.getItem('full_name'),
      label: +localStorage.getItem('profile_id')
    }];
    this.loaNormalLevels.forEach(level => {
      if (level.members) {
        level.members.forEach(member => {
          if (member.profile && member.profile_name) {
            this.toProfileList.push({name: member.profile_name, label: member.profile});
          }
        });
      }
    });
    const isClear = event.isClear;
    if (isNotSecondEmit || isClear) {
      // prevent emit at initail (2 times)
      this.populateSignaturePositions();
    }
  }

  back() {
    // if user click back, return original variable.
    this.editSuccess.emit(this.memoData);
  }

  closeToggle() {
    this.closed.emit();
  }

  getFile(file) {
    if (file === null) {
      this.documentFile = null;
      this.pdfMemo = null;
      return;
    }
    this.documentFile = new File([file], file.name);
    if (this.documentFile) {
      const reader = new FileReader();
      reader.onloadend = (e: any) => {
        this.pdfMemo = e.target.result;
      };
      reader.readAsArrayBuffer(this.documentFile);
    }
    this.populateSignaturePositions();
  }

  getPDFFromMemo() {
    this.memoService.getMemoRealDetail(this.memoData.id).subscribe(memo => {
      this.pdfTotalPages = memo.count_page;
      this.pdfMemo = memo.word_cloud_pdf;
    });
  }

  getPoint($event) {
    if (!this.selectPositionMode || !this.currentSelectSignature) {
      return;
    }

    // 1. PDF -> Image, Container
    const bounds = this.pdfImageContainer.nativeElement.getBoundingClientRect();

    // 2. Position of Mouse Pointer WRT Above Container
    const posx = $event.clientX - bounds.left;
    const posy = $event.clientY - bounds.top;

    // 3. Mouse Pointer != Rectangle Top Left Hence, Less Difference
    const posx_remove_rectabngle_width = posx - (this.RECT_SIZE_WIDTH / 2);
    const posy_add_rectangle_height = posy - (this.RECT_SIZE_HEIGHT / 2);

    // 4. Convert Absolute Pixel Information to Percentage WRT Page Size
    const posx_percentage = (posx_remove_rectabngle_width / this.PAGE_SIZE_WIDTH) * 100;
    const posy_percentage = (posy_add_rectangle_height / this.PAGE_SIZE_HEIGHT) * 100;


    // posx = posx - (type =='checkbox' ? this.CHECKBOX_SIZE_WIDTH : this.RECT_SIZE_WIDTH ) / 2;
    // posy = posy - (type =='checkbox' ? this.CHECKBOX_SIZE_HEIGHT : this.RECT_SIZE_HEIGHT ) / 2;

    const signature_location_data = this.currentSelectSignature;
    signature_location_data.positions[(this.selected_page_number - 1)] = {
      page: this.selected_page_number,
      X: posx_percentage,
      Y: posy_percentage
    };

    // this.signatureClickButtonName = null;
  }

  clicked($event) {
    this.selectPositionMode = false;
  }

  clear() {
    if (this.currentSelectSignature) {
      this.currentSelectSignature.positions[(this.selected_page_number - 1)] = {
        page: this.selected_page_number,
        X: null,
        Y: null
      };
    }
  }

  prepareDataForSave() {
    if (this.memoData.is_custom_memo_number) {
      const data = {
        name: this.memoData.name || '',
        subject: this.memoData.name || '',
        detail: this.memoData.detail,
        to: this.memoData.to,
        from: this.memoData.created_job_position,
        cc_list: this.memoData.cc_list,
        loa: this.loaCustomLevels,
        // loa: this.memoData.loa,
        memo_type: 'upload',
        department: this.memoData.department,
        signature_position: this.signaturePositions,
        signed_document: null,
        // memo_number: this.memoData.memo_number,
        is_custom_memo_number: this.memoData.is_custom_memo_number,
        announcement: this.memoData.announcement_list,
        is_permission: this.isPermissionOnlyRelated.value,
        reference: this.memoData.Ref || [],
      };

      if (this.memoData.memo_number && !this.memoNumberAlreadyExist) {
        data['memo_number'] = this.memoData.memo_number;
      }

      if (this.memoData.loa_group != null) {
        data['loa_group'] = this.memoData.loa_group.toString();
        data['custom_loa_group_id'] = null;
      } else if (this.memoData['loa'].lenght > 0 && !this.memoData['loa_group']) {
        data['loa_group_id'] = null; // custom loa
      }

      // if (this.loa_id != null) {
      //   data['loa_group'] = (this.loa_id.toString());
      //   data['custom_loa_group_id'] = null;
      // } else {
      //   data['loa_group_id'] = null; // custom loa
      // }

      if (this.memoData.department) {
        data['department'] = (this.memoData.department.toString());
      }

      return data;
    } else {
      const data = {
        name: this.memoData.name || '',
        subject: this.memoData.name || '',
        detail: this.memoData.detail,
        to: this.memoData.to,
        from: this.memoData.created_job_position,
        cc_list: this.memoData.cc_list,
        loa: this.loaCustomLevels,
        // loa: this.memoData.loa,
        memo_type: 'upload',
        department: this.memoData.department,
        signature_position: this.signaturePositions,
        announcement: this.memoData.announcement_list,
        is_permission: this.isPermissionOnlyRelated.value,
        reference: this.memoData.Ref || [],
      };

      if (this.memoData.loa_group != null) {
        data['loa_group'] = this.memoData.loa_group.toString();
        data['custom_loa_group_id'] = null;
      } else if ((this.memoData['loa'] || []).lenght > 0 && !this.memoData['loa_group']) {
        data['loa_group_id'] = null; // custom loa
      }

      // if (this.loa_id != null) {
      //   data['loa_group'] = (this.loa_id.toString());
      //   data['custom_loa_group_id'] = null;
      // } else {
      //   data['loa_group_id'] = null; // custom loa
      // }

      if (this.memoData.department) {
        data['department'] = (this.memoData.department.toString());
      }

      return data;
    }
  }

  save() {

    const data = this.prepareDataForSave();
    if (!this.memoService.validateMemo(data)) {
      return;
    }

    this.isLoading = true;
    this.memoService.updateMemoReal(this.memoData.id, data).subscribe(
      memo => {
        this.savePreview(() => {
          this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
          this.isLoading = false;
          if (this.documentFile) {
            const fd = new FormData();
            fd.append('word_cloud_pdf', this.documentFile);
            this.memoService.updateMemoReal(this.memoData.id, fd).subscribe(memo2 => {
              memo2.unread_comment_count = 0;
              memo2.unread_attachment_count = 0;
              this.editSuccess.emit(memo2);
            });
          } else {
            memo.unread_comment_count = 0;
            memo.unread_attachment_count = 0;
            this.editSuccess.emit(memo);
          }
        });
      },
      error => {
        this.isLoading = false;
        this.notification.showSpecificError(error.error);
      }
    );

    this.saveAttachments();
  }

  saveAttachments() {
    if (this.files) {
      const at = new FormData();
      at.append('memo', this.memoData.id);
      forEach(this.files, file => {
          const name = get(file, 'name', 'relativePath');
          file.fileEntry ? at.append(name, file.fileEntry) : at.append(name, file);
        }
      );
      this.memoService.uploadMemoAttachment(at).subscribe(
        res => {
          // this.isLoading = false;
        },
        error => (this.isLoading = false)
      );
    }
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  checkFileSize(file) {
    if (file != null && file.size / (1024 * 1024) >= 30) {
      this.notificationService.showSpecificError('กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 30 MB');
      return false;
    }

    return true;
  }

  dropped(event: UploadEvent) {
    for (const droppedFile of event.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          if (!this.checkFileSize(file)) {
            return;
          }

          this.files.push(file);
        });
      }
    }
  }

  selectFile(fileInput) {
    _.map(fileInput.target.files, (file) => {
      if (!this.checkFileSize(file)) {
        return;
      }

      this.files.push(file);
    });
  }

  removeFile(fileIndex) {
    this.signaturePositions = [];
    this.documentUploaded = false;
    this.files.splice(fileIndex, 1);
  }

  Preview() {
    this.isLoadingPreview = true;
    const data = this.prepareDataForSave();
    this.memoService.updateMemoReal(this.memoData.id, data).subscribe(
      memo => {
        // this.openPreview(() => {
        //   this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        //   this.getDataForPreview();
        // });
      },
      error => {
        this.isLoadingPreview = false;
        this.notification.showSpecificError(error.error);
      }
    );
  }

  getDataForPreview() {
    const params = new HttpParams()
      .set('memo_id', this.memoData.id);
    this.memoService.getMemoRealList(params).subscribe(
      memo => {
        memo.results.forEach(obj => {
          if (this.memoData.id === obj.id) {
            window.open(obj.signed_document);
            this.isLoadingPreview = false;
          }
        });
      });

  }

  openPreview(awit) {
    const params = new HttpParams()
      .set('memo_id', this.memoData.id);
    this.memoService.PreviewMemoReal(params).subscribe(
      preview => {
      },
      error => {
        this.preview_url = error.url;
        awit();
      }
    );
  }

  savePreview(f) {
    const params = new HttpParams()
      .set('memo_id', this.memoData.id);
    this.memoService.PreviewMemoReal(params).subscribe(
      () => {
        f();
      },
      error => {
        this.preview_url = error.url;
        f();
        // window.open(this.preview_url);
      }
    );
  }

  changeMemoNumber(e) {
    if (this.memoNumberList[0].id === e.id) {
      this.memoData.is_custom_memo_number = false;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[0].id;
    } else if (this.memoNumberList[1].id === e.id) {
      this.memoData.is_custom_memo_number = true;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[1].id;
    }
  }

  loadLOAList() {
      this.loaListAll.push({
        name: 'CUSTOM LOA',
        id: 'cus',
        department: null
      });
      this.loaList = _.cloneDeep(this.loaListAll);
      this.loaList = _.filter(this.loaListAll, loa => loa.department === this.memoData.department || loa.department === null);
      this.loaList.forEach(obj => {
        if (this.memoData.is_custom_loa) {
          this.loa = 'CUSTOM LOA';
        }
        if (this.memoData.loa_group === obj.id) {
          this.loa = obj.name;
        }
      });
      this.showLOAData();
  }

  populateSignaturePositions() {
    this.signaturePositions = [];
    if (this.isCustomLOA) {
      let indexCounter = 2;
      (this.loaCustomLevels || []).forEach((level, levelIndex) => {
        level.members.forEach((member, memberIndex) => {

          if (memberIndex < level.min_approve_count) {
            const data: SignaturePosition = {
              sequence: indexCounter,
              name: undefined,
              positions: null,
              signature: member.signature
            };

            if (level.user_type === this.loaSignUserChoices[0].value) {  // user type : user
              data.name = member.full_name ? `level ${levelIndex + 1}: ${member.full_name}` : `level ${level.level}`;
            } else if (level.user_type === this.loaSignUserChoices[1].value) {  // user type : none user
              data.name = member.name ? `level ${levelIndex + 1}: ${member.name}` : `level ${level.level}`;
            }
            this.signaturePositions.push(data);
          }

        });
        indexCounter += level.min_approve_count;
      });

    } else {
      let indexCounter = 2;
      _.forEach(this.loaNormalLevels, (level: Level) => {
        if (level.min_approve_count > 1) {
          for (let i = 0; i < level.min_approve_count; i++) {
            this.signaturePositions.push({
              sequence: indexCounter,
              name: `level ${level.level} - ${i + 1}`,
              positions: null,
              signature: level.members[i].signature
            });
          }
        } else {
          this.signaturePositions.push({
            sequence: indexCounter,
            name: level.members[0].profile_name ? `level ${level.level}: ${level.members[0].profile_name}` : `level ${level.level}`,
            positions: null
          });
        }
        indexCounter += level.min_approve_count;
      });
    }

    const prePositions: Array<SignaturePosition> = [
      {
        sequence: 0,
        name: 'date',
        positions: null,
        type: 'date'
      },
      {
        sequence: 1,
        name: 'memo number',
        positions: null,
        type: 'memo_number'
      }
    ];

    this.signaturePositions = prePositions.concat(this.signaturePositions);
    // this.updateToProfileList();
  }

  updateToProfileList() {
    this.toProfileList = this.signaturePositions
      .map((signaturePosition, index) => {
        if (index < 2) {
          return null;
        }
        if (!signaturePosition.name) {
          return null;
        }
        const splitNames: string[] = signaturePosition.name.split(':');
        const name: string = splitNames.length >= 2 ? splitNames[1].trim() : splitNames[0].trim();
        if (name.includes('undefined')) {
          return null;
        }
        return name;
      })
      .filter(name => !!name)
      .map(name => {
        return this.profileListAll.find(profile => profile.name === name);
      });
  }

  showLOAData() {
    if (this.memoData.is_custom_loa) { // custom group
      this.loa_id = null;
      this.loaCustomLevels = [];
      this.getApprovalList('');
      this.loaNormalLevels = [];
      this.isCustomLOA = true;
      this.countnumber += 1;
      this.loaCustomLevels = this.memoData.loa;
      _.forEach(this.loaCustomLevels, obj => {
        if (obj.signature_required) {
          this.contentApprovel.push({
            content: 'MEMOS.SIGNATURE-REQUIRE'
          });
        } else {
          this.contentApprovel.push({
            content: 'MEMOS.NO-SIGNATURE-REQUIRE'
          });
        }
      });
      if (this.loaCustomLevels === null) {
        this.loaCustomLevels = [];
      }

    } else { // normal group
      this.loa_id = this.memoData.loa_group;
      this.loaList.forEach(obj => {
        if (this.memoData.loa_group === obj.id) {
          this.loaNormalLevels = obj.levels;
        }
      });

      this.loaCustomLevels = [];
      this.isCustomLOA = false;
    }
  }

  selectLOA(data) {
    if (data === undefined) { // x
      this.loa_id = null;
      // this.getApprovalList('');
      this.signaturePositions = [];
      this.loaNormalLevels = null;
      this.isCustomLOA = false;
      this.loaCustomLevels = [];
      this.currentSelectSignature = undefined;
    } else {
      if (data.id === 'cus') { // custom loa
        // this.getApprovalList('');
        this.loa_id = null;
        this.loaNormalLevels = [];
        this.loaCustomLevels = [];
        this.isCustomLOA = true;
        this.countnumber += 1;
        this.contentApprovel.push({
          content: 'MEMOS.SIGNATURE-REQUIRE'
        });
        this.addSignatures();
      } else {  // normal loa
        this.getApprovalList(data.id);
        this.loa_id = data.id;
        this.loaNormalLevels = data.levels;
        this.loaCustomLevels = [];
        this.isCustomLOA = false;
      }
    }
    this.memoData.loaSelected = true;
    this.populateSignaturePositions();
  }

  getApprovalList(loa = '', custom?: boolean) {
    // const params = new HttpParams()
    //   .set('none_pagination', 'True')
    //   .set('loa', loa)
    //   .set('role', '1')
    //   .set('active_user', '1');
    // this.userService.getProfileList(params).subscribe((profiles: any) => {
    //   this.approvalList = _.orderBy(profiles, [profile => profile.full_name.toLowerCase()], 'asc');
    // });
    const generalRole = '1';
    const params = new HttpParams()
      .set('type', 'profile')
      .set('company', localStorage.getItem('company_id'))
      .set('role', generalRole);
    if (loa !== '') {
      params.set('loa', loa);
    }
    this.memoService.getDropDown(params).subscribe(dropDown => {
      const profiles = (dropDown.profile || []).map(item => {
        return {full_name: item.name, id: item.id};
      });
      // this.count_levels = custom ? 0 : this.count_levels;
      this.approvalList = _.orderBy(profiles, [profile => profile.full_name.toLowerCase()], 'asc');
      this.approvallistLevels[this.count_levels] = this.approvalList;
    });
  }

  addSignatures() {
    this.contentApprovel.push({
      content: 'MEMOS.SIGNATURE-REQUIRE'
    });
    this.loaCustomLevels.push({
      title: this.translate.instant('MEMOS.LEVEL') + `${this.loaCustomLevels.length + 1}`,
      members: [[]],
      signature_required: true,
      min_approve_count: 1,
      user_type: this.loaSignUserChoices[0].value
    });
    this.approvallistLevels[this.count_levels] = _.cloneDeep(this.approvalList);
    this.count_levels += 1;
  }

  removeSignatures() {
    this.loaCustomLevels.pop();
  }

  onChangeLoaUserType(levelIndex: number) {
    this.loaCustomLevels[levelIndex].members = [];
    this.addMemberToLevel(levelIndex);
  }

  addMemberToLevel(levelIndex: number) {
    if (this.loaCustomLevels[levelIndex].user_type === this.loaSignUserChoices[0].value) {
      this.loaCustomLevels[levelIndex].members.push([]);
    } else if (this.loaCustomLevels[levelIndex].user_type === this.loaSignUserChoices[1].value) {
      this.loaCustomLevels[levelIndex].members.push({
        name: '',
        job_position: '',
        email: ''
      });
    }
    this.loaCustomLevels[levelIndex].min_approve_count = this.loaCustomLevels[levelIndex].members.length;
    this.guardRangApproveCount(levelIndex);
  }

  onInputApproveCount(levelIndex: number) {
    this.guardRangApproveCount(levelIndex);
    this.populateSignaturePositions();
  }

  removeMemberFromLevel(levelIndex: number) {
    this.loaCustomLevels[levelIndex].members.pop();
    this.guardRangApproveCount(levelIndex);
    this.populateSignaturePositions();
  }

  guardRangApproveCount(levelIndex: number) {
    const currentCount = this.loaCustomLevels[levelIndex].min_approve_count;
    const maxCount = this.loaCustomLevels[levelIndex].members.length;
    if (currentCount > maxCount) {
      this.loaCustomLevels[levelIndex].min_approve_count = maxCount;
    } else if (currentCount < 1) {
      this.loaCustomLevels[levelIndex].min_approve_count = 1;
    }
  }

  checkApproval(index) {
    const checkApproval = this.loaCustomLevels[index].signature_required;

    if (checkApproval) {
      this.contentApprovel[index].content = 'MEMOS.SIGNATURE-REQUIRE';
    } else {
      this.contentApprovel[index].content = 'MEMOS.NO-SIGNATURE-REQUIRE';
    }
  }

  selectedApprover(selectedApprover?, index?, index2?, data?) {
    if (this.loaCustomLevels[index]['members'][index2] !== selectedApprover
      && this.approvalList.length !== this.approvallistLevels[index].length) {
      if (this.loaCustomLevels[index]['members'][index2].length !== 0) {
        this.approvallistLevels[index].push(this.loaCustomLevels[index]['members'][index2]);
      }
    }
    let listApprove = [];
    [listApprove, this.approvallistLevels[index]] = _.partition(this.approvallistLevels[index], function (n) {
      return selectedApprover.id === n.id;
    });
    this.approvallistLevels[index] = _.orderBy(this.approvallistLevels[index], ['full_name'], ['asc']);
    this.loaCustomLevels[index]['members'][index2] = selectedApprover;
    this.count_sign += 1;
    this.populateSignaturePositions();
  }

  initialCheckMemoNumberExist() {
    this.userKeyMemoNumber = this.memoData.memo_number;
    const sub = this.userKeyMemoNumber$
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        map(memoNumber => memoNumber.trim()),
        filter(memoNumber => memoNumber !== ''),
        switchMap((memoNumber: string) => {
          const params = new HttpParams().set('memo_number', memoNumber);
          return this.memoService.isMemoNumberExist(params);
        }))
      .subscribe((isExist: boolean) => {
        this.memoNumberAlreadyExist = isExist;
        if (!this.memoNumberAlreadyExist) {
          this.memoData.memo_number = this.userKeyMemoNumber.trim();
        } else {
          this.memoData.memo_number = null;
        }
      });

    this.subscriptions.add(sub);
  }

  addTagNonMemoRef(name: string) {
    return {label: name, value: name, tag: true};
  }
}
