import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MemosRoutingModule } from './memos-routing.module';
import { MemosComponent } from './memos.component';
import { CoreModule } from '../../core/core.module';
import { LoaModule } from './loa/loa.module';
import { MemoModule } from './memo/memo.module';
import {ApprovalModule} from '../approval/approval.module';

@NgModule({
  imports: [
    CommonModule,
    MemosRoutingModule,
    CoreModule,
    RouterModule,
    LoaModule,
    MemoModule,
    ApprovalModule
  ],
  declarations: [
    MemosComponent
  ]
})
export class MemosModule {
}
