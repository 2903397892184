import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {NotificationService} from '../../shared/services/notification.service';
import {HttpParams} from '@angular/common/http';
import {UserService} from '../../modules/permission/user/user.service';
import {Subject} from 'rxjs/Subject';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {SubSink} from 'subsink';
import * as _ from 'lodash';
import {MemoService} from '../../modules/memos/memo/memo.service';
import {SweetAlertService} from '../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';

export interface MentionProfile {
  id: number;
  full_name: string;
}

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  newComment = '';
  photo = localStorage.getItem('photoUrl');
  fileUpload: File;
  profileList: MentionProfile[] = [];
  debouncer = new Subject<string>();
  alreadySearchText = [''];
  userList = [];
  userDict = {};
  isMentionOpen = false;

  @Input() memo_id: number;
  @Output() submitted = new EventEmitter();
  @Output() uploaded = new EventEmitter();

  constructor(private notificationService: NotificationService,
              private memoService: MemoService,
              private swal: SweetAlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.getUserList();
  }

  mentionOpened() {
    this.isMentionOpen = true;
  }

  mentionClosed() {
    this.isMentionOpen = false;
  }

  handleEnterKey($event) {
    if (!this.isMentionOpen) {
      $event.preventDefault();
      this.addComment();
    }
  }

  addComment() {
    this.createUserDic();
    const returnedObject = this.checkRegexPattern(this.newComment);
    if (returnedObject.error === true) {
      this.notificationService.showSpecificError(
        `ต้องเว้นวรรคอย่างน้อย 1 ช่องหลังจาก Tag คนด้วย @ หรือ ${
          returnedObject.value
        } ไม่มีอยู่ในระบบ`
      );
      return;
    }
    const mentionedPerson = returnedObject.result;
    const comment = [{newComment: this.newComment, mentionedPerson: mentionedPerson}];
    this.submitted.emit(comment);
    setTimeout(() => {
      this.newComment = '';
    }, 600);
  }

  getUserList() {
    const params = new HttpParams().set('memo_id', this.memo_id.toString());
    this.memoService.getMentionProfiles(params).subscribe(
      (profiles: MentionProfile[]) => {
        this.profileList = profiles;
      },
      (error: { error: string }) => {
        if (this.translate.currentLang === 'th') {
          this.swal.toastNotification({type: 'error', content: 'ไม่สามารถโหลดข้อมูล user สำหรับการกล่าวถึงได้'});
        } else {
          this.swal.toastNotification({type: 'error', content: 'Can\'t get mention users.'});
        }
      }
    );
  }

  uploadFile(fileInput) {
    if (fileInput.target.files[0].size / (1024 * 1024) >= 30) {
      this.notificationService.showSpecificError('กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 30 MB');
      this.fileUpload = null;
      return;
    }
    this.uploaded.emit(fileInput);
  }

  triggerDebouncer(event) {
    this.debouncer.next(event);
  }

  createUserDic() {
    // ******** problem will come when two or more persons have exact same full name
    for (const eachElement of this.profileList) {
      this.userDict[eachElement['full_name']] = eachElement['id'];
    }
  }

  checkRegexPattern(text) {
    // with regex u flag , \u{0E01}-\u{0E5B} stand for thai languages in unicode format , it mean ก-๙
    const regex = /(?:@)([\u{0E01}-\u{0E5B}a-zA-Z\d]+)(\s)([\u{0E01}-\u{0E5B}a-zA-Z\d]+)/gmu;
    let results = [];
    results = text.match(regex);
    if (results) {
      return this.findMentionedPersonID(results);
    }
    return {result: [], value: null, error: false};
  }

  findMentionedPersonID(inputArr) {
    const tempArr = {result: [], value: '', error: false};
    inputArr = inputArr && inputArr.filter(Boolean);
    for (const eachElement of inputArr) {
      if (_.isNil(this.userDict[eachElement.substring(1)])) {
        tempArr['error'] = true;
        tempArr['value'] = eachElement.substring(1);
        break;
      }
      tempArr['result'].push(this.userDict[eachElement.substring(1)]);
    }
    return tempArr;
  }

}
