import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../../../shared/services/notification.service';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {MemoService} from '../../../memos/memo/memo.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-loa-list',
  templateUrl: './loa-list.component.html',
  styleUrls: ['./loa-list.component.scss']
})
export class LoaListComponent implements OnInit {
  pageList = 'loa-list';
  companyList: { id: number; code_name: string }[] = [];
  selectedCompanyId: number;
  onSelected: string;
  isLoading = true;
  showResults: boolean;
  styleScrollLoa = 'hidden';
  typeLoa: string;
  isList = true;
  isEdit = false;
  isCreate = false;
  selectedLOA;
  rowGroupMetadata: any;
  departmentsModeList: { label: string; value: string }[] = [
    {label: 'By Department', value: 'by-department'},
    {label: 'By Budget', value: 'by-budget'}
  ];
  loaList = [];
  selectedDepartmentsMode: string = this.departmentsModeList[0].value;
  detail;
  selectedYear;
  yearList = [];
  searchName = '';
  searchCostcentre: string;

  constructor(
    private notification: NotificationService,
    private swal: SweetAlertService,
    private apiService: MemoService,
    private translate: TranslateService) {
  }

  ngOnInit() {
    this.loadCompaniesDropdown();
  }

  loadCompaniesDropdown() {
    this.apiService.getCompanies()
      .pipe(
        map((companies: any[]) => {
          return companies.map(company => {
            return {id: company.id, code_name: company.code_name};
          });
        })
      )
      .subscribe((companies) => {
          this.companyList = companies;
          this.selectedCompanyId = this.companyList.length > 0 ? this.companyList[0].id : undefined; // companyList[0] is default_company
          this.onSelected = 'by-department';
          this.loadOffice();
        },
        error => this.swal.toastNotification({type: 'error', content: 'Unable to load company list'})
      );
  }

  loadOffice() {
    this.loaList = [];
    this.showResults = false;
    this.isLoading = true;
    const params = new HttpParams()
      .set('company_id', (this.selectedCompanyId || '').toString());
    this.apiService.getOfficeList(params).subscribe(
      (data: any) => {
        _.forEach(data, (obj) => {
          this.loaList.push({
            id: obj.id,
            name: obj.name,
            name_en: obj.name_en,
            groups: []
          });
        });
        this.loadGroup();
        this.updateRowGroupMetaData();
        this.showResults = true;
        this.isLoading = false;
      },
      error => this.swal.toastNotification({type: 'error', content: 'Unable to load office list'})
    );
  }

  loadGroup() {
    this.apiService.getGroupList().subscribe(
      (groups: any) => {
        groups.forEach(group => {
          this.loaList.map(office => {
            if (group.office === office.id) {
              office.groups.push({
                name: group.name,
                name_en: group.name_en,
                id: group.id,
                code: group.code_number
              });
            }
          });
        });
      },
      error => this.swal.toastNotification({type: 'error', content: 'Unable to load group list'})
    );
  }

  loadWBSList() {
    this.loaList = [];
    this.showResults = false;
    this.isLoading = true;
    const params = new HttpParams()
      .set('company_id', (this.selectedCompanyId || '').toString())
      .set('year', this.selectedYear.toString())
      .set('none_pagination', 'True');
    this.apiService.getWBSBudgetDashboardList(params).subscribe(
      (data: any) => {
        _.forEach(data, (obj) => {
          this.loaList.push({
            id: obj.id,
            name: obj.name,
            code: obj.code, // name wbs
            costcentre: obj.costcentre
          });
        });
        this.updateRowGroupMetaData();
        this.showResults = true;
        this.isLoading = false;
      },
      error => this.swal.toastNotification({type: 'error', content: 'Unable to load wbs list'})
    );
  }

   loadYear() {
    this.onSelected = 'by-budget';
    this.yearList = [];
    this.loaList = [];
    this.selectedYear = null;
    const params = new HttpParams()
      .set('company_id', (this.selectedCompanyId || '').toString());
    this.apiService.getWBSYearList(params).subscribe((year) => {
      this.yearList = year;
      this.selectedYear = this.yearList.length > 0 ? this.yearList[this.yearList.length - 1].year : null;
      if (this.selectedYear) {
        this.loadWBSList();
      }
    });
  }

  onSelectYear() {
    if (!this.selectedYear) {
      return;
    }
    this.loadWBSList();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.loaList) {
      for (let i = 0; i < this.loaList.length; i++) {
        const rowData = this.loaList[i];
        const value = rowData.id;
        if (i === 0) {
          this.rowGroupMetadata[value] = {index: 0, size: 1};
        } else {
          const previousRowData = this.loaList[i - 1];
          const previousRowGroup = previousRowData.value;
          if (value === previousRowGroup) {
            this.rowGroupMetadata[value].size++;
          } else {
            this.rowGroupMetadata[value] = {index: i, size: 1};
          }
        }
      }
    }
  }

  filterCompany(event) {
    this.checkType();
  }

  checkType() {
    if (this.onSelected === 'by-department') {
      this.pageList = 'loa-list';
      this.loadOffice();
    } else if (this.onSelected === 'by-budget') {
      this.pageList = 'loa-wbs-list';
      this.loadYear();
      // this.loadCostcentreLOAList();
    }
  }

  selectPage(event?, data?) {
    if (this.pageList === 'loa-list') {
      this.onSelected = 'by-department';
      this.detail = data;
      this.pageList = 'general';
    } else if (this.pageList === 'loa-wbs-list') {
      this.onSelected = 'by-budget';
      this.detail = data;
      this.pageList = 'general';
    } else {
      if (this.onSelected === 'by-department') {
        this.onSelected = 'by-department';
        this.pageList = 'loa-list';
      } else {
        this.onSelected = 'by-budget';
        this.pageList = 'loa-wbs-list';
      }
    }
  }

  createNew(type: string) {
    this.isLoading = false;
    this.styleScrollLoa = 'scroll';
    this.typeLoa = type;
    this.isEdit = false;
    this.isList = false;
    this.isCreate = true;
  }

  updateLOA(loa) {
    this.styleScrollLoa = 'hidden';
    // const loaIndex = this.loaList.findIndex(obj => obj.id === loa.id);
    // this.loaList[loaIndex] = loa;
    this.closeLOA();
  }

  closeLOA(event?) {
    this.styleScrollLoa = 'hidden';
    this.isList = true;
    this.isEdit = false;
    this.isCreate = false;
    this.checkType();
  }

  createLOA(loa) {
    this.styleScrollLoa = 'hidden';
    this.isList = true;
    this.isCreate = false;
    this.checkType();
  }

  onSelectMode(event) {
    this.onSelected = event.value;
    this.checkType();
  }
}
