import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormExpensesClaim, TableExpensesClaim} from '../../memo-template';

@Component({
  selector: 'app-table-template-expenses-claim',
  templateUrl: './table-template-expenses-claim.component.html',
  styleUrls: ['./table-template-expenses-claim.component.scss']
})
export class TableTemplateExpensesClaimComponent implements OnInit {

  totalTax = 0;
  totalPriceAmount = 0; // totalAmount + totalTax
  taxList = [
    {name: '0 %', value: false},
    {name: '7 %', value: true}
  ];

  // tslint:disable-next-line:no-input-rename
  @Input('formExpensesClaimData') formExpensesClaimData: FormExpensesClaim;
  @Output() formExpensesClaimDataChange = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    if (this.formExpensesClaimData.expenses_claim_table && this.formExpensesClaimData.expenses_claim_table.length > 0) {
      this.mapDataToLineItems(this.formExpensesClaimData.expenses_claim_table);
    }
  }

  mapDataToLineItems(line_items: any[]): TableExpensesClaim[] {
    return line_items
      .map(line_item => {
        return {
          id: line_item.id,
          description: line_item.description,
          quantity: line_item.quantity,
          unit_price: line_item.unit_price,
          amount: line_item.amount,
        } as TableExpensesClaim;
      });
  }

  onAddRowToLineItems() {
    this.formExpensesClaimData.expenses_claim_table.push({
      sequence: (this.formExpensesClaimData.expenses_claim_table || []).length + 1,
      id: null,
      description: null,
      quantity: 0,
      unit_price: 0,
      amount: 0,
    });
  }

  onRemoveRowFromLineItems(index) {
    this.formExpensesClaimData.expenses_claim_table.splice(index, 1);
    this.reOrderSequence();
    this.calSumAmount();
    this.updateTax();
  }

  reOrderSequence() {
    (this.formExpensesClaimData.expenses_claim_table || []).forEach((d, index) => {
      d.sequence = index + 1;
    });
  }

  calSumAmount() {
    const sumAmount = this.formExpensesClaimData.expenses_claim_table
      .reduce((sum, cur) => sum + cur.amount, 0);
    this.formExpensesClaimData.sum_amount = this.limitTwoDecimal(sumAmount);
  }

  updateTax() {
    if (this.formExpensesClaimData.is_vat_7) {
      this.totalTax = this.limitTwoDecimal(this.formExpensesClaimData.sum_amount * (7 / 100));
    } else {
      this.totalTax = 0;
    }
    this.totalPriceAmount = this.limitTwoDecimal(this.totalTax + this.formExpensesClaimData.sum_amount);
  }

  limitTwoDecimal(num: number, mode: 'round' | 'floor' = 'round'): number {
    return mode === 'round'
      ? Math.round(num * 100) / 100
      : Math.floor(num * 100) / 100;
  }

  updateTableLineItem(lineItem, key: string, val: string) {
    if (key === 'quantity') {
      lineItem[key] = Math.floor(+val);
    } else {
      lineItem[key] = this.limitTwoDecimal(+val, 'round');
    }
    lineItem.amount = this.limitTwoDecimal(lineItem.quantity * lineItem.unit_price);
    this.calSumAmount();
    this.updateTax();
  }
}
