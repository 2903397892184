import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileSystemFileEntry, UploadEvent} from 'ngx-file-drop';
import {SweetAlertService} from '../../../../../../../shared/services/sweet-alert.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-memo-upload-attachment',
  templateUrl: './memo-upload-attachment.component.html',
  styleUrls: ['./memo-upload-attachment.component.scss']
})
export class MemoUploadAttachmentComponent implements OnInit {

  file: File;
  fileUpload: File;


  @Input() numberLabel: string;
  // tslint:disable-next-line:no-input-rename
  @Input('attachment') files;
  @Output() attachmentChange = new EventEmitter();

  constructor(
    private swal: SweetAlertService
  ) {
  }

  ngOnInit() {
  }

  checkFileSize(file) {
    if (file != null && file.size / (1024 * 1024) >= 30) {
      this.swal.toastNotification({type: 'warning', content: 'กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 30 MB'});
      return false;
    }

    return true;
  }

  dropped(event: UploadEvent) {
    for (const droppedFile of event.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          if (!this.checkFileSize(file)) {
            return;
          }

          this.files.push(file);
        });
      }
    }
  }

  selectFile(fileInput) {
    _.map(fileInput.target.files, (file) => {
      if (!this.checkFileSize(file)) {
        return;
      }

      this.files.push(file);
    });
  }

  removeFile(fileIndex) {
    this.files.splice(fileIndex, 1);
  }

}
