import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {UserService} from '../../../../permission/user/user.service';
import {forEach, get} from 'lodash';
import {TemplateListService} from './template-list.service';
import {MemoService} from '../../memo.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {ThemeService} from '../../../../../shared/services/theme.service';
import {NgbThaiDateParserFormatter} from '../../../../../core/NgDateParser';
import {FormTemplateListService} from './service-template/form-template-list.service';
import {environment} from '../../../../../../environments/environment';
import {TemplatePrService} from './template-pr/template-pr.service';

const PRM_COMPANY_ID = '10';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit {

  memoData: any;
  isLoading = false;
  isLoadingPreview = false;
  showPreview = false;
  isEditMode = false;
  isAlreadySave = false;

  documentData: any;
  selectedTemplate = null;
  isPermissionOnlyRelated = {value: true, label: 'Only related', label_th: 'แค่ผู้ที่เกี่ยวข้อง'};
  extensionFile = '';
  isCustomNumberMemo = false;
  loadForm = false;

  templateMemo = [];
  departments = [];
  // tooltip
  toolTipSave = '';
  toolTipPreview = '';
  loaGroupId = null;
  preview_url = '';

  isSavedTemplate: boolean;
  files = [];
  isSmallScreen: boolean;

  formTemplateData: any;
  formExpensesClaimData: any;

  apiTemplateList = {
    formAsset: 'memos-form-asset',
    formExpenses: 'memos-form-expenses-claim',
    formInternal: 'memos-form-internal',
    formBudgetRequest: 'memos-form-budget-request',
    formBudgetTransfer: 'memos-form-budget-transfer',
    formBGPUProductSell: 'memos-form-bgpu-product-sell',
    formPR: 'memos-form-pr',
  };

  formList = {
    asset_form: 'asset_form',
    expense_claim_form: 'expenses_claim_form',
    internal_form: 'internal_form',
    budget_request_form: 'budget_request_form',
    budget_transfer: 'budget_transfer_form',
    bgpu_product_sell_form: 'bgpu_product_sell_form',
    pr_form: 'pr_form'
  };
  memoDateSelectTemplate: string;
  apiTemplate: string;

  @ViewChild('pdfImageContainer', {static: false}) pdfImageContainer: ElementRef;

  // tslint:disable-next-line:no-input-rename
  @Input('memoData') _memoData;
  _initialMemoData;

  @Output() savedMemo = new EventEmitter();
  @Output() editSuccess = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() togglePreview = new EventEmitter();

  constructor(
    private userService: UserService,
    private templateListService: TemplateListService,
    private memoService: MemoService,
    private translate: TranslateService,
    private swal: SweetAlertService,
    private themeService: ThemeService,
    private dateFormat: NgbThaiDateParserFormatter,
    private formTemplateService: FormTemplateListService,
    private templatePrService: TemplatePrService,
  ) {
    this.templateMemo = [
      {name: 'Asset - แบบฟอร์มการขอจำหน่าย/จ่าย/โอน/ทิ้ง ทรัพย์สิน', value: 'asset'},
      {name: 'Internal - แบบฟอร์มขออนุมัติเรื่องทั่วไป', value: 'internal'},
      {name: 'Expense Claim - แบบฟอร์มการขอเบิกจ่าย/เคลียร์ค่าใช้จ่ายต่างๆ', value: 'expenses claim'},
      {name: 'Budget Request - แบบฟอร์มขอเพิ่มงบประมาณ', value: 'budget request'},
      {name: 'Budget Transfer - แบบฟอร์มขอโอนงบประมาณ', value: 'budget transfer'},
    ];
    if (environment.web_instance === 'BG') {
      this.templateMemo.push(
        {name: 'แบบฟอร์มซื้อผลิตภัณฑ์ของ BGPU', value: 'BGPU product sell'}
      );
    }
    const userCompanies = this.userService.getUserCompanyIds();
    if (userCompanies.includes(PRM_COMPANY_ID)) {
      this.templateMemo.push(
        {name: 'Purchase Requisition - ใบขอซื้อ', value: 'PR'}
      );
    }
  }

  async ngOnInit() {
    if (this._memoData.is_permission) {
      this.isPermissionOnlyRelated.value = this._memoData.is_permission;
    }
    this.isSmallScreen = this.themeService.isMobile();
    this.selectedTemplate = this._memoData.memo_template ? this._memoData.memo_template : null;
    if (this.selectedTemplate) {
      this.isEditMode = true;
      this.onSelectedTemplate();
    }

    // some memo detail is large, so I will load detail after user selected this Memo
    if (!this._memoData.detail) {
      this._memoData['detail'] = await this.getDetailOfMemoReal();
    }
    this._initialMemoData = Object.assign({}, this._memoData);
  }

  async getDetailOfMemoReal() {
    return new Promise<any>((resolve) => {
      this.memoService.getDetailOfMemoReal(this._memoData.id).subscribe(res => {
        if (res.detail) {
          resolve(res.detail);
        } else {
          resolve(null);
        }
      });
    });
  }

  save(type: 'save' | 'preview' = 'save') {
    if (this.selectedTemplate === 'PR') {
      if (!this.templatePrService.validateForm(this.formTemplateData)) {
        return;
      }
    }
    if (!this.memoService.validateMemo(this._memoData)) {
      return;
    }

    const argsMapping = {
      'asset': [this.apiTemplateList.formAsset, this.formList.asset_form],
      'expenses claim': [this.apiTemplateList.formExpenses, this.formList.expense_claim_form],
      'budget request': [this.apiTemplateList.formBudgetRequest, this.formList.budget_request_form],
      'budget transfer': [this.apiTemplateList.formBudgetTransfer, this.formList.budget_transfer],
      'BGPU product sell': [this.apiTemplateList.formBGPUProductSell, this.formList.bgpu_product_sell_form],
      'PR': [this.apiTemplateList.formPR, this.formList.pr_form],
    };

    if (this.selectedTemplate === 'internal') {
      if (type === 'save') {
        this.memoDataDefault();
      } else {
        this.previewDefault(this.formList.internal_form);
      }
    } else {
      const [apiForm, formTemplate] = argsMapping[this.selectedTemplate];
      this.saveFormTemplate(apiForm, formTemplate, type);
    }
  }

  validateMemo() {
    const validate = {
      selectedTemplate: this.selectedTemplate,
      loaId: this.loaGroupId,
      isPermissionOnlyRelated: this.isPermissionOnlyRelated.value,
      isCustomNumberMemo: this.isCustomNumberMemo,
      isSavedTemplate: this.isSavedTemplate = true
    };
    return validate;
  }

  memoDataDefault() {
    this.isLoading = true;
    const validate = this.validateMemo();
    const data = this.templateListService.checkTemplate(this._memoData, validate);
    this.memoService.updateMemoReal(this._memoData.id, data).subscribe(
      memo => {
        this.savePreview(() => {
          this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
          this.isLoading = false;
          memo.unread_comment_count = 0;
          memo.unread_attachment_count = 0;
          this.memoService.getMemoRealDetail(memo.id).subscribe(memoData => {
            this.editSuccess.emit(memoData);
            this.templateMemo = [this.selectedTemplate];
          });
        });
      },
      error => {
        this.isLoading = false;
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      }
    );
    this.saveAttachments();
  }

  previewDefault(templateForm) {
    this.isLoadingPreview = true;
    const validate = this.validateMemo();
    const data = this.templateListService.checkTemplate(this._memoData, validate);
    this.memoService.updateMemoReal(this._memoData.id, data).subscribe(
      memo => {
        this._memoData[templateForm] = memo[templateForm];
        this._memoData.loa_group = memo['loa_group'];
        this.openPreview(() => {
          this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
          // this.messageService.add({severity: 'success', summary: 'Save Changes'});
          this.getDataForPreview();
        });
      },
      error => {
        this.isLoadingPreview = false;
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      }
    );
  }

  savePreview(f) {
    const params = new HttpParams().set('memo_id', this._memoData.id);
    this.memoService.PreviewMemoReal(params).subscribe(data => {
        this.preview_url = data.url;
        this.isAlreadySave = true;
        f();
      },
      error => {
        this.isLoading = false;
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
        // window.open(this.preview_url);
      }
    );
  }

  saveAttachments() {
    if (this.files) {
      const at = new FormData();
      at.append('memo', this._memoData.id);
      forEach(this.files, file => {
          const name = get(file, 'name', 'relativePath');
          file.fileEntry ? at.append(name, file.fileEntry) : at.append(name, file);
        }
      );
      this.memoService.uploadMemoAttachment(at).subscribe(
        res => {
          // this.isLoading = false;
        },
        error => (this.isLoading = false)
      );
    }
  }

  openPreview(awit) {
    const params = new HttpParams().set('memo_id', this._memoData.id);
    this.memoService.PreviewMemoReal(params).subscribe(
      preview => {
        this.isAlreadySave = true;
        this.preview_url = preview.url;
        awit();
      },
      error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      }
    );
  }

  getDataForPreview() {
    this.memoService.getMemoRealDetail(this._memoData.id).subscribe(
      memo => {
        if (this.isSmallScreen) {
          window.open(memo.signed_document);
        } else {
          this.documentData = memo.signed_document;
          this.showPreview = true;
        }
        this.isLoadingPreview = false;
      });
  }

  closePreview(event?) {
    this.showPreview = false;
  }

  back() {
    // if user click back, return original variable.
    this.editSuccess.emit(this._memoData);
  }

  onSelectedTemplate(template?: { value: string, showLabel: string }) {
    if (!this.isEditMode) {
      this._memoData = Object.assign({}, this._initialMemoData);
    }

    if (this.selectedTemplate === 'asset') {
      this.getFormTemplate(this.apiTemplateList.formAsset, this.formList.asset_form);
      this.memoDateSelectTemplate = this.formList.asset_form;
    }
    if (this.selectedTemplate === 'expenses claim') {
      this.getFormTemplate(this.apiTemplateList.formExpenses, this.formList.expense_claim_form);
    }
    if (this.selectedTemplate === 'internal') {
      this.getFormTemplate(this.apiTemplateList.formInternal, this.formList.internal_form);
    }
    if (this.selectedTemplate === 'budget request') {
      this.getFormTemplate(this.apiTemplateList.formBudgetRequest, this.formList.budget_request_form);
    }
    if (this.selectedTemplate === 'budget transfer') {
      this.getFormTemplate(this.apiTemplateList.formBudgetTransfer, this.formList.budget_transfer);
    }
    if (this.selectedTemplate === 'BGPU product sell') {
      this.getFormTemplate(this.apiTemplateList.formBGPUProductSell, this.formList.bgpu_product_sell_form);
    }
    if (this.selectedTemplate === 'PR') {
      this.getFormTemplate(this.apiTemplateList.formPR, this.formList.pr_form);
    }
  }

  getFormTemplate(apiForm, formTemplate) {
    this.loadForm = true;
    if (this._memoData[formTemplate]) {
      const params = {
        id: this._memoData[formTemplate],
        api: apiForm
      };
      this.memoService.getFormTemplate(params).subscribe(res => {
        this.formTemplateData = this.formTemplateService.checkTemplateResponse(formTemplate, res);
        this.loadForm = false;
      }, error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      });
    } else {
      this.loadForm = false;
      this.formTemplateData = this.formTemplateService.checkFormTemplate(formTemplate, this._memoData.id);
    }
  }


  saveFormTemplate(apiForm, formTemplate, type: 'save' | 'preview') {
    if (this._memoData[formTemplate]) {
      this.updateFormTemplate(apiForm, formTemplate, type);
    } else {
      this.createFormTemplate(apiForm, formTemplate, type);
    }
  }

  createFormTemplate(apiForm, formTemplate, type: 'save' | 'preview') {
    const data = this.formTemplateService.checkConvertData(formTemplate, this.formTemplateData);
    this.memoService.createFormTemplate(data, apiForm).subscribe(response => {
      this.checkSaveOrPreview(formTemplate, type);
      this.formTemplateData = this.formTemplateService.checkTemplateResponse(formTemplate, response);
    }, error => {
      if (error.error && error.error.en) {
        this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
      } else {
        this.swal.toastNotification({type: 'error'});
      }
    });
  }

  updateFormTemplate(api, formTemplate, type: 'save' | 'preview') {
    const data = this.formTemplateService.checkConvertData(formTemplate, this.formTemplateData);
    const params = {
      id: this.formTemplateData.id,
      apiForm: api
    };
    this.memoService.updateFormTemplate(params, data).subscribe(res => {
      this.checkSaveOrPreview(formTemplate, type);
      this.formTemplateData = this.formTemplateService.checkTemplateResponse(formTemplate, res);
    }, error => {
      if (error.error && error.error.en) {
        this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
      } else {
        this.swal.toastNotification({type: 'error'});
      }
    });
  }

  checkSaveOrPreview(formTemplate, type: 'save' | 'preview') {
    if (type === 'preview') {
      this.previewDefault(formTemplate);
    } else if (type === 'save') {
      this.memoDataDefault();
    }
  }

  get isEnLanguage() {
    return this.translate.currentLang === 'en';
  }
}
