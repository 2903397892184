import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeedRoutingModule } from './feed-routing.module';
import {FeedListComponent, HighlightSearch} from './feed-list/feed-list.component';
import {CoreModule} from '../../core/core.module';
import {FormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DndDirective} from './dnd.directive';
import {FeedService} from './feed.service';
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {MentionModule} from 'angular-mentions';
import {FeedImagesPreviewComponent} from './feed-images-preview/feed-images-preview.component';
import {NgxGalleryModule} from 'ngx-gallery';
import {MemoModule} from '../memos/memo/memo.module';
import {CheckboxModule} from 'primeng/checkbox';
import {TranslateModule} from '@ngx-translate/core';
import {MatInputModule} from '@angular/material/input';
import { FeedAnnouncementComponent } from './feed-announcement/feed-announcement.component';

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: {enable: false},
    rotate: {enable: false}
  };
}
@NgModule({
  declarations: [
    FeedListComponent,
    HighlightSearch,
    DndDirective,
    FeedImagesPreviewComponent,
    FeedAnnouncementComponent
  ],
    imports: [
        CommonModule,
        FeedRoutingModule,
        CoreModule,
        FormsModule,
        ButtonModule,
        DropdownModule,
        OverlayPanelModule,
        MentionModule,
        NgxGalleryModule,
        MemoModule,
        CheckboxModule,
        TranslateModule,
        MatInputModule
    ]
    ,
  providers: [FeedService, {provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig}]
})
export class FeedModule { }
