import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileSystemDirectoryEntry, FileSystemFileEntry, UploadEvent } from 'ngx-file-drop';
import * as _ from 'lodash';
import {NotificationService} from '../../shared/services/notification.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-attachment-modal',
  templateUrl: './attachment-modal.component.html',
  styleUrls: ['./attachment-modal.component.scss']
})
export class AttachmentModalComponent {

  modal: NgbModalRef;
  files: any[] = [];
  uploadDocument: any;
  upload: any;
  cancel: any;
  fileUpload: File;


  @Input() data: any;
  @Input() isLoading = false;
  @Output() submitted = new EventEmitter();
  @ViewChild('attachmentModal', { static: true }) attachmentModal: ElementRef;

  constructor(private modalService: NgbModal,
              private notificationService: NotificationService,
              private translate: TranslateService
  ) {
  }

  open(content) {
    this.uploadDocument = this.translate.instant('MEMOS.UPLOAD-DOCUMENT');
    this.upload = this.translate.instant('MEMOS.UPLOAD');
    this.cancel = this.translate.instant('MEMOS.CANCEL');
    this.modal = this.modalService.open(content);
  }

  openFromOutside() {
    this.open(this.attachmentModal);
  }

  submit() {
    this.submitted.emit(this.files);
    if (!this.isLoading) {
      this.modal.close();
      this.files = [];
    }
  }

  checkFileSize(file) {
    if (file != null && file.size / (1024 * 1024) >= 30) {
      this.notificationService.showSpecificError('กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 30 MB');
      return false;
    }

    return true;
  }

  dropped(event: UploadEvent) {
    for (const droppedFile of event.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          if (!this.checkFileSize(file)) {
            return;
          }

          this.files.push(file);
        });
      }
    }
  }

  selectFile(fileInput) {
    _.map(fileInput.target.files, (file) => {
      if (!this.checkFileSize(file)) {
        return;
      }

      this.files.push(file);
    });
  }

  removeFile(fileIndex) {
    this.files.splice(fileIndex, 1);
  }

  closeModal() {
    this.files = [];
    this.modal.close();
  }
}
