import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbDropdownConfig, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import {get} from 'lodash';

import {API_URL} from '../../../shared/constants/api.constant';
import {NotificationService} from '../../../shared/services/notification.service';
import {MemoService} from '../../memos/memo/memo.service';
import {HttpParams} from '@angular/common/http';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {ThemeService} from '../../../shared/services/theme.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TitleService} from '../../../shared/services/title-service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-executive-list',
  templateUrl: './executive-list.component.html',
  styleUrls: ['./executive-list.component.scss']
})
export class ExecutiveListComponent implements OnInit, OnDestroy {
  memoList = [];
  department: string;
  count = 0;
  currentPage = 1;
  setPageSize = 10;
  showResults = false;
  data: any;
  toggleSideMenu = true;
  type: string;
  isMobile = false;
  attachment = [];
  viewType = 'pending';
  selectedMemo: null;
  parentViewType = 0;
  status = '';
  modal: NgbModalRef;
  attachedFiles = [];
  pdfFile: string;
  isAdmin = false;
  isSelf = localStorage.profile_id;
  nameList = [];
  memoNumberList = [];
  selectedName;
  selectedMemoNumber;
  memoId: string = null;
  // notiSelectedMemoSubject: Subscription;
  subscriptions = new Subscription();

  @ViewChild('executiveDetail', {static: false}) executiveDetail: any;

  constructor(
    private memoService: MemoService,
    private notification: NotificationService,
    private swal: SweetAlertService,
    private dropdownConfig: NgbDropdownConfig,
    private themeService: ThemeService,
    private translate: TranslateService,
    private titleService: TitleService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    // private notificationService: NotificationService
  ) {
    dropdownConfig.placement = 'bottom-right';
    dropdownConfig.autoClose = false;
  }

  ngOnInit() {
    this.loadMemos();
    this.isAdmin = localStorage.getItem('role_name') === 'Admin';
    this.titleService.setTitle(this.translate.instant('SIDEBAR.PENDING'));
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.titleService.setTitle(this.translate.instant('SIDEBAR.PENDING'));
    });
    this.subscriptions.add(translateSub);
    this.checkParams();
    // const notifyPending = JSON.parse(localStorage.getItem('notify-pending'));
    // if (notifyPending) {
    //   this.clickMemo(notifyPending);
    //   localStorage.setItem('notify-pending', 'true');
    // }
    // this.notiSelectedMemoSubject = this.notificationService.getSelectedNotificationMemo()
    //   .subscribe(memo => {
    //       if (memo) {
    //         this.clickMemo(memo);
    //       }
    //     },
    //     err => {
    //       this.swal.toastNotification({type: 'error', content: err.status});
    //     }
    //   );
    // window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy() {
    // localStorage.removeItem('notify-pending');
    this.subscriptions.unsubscribe();
  }

  checkParams() {
    this.activatedRoute.params.subscribe(params => { // handle routing by id
      this.currentPage = params.page || 1;
      this.memoId = params.memo;
    });
  }

  loadMemos() {
    this.showResults = false;
    let params = new HttpParams();
    if (this.isSelf) {
      params = params.append('pending_wait', 'true');
    }
    if (!this.isSelf) {
      params = params.append('pending_all', 'true');
    }
    if (this.selectedName) {
      params = params.append('name', this.selectedName);
    }
    if (this.selectedMemoNumber) {
      params = params.append('memo_number', this.selectedMemoNumber);
    }
    params = params.append('page_size', this.setPageSize.toString());
    params = params.append('page', this.currentPage.toString());
    params = params.append('ordering', '-created_at');
    this.memoService.getMemoRealList(params).subscribe(
      (memos: any) => {
        this.count = memos.count;
        this.notification.memoNotificationCount = this.count;
        this.memoList = memos.results;
        this.loadDataFilter();
        this.showResults = true;
        if (this.memoId) {
          const findMemo = _.find(memos.results, {id: parseInt(this.memoId, 10)});
          if (findMemo) {
            this.clickMemo(findMemo);
          } else {
            return this.navigateToPage(
              '/memo/memo/real/list/',
              this.memoId
            );
          }
        }
      },
      error => this.swal.toastNotification({type: 'error', content: 'Unable to load Memo'})
    );
  }

  navigateToPage(url: string, Id: string) {
    return this.router.navigate((url + Id).split('/'));
  }

  loadDataFilter() {
    this.nameList = this.memoList.map(obj => ({id: obj.id, name: obj.name}));
    this.memoNumberList = this.memoList.map(obj => ({id: obj.id, name: obj.memo_number}));
    this.nameList = _.uniqBy(this.nameList, 'name');
    this.memoNumberList = _.uniqBy(this.memoNumberList, 'name');
  }

  changePage() {
    this.loadMemos();
  }

  clickMemo(memo) {
    const isTabletOrMobilePortrait = this.themeService.isTabletOrMobilePortrait();
    if (isTabletOrMobilePortrait) {
      this.toggleSideMenu = false;
    }
    this.selectedMemo = null;
    this.pdfFile = null;
    setTimeout(() => {
      this.selectedMemo = memo;
      this.loadAttachedFiles(memo.id);
      this.loadPreviewData(memo);
      this.parentViewType = 0;
    }, 1);
  }

  loadAttachedFiles(id) {
    const params = new HttpParams().set('memo', id);
    this.memoService.getMemoAttachment(params).subscribe(attachedFiles => {
      this.attachedFiles = attachedFiles['results'];
    });
  }

  loadPreviewData(data) {
    setTimeout(() => {
      switch (data.upload_type) {
        case 'offline_with_memo_number':
          if (data.signed_document) {
            this.pdfFile = data.signed_document;
          } else {
            this.pdfFile = null;
          }
          break;
        case 'offline':
          if (data.signed_document) {
            this.pdfFile = data.signed_document;
          } else {
            this.pdfFile = null;
          }
          break;
        default:
          if (data.signed_document) {
            this.pdfFile = data.signed_document;
          } else {
            this.pdfFile = API_URL.memo_template + '?type=' + data.type + '&id=' + data.id;
          }
          break;
      }
    }, 1);
  }

  checkIfSelected(memo) {
    return get(this.selectedMemo, 'id', '') === memo.id;
  }

  // filterByStatus(status) {
  //   this.viewType = status;
  //   this.selectedMemo = null;
  //   switch (status) {
  //     case 'pending':
  //       this.isSelf = localStorage.profile_id;
  //       this.loadMemoReal();
  //       break;
  //     case 'all':
  //       this.isSelf = null;
  //       this.loadMemoReal();
  //       break;
  //     default:
  //       break;
  //   }
  // }

  actionChange(toggleSide?, data?) {
    // const memoIndex = this.memoList.findIndex(obj => obj.id === memo.id);
    // this.memoList[memoIndex] = memo;
    // this.clickMemo(memo);
    this.toggleSideMenu = toggleSide;
    this.loadMemos();
    this.selectedMemo = null;
  }
}
