import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/components/common/messageservice';
import {HttpParams} from '@angular/common/http';
import {MemoService} from '../../modules/memos/memo/memo.service';
import {HttpClientService} from './http-client.service';
import {Notification} from '../../core/navbar/navbar.models';
import {API_URL} from '../constants/api.constant';
import {map} from 'rxjs/operators';

@Injectable()
export class NotificationService {
  memoNotificationCount = 0;

  constructor(private messageService: MessageService,
              private router: Router,
              private memoService: MemoService,
              private http: HttpClientService
  ) {

  }

  updateNotificationCount() {
    let params = new HttpParams();
    params = params.append('pending_wait', 'true');
    this.memoService.getMemoRealList(params).subscribe(
      (memos: any) => {
        this.memoNotificationCount = memos.count;
      });
  }

  showSuccess() {
    this.messageService.add({severity: 'success', summary: 'SAVE CHANGE'});
  }

  showSpecificSuccess(data) {
    this.messageService.add({severity: 'success', summary: 'SAVE CHANGE', detail: data});
  }

  rejectedPlayerAdded() {
    this.messageService.add({severity: 'success', summary: 'REJECTED PLAYER ADDED'});
  }

  showRequestSent() {
    this.messageService.add({severity: 'success', summary: 'Request has been sent'});
  }

  showSucceedToCreateRequest() {
    this.messageService.add({severity: 'success', summary: 'NEW REQUEST CREATED'});
  }

  showNotification(status) {
    switch (status) {
      case 200:
        this.messageService.add({severity: 'success', summary: 'บันทึกการเปลี่ยนแปลง'});
        break;
      case 400:
        this.messageService.add({
          severity: 'error',
          summary: 'ผิดพลาด',
          detail: 'ข้อมูลไม่ถูกต้อง'
        });
        break;
      case 401:
        // this.router.navigate(['/login']);
        break;
      case 403:
        this.messageService.add({severity: 'error', summary: 'ผิดพลาด', detail: 'พบข้อผิดพลาด'});
        break;
      case 404:
        this.messageService.add({severity: 'error', summary: 'ผิดพลาด', detail: 'พบข้อผิดพลาด'});

        break;
      case 500:
        this.messageService.add({
          severity: 'error',
          summary: 'ผิดพลาด',
          detail: 'เซิฟเวอร์ขัดข้อง กรุณาติดต่อผู้ดูแลระบบ'
        });
        break;
    }
  }

  showNotiIdPpn() {
    this.messageService.add({
      severity: 'error',
      summary: 'ข้อมูลไม่ถูกต้อง',
      detail: 'เลขบัตรประชาชน หรือ หมายเลขพาสปอร์ต ซ้ำกัน'
    });
  }

  showSpecificError(error) {
    this.messageService.add({severity: 'error', summary: 'ผิดพลาด', detail: error});
  }

  checkIfURLExpired() {
    this.router.navigate(['/expire']);
  }

  // New notifacation
  getAllNotification(params?) {
    return this.http.get(API_URL.notification, params);
  }

  updateIsRead(id) {
    return this.http.post(API_URL.notification + id + '/' + 'read' + '/', id);
  }

  createThaiDescription(notification) {
    const MAP_WORD = [
      {en: 'mentioned you in a memo comment.', th: 'กล่าวถึงคุณในความคิดเห็น'},
      {en: 'replied to your memo comment on Memo', th: 'ตอบความคิดเห็นของคุณใน '},
      {en: 'posted in news feed.', th: 'โพสต์ในหน้าฟีดข่าว'},
      {en: 'posted and mentioned you in news feed ', th: 'โพสต์และกล่าวถึงคุณในหน้าฟีดข่าว'},
      {en: 'replied to your post.', th: 'ตอบโพสต์ของคุณ'},
      {en: 'replied and mentioned you in your post.', th: 'ตอบและกล่าวถึงคุณในโพสต์ของคุณ'},
      {en: 'also commented on their post.', th: 'แสดงความคิดเห็นในโพสต์ของพวกเขาด้วย'},
      {en: 'has a new announcement from', th: 'มีประกาศใหม่จาก'},
      {en: 'has approved Memo', th: 'อนุมัติเอกสาร'},
      {en: 'has rejected Memo', th: 'ปฏิเสธเอกสาร'},
      {en: 'has terminated Memo', th: 'ยุติเอกสาร'},
      {en: 'has revised memo', th: 'แก้ไขเอกสาร'},
      {en: 'Pending for approve Memo', th: 'อยู่ระหว่างดำเนินการอนุมัติเอกสาร'},
      {
        en: 'You are assigned to be responsible for managing the pending approval of',
        th: 'คุณถูกแต่งตั้งให้จัดการเอกสารรอการอนุมัติ คุณสามารถเซ็นอนุมัติเอกสารแทนได้ทันที'
      },
      {
        en: 'is documents are transferred to your E-memo account. Now you access and manage their documents.',
        th: 'คุณได้รับการถ่ายโอนสิทธิ์ คุณสามารถเข้าถึงและจัดการเอกสารได้ทันที'
      },
      {en: 'from' , th: 'จาก'},
      {en: 'to', th: 'ถึง'}
    ];

    let descriptionThai = notification.verb;
    MAP_WORD.forEach(word => {
      descriptionThai = descriptionThai.replace(word.en, word.th);
    });
    notification.description_th = descriptionThai;

    return {
      ...notification,
      description_th: descriptionThai
    };
  }
}
