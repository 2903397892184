import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {MemoService} from '../../memo.service';
import {MessageService} from 'primeng/components/common/messageservice';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../../../../shared/services/notification.service';
import {HttpParams} from '@angular/common/http';
import {HttpClientService} from '../../../../../shared/services/http-client.service';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ThemeService} from '../../../../../shared/services/theme.service';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../../../shared/services/authentication.service';

@Component({
  selector: 'app-memo-real-detail',
  templateUrl: './memo-real-detail.component.html',
  styleUrls: ['./memo-real-detail.component.scss']
})
export class MemoRealDetailComponent implements OnInit, OnDestroy {
  showResults = false;
  isEasyReading = false;
  isLoading = false;
  isSaveAnnounce = false;
  isSaveRecall = false;
  showPreview = false;
  documentData: any;
  isPdfLoading = true;
  showPdfFile: any;
  attachmentCount = 0;
  modal: NgbModalRef;
  allDepartmentList = [];
  labelMulti = '';
  toolTipPublish = '';
  subscriptions = new Subscription();
  recall_reason: string = null;

  @Input() viewType = 2;
  @Input() pdfFile: string;
  @Input() document: any;
  @Output() memoDeleted = new EventEmitter();
  @Output() toggleEdit = new EventEmitter();
  @Output() editSuccess = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() updatedMemo = new EventEmitter();
  @ViewChild('attachModal', {static: false}) attachModal: any;

  constructor(
    private modalService: NgbModal,
    private notification: NotificationService,
    private messageService: MessageService,
    private memoService: MemoService,
    private httpClientService: HttpClientService,
    private swal: SweetAlertService,
    private translate: TranslateService,
    public themeService: ThemeService,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    if (this.document.view_comment) {
      this.viewType = 1;
    } else {
      this.viewType = 2;
    }
    this.loadDepartmentList();

    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateContent();
    });
    this.subscriptions.add(translateSub);
    this.translateContent();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  translateContent() {
    this.toolTipPublish = this.translate.instant('TOOLTIP.BEFORE-PUBLISH');
  }

  deleteDocument(data) {
    this.memoDeleted.emit(data.data);
  }

  openPDF() {
    if (this.document.signed_document) {
      window.open(this.document.signed_document);
    } else {
      this.memoService.openMemoDraftPDF(this.document.type, this.document.id);
    }
  }

  sendLogPrint(id) {
    this.memoService.sendLogPrint(id).subscribe();
  }

  publishMemo() {
    this.showResults = true;
    const data = {
      status: 'pending'
    };
    this.memoService.updateMemoReal(this.document.id, data).subscribe(
      (memo) => {
        this.showResults = false;
        this.document.status = memo.status;
        this.document.name = memo.name;
        this.document.memo_number = memo.memo_number;
        this.pdfFile = (memo.memo_type === 'upload' && memo.status !== 'draft') ? memo.signed_document : memo.word_cloud_pdf;
        this.notification.updateNotificationCount();
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.DOCUMENT-IS-PUBLISH')});
        this.modal.close();
        // this.closed.emit();
      },
      error => {
        this.showResults = false;
        this.handlePublishError(error);
        this.modal.close();
      }
    );
  }

  handlePublishError(error) {
    if (error.error && error.error.en) {
      let msgList: string[] = error.error[this.translate.currentLang];
      if (this.document.memo_template === 'PR') {
        msgList = this.formPRTranslateErrorMsg(msgList);
      }
      this.swal.toastNotification({type: 'error', content: msgList.join('\n')});
    } else {
      this.swal.toastNotification({type: 'error'});
    }
  }

  formPRTranslateErrorMsg(msgList: string[]): string[] {
    return msgList.map((msg) => {
      if (this.translate.currentLang !== 'th') {
        return msg;
      }

      let endText = 'is locked. Please update item detail.';
      if (msg.endsWith(endText)) {
        return msg.replace(endText, 'ถูกล็อค กรุณาอัพเดทรายละเอียดรายการ');
      }

      endText = 'is invalid. Please update item detail.';
      if (msg.endsWith(endText)) {
        return msg.replace(endText, 'ไม่มีในระบบ กรุณาอัพเดทรายละเอียดรายการ');
      }

      return msg;
    });
  }

  windowOpen() {
    window.open(this.pdfFile);
  }
  windowDownload() {
    this.memoService.printFile(this.pdfFile).subscribe(pdfFile => {
        const blob: any = new Blob([pdfFile.body], {type: 'application/pdf'});
        const link: any = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.document.memo_number;
        // console.log(link);
        link.click();
      },
      error => {
        if (error.error && error.error.en) {
        this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
      } else {
        this.swal.toastNotification({type: 'error'});
      }
      }
    );
  }

  checkIsAuthor() {
    return _.toNumber(localStorage.getItem('user_id')) === _.get(this.document, 'created_user', '');
  }

  checkIsAuthorOrAdmin() {
    return this.checkIsAuthor() || this.authenticationService.isAdmin();
  }

  checkDeleteMemoPermission() {
    if (this.authenticationService.isAdmin()) {
      return ['draft', 'rejected', 'terminated'].includes(this.document.status);
    } else if (this.checkIsAuthor()) {
      return ['draft', 'rejected'].includes(this.document.status);
    } else {
      return false;
    }
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  closePreview(reason) {
    this.showPreview = false;
  }

  preview() {
    this.document['preview'] = true;
    if (this.document.signed_document) {
      this.documentData = this.document.signed_document;
      this.showPreview = true;
      // Check iOS and Android
      if (this.httpClientService.isMobileOperationSystem()) {
        this.showPreview = false;
        this.openPDF();
      }
    } else {
      if (this.document.upload_type === 'offline') {
        this.notification.showSpecificError(
          'การตั้งเรื่องฉบับนี้ ถูกสร้างขึ้นแบบ Offline ผู้จัดทำยังไม่ได้อัพโหลดเอกสารที่เกี่ยวข้อง'
        );
      } else {
        this.documentData = this.document;
        this.showPreview = true;
        // Check iOS and Android
        if (this.httpClientService.isMobileOperationSystem()) {
          this.showPreview = false;
          this.openPDF();
        }
      }
    }
  }

  edit() {
    this.toggleEdit.emit(this.document);
  }

  selectView(view) {
    this.viewType = view;
    this.updateMemoIsRead(view);
  }

  updateMemoIsRead(view) {
    if (view === 0) {
    } else if (view === 1) { // comment
      this.document.unread_comment_count = 0;
    } else if (view === 2) { // attachment
      this.document.unread_attachment_count = 0;
    }
  }

  countAttachment(count) {
    this.attachmentCount = count;
  }

  closeToggle() {
    this.closed.emit();
  }

  loadDepartmentList() {
    const params = new HttpParams().set('current_user_company', 'true');
    this.memoService.getDepartmentList(params).subscribe(
      (res: any) => {
        (res.results || res || []).forEach(obj => {
          this.allDepartmentList.push({
            'label': obj.name,
            'value': obj.id
          });
        });
      },
      error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      }
    );
  }


  saveAnnouncement() {
    const data = {
      announcement: this.document.announcement,
    };
    this.isSaveAnnounce = true;
    this.memoService.updateMemoReal(this.document.id, data).subscribe(
      memo => {
        this.isSaveAnnounce = false;
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        this.modal.close();
      },
      error => {
        this.isSaveAnnounce = false;
        if (error.error && error.error.en) {
        this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
      } else {
        this.swal.toastNotification({type: 'error'});
      }
        this.modal.close();
      }
    );
  }

  submitRecall() {
    this.isSaveRecall = true;
    const data = {
      status: 'draft',
      reason: this.recall_reason
    };
    this.memoService.recallMemo(this.document.id, data).subscribe(
      memo => {
        this.isSaveRecall = false;
        this.document = {...this.document, ...memo};
        this.pdfFile = (memo.memo_type === 'upload' && memo.status !== 'draft') ? memo.signed_document : memo.word_cloud_pdf;
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        this.modal.close();
      },
      error => {
        this.isSaveRecall = false;
        if (error.error && error.error.en) {
        this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
      } else {
        this.swal.toastNotification({type: 'error'});
      }
        this.modal.close();
      }
    );
  }
}
