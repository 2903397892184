import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingRoutingModule } from './setting-routing.module';
import { GeneralModule } from './general/general.module';
import {ThemeModule} from './theme/theme.module';
import {ConfigurationComponent} from './configuration/configuration.component';
import {CoreModule} from '../../core/core.module';
import {FormsModule} from '@angular/forms';
import { DepartmentListComponent } from './department/department-list/department-list.component';
import { DepartmentEditComponent } from './department/department-edit/department-edit.component';
import { DepartmentCreateComponent } from './department/department-create/department-create.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../shared/shared.module';
import {CentralDepartmentComponent} from './department/central-department/central-department.component';

@NgModule({
  imports: [
    CommonModule,
    SettingRoutingModule,
    GeneralModule,
    ThemeModule,
    CoreModule,
    FormsModule,
    TranslateModule,
    SharedModule
  ],
  declarations: [
    ConfigurationComponent,
    DepartmentListComponent,
    DepartmentEditComponent,
    DepartmentCreateComponent,
    CentralDepartmentComponent,
  ]
})
export class SettingModule { }
