import {Component, Input, OnInit} from '@angular/core';
import {ThemeService} from '../../../../shared/services/theme.service';


@Component({
  selector: 'app-preview-theme',
  templateUrl: './preview-theme.component.html',
  styleUrls: ['./preview-theme.component.scss']
})
export class PreviewThemeComponent implements OnInit {

  // mainLogo = '../../../assets/images/Cloudhm-white.png';
  mainLogo = '/assets/images/LOGO_template_white.png';
  flatIconPath = '../../../assets/images/flaticons/';
  isDesktop: boolean;
  icons = {
    notes: this.flatIconPath + 'notes.svg',
    profits: this.flatIconPath + 'profits.svg',
    hierarchy: this.flatIconPath + 'hierarchy.svg',
    user: this.flatIconPath + 'user.svg',
    teamwork: this.flatIconPath + 'teamwork.svg',
    megaphone: this.flatIconPath + 'megaphone.svg',
    edit: this.flatIconPath + 'edit.svg',
    list: this.flatIconPath + 'list.svg',
    gear: this.flatIconPath + 'gear.svg'
  };
  hoverList = {
    'new_feed': false,
    'department': false,
    'approval': false,
    'memo_list': false,
    'manage_user': false,
  };
  hover: boolean;

  @Input() theme: any;

  constructor(
    public themeService: ThemeService,
  ) {
  }

  ngOnInit() {

  }

  hoverText(type, value) {
    this.hoverList[type] = value;
  }
}
