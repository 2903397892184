import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {FormBuilder} from '@angular/forms';
import {MemoService} from '../../../memo.service';
import {TemplateListService} from '../template-list.service';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../../shared/services/sweet-alert.service';
import {NotificationService} from '../../../../../../shared/services/notification.service';
import {FormExpensesClaim} from '../memo-template';
import * as _ from 'lodash';
import {UserService} from '../../../../../permission/user/user.service';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {
  EXPENSES_CLAIM,
  getCompanyCodeNameFromId,
  getLevelBudget
} from '../../../../../../shared/constants/helper';

export interface LineItem {
  id: number;
  no: number;
  list: string;
  count: number;
  price_unit: number;
  price: number;
}

@Component({
  selector: 'app-template-expenses-claim',
  templateUrl: './template-expenses-claim.component.html',
  styleUrls: ['./template-expenses-claim.component.scss']
})
export class TemplateExpensesClaimComponent implements OnInit, OnDestroy {

  yearList = [];
  departmentList = [];
  memoReference = [];
  announcementList = [];
  wbsList = [];
  costCentreList = [];
  companyList = [];
  companyName;
  selectedCompany;
  selectedYear;
  selectedWBS;
  department;
  departmentName;
  company_id;
  isPermissionOnlyRelated = {value: false, label: 'Only related'};
  memoNumber = 'Auto Generated';
  memoNumberList = [
    {
      name: 'Auto Generated',
      name_th: 'สร้างเลขเอกสารแบบอัตโนมัติ',
      id: 'Auto Generated'
    },
    {
      name: 'Custom Memo Number',
      name_th: 'สร้างเลขเอกสารด้วยตนเอง',
      id: 'Custom Memo Number'
    }
  ];
  taxList = [
    {name: '0 %', id: 0},
    {name: '7 %', id: 7}
  ];

  profileList = [];
  profileListAll = [];
  profileListAllTemp = [];
  profile_id = null;
  loadProfile = false;
  job_position;
  loadTo = false;
  level = 0;
  level_list = [
    {'range': 0, 'id': 0},
    {'range': 3000, 'id': 1},
    {'range': 10000, 'id': 2},
    {'range': 100000, 'id': 3},
    {'range': 200000, 'id': 4}
  ];
  isLoading = {
    department: false,
    company: false,
    year: false,
    wbs: false,
    costcentre: false,
    to: false,
    cc: false,
    ref: false,
    announcement: false,
  };

  userKeyMemoNumber$ = new Subject<string>();
  userKeyMemoNumber: string;
  memoNumberAlreadyExist = false;
  subscriptions = new Subscription();
  permission_company;

  // tslint:disable-next-line:no-input-rename
  @Input('memoData') memoData;
  // tslint:disable-next-line:no-input-rename
  @Input('attachment') file;
  // tslint:disable-next-line:no-input-rename
  @Input('formExpensesClaimData') formExpensesClaimData: FormExpensesClaim;
  @Input() selectedTemplate;
  @Output() memoDataChange = new EventEmitter();
  @Output() attachmentChange = new EventEmitter();
  @Output() formExpensesClaimDataChange = new EventEmitter();

  constructor(
    private builder: FormBuilder,
    private memoService: MemoService,
    private templateListService: TemplateListService,
    private translate: TranslateService,
    private swal: SweetAlertService,
    private userService: UserService,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    if (this.memoData.is_permission) {
      this.isPermissionOnlyRelated.value = this.memoData.is_permission;
    }
    this.memoData.customMemoNumberDropDown = this.memoData.is_custom_memo_number
      ? this.memoNumberList[1].id
      : this.memoNumberList[0].id;
    this.company_id = localStorage.getItem('company_id');
    this.department = localStorage.getItem('department_id');
    this.permission_company = localStorage.getItem('permission_company');
    this.profile_id = localStorage.getItem('profile_id');
    this.memoData.company_by_selection = this.company_id;
    this.getJobPosition();
    if (this.permission_company) {
      this.loadCompanyPermission();
    } else {
      this.loadAllGeneralProfile();
      this.loadAnnouncement();
      this.loadCompanyAndDepartment();
    }
    this.initialCheckMemoNumberExist();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadDepartmentForPermissionCompany() {
    this.memoData.company_by_selection = this.selectedCompany;
    this.isLoading.department = true;
    this.isLoading.year = true;
    this.isLoading.ref = true;
    const params = new HttpParams()
      .set('type', 'memo_number,department,wbs_year')
      .set('company', this.selectedCompany)
      .set('department', +this.selectedCompany === +this.company_id ? this.department : null);
    this.memoService.getDropDown(params).subscribe((data) => {
      this.memoReference = data.memo_number;
      this.departmentList = _.orderBy(data.department, [department => department.label], 'asc');
      this.departmentList = this.departmentList.map(item => {
        return {
          label: (item.context.code_number as string) + ' - ' + (item.label),
          value: item.value,
        };
      });
      this.memoData.department = this.initialSelectedDepartment(this.departmentList);
      this.yearList = data.wbs_year;
      this.selectedYear = this.yearList.length > 0 ? this.yearList[this.yearList.length - 1].year : '';
      this.isLoading.department = false;
      this.isLoading.year = false;
      this.isLoading.ref = false;
      if (this.memoData.department && this.selectedYear) {
        this.loadWBS();
      }
    });
    this.loadAllGeneralProfile();
    this.loadAnnouncement();
  }

  loadCompanyPermission() {
    this.isLoading.company = true;
    if (this.memoData.department) {
      const params_company = new HttpParams()
        .set('type', 'company')
        .set('profile', this.profile_id);
      this.memoService.getDropDown(params_company).subscribe((data) => {
        this.selectedCompany = data.company[0].value;
        this.companyList = data.company.map((item) => {
          if (item.context && item.context.code_name) {
            item.label = `${item.context.code_name} - ${item.label}`;
          }
          return item;
        });
        this.isLoading.company = false;
        this.loadDepartmentForPermissionCompany();
      });
    } else {
      const company = this.company_id + ',' + this.permission_company;
      const params = new HttpParams()
        .set('type', 'company')
        .set('company', company);
      this.memoService.getDropDown(params).subscribe((data) => {
        this.companyList = data.company;
        this.isLoading.company = false;
      });
    }
  }

  loadCompanyAndDepartment() {
    this.isLoading.department = true;
    this.isLoading.year = true;
    this.isLoading.ref = true;
    const params = new HttpParams()
      .set('type', 'memo_number,department,company,wbs_year')
      .set('company', this.company_id)
      .set('department', this.department);
    this.memoService.getDropDown(params).subscribe((data) => {
      this.companyName = data.company[0].label;
      this.selectedCompany = data.company[0].value;
      this.companyList = data.company;
      this.memoReference = data.memo_number;
      this.departmentList = _.orderBy(data.department, [department => department.label], 'asc');
      this.departmentList = this.departmentList.map(item => {
        return {
          label: (item.context.code_number as string) + ' - ' + (item.label),
          value: item.value,
        };
      });
      this.memoData.department = this.initialSelectedDepartment(this.departmentList);
      this.yearList = data.wbs_year;
      this.selectedYear = this.yearList.length > 0 ? this.yearList[this.yearList.length - 1].year : null;
      this.isLoading.department = false;
      this.isLoading.year = false;
      this.isLoading.ref = false;
      if (this.memoData.department && this.selectedYear) {
        this.loadWBS();
      }
    });
  }

  selectedTo(event) {
    if (event.id === '') {
      this.memoData.to_none_user = event.name;
    }
  }

  loadAnnouncement() {
    this.isLoading.announcement = true;
    const params = new HttpParams()
      .set('type', 'department')
      .set('company', this.selectedCompany ? this.selectedCompany : localStorage.getItem('company_id').toString());
    this.memoService.getDropDown(params).subscribe((data) => {
      let departmentL = data.department;
      departmentL = departmentL.map(item => {
        return {
          label: (item.context.code_number as string) + ' - ' + (item.label as string),
          value: item.value,
        };
      });
      this.announcementList = _.orderBy(departmentL, [department => department.label], 'asc');
      this.isLoading.announcement = false;
    });
  }

  onSelectDepartment() {
    // this.clearYear();
    this.clearWBS();
    this.clearCostCentre();
    this.loadWBS();
  }

  onSelectYear() {
    this.clearWBS();
    this.clearCostCentre();
    this.loadWBS();
  }

  onSelectWBS() {
    this.clearCostCentre();
    this.changeWBS();
  }

  clearYear() {
    this.yearList = [];
    this.selectedYear = null;
  }

  clearWBS() {
    this.wbsList = [];
    this.formExpensesClaimData.wbs = null;
  }

  clearCostCentre() {
    this.costCentreList = [];
    this.formExpensesClaimData.cost_centre = null;
  }

  loadWBS() {
    this.isLoading.wbs = true;
    this.wbsList = [];
    this.selectedWBS = null;
    const params = new HttpParams()
      .set('type', 'wbs')
      .set('year', this.selectedYear)
      .set('company', this.selectedCompany);
    this.memoService.getDropDown(params).subscribe((wbs) => {
        this.wbsList = wbs.wbs;
        this.wbsList = this.wbsList.map(item => {
          return {code: item.label, id: item.value};
        });
        this.isLoading.wbs = false;
        if (this.formExpensesClaimData.wbs) {
          this.changeWBS();
        }
      },
      error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      });
  }

  changeWBS() {
    this.isLoading.costcentre = true;
    this.costCentreList = [];
    this.formExpensesClaimData.cost_centre = null;
    const params = new HttpParams()
      .set('type', 'costcentre')
      .set('wbs', this.formExpensesClaimData.wbs.toString())
      .set('company', this.selectedCompany ? this.selectedCompany : localStorage.getItem('company_id').toString());
    this.memoService.getDropDown(params).subscribe((costcentre) => {
        this.costCentreList = costcentre.costcentre;
        this.isLoading.costcentre = false;
      },
      error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      });
  }

  sendLevel() {
    const total = this.formExpensesClaimData.sum_amount || 0;
    const companyCode = getCompanyCodeNameFromId(this.selectedCompany, this.companyList);
    if (companyCode) {
      this.level = getLevelBudget(EXPENSES_CLAIM, total, companyCode);
    }
    return this.level;
  }

  changeMemoNumber(e) {
    if (this.memoNumberList[0].id === e.id) {
      this.memoData.is_custom_memo_number = false;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[0].id;
    } else if (this.memoNumberList[1].id === e.id) {
      this.memoData.is_custom_memo_number = true;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[1].id;
    }
  }

  getJobPosition() {
    this.job_position = localStorage.getItem('job_position');
  }

  loadAllGeneralProfile() {
    this.isLoading.to = true;
    this.isLoading.cc = true;
    const generalRole = '1';
    const params = new HttpParams()
      .set('type', 'profile')
      // .set('company', this.selectedCompany ? this.selectedCompany : localStorage.getItem('company_id').toString())
      .set('role', generalRole);
    this.memoService.getDropDown(params).subscribe(profiles => {
      this.profileListAllTemp = profiles.profile.map(item => item);
      this.profileListAll = profiles.profile;
      this.isLoading.to = false;
      this.isLoading.cc = false;
    }, error => {
      if (error.error && error.error.en) {
        this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
      } else {
        this.swal.toastNotification({type: 'error'});
      }
    });
  }

  filterUser(event: { isCustom: boolean; isClear: boolean; data: { name: string; label: number }[] }) {
    this.loadProfile = true;
    this.loadTo = true;
    this.profileList = event.data;
    this.profileListAllTemp = this.profileListAll.map(item => item);
    const profile = event.data.map(i => {
      return {
        name: i.name,
        name_en: i.name,
        id: ''
      };
    });
    profile.forEach(p => this.profileListAllTemp.push(p));
    setTimeout(() => {
      this.loadProfile = false;
      this.loadTo = false;
    }, 10);
  }

  initialCheckMemoNumberExist() {
    this.userKeyMemoNumber = this.memoData.memo_number;
    const sub = this.userKeyMemoNumber$
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        map(memoNumber => memoNumber.trim()),
        filter(memoNumber => memoNumber !== ''),
        switchMap((memoNumber: string) => {
          const params = new HttpParams().set('memo_number', memoNumber);
          return this.memoService.isMemoNumberExist(params);
        }))
      .subscribe((isExist: boolean) => {
        this.memoNumberAlreadyExist = isExist;
        if (!this.memoNumberAlreadyExist) {
          this.memoData.memo_number = this.userKeyMemoNumber.trim();
        } else {
          this.memoData.memo_number = null;
        }
      });

    this.subscriptions.add(sub);
  }

  addTagNonMemoRef(name: string) {
    return {label: name, value: name, tag: true};
  }

  get isEnLanguage() {
    return this.translate.currentLang === 'en';
  }

  initialSelectedDepartment(departmentList: any[]) {
    let initialDepartment = departmentList.length > 0 ? departmentList[0].value : null;
    const ownDepartmentIds = localStorage.getItem('department_id');
    if (!ownDepartmentIds) {
      return;
    }
    const ownDepartmentId = ownDepartmentIds.split(',')[0];
    (departmentList || []).forEach(d => {
      if (d.value === Number(ownDepartmentId)) {
        initialDepartment = d.value;
        return;
      }
    });
    return initialDepartment;
  }
}
