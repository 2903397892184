import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { MemosModule } from '../memos/memos.module';
import { CoreModule } from '../../core/core.module';
import { PermissionModule } from '../permission/permission.module';
import { SettingModule } from '../setting/setting.module';
import { TranslateService } from '../../translate/translate.service';
import { TRANSLATION_PROVIDERS } from '../../translate/translation';
import { UserProfileModule } from '../user-profile/user-profile.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ResetPasswordModule } from '../reset-password/reset-password.module';
import { FeedModule } from '../feed/feed.module';
import { TitleService } from '../../shared/services/title-service';
import { OperationLogModule } from '../operation-log/operation-log.module';

@NgModule({
  imports: [
    CommonModule,
    MemosModule,
    RouterModule,
    TranslateModule,
    CoreModule,
    PermissionModule,
    SettingModule,
    UserProfileModule,
    NgbModule,
    ResetPasswordModule,
    FeedModule,
    OperationLogModule,
  ],
  declarations: [MainComponent],
  providers: [TRANSLATION_PROVIDERS, TranslateService, TitleService],
  exports: [MainComponent],
})
export class MainModule {}
