import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL, GATEWAY_URL, API_CONSTANT } from '../constants/api.constant';
import { HttpClientService } from './http-client.service';
import { Router } from '@angular/router';
import { SidebarService } from 'src/app/core/sidebar/sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  newToken: Object;
  personalInfo: Object;

  constructor(
    private http: HttpClient,
    private httpService: HttpClientService,
    private router: Router,
    private sidebar: SidebarService,
    private translateService: TranslateService
  ) {}

  login(data) {
    return this.http.post(API_URL.login, data);
  }

  loginFromEmailApproval(token): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.post(API_URL.login_from_email_approval, {}, {headers: headers});
  }

  forgotPassword(data) {
    return this.http.post(API_URL.reset_password, data);
  }

  validateResetPasswordToken(data) {
    return this.http.post(API_URL.reset_password_validate, data);
  }

  resetPassword(data) {
    return this.http.post(API_URL.reset_password_confirm, data);
  }

  switchHRFromFa(data) {
    return this.http.post(API_CONSTANT.login_via_fa, data);
  }

  getUserInfo(token) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(API_URL.me, { headers: headers });
  }

  submitGoogleAccount(data) {
    return this.http.post(API_URL.register_gmail, data);
  }

  convertToken(data) {
    return this.http.post(API_URL.convert_token, data);
  }

  saveToken(data) {
    this.newToken = data;
  }

  getToken() {
    return this.newToken;
  }

  savePersonalData(data) {
    this.personalInfo = data;
  }

  getPersonalData() {
    return this.personalInfo;
  }

  refreshToken(token) {
    return this.http.post(GATEWAY_URL.refresh_token, token);
  }

  getHeaders() {
    const token = localStorage.getItem('currentUser');
    return new HttpHeaders().set('Authorization', 'Bearer ' + token);
  }

  isAdmin() {
    const roleName = localStorage.getItem('role_name');
    return roleName === 'Admin' || roleName === 'Master Admin';
  }

  isViewAllCompany() {
    return  localStorage.is_view_all_company === 'true';
  }

  isIt() {
    return localStorage.getItem('role') === '4';
  }

  isPrepareMgr() {
    return localStorage.getItem('role') === '8';
  }
  isGeneral() {
    return localStorage.getItem('role') === '1';
  }

  checkNavigateRoute(data) {
    switch (data) {
      case 'IT':
        this.router.navigate(['/setting-loa']);
        break;
      case 'Admin':
      case 'Master Admin':
        this.router.navigate(['/permission', 'profile', 'list']);
        break;
      default:
        this.router.navigate(['/memo', 'memo', 'real', 'list']);
        break;
    }
  }

  setProfileInLocalStorage(data) {
    this.sidebar.setActiveSidebar(true);
    localStorage.setItem('currentUser', data.token);
    localStorage.setItem('role_name', data.user.role_name);
    const firstName = data.user.first_name;
    const lastName = data.user.last_name;
    localStorage.setItem('username', data.user.username);
    localStorage.setItem('photoUrl', data.user.photo || '');
    localStorage.setItem('email', data.user.email);
    localStorage.setItem('name', firstName);
    localStorage.setItem('role', data.user.role);
    localStorage.setItem('level', data.user.level);
    localStorage.setItem('is_view_all_budget', data.user.is_view_all_budget);
    localStorage.setItem('is_view_all_company', data.user.is_view_all_company);
    localStorage.setItem('company_id', data.user.company_id);
    localStorage.setItem('permission_company', data.user.permission_company);
    localStorage.setItem('department_id', data.user.department_id);
    localStorage.setItem('full_name', firstName + ' ' + lastName || '');
    localStorage.setItem('user_id', data.user.user_id);
    localStorage.setItem('profile_id', data.user.profile_id);
    localStorage.setItem('job_position', data.user.job_position);
    localStorage.setItem('has_signature', data.user.has_signature);
    const defaultLang = localStorage.getItem('lang');
    if (!defaultLang) {
      localStorage.setItem('lang', 'en');
    }
    localStorage.setItem('lang', defaultLang);
    this.translateService.use(defaultLang);
    this.savePersonalData(data);
    this.httpService.setHeader();
    const roleName: string = data.user.role_name;
    this.checkNavigateRoute(roleName);
  }

  clearStorage() {
    const langNow = localStorage.getItem('lang');
    const lang = langNow === 'null' ? 'en' : langNow;
    localStorage.clear();
    localStorage.setItem('lang', lang);
    this.translateService.use(lang);
  }
}
