import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, TemplateRef} from '@angular/core';
import {MemoService} from '../memo.service';
import {HttpParams} from '@angular/common/http';
import {NotificationService} from '../../../../shared/services/notification.service';
import * as _ from 'lodash';
import {interval, Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ThemeService} from '../../../../shared/services/theme.service';
import {HttpClientService} from '../../../../shared/services/http-client.service';


@Pipe({
  name: 'highlight'
})
export class HighlightComment implements PipeTransform {
  transform(text: string, search: any) {
    // with regex u flag , \u{0E01}-\u{0E5B} stand for thai languages in unicode format , it mean ก-๙
    const regex = /(?:@)([\u{0E01}-\u{0E5B}a-zA-Z\d]+)(\s)([\u{0E01}-\u{0E5B}a-zA-Z\d]+)/gmu;
    let result = text;
    result = result.replace(regex, '<span class="text-blue">  $& </span>');
    return result;
  }
}

@Component({
  selector: 'app-memo-comment',
  templateUrl: './memo-comment.component.html',
  styleUrls: ['./memo-comment.component.scss']
})
export class MemoCommentComponent implements OnInit, OnDestroy {
  newComment: string;
  commentList = [];
  owner = localStorage.full_name;
  modal: NgbModalRef;
  documentData = undefined;
  showResult = false;
  showPreview = false;
  currentPage = 1;
  finalPage = false;
  chatSpin = false;
  subscription: Subscription;
  translateSub: Subscription;
  currentComment: number = null;
  photo = localStorage.getItem('photoUrl');
  chackLanguageEn =  false;
  isMobile: boolean;
  isLoadingComment = false;

  @Input() profileID = _.toNumber(localStorage.getItem('profile_id'));
  @Input() data: any;
  @Output() submitted = new EventEmitter();

  constructor(
    private notification: NotificationService,
    private memoService: MemoService,
    public translate: TranslateService,
    private modalService: NgbModal,
    private httpClientService: HttpClientService,
    private themeService: ThemeService
  ) {
  }

  ngOnInit() {
    this.getCommentList();
    this.isMobile = this.themeService.isMobile();
    this.translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateComment();
    });
  }

  onResize(event) {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
  }

  addThaiComment(comments?: Array<any>) {
    return comments.map(comment => ({
      ...comment,
      // tslint:disable-next-line:max-line-length
      commentTh: comment.message != null ? comment.message.replace('Terminated reason', 'เหตุผลการไม่อนุมัติ').replace('Rejected reason', 'เหตุผลที่ต้องแก้ไข') : comment.message
    }));
  }

  translateComment() {
    this.chackLanguageEn = this.translate.currentLang === 'en';
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.translateSub.unsubscribe();
  }

  startCommentListRunner() {
    const source = interval(60000);
    this.subscription = source.subscribe(val => this.getRefreshCommentList());
  }

  getCommentList() {
    // this.currentPage = 1;
    // this.finalPage = false;
    // this.chatSpin = true;
    const params = new HttpParams().set('memo', this.data).set('none_pagination', 'True');
    this.memoService.getCommentList(params).subscribe((comments: any) => {
      // this.chatSpin = false;
      // this.commentTh = comments;
      this.commentList = this.addThaiComment(comments);
      this.translateComment();
      // console.log('commentList', this.commentList);
      // this.currentPage++;
      // if (comments.next == null) {
        // this.finalPage = true;
        // this.chatSpin = true;
      // }
      this.showResult = true;
      this.startCommentListRunner();
    });
  }

  getRefreshCommentList() {
    // this.currentPage = 1;
    // this.finalPage = false;
    // this.chatSpin = true;
    const params = new HttpParams().set('memo', this.data).set('none_pagination', 'True');
    this.memoService.getCommentList(params).subscribe((comments: any) => {
      // this.chatSpin = false;
      this.commentList = this.addThaiComment(comments);
      // this.currentPage++;
      // if (comments.next == null) {
      //   this.finalPage = true;
      //   this.chatSpin = true;
      // }
      this.showResult = true;
    });
  }

  addComment(newComment) {
    if (newComment[0].newComment == null || newComment[0].newComment.trim().length === 0) {
      return;
    }
    const profileID = _.toNumber(localStorage.getItem('profile_id')) || this.profileID;
    const fd = {
      'mentioned_person': newComment[0].mentionedPerson,
      'memo': this.data,
      'message': newComment[0].newComment || '',
      'created_profile': profileID.toString()
    };
    this.memoService.createNewComment(fd).subscribe((comment) => {
      this.commentList.push(comment);
      this.commentList = this.addThaiComment(this.commentList);
      newComment = [];
    });
  }

  deleteComment(commentId: number) {
    this.memoService
      .deleteComment(commentId)
      .subscribe(
        () => (this.commentList = this.commentList.filter(stream => stream.id !== commentId)),
        error => this.notification.showNotification(error.status)
      );
    this.modal.close();
  }

  isOwner({created_by}) {
    return this.owner === created_by;
  }


  // onScroll(event) {
  //   if (event.target.offsetHeight + event.target.scrollTop < event.target.scrollHeight) {
  //     return;
  //   }
  //   if (this.finalPage === true) {
  //     return;
  //   }
  //   this.chatSpin = false;
  //   const params = new HttpParams()
  //     .set('memo', this.data)
  //     .set('page', this.currentPage.toString());
  //   this.memoService.getCommentList(params).subscribe(
  //     (comments: any) => {
  //       this.commentList = this.commentList.concat(comments.results);
  //       this.currentPage++;
  //       if (comments.next == null) {
  //         this.finalPage = true;
  //       }
  //       this.chatSpin = true;
  //     },
  //     error => console.log('error', error)
  //   );
  // }

  uploadFile(event) {
    const profileID = _.toNumber(localStorage.getItem('profile_id')) || this.profileID;
    const fd = new FormData();
    fd.append('file_upload', event.target.files[0]);
    fd.append('created_profile', profileID.toString());
    fd.append('memo', this.data);
    this.memoService.createNewComment(fd).subscribe(res => {
      this.getCommentList();
    });
  }

  getExtension({file_upload}): string {
    return file_upload.substr(file_upload.lastIndexOf('.') + 1);
  }

  checkImageURL(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) !== null;
  }

  preview(file) {
    if (this.isMobile) {
      this.showPreview = false;
      window.open(file);
    } else {
      if (location.protocol === 'https:') {
        file = file.replace(/^http:/, 'https:');
      }
      this.documentData = file;
      this.showPreview = true;
     }
  }

  closePreview() {
    this.showPreview = false;
  }

  // trackByFn(index, item) {
  //   return item.id;
  // }

  openDeleteModal(modal: TemplateRef<any>, commentId: number) {
    this.modal = this.modalService.open(modal);
    this.currentComment = commentId;
  }

  downloadComment() {
    this.isLoadingComment = true;
    console.log(this.data);
    this.memoService.getCommentLogCSV(this.data).subscribe(
      res => {
        const filename = 'Comment Log Memo.csv';
        this.httpClientService.createDownloadFile(res, filename);
        this.isLoadingComment = false;
      },
      err => {
        this.isLoadingComment = false;
      }
    );
  }
}
