import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'humanDateTime' })
export class HumanDatetimePipe implements PipeTransform {
  transform(value: any): any {
    return (value === null || value === undefined) ? '-' : moment(value).format('DD MMMM YYYY');
  }

}
