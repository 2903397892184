import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { GeneralModule } from '../../setting/general/general.module';
import { MemoRoutingModule } from './memo-routing.module';
import { MemoRealListComponent } from './real/memo-real-list/memo-real-list.component';
import { CoreModule } from '../../../core/core.module';
import { MemoService } from './memo.service';
import {
  AutoCompleteModule,
  CheckboxModule,
  CodeHighlighterModule, ColorPickerModule, DropdownModule,
  InputSwitchModule,
  MultiSelectModule
} from 'primeng/primeng';
import { MemoRealEditComponent } from './real/memo-real-edit/memo-real-edit.component';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { MemoRealDetailComponent } from './real/memo-real-detail/memo-real-detail.component';
import { MemoSignModalComponent } from './real/memo-sign-modal/memo-sign-modal.component';
import { MemoTemplateComponent } from './template/memo-template/memo-template.component';
import { TemplateOneComponent } from './template/template-one/template-one.component';
import { TemplateWordcloudComponent } from './template/template-wordcloud/template-wordcloud.component';
import { MemoDraftCreateComponent } from './draft/memo-draft-create/memo-draft-create.component';
import { MemoDraftListComponent } from './draft/memo-draft-list/memo-draft-list.component';
import { MemoDraftUpdateComponent } from './draft/memo-draft-update/memo-draft-update.component';
import {HighlightComment, MemoCommentComponent} from './memo-comment/memo-comment.component';
import { MemoLinkModalComponent } from './memo-link-modal/memo-link-modal.component';
import { MemoPreviewComponent } from './memo-preview/memo-preview.component';
import { MemoRealAttachmentComponent } from './real/memo-real-attachment/memo-real-attachment.component';
import { MemoRealChoiceComponent } from './real/memo-real-choice/memo-real-choice.component';
import { MemoLogoModalComponent } from './memo-logo-modal/memo-logo-modal.component';
import {LoaModule} from '../loa/loa.module';
import {FileUploadModule} from '../../file-upload/file-upload.module';
import {EditorModule} from 'primeng/editor';
import {FileDropModule} from 'ngx-file-drop';
import {TranslateModule} from '@ngx-translate/core';
import { TemplateUploadComponent } from './template/template-upload/template-upload.component';
import { MemoRealHistoryComponent } from './real/memo-real-history/memo-real-history.component';
import {UploadTemplateCustomizeComponent} from './template/template-upload/upload-template-customize/upload-template-customize.component';
import {AngularResizedEventModule} from 'angular-resize-event';
import {DateFormatService} from '../../../shared/services/date-format.service';
import { LoaNameThPipe } from 'src/app/shared/pipes/loa-name-th.pipe';
import { TemplateListComponent } from './template/template-list/template-list.component';
import { TemplateAssetComponent } from './template/template-list/template-asset/template-asset.component';
import { TemplateExpensesClaimComponent } from './template/template-list/template-expenses-claim/template-expenses-claim.component';
import { TemplateBudgetRequestComponent } from './template/template-list/template-budget-request/template-budget-request.component';
import { TemplateBudgetTransferComponent } from './template/template-list/template-budget-transfer/template-budget-transfer.component';
import { MatInputCommifiedDirective } from '../../../shared/directive/mat-input-commified.directive';
// tslint:disable-next-line:max-line-length
import { MemoUploadAttachmentComponent } from './template/template-list/shared-template/memo-upload-attachment/memo-upload-attachment.component';
import { TableTemplateAssetComponent } from './template/template-list/shared-template/table-template-asset/table-template-asset.component';
import { MemoLoaSelectComponent } from './template/template-list/shared-template/memo-loa-select/memo-loa-select.component';
// tslint:disable-next-line:max-line-length
import { TableTemplateExpensesClaimComponent } from './template/template-list/shared-template/table-template-expenses-claim/table-template-expenses-claim.component';
import { TemplateInternalComponent } from './template/template-list/template-internal/template-internal.component';
import { ResizableDraggableComponent } from './template/template-upload/resizable-draggable/resizable-draggable.component';
import { AngularDraggableModule } from 'angular2-draggable';
import {TemplateBGPUProductSellComponent} from './template/template-list/template-bgpu-product-sell/template-bgpu-product-sell.component';
import {
  TableTemplateBGPUProductSellComponent
} from './template/template-list/shared-template/table-template-bgpu-product-sell/table-template-bgpu-product-sell.component';
import {MemoInputCompanyComponent} from './inputs/memo-input-company/memo-input-company.component';
import {TemplatePrComponent} from './template/template-list/template-pr/template-pr.component';
import {
  TableTemplatePrComponent
} from './template/template-list/shared-template/table-template-pr/table-template-pr.component';
import {MasterItemService} from '../../master-item/master-item.service';
import {SharedModule} from '../../../shared/shared.module';
import {TemplatePrService} from './template/template-list/template-pr/template-pr.service';
import {
  PrItemDropdownComponent
} from './template/template-list/shared-template/table-template-pr/pr-item-dropdown/pr-item-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    MemoRoutingModule,
    FroalaEditorModule,
    FroalaViewModule,
    FormsModule,
    PdfViewerModule,
    AutoSizeInputModule,
    CoreModule,
    GeneralModule,
    CheckboxModule,
    CanvasWhiteboardModule,
    LoaModule,
    FileUploadModule,
    EditorModule,
    CodeHighlighterModule,
    FileDropModule,
    TranslateModule,
    MultiSelectModule,
    InputSwitchModule,
    DragDropModule,
    DropdownModule,
    AngularResizedEventModule,
    ColorPickerModule,
    AutoCompleteModule,
    ReactiveFormsModule,
    AngularDraggableModule,
    SharedModule,
  ],
  declarations: [
    MemoRealListComponent,
    MemoRealEditComponent,
    MemoRealDetailComponent,
    MemoSignModalComponent,
    MemoTemplateComponent,
    TemplateOneComponent,
    TemplateWordcloudComponent,
    MemoDraftCreateComponent,
    MemoDraftListComponent,
    MemoDraftUpdateComponent,
    MemoCommentComponent,
    MemoLinkModalComponent,
    MemoPreviewComponent,
    MemoRealAttachmentComponent,
    MemoRealChoiceComponent,
    MemoLogoModalComponent,
    TemplateUploadComponent,
    HighlightComment,
    MemoRealHistoryComponent,
    UploadTemplateCustomizeComponent,
    LoaNameThPipe,
    TemplateListComponent,
    TemplateAssetComponent,
    TemplateExpensesClaimComponent,
    TemplateBudgetRequestComponent,
    TemplateBudgetTransferComponent,
    MemoUploadAttachmentComponent,
    TableTemplateAssetComponent,
    MemoLoaSelectComponent,
    TableTemplateExpensesClaimComponent,
    TemplateInternalComponent,
    MatInputCommifiedDirective,
    ResizableDraggableComponent,
    TemplateBGPUProductSellComponent,
    TableTemplateBGPUProductSellComponent,
    MemoInputCompanyComponent,
    TemplatePrComponent,
    TableTemplatePrComponent,
    PrItemDropdownComponent,
  ],
  exports: [
    MemoPreviewComponent,
    MemoCommentComponent,
    MemoRealAttachmentComponent,
    MemoRealHistoryComponent,
],
  providers: [MemoService, DateFormatService, MasterItemService, TemplatePrService]
})
export class MemoModule {
}
