import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { loaRoutes } from './loa/loa-routing.module';
import { childMemoRoutes } from './memo/memo-routing.module';

export const memoRoutes: Routes = [
  {
    path: 'memo',
    children: [
      ...loaRoutes,
      ...childMemoRoutes
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(memoRoutes)],
  exports: [RouterModule]
})
export class MemosRoutingModule { }
