import { TranslateService } from './../../../../../../translate/translate.service';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormExpensesClaim} from '../memo-template';
import {HttpParams} from '@angular/common/http';
import {MemoService} from '../../../memo.service';
import * as _ from 'lodash';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';


@Component({
  selector: 'app-template-internal',
  templateUrl: './template-internal.component.html',
  styleUrls: ['./template-internal.component.scss']
})
export class TemplateInternalComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line:no-input-rename
  @Input('memoData') memoData;
  // tslint:disable-next-line:no-input-rename
  @Input('attachment') file;
  @Output() memoDataChange = new EventEmitter();
  @Output() attachmentChange = new EventEmitter();

  isPermissionOnlyRelated = {value: false, label: 'Only related'};
  departmentList = [];
  profileList = [];
  profileListAll = [];
  companyList = [];
  profile_id = null;
  companyId;
  selectedCompany$ = new Subject<number>();
  selectedDepartment;
  department;
  company_id;
  memoReference = null;
  memoNumberList = [
    {
      name: 'Auto Generated',
      name_th: 'สร้างเลขเอกสารแบบอัตโนมัติ',
      id: 'Auto Generated'
    },
    {
      name: 'Custom Memo Number',
      name_th: 'สร้างเลขเอกสารด้วยตนเอง',
      id: 'Custom Memo Number'
    }
  ];
  loadTo = false;
  isCustomMemo = false;
  isLoadingList = {
    company: false,
    to: false,
    cc: false,
    ref: false,
    announcement: false,
  };

  userKeyMemoNumber$ = new Subject<string>();
  userKeyMemoNumber: string;
  memoNumberAlreadyExist = false;
  subscriptions = new Subscription();

  constructor(
    private memoService: MemoService,
    private translate: TranslateService,
    ) {
  }

  ngOnInit() {
    if (this.memoData.is_permission) {
      this.isPermissionOnlyRelated.value = this.memoData.is_permission;
    }
    this.memoData.customMemoNumberDropDown = this.memoData.is_custom_memo_number
      ? this.memoNumberList[1].id
      : this.memoNumberList[0].id;
    this.company_id = localStorage.getItem('company_id');
    this.profile_id = localStorage.getItem('profile_id');
    this.department = this.memoData.department;

    this.loadCompany();
    const sub = this.selectedCompany$.pipe(distinctUntilChanged()).subscribe(() => {
      this.getRefMemos();
      this.loadAllGeneralProfile();
      this.memoData.company_by_selection = this.companyId  || this.company_id;
    });
    this.subscriptions.add(sub);

    this.initialCheckMemoNumberExist();
    // this.loadDepartment();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadCompany() {
    this.isLoadingList.company = true;
    const params = new HttpParams()
      .set('type', 'company')
      .set('profile', this.profile_id);
    this.memoService.getDropDown(params).subscribe((dropdown) => {
      this.companyList = dropdown.company.map((item) => {
        if (item.context && item.context.code_name) {
          item.label = `${item.context.code_name} - ${item.label}`;
        }
        return item;
      });
      this.companyId = this.memoData.company_id;
      this.isLoadingList.company = false;
      this.selectedCompany$.next(this.companyId);
    });
  }

  // loadDepartment() {
  //   this.selectedDepartment = null;
  //   this.departmentList = [];
  //   const params = new HttpParams()
  //     .set('type', 'department')
  //     .set('department', this.department);
  //   this.memoService.getDropDown(params).subscribe((department) => {
  //     this.departmentList = department.department;
  //   });
  // }

  filterUser(event: { isCustom: boolean; isClear: boolean; data: { name: string; label: number }[] }) {
    this.loadTo = true;
    this.profileList = event.data;
    setTimeout(() => {
      this.loadTo = false;
    }, 10);
  }

  updateNormalLoa(event) {
    this.loadTo = true;
    this.profileList = [{
      name: localStorage.getItem('full_name'),
      label: +localStorage.getItem('profile_id')
    }];
    event.data.forEach(level => {
      if (level.members) {
        level.members.forEach(member => {
          if (member.profile && member.profile_name) {
            this.profileList.push({name: member.profile_name, label: member.profile});
          }
        });
      }
    });
    setTimeout(() => {
      this.loadTo = false;
    }, 10);
  }

  changeMemoNumber(e) {
    if (this.memoNumberList[0].id === e.id) {
      this.memoData.is_custom_memo_number = false;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[0].id;
    } else if (this.memoNumberList[1].id === e.id) {
      this.memoData.is_custom_memo_number = true;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[1].id;
    }
  }

  getRefMemos() {
    this.isLoadingList.ref = true;
    this.isLoadingList.announcement = true;
    const params = new HttpParams()
      .set('type', 'memo_number,department')
      .set('company', this.companyId);
    this.memoService.getDropDown(params).subscribe(dropDown => {
      this.memoReference = dropDown.memo_number;
      let departmentL = dropDown.department;
      departmentL = departmentL.map(item => {
        return {
          label: (item.context.code_number as string) + ' - ' + (item.label as string),
          value: item.value,
        };
      });
      this.departmentList = _.orderBy(departmentL, [department => department.label], 'asc');
      // this.memoData.department = this.departmentList.length > 0 ? this.departmentList[0].value : null;
      this.isLoadingList.ref = false;
      this.isLoadingList.announcement = false;
    });
  }

  loadAllGeneralProfile() {
    this.isLoadingList.cc = true;
    this.isLoadingList.to = true;
    const generalRole = '1';
    const params = new HttpParams()
      .set('type', 'profile')
      // .set('company', this.companyId)
      .set('role', generalRole);
    this.memoService.getDropDown(params).subscribe(dropDown => {
      const profiles = dropDown.profile;
      this.profileListAll = _.orderBy(profiles, [profile => profile.name.toLowerCase()], 'asc');
      this.isLoadingList.cc = false;
      this.isLoadingList.to = false;
    });
  }

  initialCheckMemoNumberExist() {
    this.userKeyMemoNumber = this.memoData.memo_number;
    const sub = this.userKeyMemoNumber$
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        map(memoNumber => memoNumber.trim()),
        filter(memoNumber => memoNumber !== ''),
        switchMap((memoNumber: string) => {
          const params = new HttpParams().set('memo_number', memoNumber);
          return this.memoService.isMemoNumberExist(params);
        }))
      .subscribe((isExist: boolean) => {
        this.memoNumberAlreadyExist = isExist;
        if (!this.memoNumberAlreadyExist) {
          this.memoData.memo_number = this.userKeyMemoNumber.trim();
        } else {
          this.memoData.memo_number = null;
        }
      });

    this.subscriptions.add(sub);
  }

  addTagNonMemoRef(name: string) {
    return {label: name, value: name, tag: true};
  }

  selectedTo(event) {
    if (event.label === '') {
      this.memoData.to_none_user = event.name;
    }
  }

  get isEnLanguage() {
    return this.translate.currentLang === 'en';
  }
}
