import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {

  @Input() placeholder = 'Search';
  @Input() filter = '';
  @Output() filterChange = new EventEmitter();

  debouncer = new Subject<string>();

  constructor() {
    this.debouncer.pipe(
      debounceTime(1200),
      distinctUntilChanged()
    ).subscribe((filter) => this.filterChange.emit(filter));
  }

  ngOnInit() {
  }

  onFilterChange(filter) {
    this.debouncer.next(filter);
  }

  onClearFilter() {
    this.filter = '';
    this.filterChange.emit(this.filter);
  }

}
