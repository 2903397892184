import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import * as _ from 'lodash';

import {LoaService} from '../loa.service';
import {UserService} from '../../../permission/user/user.service';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {MemoService} from '../../memo/memo.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-loa-update',
  templateUrl: './loa-update.component.html',
  styleUrls: ['./loa-update.component.scss']
})
export class LoaUpdateComponent implements OnInit, OnDestroy {
  memberList = [];
  listApprove = [];
  approvallistLevels = {};
  sequenceList: any[] = [];
  job = [];
  member = [];
  showResults = false;
  loaData: any;
  isLoading = false;
  count = 0;
  newSequence = [];
  loaList: number;
  levelCount = 0;
  minApprove: 1;
  count_levels = 0;
  department: string;
  contentSignatureRequired = [];
  oldApprovallist = [];

  loaSignUserChoices = [
    {value: 'user', label: 'user'},
    {value: 'none user', label: 'non-user'},
  ];

  loaSignNoneUserPlaceHolder = {
    name: 'Contact Name',
    job_position: 'Job Position',
    email: 'Email Address'
  };

  subscriptions = new Subscription();

  @Input() loa;
  @Input() type;
  @Input() selectedCompanyId: number;
  @Output() updated = new EventEmitter();
  @Output() closed = new EventEmitter();

  constructor(
    private userService: UserService,
    private location: Location,
    private swal: SweetAlertService,
    private router: Router,
    private loaService: LoaService,
    private translate: TranslateService,
    private memoService: MemoService,
  ) {
  }

  ngOnInit() {
    this.sequenceList = [];
    this.loadMemberList();
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateSignatureRequired();
    });
    this.subscriptions.add(translateSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  translateSignatureRequired() {
    for (let i = 0; i < this.loaData.levels.length; i++) {
      if (this.loaData.levels[i].signature_required) {
        this.contentSignatureRequired[i].content = this.translate.instant('LOA.SIGNATURE-REQUIRE');
      } else {
        this.contentSignatureRequired[i].content = this.translate.instant('LOA.NO-SIGNATURE');
      }
    }
  }

  filterDelete(items) {
    return _.filter(items, item => item && !item.delete);
  }

  loadMemberList() {
    const generalRole = '1';
    const params = new HttpParams()
      .set('type', 'profile')
      .set('role', generalRole);
    this.memoService.getDropDown(params).subscribe(dropDown => {
      this.memberList = (dropDown.profile || []).map(item => {
        return {full_name: item.name, id: item.id};
      });
      this.loadLOAData(this.loa.id);
    });
  }

  loadLOAData(id) {
    this.showResults = false;
    this.loaService.getLOAData(id).subscribe(
      (data: any) => {
        this.loaData = data;
        this.levelCount = data.count;
        _.forEach(this.loaData.levels, obj => {
          if (obj.signature_required) {
            this.contentSignatureRequired.push({
              content: this.translate.instant('LOA.SIGNATURE-REQUIRE')
            });
          } else {
            this.contentSignatureRequired.push({
              content: this.translate.instant('LOA.NO-SIGNATURE')
            });
          }
          this.approvallistLevels[this.count_levels] = _.cloneDeep(this.memberList);
          this.count_levels += 1;
        });
        this.showResults = true;
      },
      error => this.swal.toastNotification({type: 'error', content: 'Unable to load loa'})
    );

  }

  addLevelBox(loaData) {
    this.contentSignatureRequired.push({
      content: this.translate.instant('LOA.SIGNATURE-REQUIRE')
    });
    this.levelCount += 1;
    this.minApprove = 1;
    this.loaData.levels.push({
      level: this.levelCount,
      min_approve_count: this.minApprove,
      count: 1,
      members: [{profile: null}],
      signature_required: true,
      user_type: this.loaSignUserChoices[0].value
    });
    this.approvallistLevels[this.count_levels] = _.cloneDeep(this.memberList);
    this.count_levels += 1;
  }

  addMemberToLevel(level) {
    level.count += 1;
    level.members.push({profile: null});
  }

  clear() {
    this.job = [];
    this.member = [];
  }

  submitLOA() {
    const msg = [];
    if (!this.loaData.name) {
      msg.push(this.translate.instant('MEMOS.LOA-NO-NAME'));
    }
    if (!this.type) {
      msg.push(this.translate.instant('MEMOS.LOA-NO-TYPE'));
    }
    if (this.loaData.levels.length === 0) {
      msg.push(this.translate.instant('MEMOS.LOA-NO-LEVEL'));
    }
    if (msg.length > 0) {
      this.swal.toastNotification({type: 'error', content: msg.join(', ')});
      return;
    } else {
      this.isLoading = true;
      const data = this.loaData;
      this.loaService.updateLOA(this.loaData.id, data).subscribe(
        updatedLOA => {
          this.isLoading = false;
          this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
          this.updated.emit(updatedLOA);
        },
        error => {
          this.isLoading = false;
          this.swal.toastNotification({type: 'error', content: 'Unable to update loa'});
        }
      );
    }

  }

  selectMember(data, index, sequence, i?) {
    this.sequenceList[index] = {
      profile: data.id,
      sequence: index + 1,
      signature: data.signature,
      role: data.role,
    };
    if (this.oldApprovallist[sequence]) {
      if (this.oldApprovallist[sequence].id !== data.id) {
        this.listApprove.forEach(obj => {
          if (obj.id === this.oldApprovallist[sequence].id) {
            this.approvallistLevels[i].push(obj);
            const remove = _.remove(this.listApprove, function (n) {
              return n.id === obj.id;
            });
          }
        });
      }
    }
    [this.listApprove, this.approvallistLevels[i]] = _.partition(this.approvallistLevels[i], function (n) {
      return data.id === n.id;
    });
    this.approvallistLevels[i] = _.orderBy(this.approvallistLevels[i], ['full_name'], ['asc']);
    if (sequence.id) {
      this.sequenceList[index]['id'] = sequence.id;
    }
    this.oldApprovallist[sequence] = data;
  }

  checkSignature() {
    const filteredSequence = this.sequenceList.filter(sequence => !sequence.signature);
    return filteredSequence.length !== 0;
  }

  back() {
    this.location.back();
  }

  close() {
    this.closed.emit();
  }


  removeApprove(members, index, level, levelIndex) {
    level.count -= 1;
    if (members[index].id) {
      members[index]['delete'] = true;
    } else {
      members.splice(index, 1);
    }
    this.guardRangeApproveCount(levelIndex);
  }

  removeLevel(levels, index) {
    if (levels[index].id) {
      levels[index]['delete'] = true;
    } else {
      levels.splice(index, 1);
    }
    this.contentSignatureRequired = [];
    _.forEach(levels, obj => {
      if (obj.signature_required) {
        this.contentSignatureRequired.push({
          content: 'Signature require for approval'
        });
      } else {
        this.contentSignatureRequired.push({
          content: 'No signature require'
        });
      }
    });
  }

  getLevelNumber(level, index) {
    const p = _.filter(this.loaData.levels, (item, levelIndex) => (levelIndex < index && !item.delete)).length + 1;
    level.level = p;
    return p;
  }

  checkApproval(index) {
    const checkApproval = this.loaData.levels[index].signature_required;

    if (checkApproval) {
      this.contentSignatureRequired[index].content = this.translate.instant('LOA.SIGNATURE-REQUIRE');
    } else {
      this.contentSignatureRequired[index].content = this.translate.instant('LOA.NO-SIGNATURE');
    }
  }

  guardRangeApproveCount(index: number) {
    const currentCount = this.loaData.levels[index].min_approve_count;
    const maxCount = this.loaData.levels[index].count;
    if (currentCount > maxCount) {
      this.loaData.levels[index].min_approve_count = maxCount;
    } else if (currentCount < 1) {
      this.loaData.levels[index].min_approve_count = 1;
    }
  }

  onChangeLoaUserType(level) {
    level.members = [];
    level.count = 0;
    this.addMemberToLevel(level);
  }
}
