import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {API_URL} from '../../shared/constants/api.constant';
import {HttpClientService} from '../../shared/services/http-client.service';
import {Subject} from 'rxjs';

@Injectable()
export class FeedService {
  dataChanged = new Subject();

  constructor(private httpLocal: HttpClient, private http: HttpClientService) {

  }

  getFeedList(params?) {
    // return this.http.get(API_URL.news_feed, params);
  }


  getFeedCalendar(params?) {
    // return this.http.get(API_URL.news_feed + 'calendar/', params);
  }

  getFeedCalendarEvent(params?) {
    return this.http.get(API_URL.calendar_dashboard_event + 'all/', params);
  }

  // Post

  getFeedPostList(params?) {
    return this.http.get(API_URL.news_feed_post, params);
  }

  getAllPost(params?) {
    return this.http.get(API_URL.news_feed_post);
  }

  getRelatedPost(params?) {
    return this.http.get(API_URL.news_related_post);
  }

  createFeedPost(data) {
    return this.http.post(API_URL.news_feed_post, data);
  }

  updateFeedPost(id, data) {
    return this.http.patch(API_URL.news_feed_post + id + '/', data);
  }

  deleteFeedPost(id) {
    return this.http.delete(API_URL.news_feed_post + id + '/');
  }

  // Comment

  getFeedCommentList(params?) {
    return this.http.get(API_URL.news_feed_comment, params);
  }

  createFeedComment(data) {
    return this.http.post(API_URL.news_feed_comment, data);
  }

  deleteFeedComment(id) {
    return this.http.delete(API_URL.news_feed_comment + id + '/');
  }

  // Sticky Note

  getStickyNote() {
    // return this.http.get(API_URL.news_feed_get_sticky_note);
  }

  updateStickyNote(data) {
    // return this.http.post(API_URL.news_feed_update_sticky_note, data);
  }

  // Task

  getTaskList(params?) {
    return this.http.get(API_URL.project_mgmt_task + 'my-task/', params);
  }

  dataChange(data) {
    this.dataChanged.next(data);
  }

  getAnnouncementList(params?) {
    return this.http.get(API_URL.announcement_get + 'my/', params);
  }

  deleteAnnouncementList(id) {
    return this.http.delete(API_URL.announcement_get + id + '/');
  }

  hiddenAnnouncement(data) {
    return this.http.post(API_URL.hide_announcement, data);
  }
}

