import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';

@Component({
  selector: 'app-feed-images-preview',
  templateUrl: './feed-images-preview.component.html',
  styleUrls: ['./feed-images-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedImagesPreviewComponent implements OnInit {

  @Input() document: any;
  @Input() fileName = 'FA_file';
  @Input() data: any;
  @Input() isHiddenDelete = true;
  @Input() isNeedToken = false;
  @Input() typeFile: 'image' | 'pdf';

  @Output() closed = new EventEmitter();

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() {
  }

  ngOnInit() {

    this.galleryImages = this.document.map(attachment => new NgxGalleryImage({
      small: attachment.attachment,
      medium: attachment.attachment,
      big: attachment.attachment
    }));

    this.galleryOptions = [
      {
        'width': '100%',
        'height': '500px',
        'thumbnailsColumns': this.galleryImages.length >= 4 ? 4 : this.galleryImages.length,
        'imageAnimation': 'slide',
        'thumbnailsRemainingCount': true,
        'previewCloseOnClick': true,
        'previewCloseOnEsc': true,
        'previewZoom': true,
        'previewRotate': true,
        'imageArrows': this.galleryImages.length > 1,
        'previewArrows': this.galleryImages.length > 1,
        'thumbnails': this.galleryImages.length > 1,
        'previewAnimation': true,
        'previewKeyboardNavigation': true,
        'arrowPrevIcon': 'fa fa-arrow-circle-o-left',
        'arrowNextIcon': 'fa fa-arrow-circle-o-right',
        'closeIcon': 'fas fa-times',
      },
      {
        'breakpoint': 500,
        'width': '100%',
        'height': '300px',
        'thumbnailsColumns': 3
      },
      {
        'breakpoint': 300,
        'width': '100%',
        'height': '200px',
        'thumbnailsColumns': 2
      },
    ];
  }

  closePreview() {
    this.closed.emit({});
  }
}
