import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormAsset, FormBudgetRequest} from '../memo-template';
import {HttpParams} from '@angular/common/http';
import {UserService} from '../../../../../permission/user/user.service';
import {MemoService} from '../../../memo.service';
import * as _ from 'lodash';
import {SweetAlertService} from '../../../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {
  getLevelBudget,
  BUDGET_REQUEST,
  getCompanyCodeNameFromId,
} from '../../../../../../shared/constants/helper';

@Component({
  selector: 'app-template-budget-request',
  templateUrl: './template-budget-request.component.html',
  styleUrls: ['./template-budget-request.component.scss']
})
export class TemplateBudgetRequestComponent implements OnInit, OnDestroy {

  isLoading = false;
  isLoadingPreview = false;
  showPreview = false;
  isSmallScreen: boolean;
  documentData: any;
  isFlase = false;
  profileListAll = [];
  profileList = [];
  selectedYear;
  selectedWBS;
  costCentreList = [];
  wbsList = [];

  memoNumber = 'Auto Generated';
  memoNumberList = [
    {
      name: 'Auto Generated',
      name_th: 'สร้างเลขเอกสารแบบอัตโนมัติ',
      id: 'Auto Generated'
    },
    {
      name: 'Custom Memo Number',
      name_th: 'สร้างเลขเอกสารด้วยตนเอง',
      id: 'Custom Memo Number'
    }
  ];
  departmentList = [];
  wbsYearList: [];
  is_only_one = true;
  companyList = [];
  companyId = null;
  selectedCompany$ = new Subject<number>();
  autoSelectedCompanyByInitial = true;
  autoSelectedWbsByInitial = true;
  loadCompanyFirstTime = true;
  loadingData = false;
  loadCostcentre = false;
  CodeName: String;
  isLoadingList = {
    company: false,
    year: false,
    wbs: false,
    costcentre: false,
    cc: false,
    announcement: false,
  };
  profile_id;
  level = 5;

  subscriptions = new Subscription();

  userKeyMemoNumber$ = new Subject<string>();
  userKeyMemoNumber: string;
  memoNumberAlreadyExist = false;

  // tslint:disable-next-line:no-input-rename
  @Input('memoData') memoData;
  // tslint:disable-next-line:no-input-rename
  @Input('attachment') file;
  // tslint:disable-next-line:no-input-rename
  @Input('formBudgetRequest') formBudgetRequest: FormBudgetRequest;
  @Input() selectedTemplate;
  @Output() memoDataChange = new EventEmitter();
  @Output() attachmentChange = new EventEmitter();
  @Output() formBudgetRequestChange = new EventEmitter();

  constructor(
    private userService: UserService,
    private memoService: MemoService,
    private swal: SweetAlertService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.memoData.customMemoNumberDropDown = this.memoData.is_custom_memo_number
      ? this.memoNumberList[1].id
      : this.memoNumberList[0].id;
    this.profile_id = localStorage.getItem('profile_id');

    this.loadCompany();
    const sub = this.selectedCompany$.pipe(distinctUntilChanged()).subscribe(() => {
      if (!this.autoSelectedCompanyByInitial) {
        this.resetFormInputData();
      }
      // if (!this.isUserSelectOwnMajorCompany()) {
      //   this.memoData.company_by_selection = this.companyId;
      // } else {
      //   this.memoData.company_by_selection = null;
      // }
      this.memoData.company_by_selection = this.companyId;
      this.loadAllGeneralProfile();
      this.getDropDownMemos();
      this.autoSelectedCompanyByInitial = false;
    });
    this.subscriptions.add(sub);

    if (this.formBudgetRequest.wbs) {
      Number(this.formBudgetRequest.wbs);
      this.loadWBS();
    }

    this.initialCheckMemoNumberExist();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  checkLevel() {
    const total = this.formBudgetRequest.add_budget || 0;
    if (total < 10000000) {
      const companyCode = getCompanyCodeNameFromId(this.companyId, this.companyList);
      if (companyCode) {
        this.level = getLevelBudget(BUDGET_REQUEST, total, companyCode);
      }
    }
  }

  updateBudgetRequest(value: string) {
    this.formBudgetRequest.add_budget = this.limitTwoDecimal(+value, 'floor');
    this.checkLevel();
  }

  limitTwoDecimal(num: number, mode: 'round' | 'floor' = 'round'): number {
    return mode === 'round'
      ? Math.round(num * 100) / 100
      : Math.floor(num * 100) / 100;
  }

  changeMemoNumber(e) {
    if (this.memoNumberList[0].id === e.id) {
      this.memoData.is_custom_memo_number = false;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[0].id;
    } else if (this.memoNumberList[1].id === e.id) {
      this.memoData.is_custom_memo_number = true;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[1].id;
    }
  }

  loadCompany() {
    this.isLoadingList.company = true;
    const params = new HttpParams()
      .set('type', 'company')
      .set('profile', this.profile_id);
    this.memoService.getDropDown(params).subscribe((dropdown) => {
      this.companyList = dropdown.company.map((item) => {
        if (item.context && item.context.code_name) {
          item.label = `${item.context.code_name} - ${item.label}`;
        }
        return item;
      });
      this.companyId = this.memoData.company_id;
      this.selectedCompany$.next(this.companyId);
      this.isLoadingList.company = false;
    });
  }

  getDropDownMemos() {
    this.isLoadingList.announcement = true;
    this.isLoadingList.year = true;
    this.loadingData = true;
    const params = new HttpParams()
      .set('type', 'memo_number,department,wbs_year,profile')
      .set('company', this.companyId);
    this.memoService.getDropDown(params).subscribe(dropDown => {
      let departmentL = dropDown.department;
      departmentL = departmentL.map(item => {
        return {
          label: (item.context.code_number as string) + ' - ' + (item.label as string),
          value: item.value,
        };
      });
      this.departmentList = _.orderBy(departmentL, [department => department.label], 'asc');
      this.wbsYearList = dropDown.wbs_year;
      this.loadingData = false;
      this.isLoadingList.announcement = false;
      this.isLoadingList.year = false;
    });
  }

  loadAllGeneralProfile() {
    this.isLoadingList.cc = true;
    const generalRole = '1';
    const params = new HttpParams()
      .set('type', 'profile')
      // .set('company', this.companyId)
      .set('role', generalRole);
    this.memoService.getDropDown(params).subscribe(profiles => {
      this.profileListAll = profiles.profile;
      this.isLoadingList.cc = false;
    });
  }

  loadWBS() {
    this.isLoadingList.wbs = true;
    this.wbsList = [];

    let params = new HttpParams()
      .set('type', 'wbs')
      .set('year', this.formBudgetRequest.year_wbs);
    const isUserSelectOwnMajorCompany = this.isUserSelectOwnMajorCompany();
    if (isUserSelectOwnMajorCompany) {
      params = params.set('department', localStorage.getItem('department_id'));
    } else {
      params = params.set('company', this.companyId);
    }
    this.memoService.getDropDown(params).subscribe(
      dropdown => {
        if (isUserSelectOwnMajorCompany) {
          this.wbsList = dropdown.wbs;
        } else {
          this.wbsList = dropdown.wbs.map(i => Object({code: i.label, id: i.value}));
        }
        this.isLoadingList.wbs = false;
        if (this.formBudgetRequest.wbs) {
          this.changeWBS();
        }
      },
      error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      });
  }

  changeWBS() {
    this.isLoadingList.costcentre = true;
    this.loadCostcentre = true;
    const params = new HttpParams()
      .set('type', 'costcentre')
      .set('wbs', this.formBudgetRequest.wbs.toString())
      .set('company', this.companyId);
    this.memoService.getDropDown(params).subscribe(costCentre => {
        this.costCentreList = costCentre.costcentre;
        if (!this.autoSelectedWbsByInitial) {
          this.formBudgetRequest.cost_centre = null;
        }
        this.autoSelectedWbsByInitial = false;
        this.loadCostcentre = false;
        this.isLoadingList.costcentre = false;
        this.inputDetailWbs();
      },
      error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      });
  }

  inputDetailWbs(event?) {
    const params = new HttpParams()
      .set('company_id', this.companyId)
      .set('year', this.formBudgetRequest.year_wbs)
      .set('wbs_id', this.formBudgetRequest.wbs.toString());
    this.memoService.getBudgetDetail(params).subscribe(res => {
      res.results.forEach(budgetRequest => {
        this.CodeName = budgetRequest.code;
        this.formBudgetRequest.budget = budgetRequest.budget;
        this.formBudgetRequest.available = budgetRequest.available;
        this.formBudgetRequest.po_pr_pending = budgetRequest.commitment ? budgetRequest.commitment : 0;
      });
    });
  }

  initialCheckMemoNumberExist() {
    this.userKeyMemoNumber = this.memoData.memo_number;
    const sub = this.userKeyMemoNumber$
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        map(memoNumber => memoNumber.trim()),
        filter(memoNumber => memoNumber !== ''),
        switchMap((memoNumber: string) => {
          const params = new HttpParams().set('memo_number', memoNumber);
          return this.memoService.isMemoNumberExist(params);
        }))
      .subscribe((isExist: boolean) => {
        this.memoNumberAlreadyExist = isExist;
        if (!this.memoNumberAlreadyExist) {
          this.memoData.memo_number = this.userKeyMemoNumber.trim();
        } else {
          this.memoData.memo_number = null;
        }
      });

    this.subscriptions.add(sub);
  }

  isUserSelectOwnMajorCompany(): boolean {
    const userSelectedCompanyId = this.companyId;
    const majorCompanyId = +localStorage.getItem('company_id');
    return userSelectedCompanyId === majorCompanyId;
  }

  resetFormInputData() {
    this.formBudgetRequest.year_wbs = null;
    this.formBudgetRequest.wbs = null;
    this.formBudgetRequest.cost_centre = null;
    this.formBudgetRequest.budget = 0;
    this.formBudgetRequest.available = 0;
    this.formBudgetRequest.po_pr_pending = 0;
    this.formBudgetRequest.add_budget = 0;
    this.CodeName = null;
  }

  get isEnLanguage() {
    return this.translate.currentLang === 'en';
  }
}
