import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ThemeListComponent} from './theme-list/theme-list.component';
import {ThemeEditComponent} from './theme-edit/theme-edit.component';
import {ThemeAddComponent} from './theme-add/theme-add.component';


export const themeRoutes: Routes = [
  {
    path: 'theme',
    children: [
      { path: 'list', component: ThemeListComponent },
      { path: 'edit/:id', component: ThemeEditComponent },
      { path: 'add', component: ThemeAddComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(themeRoutes)],
  exports: [RouterModule]
})
export class ThemeRoutingModule { }
