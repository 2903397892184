import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-preview-login',
  templateUrl: './preview-login.component.html',
  styleUrls: ['./preview-login.component.scss']
})
export class PreviewLoginComponent implements OnInit {

  currentTime: any;
  currentDate = moment().format('DD MMMM YYYY');

  @Input() themeLogin: any;

  constructor() {
  }

  ngOnInit() {

  }

}
