import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { generalRoutes } from './general/general-routing.module';
import { themeRoutes } from './theme/theme-routing.module';
import { ConfigurationComponent } from './configuration/configuration.component';
import { AdminGuardService } from '../../admin-guard.service';
import { AuthGuard } from '../../auth-guard.service';
import { DepartmentListComponent } from './department/department-list/department-list.component';
import {CentralDepartmentComponent} from './department/central-department/central-department.component';

export const settingRoutes: Routes = [
  {
    path: '',
    canActivateChild: [AdminGuardService],
    children: [
      ...generalRoutes,
      ...themeRoutes,
      { path: 'configuration', component: ConfigurationComponent },
      {
        path: 'department',
        children: [
          {
            path: 'list',
            component: DepartmentListComponent,
          },
          {
            path: 'create-central',
            component: CentralDepartmentComponent,
          },
          {
            path: 'edit-central/:id',
            component: CentralDepartmentComponent,
          }
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(settingRoutes)],
  exports: [RouterModule],
})
export class SettingRoutingModule {}
