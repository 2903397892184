import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularResizableDirective } from 'angular2-draggable';
import { SingleSignaturePosition } from '../upload-template-customize/upload-template-cuxtomize.models';

@Component({
  selector: 'app-resizable-draggable',
  templateUrl: './resizable-draggable.component.html',
  styleUrls: ['./resizable-draggable.component.scss'],
})
export class ResizableDraggableComponent implements OnInit {
  state = '';
  size: Size = { height: 100, width: 100 };
  @Input() img: string;
  @Input() ddItem: SingleSignaturePosition;
  @Output() changeDdItem = new EventEmitter<SingleSignaturePosition>();
  @Input() allowEdit = false;
  @Output() ddItemChange = new EventEmitter();
  @Output() convertSize = new EventEmitter();
  @Input() typeImage: string;
  constructor() {}

  ngOnInit() {
    this.size =  {
      height: this.ddItem.height,
      width: this.ddItem.width
    };
  }

  onResizeStart(event) {
    this.state = 'Resize Started';
    this.size = event.size;
    this.setSizeImg();
  }

  onResizing(event) {
    this.state = 'Resizing';
    this.size = event.size;
    this.setSizeImg();
  }

  onResizeStop(event) {
    this.state = 'Resize Stopped';
    this.size = event.size;
    this.setSizeImg();
    this.convertSize.emit(this.ddItem);
  }

  onReset(block: AngularResizableDirective) {
    block.resetSize();
    this.size = { height: 100, width: 100 };
    this.setSizeImg();
  }

  setSizeImg() {
    this.ddItem.height = this.size.height;
    this.ddItem.width = this.size.width;
  }
}

interface Size {
  height: number;
  width: number;
}
