import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MasterItemDropdown} from '../../../../../../../master-item/master-item.model';
import {concat, Observable, of} from 'rxjs';
import {Subject} from 'rxjs/Subject';
import {MasterItemService} from '../../../../../../../master-item/master-item.service';
import {catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-pr-item-dropdown',
  templateUrl: './pr-item-dropdown.component.html',
  styleUrls: ['./pr-item-dropdown.component.scss']
})
export class PrItemDropdownComponent {
  items$: Observable<MasterItemDropdown[]>;
  search$ = new Subject<string>();
  loading = false;
  items: MasterItemDropdown[] = [];

  @Input() selectedItem: MasterItemDropdown | null;
  @Output() selectedItemChange = new EventEmitter<MasterItemDropdown | null>();

  constructor(private service: MasterItemService) {
    this.items$ = concat(
      of([]),
      this.search$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(500),
        tap(() => (this.loading = true)),
        switchMap((query) => {
          return this.service.getItemDropdown(query);
        }),
        catchError(() => of([])),
        tap(() => (this.loading = false)),
      )
    );
  }

  onSelect(item: MasterItemDropdown) {
    this.selectedItem = item;
    this.selectedItemChange.next(item);
  }
}
