import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { API_URL } from '../../../shared/constants/api.constant';

@Injectable()
export class GeneralService {

  constructor(private http: HttpClientService) {

  }

  getAddress(params?) {
    return this.http.get(API_URL.memo_organization, params);
  }

  createAddress(data) {
    return this.http.post(API_URL.memo_organization, data);
  }

  updateAddress(id, data) {
    return this.http.patch(API_URL.memo_organization + id + '/', data);
  }

  deleteAddress(id) {
    return this.http.delete(API_URL.memo_organization + id + '/');
  }

  getLogo() {
    return this.http.get(API_URL.memo_logo);
  }

  createLogo(data) {
    return this.http.post(API_URL.memo_logo, data);
  }

  deleteLogo(id) {
    return this.http.delete(API_URL.memo_logo + id + '/');
  }

}
