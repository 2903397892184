import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
// import { mainRoutes } from './modules/main/main-routing.module';
import { PendingRequestComponent } from './core/pending-request/pending-request.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PageExpireComponent } from './core/page-expire/page-expire.component';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth-guard.service';
import { MainComponent } from './modules/main/main.component';
import {AdfsCallbackComponent} from './core/adfs-callback/adfs-callback.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'memo',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/memos/memos.module').then(
            (m) => m.MemosModule
          ),
      },
      {
        path: 'feed',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/feed/feed.module').then((m) => m.FeedModule),
      },
      {
        path: 'permission',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/permission/permission.module').then(
            (m) => m.PermissionModule
          ),
      },
      {
        path: 'setting',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/setting/setting.module').then(
            (m) => m.SettingModule
          ),
      },
      {
        path: 'userProfile',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/user-profile/user-profile.module').then(
            (m) => m.UserProfileModule
          ),
      },
      {
        path: 'executive',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/executive/executive.module').then(
            (m) => m.ExecutiveModule
          ),
      },
      {
        path: 'dashboard',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'setting',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/setting/setting.module').then(
            (m) => m.SettingModule
          ),
      },
      {
        path: 'general',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/setting/general/general.module').then(
            (m) => m.GeneralModule
          ),
      },
      {
        path: 'setting-loa',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/setting/general/general.module').then(
            (m) => m.GeneralModule
          ),
      },
      {
        path: 'operation-log',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/operation-log/operation-log.module').then(
            (m) => m.OperationLogModule
          ),
      },
      {
        path: 'master-item',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('../app/modules/master-item/master-item.module').then(
            (m) => m.MasterItemModule
          ),
      },
    ],
  },
  {
    path: 'approval',
    loadChildren: () =>
      import('../app/modules/approval/approval.module').then(
        (m) => m.ApprovalModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('../app/modules/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  { path: 'login', component: LoginComponent },
  { path: 'pending', component: PendingRequestComponent },
  { path: 'expire', component: PageExpireComponent },
  { path: 'adfs-callback/:jwt', component: AdfsCallbackComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
