import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ThemeService} from '../../../../shared/services/theme.service';
import {NotificationService} from '../../../../shared/services/notification.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';

@Component({
  selector: 'app-theme-edit',
  templateUrl: './theme-edit.component.html',
  styleUrls: ['./theme-edit.component.scss']
})
export class ThemeEditComponent implements OnInit {

  formGroup: FormGroup;
  signatureColorList = [
    '#f00000',
    '#f07800',
    '#ee9600',
    '#f0b400',
    '#f0f000',
    '#b4f000',
    '#78f000',
    '#3cf000',
    '#00f078',
    '#00f0f0',
    '#00b4f0',
    '#003cf0',
    '#3c00f0',
    '#7800f0',
    '#b400f0',
    '#f000b4',
  ];
  showColorPicker = {
    'bgLogin': false,
    'bgColorBoxlogin': false,
    'bgColorHome': false,
    'textBoxlogInColor': false,
    'textColor': false,
    'hoveredTextColor': false,
    'textLoginColor': false,
    'bgColorHomeTwo': false,
    'headerTableColor': false,
    'textHeaderTableColor': false,
    'bgLoginTwo': false,
  };
  themePreview = {
    'bgLogin': '#102a44',
    'bgLoginTwo': '#2663a1',
    'bgColorBoxlogin': '#ffffff',
    'bgColorHome': '#102a44',
    'bgColorHomeTwo': '#2663a1',
    'textColor': '#ffffff',
    'hoveredTextColor': '#ADADAD',
    'textBoxlogInColor': '#102a44',
    'textLoginColor': '#ffffff',
    'headerTableColor': '#102a44',
    'textHeaderTableColor': '#ffffff',
    'display_sidebar': '',
    'display_login': '',
    'display_pdf': '',
    'display_email': ''
  };
  selectPage = 'email';
  modal: NgbModalRef;
  active: boolean;

  get f(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  @Output() themeSetup = new EventEmitter();
  @Input() themeEdit;

  constructor(
    private route: ActivatedRoute,
    private themeService: ThemeService,
    private router: Router,
    private swal: SweetAlertService,
    private modalService: NgbModal,
    private builder: FormBuilder) {
  }

  ngOnInit() {
    this.themeDetail();
  }

  themeDetail() {
    this.themePreview = {
      'bgLogin': this.themeEdit.bg_login_color,
      'bgLoginTwo': this.themeEdit.bg_login_second_color,
      'bgColorBoxlogin': this.themeEdit.box_login_color,
      'bgColorHome': this.themeEdit.bg_color_primary,
      'bgColorHomeTwo': this.themeEdit.bg_color_secondary,
      'textColor': this.themeEdit.text_color,
      'hoveredTextColor': this.themeEdit.hovered_text_color,
      'headerTableColor': this.themeEdit.header_table_color,
      'textHeaderTableColor': this.themeEdit.text_header_table_color,
      'textBoxlogInColor': this.themeEdit.text_box_login_color,
      'textLoginColor': this.themeEdit.text_login_color,
      'display_sidebar': this.themeEdit.display_sidebar,
      'display_login': this.themeEdit.display_login,
      'display_pdf': this.themeEdit.display_pdf,
      'display_email': this.themeEdit.display_email
    };
    this.formGroup = this.builder.group({
      name: [this.themeEdit.name, Validators.required],
      display_email: ['', Validators],
      display_login: ['', Validators],
      display_sidebar: ['', Validators],
      display_pdf: ['', Validators],
    });
  }

  onSubmit() {
    const fd = new FormData();
    fd.append('name', this.formGroup.value.name);
    if (this.formGroup.value.display_email) {
      fd.append('display_email', this.formGroup.value.display_email);
    }
    if (this.formGroup.value.display_pdf) {
      fd.append('display_pdf', this.formGroup.value.display_pdf);
    }
    if (this.formGroup.value.display_login) {
      fd.append('display_login', this.formGroup.value.display_login);
    }
    if (this.formGroup.value.display_sidebar) {
      fd.append('display_sidebar', this.formGroup.value.display_sidebar);
    }
    fd.append('bg_login_color', this.themePreview.bgLogin.toString());
    fd.append('bg_login_second_color', this.themePreview.bgLoginTwo.toString());
    fd.append('box_login_color', this.themePreview.bgColorBoxlogin.toString());
    fd.append('text_login_color', this.themePreview.textLoginColor.toString());
    fd.append('text_box_login_color', this.themePreview.textBoxlogInColor.toString());
    fd.append('bg_color_primary', this.themePreview.bgColorHome.toString());
    fd.append('bg_color_secondary', this.themePreview.bgColorHomeTwo.toString());
    fd.append('text_color', this.themePreview.textColor.toString());
    fd.append('hovered_text_color', this.themePreview.hoveredTextColor.toString());
    fd.append('header_table_color', this.themePreview.headerTableColor.toString());
    fd.append('text_header_table_color', this.themePreview.textHeaderTableColor.toString());
    fd.append('active', this.themeEdit.active.toString());
    this.themeService.updateTheme(fd, this.themeEdit.id).subscribe(theme => {
      this.modal.close();
      this.themeSetup.emit(theme);
      this.swal.toastNotification({type: 'success'});
    }, error => {
      this.modal.close();
      this.swal.toastNotification({type: 'error'});
    });
  }

  onSelectedColor(color, typeSetup) {
    this.themePreview[typeSetup] = color;
  }

  onClickColorPicker(typeSetup) {
    this.showColorPicker[typeSetup] = !this.showColorPicker[typeSetup];
  }

  closeColorPicker(content) {
    this.showColorPicker[content] = false;
  }

  openPreviewLogo(File, page) {
    const reader = new FileReader();
    reader.readAsDataURL(File); // read file as data url
    reader.onload = (event: any) => { // called once readAsDataURL is completed
      const previewImage = event.target.result;
      this.themePreview[page] = previewImage;
    };
  }

  selectedPage(typePage) {
    this.selectPage = typePage;
  }

  openModalSave(content) {
    this.modal = this.modalService.open(content);
  }

  cancelCreateTheme() {
    this.themeSetup.emit();
  }
}
