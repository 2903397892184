import {Component, Injectable, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TitleService} from '../../shared/services/title-service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {HttpParams} from '@angular/common/http';
import {OperationLogService} from './operation-log.service';
import {SweetAlertService} from '../../shared/services/sweet-alert.service';
import {HttpClientService} from '../../shared/services/http-client.service';
import {UserService} from '../permission/user/user.service';
import {NgbThaiDateParserFormatter} from '../../core/NgDateParser';
import {NgbDateStruct, NgbCalendar, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CALENDAR_LOCALE} from '../../shared/services/constant';
import {Subscription} from 'rxjs';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private tranlate: TranslateService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    const en = CALENDAR_LOCALE.EN;
    const th = CALENDAR_LOCALE.TH;
    const dataLanguage = this.tranlate.currentLang === 'en' ? en : th;
    return dataLanguage.dayNamesShort[weekday - 1];
  }

  getMonthShortName(month: number): string {
    const en = CALENDAR_LOCALE.EN;
    const th = CALENDAR_LOCALE.TH;
    const dataLanguage = this.tranlate.currentLang === 'en' ? en : th;
    return dataLanguage.monthNamesShort[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}


interface OperationLog {
  id: number;
  create_user_name: string;
  detail: string;
  description_show: string;
  specific: string;
  model_name: string;
  created_at: Date;
  updated_at: Date;
  created_user: number;
  updated_user: number;
  adfs_email?: string;
}

@Component({
  selector: 'app-operation-log',
  templateUrl: './operation-log.component.html',
  styleUrls: ['./operation-log.component.scss'],
  providers: [{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class OperationLogComponent implements OnInit, OnDestroy {

  operation_logs: Array<OperationLog> = [];

  createdAtBefore;
  createdAtAfter;

  paginationDropdown = ['10', '20', '30', '40', '50'];
  itemsPerPage = 10;
  count: number;
  currentPage = 1;
  isLoading = false;
  isLoadingExcel = false;
  // Dropdown
  memberList: any[] = [];
  detailList: any[] = [];
  descriptionList: any[] = [];
  logList = [];
  memberSearch: number;
  detailSearch: string;
  descriptionSearch: string;

  subscriptions = new Subscription();

  constructor(
    private swal: SweetAlertService,
    private titleService: TitleService,
    private translate: TranslateService,
    private httpClientService: HttpClientService,
    private operationLogService: OperationLogService,
    private userService: UserService,
    private dateFormat: NgbThaiDateParserFormatter,
  ) {
  }

  ngOnInit() {
    this.loadOperationLog();
    this.getDropdownList();
    this.titleService.setTitle(this.translate.instant('LOG.OPERATION'));
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.titleService.setTitle(this.translate.instant('LOG.OPERATION'));
      this.detailList = this.detailList.map(item => {
          return {
            ...item,
            name: this.translate.instant('LOG.' + (item.value as string).toUpperCase())
          };
        });
      this.descriptionList = this.descriptionList.map(item => {
          return {
            ...item,
            name: this.translate.instant('LOG.' + (item.value as string).toUpperCase())
          };
        });

    });
    this.subscriptions.add(translateSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getParameter() {
    return new HttpParams()
      .set('page', this.currentPage.toString())
      .set('page_size', this.itemsPerPage.toString())
      .set('detail', this.detailSearch || '')
      .set('description', this.descriptionSearch || '')
      .set('created_user', this.memberSearch ? this.memberSearch.toString() : '')
      .set('created_at_before', this.dateFormat.format(this.createdAtBefore, true))
      .set('created_at_after', this.dateFormat.format(this.createdAtAfter, true));
  }

  clearSearch() {
    this.currentPage = 1;
    this.itemsPerPage = 10;
    this.detailSearch = '';
    this.descriptionSearch = '';
    this.memberSearch = undefined;
    this.createdAtAfter = undefined;
    this.createdAtBefore = undefined;
  }

  loadOperationLog() {
    this.isLoading = true;
    const params = this.getParameter();
    this.operationLogService.getOperationLogList(params).subscribe(
      (logs) => {
        this.logList = logs.results.map(log => {
          if (log.specific === null) {
            log.specific = '';
          }
          log.description_show = 'LOG.' + (log.description || '').toUpperCase();
          log.detail = 'LOG.' + (log.detail || '').toUpperCase();
          return log;
        });
        this.operation_logs = logs.results;
        this.isLoading = false;
        this.count = logs.count;
      }, error => {
        this.swal.toastNotification({type: 'error', content: error});
        this.isLoading = false;
      }
    );
  }

  generateReportExcel() {
    this.isLoadingExcel = true;
    const params: any = this.getParameter()
      .set('none_pagination', 'True');
    this.operationLogService.getOperationLogCSV(params).subscribe(
      res => {
        const filename = 'OperationLog.csv';
        this.httpClientService.createDownloadFile(res, filename);
        this.isLoadingExcel = false;
      },
      err => {
        this.isLoadingExcel = false;
      }
    );
  }

  getDropdownList() {
    const params = new HttpParams()
      .set('none_pagination', 'True');
    this.userService.getProfileList(params).subscribe((members: any) => {
      this.memberList = members;
    });
    this.operationLogService.getOperationLogDropDown().subscribe(
      (choice: any) => {
        this.detailList = choice.operation_detail;
        this.descriptionList = choice.operation_description;
        this.detailList = this.detailList.map(item => {
          return {
            name: this.translate.instant('LOG.' + (item.name as string).toUpperCase()),
            value: item.name
          };
        });
        this.descriptionList = this.descriptionList.map(item => {
          return {
            name: this.translate.instant('LOG.' + (item.name as string).toUpperCase()),
            value: item.name
          };
        });

      },
      error => {
        this.swal.toastNotification({type: 'error', content: error});
      }
    );
  }

  changePageSize(e) {
    this.currentPage = 1;
    this.itemsPerPage = e;
    this.loadOperationLog();
  }

  changePage() {
    this.loadOperationLog();
  }

  filterChange(e) {
    this.loadOperationLog();
  }
}
