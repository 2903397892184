import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ConfigurationService} from '../../../../shared/services/configuration.service';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-detail-setup',
  templateUrl: './detail-setup.component.html',
  styleUrls: ['./detail-setup.component.scss']
})
export class DetailSetupComponent implements OnInit {

  setUpForm: FormGroup;
  submitted = false;
  isLoading = false;
  loading = false;
  configurationList = {
    COMPANY_NAME: '',
    COMPANY_ADDRESS: '',
    COMPANY_TEL: '',
    EMAIL_SENDER_NAME: '',
    EMAIL_HOST: '',
    EMAIL_HOST_USER: '',
    EMAIL_HOST_PASSWORD: '',
    EMAIL_PORT: '',
    EMAIL_USE_TLS: true
  };
  configurationDetail = [];
  fieldTextType = false;
  modal: NgbModalRef;


  constructor(
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private swal: SweetAlertService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.getDetailSetUp();
  }

  getDetailSetUp() {
    this.loading = true;
    this.configurationList = {
      COMPANY_NAME: '',
      COMPANY_ADDRESS: '',
      COMPANY_TEL: '',
      EMAIL_SENDER_NAME: '',
      EMAIL_HOST: '',
      EMAIL_HOST_USER: '',
      EMAIL_HOST_PASSWORD: '',
      EMAIL_PORT: '',
      EMAIL_USE_TLS: true
    };
    this.configurationService.fetch().subscribe(details => {
      this.configurationDetail = details.results;
      details.results.forEach(detail => {
        this.configurationList[detail.key] = detail.value;
      });
      this.loading = false;
    });
  }

  onSubmit(content, f) {
    const email = [];
    this.submitted = false;
    if (this.configurationList.EMAIL_SENDER_NAME.trim() === '') {
      Object.keys(this.configurationList).forEach(key => {
        if (key.includes('EMAIL') && key !== 'EMAIL_SENDER_NAME' && key !== 'EMAIL_USE_TLS') {
          email.push({key: this.configurationList[key]});
        }
      });
     if (!email.every(item => item.key === '' || item.key === null )) {
       return this.submitted = true;
     }
    }
    this.open(content);
  }

  detailSetup() {
    this.isLoading = true;
    this.configurationDetail.forEach(detail => {
      detail.value = this.configurationList[detail.key];
    });
    this.configurationService.save(this.configurationDetail).subscribe(result => {
      this.isLoading = false;
      this.swal.toastNotification({type: 'success', content: 'save'});
      this.detailSetup();
      this.modal.close();
    }, response => {
      if (response.error && response.error.en) {
        this.swal.toastNotification({type: 'error', content: response.error[this.translate.currentLang].join('\n')});
      } else {
        this.swal.toastNotification({type: 'error'});
      }
      this.isLoading = false;
      this.modal.close();
    });
  }

  clear() {

  }

  togglePassword() {
    this.fieldTextType = !this.fieldTextType;
  }

  open(content?) {
    this.modal = this.modalService.open(content);
  }

  checkSenderName() {
    return !!this.configurationList.EMAIL_SENDER_NAME;
  }
}
