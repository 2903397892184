import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from '../../memo.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-memo-real-edit',
  templateUrl: './memo-real-edit.component.html',
  styleUrls: ['./memo-real-edit.component.scss']
})

export class MemoRealEditComponent implements OnInit {
  modal: NgbModalRef;
  ext_emails = {'data': []};
  pdfSrc: string;
  emailList = [];
  purpose_data = {
    'purpose_data' : {
      'A1': '',
      'A2': '',
      'A3': '',
      'B1': '',
      'B2': '',
      'B3': '',
      'B4': '',
      'C1': '',
      'C2': '',
      'C3': '',
      'C4': '',
      'D1': '',
      'D2': '',
      'E1': '',
      'E2': '',
      'F1': '',
      'F2': '',
      'G1': '',
      'G2': '',
      'request_type': '0',
      'other': ''
    }
  };

  // @Input()  pdfSrc: string;
  @Input()  memo;
  @Output() closed = new EventEmitter();
  @Output() wonder = new EventEmitter();

  constructor(private modalService: NgbModal,
              private memoService: MemoService
  ) {}

  ngOnInit() {
    this.loadPurposePDF();
    this.reformatPurposeData();
    this.reformatEmailExt();
  }

  reformatPurposeData() {
    if (!this.memo.purpose_data) {
      return;
    }
    this.purpose_data = {
      'purpose_data' : {
        'A1': this.memo.purpose_data.A1,
        'A2': this.memo.purpose_data.A2,
        'A3': this.memo.purpose_data.A3,
        'B1': this.memo.purpose_data.B1,
        'B2': this.memo.purpose_data.B2,
        'B3': this.memo.purpose_data.B3,
        'B4': this.memo.purpose_data.B4,
        'C1': this.memo.purpose_data.C1,
        'C2': this.memo.purpose_data.C2,
        'C3': this.memo.purpose_data.C3,
        'C4': this.memo.purpose_data.C4,
        'D1': this.memo.purpose_data.D1,
        'D2': this.memo.purpose_data.D2,
        'E1': this.memo.purpose_data.E1,
        'E2': this.memo.purpose_data.E2,
        'F1': this.memo.purpose_data.F1,
        'F2': this.memo.purpose_data.F2,
        'G1': this.memo.purpose_data.G1,
        'G2': this.memo.purpose_data.G2,
        'request_type': this.memo.purpose_data.request_type,
        'other': this.memo.purpose_data.other
      }
    };
  }

  reformatEmailExt() {
    if (!this.memo.ext_emails) {
      return;
    }
    this.memo.ext_emails.data.forEach(obj => {
      this.emailList.push({email: obj});
    });
  }

  loadPurposePDF() {
    if (this.memo.signed_document) {
      this.pdfSrc = this.memo.signed_document;
      return;
    }
    this.memo.attachments.map(obj => {
      if (!obj.is_primary) {
        return;
      }
      if (obj.attachment_type === 'purposeThai' || obj.attachment_type === 'purposeEnglish') {
        this.pdfSrc = obj.file;
      }
    });
  }

  openSignModal(signature) {
    this.modal = this.modalService.open(signature, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

  close() {
    this.modal.close();
  }

  closeToggle() {
    this.closed.emit();
  }

  openShare(shared) {
    this.modal = this.modalService.open(shared, {backdrop: 'static', keyboard: false});
  }

  closeModal() {
    this.modal.close();
  }

  addEmail() {
    this.emailList.push({email: ''});
  }

  setSharedList() {
    this.ext_emails = {'data': []};
    if (!this.emailList) {
      return;
    }
    this.emailList.forEach(obj => {
      this.ext_emails.data.push(obj.email);
    });
    this.modal.close();
    this.memoService.updateMemoReal(this.memo.id, {ext_emails: this.ext_emails}).subscribe();
  }

  submit() {
    this.memoService.updateMemoReal(this.memo.id, this.purpose_data).subscribe(
      res => {
        // const params = new HttpParams()
        //   .set('memo_id', this.memo.id);
        // this.memoService.renderPDF(params).subscribe();
        setTimeout(() => {
          this.wonder.emit();
          this.closeToggle();
        }, 1000);
      },
      error => {}
    );
  }

  sendBatchUpdate(event) {
  }

  onCanvasClear() {
  }

  onCanvasUndo(event) {
  }

  onCanvasRedo(event) {
  }

  onSave(event) {
    const data = new FormData();
    data.append('customer_signature', event);
    this.memoService.updateMemoReal(this.memo.id, data).subscribe(
      res => {
        // const params = new HttpParams().set('memo_id', this.memo.id);
        // this.memoService.renderPDF(params).subscribe();
        setTimeout(() => {
          this.wonder.emit();
          this.closeToggle();
        }, 900);
      }
    );
    this.modal.close();
  }

  deletedMemo() {
    this.memoService.deleteMemoReal(this.memo.id).subscribe(
      res => {
        this.wonder.emit();
        this.closeToggle();
      }
    );
  }
}
