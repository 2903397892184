import {Injectable} from '@angular/core';
import {HttpClientService} from './http-client.service';
import {API_URL} from '../constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClientService) {
  }

  fetch() {
    return this.http.get(API_URL.configuration);
  }

  save(model: any) {
    return this.http.patch(API_URL.bulk_update, model);
  }
}
