import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-memo-input-company',
  templateUrl: './memo-input-company.component.html',
  styleUrls: ['./memo-input-company.component.scss'],
})
export class MemoInputCompanyComponent implements OnInit {
  disabled = false;
  selected: string;

  @Input() loading;

  @Input() companyId: number;
  @Output() companyIdChange = new EventEmitter<number>();

  @Input() customHeader: string;
  @Output() customHeaderChange = new EventEmitter<string>();

  @Input() customFooter: string;
  @Output() customFooterChange = new EventEmitter<string>();

  _rawItems: CompanyItem[] = [];
  _items: FormattedDropdownItem[] = [];
  @Input() set companyItems(val: CompanyItem[]) {
    this._rawItems = val;
    this._items = this.formatDropdownItems(this._rawItems);
    this.initialSelectedItem(this.companyId, this.customHeader, this.customFooter);
  }

  ngOnInit() {
  }

  formatDropdownItems(rawItems: CompanyItem[]): FormattedDropdownItem[] {
    const formatted: FormattedDropdownItem[] = [];
    rawItems.forEach(item => {
      formatted.push({
        label: item.label,
        value: `${item.value}____`,
      });
      if (item.context) {
        (item.context.sub_companies || []).forEach(subCompany => {
          formatted.push({
            label: subCompany.name || subCompany.name_en,
            value: `${item.value}____${subCompany.code_name}`,
          });
        });
      }
    });
    return formatted;
  }

  onSelectItem(item: FormattedDropdownItem) {
    const split = item.value.split('____');
    const companyId = Number(split[0]);
    const subCompanyCode = split.length === 2 ? split[1] : null;
    this.emitOutputData(companyId, subCompanyCode);
  }

  emitOutputData(companyId: number, subCompanyCode?: string) {
    let cleanedSubCompanyCode = subCompanyCode;
    if (!subCompanyCode || subCompanyCode === '') {
      cleanedSubCompanyCode = null;
    }
    this.companyIdChange.emit(companyId);
    this.customHeaderChange.emit(subCompanyCode);
    this.customFooterChange.emit(subCompanyCode);
  }

  initialSelectedItem(companyId: number, customHeader: string, customFooter: string) {
    if (this._items.length === 1) {
      this.selected = this._items[0].value;
      this.emitOutputData(companyId);
      this.disabled = true;
      return;
    }

    let initialValue = `${companyId}____`;
    let subCompanyCode = null;
    if (customHeader || customFooter) {
      // custom header should same as footer and it should be sub company code
      subCompanyCode = customHeader ? customHeader : customFooter;
      initialValue += subCompanyCode;
    }

    (this._items || []).forEach(item => {
      if (item.value === initialValue) {
        this.selected = item.value;
        this.emitOutputData(companyId, subCompanyCode);
      }
    });
  }
}

export interface CompanyItem {
  label: string;
  value: number;
  context?: {
    name_en: string;
    code_name: string;
    code_number: string;
    sub_companies: SubCompany[];
  };
}

export interface SubCompany {
  id: number;
  code_name: string;
  name: string;
  name_en: string;
  parent_company: number;
}

export interface FormattedDropdownItem {
  label: string;
  value: string;  // should be format {companyId}____{subCompanyCode}
}
