import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {MemoService} from '../../../memos/memo/memo.service';
import {
  CentralDepartment,
  CentralDepartmentChoiceResponse,
  CentralDepartmentCompanyChoice,
  CentralDepartmentInstanceChoice
} from './central-department.models';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';

@Component({
  selector: 'app-central-department',
  templateUrl: './central-department.component.html',
  styleUrls: ['./central-department.component.scss']
})
export class CentralDepartmentComponent implements OnInit {

  componentMode$: Observable<'edit' | 'create'>;
  departmentId: number = null;

  instanceItems: CentralDepartmentInstanceChoice[] = [];
  // selectedInstance = [];

  companiesItemsAll: CentralDepartmentCompanyChoice[] = [];
  companiesItems: CentralDepartmentCompanyChoice[] = [];
  // selectedCompanyPermission = [];

  centralDepartmentName = null;

  centralDepartment: CentralDepartment = null;
  initialComplete = false;
  disabledCompanyPermission = true;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: MemoService,
              private swal: SweetAlertService) {
  }

  ngOnInit() {
    this.componentMode$ = this.route.params.pipe(
      tap(params => this.initialDepartment(params['id'])),
      map(params => params['id'] ? 'edit' : 'create'),
    );
  }

  initialDepartment(id: string | number) {
    this.departmentId = typeof id === 'string' ? +id : id;

    this.apiService.getCentralDepartmentChoice().subscribe((res: CentralDepartmentChoiceResponse) => {
      this.instanceItems = res.instances;
      this.companiesItemsAll = res.companies;
      if (!id) {
        this.departmentId = null;
        this.centralDepartment = {
          name: null,
          permission_companies: null,
          permission_instances: null,
        };
        this.initialComplete = true;
      } else {
        this.apiService.getCentralDepartmentById(this.departmentId).subscribe(
          (department: CentralDepartment) => {
            this.centralDepartment = {
              name: department.name,
              permission_companies: department.permission_companies,
              permission_instances: department.permission_instances,
            };
            this.onSelectInstance(this.centralDepartment.permission_instances);
            this.initialComplete = true;
          }, error => {
            this.handleError(error.error);
          }
        );
      }
    });
  }

  onSelectInstance(instanceValue: string[]) {
    this.companiesItems = this.companiesItemsAll.filter(company => instanceValue.includes(company.instance));
    this.centralDepartment.permission_instances = [...instanceValue];
    if (this.initialComplete) {
      this.centralDepartment.permission_companies = [];
    }
    this.disabledCompanyPermission = instanceValue.length === 0;
  }

  get disableSaveButton(): boolean {
    const name = this.centralDepartment.name;
    const instances = this.centralDepartment.permission_instances;
    const companies = this.centralDepartment.permission_companies;

    if (!name) {
      return true;
    }
    if (!instances || instances.length === 0) {
      return true;
    }
    return !companies || companies.length === 0;
  }

  onClickCancel() {
    this.redirectToDepartmentList();
  }

  onClickSave(mode: 'edit' | 'create') {
    if (mode === 'edit') {
      this.editDepartment();
    } else if (mode === 'create') {
      this.createDepartment();
    }
  }

  createDepartment() {
    this.apiService.createCentralDepartment(this.centralDepartment).subscribe(
      res => {
        this.swal.toastNotification({
          type: 'success',
          content: 'Created'
        });
        this.redirectToDepartmentList();
      },
      error => {
        this.handleError(error.error);
      }
    );
  }

  editDepartment() {
    this.apiService.updateCentralDepartment(this.departmentId, this.centralDepartment).subscribe(
      res => {
        this.swal.toastNotification({
          type: 'success',
          content: 'Updated'
        });
        this.redirectToDepartmentList();
      },
      error => {
        this.handleError(error.error);
      }
    );
  }

  redirectToDepartmentList() {
    this.router.navigate(['/setting/department/list']);
  }

  handleError(error) {
    let errorMessage = 'Error';
    if ('en' in error) {
      if (Array.isArray(error['en'])) {
        errorMessage = (error['en'] as string[]).join('\n');
      } else if (typeof error['en'] === 'string') {
        errorMessage = error['en'];
      }
    }
    this.swal.toastNotification({
      type: 'error',
      content: errorMessage
    });
  }
}
