import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class TitleService {
  private titleMessage = new Subject();

  setTitle(title: any) {
    this.titleMessage.next({title: title});
  }

  getTitle(): Observable<any> {
    return this.titleMessage.asObservable();
  }
}
