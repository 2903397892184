import {Directive, HostListener, ElementRef, OnInit, Input} from '@angular/core';
import {CustomCurrencyFormatPipe} from '../pipes/custom-currency-format.pipe';

@Directive({
  selector: '[InputCurrencyFormat]',
  providers: [CustomCurrencyFormatPipe]
})
export class InputCurrencyFormatDirective implements OnInit {

  private el: HTMLInputElement;
  @Input() fractionSize = 2;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CustomCurrencyFormatPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.currencyPipe.transform(this.el.value, this.fractionSize);
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = this.currencyPipe.parse(value, this.fractionSize);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = this.currencyPipe.transform(value, this.fractionSize);
  }

}
