import { Component, Output, OnInit, EventEmitter, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-confirmation',
  templateUrl: './user-confirmation.component.html',
  styleUrls: ['./user-confirmation.component.scss']
})
export class UserConfirmationComponent implements OnInit {

  modal: NgbModalRef;
  user: any;

  @Output() submitted = new EventEmitter();
  @ViewChild('userConfirmation', { static: true }) userConfirmation: any;

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {

  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  openFromOutside(user) {
    this.user = user;
    this.open(this.userConfirmation);
  }

  submit() {
    this.submitted.emit();
    this.modal.close();
  }

}
