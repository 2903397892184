import {Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Component, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import {CanvasWhiteboardComponent} from 'ng2-canvas-whiteboard';
import {ProfileService} from '../../shared/services/profile-service';
import {SweetAlertService} from '../../shared/services/sweet-alert.service';
import {HttpParams} from '@angular/common/http';
import {UserProfileService} from './user-profile.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TitleService} from '../../shared/services/title-service';
import * as _ from 'lodash';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {Subscription} from 'rxjs';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {SignatureService} from '../../shared/services/signature.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  profile: any;
  modal: NgbModalRef;
  confirmModalRef: NgbModalRef;
  passwordForm: FormGroup;
  submitted = false;
  passwordNotMatch = false;
  isLoading = false;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  file_input: any = '';
  blob_output: any = '';
  errorMsg: any = '';
  currentSignature: any;
  signature: any;
  strength: any;
  newPassword: string;
  check_security = false;
  qrUrl: string;
  checkOtp = false;
  isLoadingOtp = false;
  otp: any;
  pdfName: any;
  showPreview = false;
  previewPDF: any = '../../../assets/images/otp.pdf';
  profilePic: File;

  signatureUploadMode = true;
  signatureSignMode = false;
  signImageBlob: any = null;
  signatureColorList = [
    '#000000',
    '#4169e1',
    '#EB2B36',
    '#266D1F'
  ];
  selectedColor = this.signatureColorList[0];
  showColorPicker = false;

  lineCodiumUrl: string;
  lineUserTextEncrypt: string;

  typeEmail = [];
  emailSettingNotication: any;

  checkLowerCase = false;
  checkUpperCase = false;
  checkNumber = false;
  checkSymbols = false;
  checkLength = false;

  subscriptions = new Subscription();

  @ViewChild('imageCropper', {static: false}) cropperElement: ElementRef;
  @ViewChild('previewSignature', {static: false}) previewElement: ElementRef;
  @ViewChild('confirmExitModal', {static: true}) confirmExitElement: ElementRef;
  @ViewChild('canvasWhiteboard', {static: false}) canvasWhiteboard: CanvasWhiteboardComponent;

  get f(): { [key: string]: AbstractControl } {
    return this.passwordForm.controls;
  }

  constructor(
    private userProfileService: UserProfileService,
    private formBuilder: FormBuilder,
    private router: Router,
    public modalService: NgbModal,
    private swal: SweetAlertService,
    private translate: TranslateService,
    public profileService: ProfileService,
    private titleService: TitleService,
    public authService: AuthenticationService,
    private signatureService: SignatureService,
  ) {
  }

  ngOnInit() {
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateTilte();
    });
    this.subscriptions.add(translateSub);
    this.translateTilte();
    this.checkOtp = false;
    this.getUserProfile();
    this.passwordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', [Validators.required]]
    });
    this.canvasWhiteboard = null;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  translateTilte() {
    this.titleService.setTitle(this.translate.instant('PROFILE.PROFILE'));
    this.typeEmail = [];
  }

  getUserProfile() {
    this.profileService.loadInputData().subscribe((profile: any) => {
      this.profile = profile;
      this.checkOtp = profile.otp_enable;
      this.currentSignature = profile.signature;
      this.emailSettingNotication = _.cloneDeep(profile.mail_notify);
    });
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  openUploadSign(cropImg) {
    this.modal = this.modalService.open(cropImg, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

  onSubmit() {
    this.submitted = true;

    if (this.passwordForm.invalid) {
      return;
    }
    if (this.passwordForm.value['newPassword'] !== this.passwordForm.value['confirmNewPassword']) {
      this.passwordNotMatch = true;
      return;
    }
    if (!this.checkLowerCase || !this.checkUpperCase || !this.checkNumber || !this.checkSymbols || !this.checkLength) {
      this.check_security = true;
      return;
    } else {
      this.check_security = false;
      this.passwordNotMatch = false;
      this.setPassword();
    }
    // if (this.strength < 3) {
    //   this.check_security = true;
    //   return;
    // } else {
    //   this.check_security = false;
    //   this.passwordNotMatch = false;
    //   this.setPassword();
    // }
  }

  setPassword() {
    this.isLoading = true;
    const data = {
      current_password: this.passwordForm.value['currentPassword'],
      new_password: this.passwordForm.value['confirmNewPassword']
    };
    this.profileService.setPassword(data).subscribe(
      () => {
        this.modal.close();
        this.isLoading = false;
        this.swal.toastNotification({
          type: 'success',
          content: this.translate.instant('PROFILE.SUCCESS-CHANGE-PASSWORD')
        });
        this.clear();
      },
      error => {
        this.isLoading = false;
        this.swal.toastNotification({type: 'error', content: error.error.new_password[0]});
      }
    );
  }

  updateProfile() {
    this.isLoading = true;
    const fd = new FormData();
    if (this.profilePic) {
      fd.append('photo', this.profilePic);
    }
    this.profileService.updateProfile(localStorage.profile_id, fd).subscribe(
      (data) => {
        this.profile.photo = data.photo;
        this.userProfileService.setUserProfileImage(data.photo);
        this.modal.close();
        this.isLoading = false;
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        this.clear();
      },
      error => {
        this.isLoading = false;
        this.swal.toastNotification({type: 'error', content: 'Unable to update profile'});
      }
    );
  }

  clear() {
    this.passwordForm.reset({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    });
  }

  toggleEdit() {
    this.router.navigate(['/userProfile', 'edit']);
  }

  async updateSignature() {
    if (await this.signatureService.checkOnlyWhiteBackground(this.blob_output)) {
      this.swal.toastNotification({type: 'error', content: this.translate.instant('MEMOS.EMPTY-SIGNATURE')});
      return;
    }
    const fd = new FormData();
    if (this.blob_output) {
      fd.append('signature', this.signature);
    }
    this.profileService.updateProfile(localStorage.profile_id, fd).subscribe(
      () => {
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        this.getUserProfile();
      },
      error => this.swal.toastNotification({type: 'error', content: 'Unable to load user profile'})
    );
  }

  fileChangeEvent(file: any): void {
    this.errorMsg = '';
    this.imageChangedEvent = file;
    this.file_input = file.target.files[0];
  }

  imageCropped(output: ImageCroppedEvent) {
    this.croppedImage = output.base64;
    this.blob_output = output.file;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.errorMsg = 'ไม่สามารถประมวลผลภาพได้ กรุณาอัพโหลดภาพใหม่อีกครั้ง';
    // show message
  }

  clearSignature() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.file_input = '';
    this.blob_output = '';
    this.signImageBlob = null;
  }

  async saveSignature() {
    if (this.signatureUploadMode) {
      if (this.file_input && this.blob_output) {
        const filename =
          this.file_input.name.substring(0, this.file_input.name.lastIndexOf('.')) + '.png';
        const fileSignature = new File([this.blob_output], filename, {type: this.blob_output.type});
        this.signature = fileSignature;
        await this.updateSignature();
      }
    } else if (this.signatureSignMode) {
      this.signature = new File([this.blob_output], 'online-sign.png', {type: this.blob_output.type});
      await this.updateSignature();
      this.clearSignature();
    }
    this.modalService.dismissAll();
  }

  strengthChange(strength: number): void {
    this.strength = strength;
  }

  openQRcode(event?) {
    this.confirm();
    this.openQRcodeModal(event);
  }

  confirm() {
    this.isLoadingOtp = true;
    if (this.profile.otp_enable) {
      const params = new HttpParams();
      this.profileService.OpenOtp(params).subscribe((openOtp: any) => {
        this.qrUrl = openOtp.key_url;
        this.isLoadingOtp = false;
        // this.swal.toastNotification({type: 'success', content: 'open OTP success'});
      });
    }

  }

  openQRcodeModal(event) {
    // this.confirmCount = 0;
    this.modal = this.modalService.open(event, {backdrop: 'static', keyboard: false});
  }

  closeModal() {
    if (!this.checkOtp) { // if not confirm then back to old state
      this.profile.otp_enable = !this.profile.otp_enable;
      this.modal.close();
    } else { // confirmed to open
      this.confirmModalRef = this.modalService.open(this.confirmExitElement);
      // if (this.confirmCount) {
      //   this.modal.close();
      // }
    }
  }

  closeModalCancel() {
    this.profile.otp_enable = !this.profile.otp_enalve;
    this.modal.close();
  }

  onOtpChange(event) {
    if (event.length === 6) {
      this.otp = event;
    }
  }

  verifyOTP() {
    if (!this.otp) {
      this.swal.toastNotification({type: 'error', content: 'Input OTP'});
    }
    const params = new HttpParams()
      .set('otp', this.otp);
    this.profileService.verifyOtp(params).subscribe(verify => {
        this.modal.close();
        this.getUserProfile();
        this.swal.toastNotification({type: 'success', content: 'Verify OTP success'});
      }, error => {
        this.swal.toastNotification({type: 'error', content: 'OTP is invalid. Please check again'});
      }
    );
  }

  cancelOTP() {
    const params = new HttpParams()
      .set('otp', this.otp);
    this.profileService.CloseOtp(params).subscribe(closeOtp => {
        this.modal.close();
        this.getUserProfile();
        this.swal.toastNotification({type: 'success', content: 'close OTP success'});
      }, error => {
        this.swal.toastNotification({type: 'error', content: 'OTP is invalid. Please check again'});
      }
    );
  }

  openPreview() {
    this.showPreview = true;
  }

  closePreview() {
    this.showPreview = false;
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.cancelOTP();
      return false;
    }
  }

  onUploadBtn() {
    this.signatureUploadMode = true;
    this.signatureSignMode = false;
  }

  onSignBtn() {
    this.signatureUploadMode = false;
    this.signatureSignMode = true;
  }

  onSave(event) {
    this.canvasWhiteboard.generateCanvasBlob((blob) => {
      this.blob_output = blob;
      this.signImageBlob = blob;
    });
  }

  onClearCanvas() {
    this.canvasWhiteboard.clearCanvas();
  }

  onUndoCanvas() {
    this.canvasWhiteboard.undo();
  }

  onClickColorPicker() {
    this.showColorPicker = !this.showColorPicker;
  }

  onSaveCanvas() {
    this.canvasWhiteboard.saveLocal();
  }

  onSelectedColor(color: string) {
    this.selectedColor = color;
  }

  openSignModal(signature) {
    this.modal = this.modalService.open(signature, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

  openLineModal(event) {
    this.modal = this.modalService.open(event, {backdrop: 'static', keyboard: false});
    this.getLineCodium();
    this.getLineUser();
  }

  getLineCodium() {
    if (!this.lineCodiumUrl) {
      this.profileService.getLinkLineCodium().subscribe(
        res => {
          this.lineCodiumUrl = res['url'];
        }
      );
    }
  }

  getImage(image) {
    this.profilePic = image;
  }

  getLineUser() {
    if (!this.lineUserTextEncrypt) {
      this.profileService.getLinkLineUser().subscribe(
        res => {
          this.lineUserTextEncrypt = res['text_encrypt'];
        }
      );
    }
  }

  confirmUpdateEmail(event) {
    this.getUserProfile();
  }

  validationLanguage() {
    this.checkLowerCase = false;
    this.checkUpperCase = false;
    this.checkNumber = false;
    this.checkSymbols = false;
    this.checkLength = false;
    if (this.newPassword) {
      this.checkLowerCase = !!this.newPassword.match(/[a-z]/g);
      this.checkUpperCase = !!this.newPassword.match(/[A-Z]/g);
      this.checkNumber = !!this.newPassword.match(/[0-9]/g);
      this.checkSymbols = !!this.newPassword.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g);
      this.checkLength = this.newPassword.length >= 8;
    }
  }

  openEditProfilePicture(event) {
    this.profilePic = null;
    this.modal = this.modalService.open(event, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

}
