import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UserRoutingModule } from './user-routing.module';
import { UserListComponent } from './user-list/user-list.component';
import { UserService } from './user.service';
import { CoreModule } from '../../../core/core.module';
import { UserConfirmationComponent } from './user-confirmation/user-confirmation.component';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    UserRoutingModule,
    FormsModule
  ],
  declarations: [
    UserListComponent,
    UserConfirmationComponent,
  ],
  providers: [
    UserService
  ]
})
export class UserModule {
}
