import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {FileSystemFileEntry} from 'ngx-file-drop';
import {NotificationService} from '../../shared/services/notification.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: [
    './file-upload.component.scss'
  ]
})
export class FileUploadComponent implements OnInit {

  @Input() label = 'File Upload';
  @Input() upload = null;
  @Input() isNoTitle = false;
  @Input() isExcelOnly = false;
  @Input() insideText = 'Browse or Drop files here';
  @Input() fileExists = false;
  @Output() uploadChange = new EventEmitter();
  @ViewChild('fileImg', { static: true }) fileImg: any;
  @ViewChild('fileExcel', { static: true }) fileExcel: any;

  imgFile: File;
  excelFile: File;

  constructor(
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
  }

  removeFile() {
    this.uploadChange.emit(null);
    this.reset();
  }

  reset() {
    this.isExcelOnly ? this.fileExcel.nativeElement.value = '' : this.fileImg.nativeElement.value = '';
    this.insideText = 'Browse or Drop files here';
    this.fileExists = false;
    this.imgFile = null;
    this.excelFile = null;
  }

  checkFileSize(file) {
    if (file != null && file.size / (1024 * 1024) >= 30) {
      this.reset();
      this.notificationService.showSpecificError('กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 30 MB');
      return false;
    }

    return true;
  }

  uploadTaskAttachmentByID(event, isDrop) {
    this.uploadChange.emit(null);
    if (isDrop) {
      if (!event.files[0].fileEntry.isFile) {
        return;
      }

      const fileEntry = event.files[0].fileEntry as FileSystemFileEntry;

      fileEntry.file((file: File) => {
        if (!this.checkFileSize(file)) {
          return;
        }

        this.insideText = event.files[0].fileEntry.name;
        this.fileExists = true;
        this.uploadChange.emit(file);
      });

    } else {
      const file = event.target.files[0];

      if (!this.checkFileSize(file)) {
        return;
      }

      this.insideText = event.target.files[0].name;
      this.fileExists = true;
      this.uploadChange.emit(file);
    }
  }
}
