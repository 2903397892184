import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MessageService} from 'primeng/api';
import {GeneralService} from '../../../../setting/general/general.service';
import {LoaService} from '../../../loa/loa.service';
import {MemoService} from '../../memo.service';
import {NotificationService} from '../../../../../shared/services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NgbThaiDateParserFormatter} from '../../../../../core/NgDateParser';
import {UserService} from '../../../../permission/user/user.service';
import * as moment from 'moment';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {forEach, get} from 'lodash';
import {FileSystemFileEntry, UploadEvent} from 'ngx-file-drop';
import {ThemeService} from '../../../../../shared/services/theme.service';
import {SweetAlertService} from '../../../../../shared/services/sweet-alert.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-template-wordcloud',
  templateUrl: './template-wordcloud.component.html',
  styleUrls: ['./template-wordcloud.component.scss']
})
export class TemplateWordcloudComponent implements OnInit, OnDestroy {
  PAGE_SIZE_WIDTH = 827;
  PAGE_SIZE_HEIGHT = 1170;
  isLoadingPreview: boolean;

  RECT_SIZE_WIDTH = 150;
  RECT_SIZE_HEIGHT = 45;

  CHECKBOX_SIZE_WIDTH = 30;
  CHECKBOX_SIZE_HEIGHT = 30;
  text = '<div>Hello World!</div><div>PrimeNG <b>Editor</b> Rocks</div><div><br></div>';
  signatureClickButtonName: any = null;
  isLoading = false;
  loaList = [];
  loaListAll = [];
  loa: any = [];
  isToggleTemplate = false;
  isAuto = true;
  documentFile: File;
  docxFile: File;
  errorMessage = false;
  isLoadingPublished = false;
  correctMessage = false;
  isLoadingData = false;
  selected_page_number = 0;
  preview_url = '';
  job_position = [];
  content = '';
  logo = {
    id: null,
    image: null
  };
  address: any;
  error: any = {};
  memoNumberList = [
    {
      name: 'Auto Generated',
      id: 'Auto Generated'
    },
    {
      name: 'Custom Memo Number',
      id: 'Custom Memo Number'
    }
    ];
  isCustomMemo = false;
  currentDay = moment().format('YYYY-MM-DD');
  profileList = [];
  profile: any;
  jobPositionList = [];
  allDepartmentList = [];
  logoList = [];
  department: any;
  remark: string;
  documentData: any;
  showPreview = false;
  modal: NgbModalRef;
  memoData: any;
  imageList = [];
  approvalList = [];
  approvallistLevels = {};
  loa_id: number;
  loadsign = [];
  loadsign_customer = [];
  data_customer: any;
  checkcustom: any;
  countnumber: any;
  files: any[] = [];
  fileUpload: File;
  to: any;
  count_sign = 0;
  count_levels = 0;
  profileListAll = [];
  departments = [];
  dataApprove: any;
  signatures = {'approver': []};
  greenCircleStart = 1;
  isSmallScreen: any;
  contentApprovel = [];
  labelMulti = '';
  memoSpecifycc = '';
  memoSelectdepartment = '';
  checkFile = false;
  memosList = [];
  suggestionsMemo = [];

  selectedTemplate = {value: 'external', showLabel: 'External Memo'};
  templateList = [
    {value: 'internal', showLabel: 'MEMOS.INTERNAL-MEMO'},
    {value: 'external', showLabel: 'MEMOS.EXTERNAL-MEMO'},
  ];
  isSavedTemplate: boolean;
  isPermissionOnlyRelated = {value: false, label: 'Only related'};

  loaSignUserChoices = [
    {value: 'user', label: 'user'},
    {value: 'none user', label: 'non-user'},
  ];
  loaSignNoneUserPlaceHolder = {
    name: 'Contact Name',
    job_position: 'Job Position',
    email: 'Email Address'
  };

  // tooltip
  toolTipSave = '';
  toolTipPreview = '';

  subscriptions = new Subscription();

  @ViewChild('pdfImageContainer', {static: false}) pdfImageContainer: ElementRef;

  // tslint:disable-next-line:no-input-rename
  @Input('memoData') _memoData;
  @Output() savedMemo = new EventEmitter();
  @Output() editSuccess = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() togglePreview = new EventEmitter();

  constructor(
    private messageService: MessageService,
    private generalService: GeneralService,
    private loaService: LoaService,
    private memoService: MemoService,
    private notification: NotificationService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private date: NgbThaiDateParserFormatter,
    private userService: UserService,
    private notificationService: NotificationService,
    private themeService: ThemeService,
    private swal: SweetAlertService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateSelect();
      this.initLoaSignUser();
    });
    this.subscriptions.add(translateSub);
    this.translateSelect();
    this.getListmemos();
    this.isSmallScreen = this.themeService.isMobile();
    this.loadAllGeneralProfile();
    this.memoData = _.cloneDeep(this._memoData);
    if (this.memoData.is_permission) {
      this.isPermissionOnlyRelated.value = this.memoData.is_permission;
    }
    if (this.memoData.cc_list) {
      this.memoData.cc_list = _.cloneDeep(this.memoData.cc_list);
    }
    this.checkTypetemplate();
    this.loadDepartmentList();
    this.getApprovalList();
    this.loadLOAList();
    this.loadDepartments();
    this.getmemoReference();
    this.isLoadingData = true;
    if (!this.memoData.customMemoNumberDropDown) {
      this.memoData.customMemoNumberDropDown = this.memoNumberList[0].id;
    }
    this.initMemoTemplate();
    this.initLoaSignUser();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  translateSelect() {
    this.labelMulti = this.translate.instant('MEMOS.CHOOSE');
    this.memoSpecifycc = this.translate.instant('MEMOS.SPECIFY-CC');
    this.memoSelectdepartment = this.translate.instant('MEMOS.SELECT-DEPARTMENT');
    this.isPermissionOnlyRelated.label = this.translate.instant('MEMOS.ONLY-RELATED');
    this.memoNumberList = [
    {
      name: this.translate.instant('MEMOS.AUTO-GENERATED'),
      id: 'Auto Generated'
    },
    {
      name: this.translate.instant('MEMOS.CUSTOM-MEMO-NUMBER'),
      id: 'Custom Memo Number'
    }
    ];

    this.toolTipSave = this.translate.instant('TOOLTIP.SAVE-MEMO');
    this.toolTipPreview = this.translate.instant('TOOLTIP.PREVIEW');
  }

  getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  loadDepartments() {
    const params = new HttpParams().set('profile_related', this.memoData.department);
    this.userService.getDepartment(params).subscribe(departments => {
      this.departments = _.orderBy(departments.results, [department => department.name.toLowerCase()], 'asc');
      if (this.departments.length === 0) {
        this.userService.getDepartment().subscribe(real_departments => {
          this.departments = real_departments.results;
        });
      }
      // this.departments = _.filter(departments.results, department => department.id === this.memoData.department);
    });
  }

  checkTypetemplate() {
    if (this.memoData.memo_template) {
      if (this.memoData.memo_template === 'internal') {
        this.selectedTemplate = {value: 'internal', showLabel: 'Internal Memo'};
        this.greenCircleStart = 2;
      }
    }
  }

  getListmemos() {
    this.memoService.getListMemosNumber().subscribe( memoslist => {
      this.memosList = memoslist.memo_numbers;
    });
  }

  getmemoReference() {
    const momosRef = [];
    this.memoService.getMomoreference(this.memoData.id).subscribe( memoreference => {
      _.forEach(memoreference.results, obj => {
        momosRef.push(obj.memo_number);
        this.memoData.Ref = momosRef;
      });
    });
  }

  filterListmemos(event) {
    this.suggestionsMemo = [];
    for (let i = 0; i < this.memosList.length; i++) {
      const memolist = this.memosList[i];
      if (memolist.toLowerCase().includes(event.query.toLowerCase())) {
        this.suggestionsMemo.push(memolist);
      }
    }
    if (this.suggestionsMemo.length === 0) {
      this.suggestionsMemo.push(event.query);
    }
  }

  loadAllGeneralProfile() {
    this.isLoadingData = true;
    const generalRole = '1';
    const params = new HttpParams().set('none_pagination', 'True')
      .set('role', generalRole);
    this.userService.getProfileList(params).subscribe((profiles: any) => {
      profiles.forEach(obj => {
        this.profileListAll.push({
          'label': obj.full_name,
          'value': obj.id
        });
      });
     this.profileListAll = _.orderBy(this.profileListAll, [profile => profile.label.toLowerCase()], 'asc');
    });
  }

  back() {
    // if user click back, return original variable.
    this.editSuccess.emit(this._memoData);
  }

  closeToggle() {
    this.closed.emit();
  }

  getFile(file) {
    this.documentFile = file;
    file ? this.getImageListFromFile(file) : this.imageList = [];
  }

  getFileDocx(file) {
    this.docxFile = file;
  }

  getImageListFromFile(file) {
    const fd = new FormData();
    fd.append('file', file);
    this.getImageList(fd);
  }

  getImageList(formData) {
    this.memoService.createImgFromPDF(formData).subscribe(
      imageURL => {
        this.imageList = imageURL.result;
        this.selected_page_number = 1;
      },
      error => {
        if (error.error && error.error.message) {
          this.notification.showSpecificError(error.error.message);
        } else {
          this.notification.showNotification(error.status);
        }

        this.isLoading = false;
      }
    );
  }

  getPoint($event) {
    if (this.signatureClickButtonName == null) {
      return;
    }
    const type = this.signatureClickButtonName[0];
    const personIndex = this.signatureClickButtonName[1];

    const bounds = this.pdfImageContainer.nativeElement.getBoundingClientRect();
    const posx = $event.clientX - bounds.left;
    const posy = $event.clientY - bounds.top;

    // posx = posx - (type =='checkbox' ? this.CHECKBOX_SIZE_WIDTH : this.RECT_SIZE_WIDTH ) / 2;
    // posy = posy - (type =='checkbox' ? this.CHECKBOX_SIZE_HEIGHT : this.RECT_SIZE_HEIGHT ) / 2;

    const signature_location_data = this.signatures[type][personIndex];
    signature_location_data.X = posx * 100 / this.PAGE_SIZE_WIDTH;
    signature_location_data.Y = posy * 100 / this.PAGE_SIZE_HEIGHT;
    signature_location_data.page = this.selected_page_number;

    // this.signatureClickButtonName = null;
  }

  clicked($event) {
    if (this.signatureClickButtonName == null) {
      return;
    }
    this.signatureClickButtonName = null;
  }

  clear(type, index) {
    const signature_location_data = this.signatures[type][index];
    signature_location_data.X = 0;
    signature_location_data.Y = 0;
    signature_location_data.page = 1;

  }

  getApprovalList(loa = '') {
    const params = new HttpParams()
      .set('none_pagination', 'True')
      .set('loa', loa)
      .set('role', '1')
      .set('active_user', '1');
    this.userService.getProfileList(params).subscribe((profiles: any) => {
      this.approvalList = _.orderBy(profiles, [profile => profile.full_name.toLowerCase()], 'asc');
    });
  }

  selectDepartment(event?) {
    if (!this.checkcustom) {
      this.loa = null;
    }
    this.loadsign = [];
    this.loaList = _.filter(this.loaListAll, loa => loa.department === this.memoData.department || loa.department === null);
  }

  loadLOAList() {
    this.loaService.getLOAList().subscribe(data => {
      this.loaListAll = data['results'];
      this.loaListAll.push({
        name: 'CUSTOM LOA',
        id: 'cus',
        department: null
      });
      this.loaList = _.cloneDeep(this.loaListAll);
      this.loaList = _.filter(this.loaListAll, loa => loa.department === this.memoData.department || loa.department === null);
      this.loaList.forEach(obj => {
        if (this.memoData.custom_loa_group !== null) {
          this.loa = 'CUSTOM LOA';
        }
        if (this.memoData.loa_group === obj.id) {
          this.loa = obj.name;
        }
      });
      this.showLOAData();
    });
  }

  showLOAData() {
    if (this.memoData.custom_loa_group !== null) { // custom group
      this.loa_id = null;
      this.loadsign_customer = [];
      this.getApprovalList('');
      this.loadsign = [];
      this.checkcustom = true;
      this.countnumber += 1;
      this.loadsign_customer = this.memoData.loa;
      _.forEach(this.loadsign_customer, obj => {
        if (obj.signature_required) {
          this.contentApprovel.push({
            content: 'MEMOS.SIGNATURE-REQUIRE'
          });
        } else {
          this.contentApprovel.push({
            content: 'MEMOS.NO-SIGNATURE-REQUIRE'
          });
        }
      });
      this.updateJobPositionList();
    } else { // normal group
      this.loa_id = this.memoData.loa_group;
      this.loaList.forEach(obj => {
        if (this.memoData.loa_group === obj.id) {
          this.loadsign = obj.levels;
        }
      });
      this.updateJobPositionList();
      this.loadsign_customer = [];
      this.checkcustom = false;
    }
    // }
  }

  selectLOA(data) {
    this.jobPositionList = [];
    if (data === undefined) { // x
      this.loa_id = null;
      this.getApprovalList('');
      this.signatures = {'approver': []};
      this.loadsign = null;
      this.checkcustom = null;
      this.loadsign_customer = [];
    } else {
      if (data.id === 'cus') {  // custom loa
        this.getApprovalList('');
        this.loa_id = null;
        this.loadsign = [];
        this.loadsign_customer = [];
        this.checkcustom = 1;
        this.countnumber += 1;
        this.contentApprovel.push({
          content: 'MEMOS.SIGNATURE-REQUIRE'
        });
        this.addSignatures();
      } else { // normal loa
        this.getApprovalList(data.id);
        this.loa_id = data.id;
        this.loadsign = data.levels;
        this.loadsign_customer = [];
        this.checkcustom = null;
        data.levels.forEach(obj => {
          obj.members.forEach(job => {
            this.jobPositionList.push({
              name: job.job_position,
              label: job.job_position
            });
          });
        });
        this.jobPositionList = _.cloneDeep(this.jobPositionList);
      }
    }
  }

  removeApprover(data, index) {
    this.loadsign_customer.splice(index, 1);
    this.contentApprovel.splice(index, 1);
    // this.memoData.approver.splice(index, 1);
    this.reformatLevels();
  }

  reformatLevels() {
    _.forEach(this.loadsign_customer, (item, index) => {
      item.title = `level ${index + 1}`;
    });
  }

  selectedApprover(selectedApprover?, index?, index2?, data?) {
    if (this.loadsign_customer[index]['members'][index2] !== selectedApprover
      && this.approvalList.length !== this.approvallistLevels[index].length) {
      if (this.loadsign_customer[index]['members'][index2].length !== 0) {
        this.approvallistLevels[index].push(this.loadsign_customer[index]['members'][index2]);
      }
    }
    let listApprove = [];
    [listApprove, this.approvallistLevels[index]] = _.partition(this.approvallistLevels[index], function (n) {
      return selectedApprover.id === n.id;
    });
    this.approvallistLevels[index] = _.orderBy(this.approvallistLevels[index], ['full_name'], ['asc']);
    this.loadsign_customer[index]['members'][index2] = selectedApprover;
    this.count_sign += 1;
    this.updateJobPositionList();
  }

  updateJobPositionList() {
    this.jobPositionList = [];
    let loa = [];
    if (this.checkcustom) {
      loa = this.loadsign_customer;
    } else {
      loa = this.loadsign;
    }

    loa.forEach(level => {
      level.members.forEach(member => {
        if (member.job_position || member.job_position !== '') {
          this.jobPositionList.push({
            name: member.job_position,
            label: member.job_position
          });
        }
      });
    });
    this.jobPositionList = _.cloneDeep(this.jobPositionList);
  }

  locateSignatureClick(buttonName, index) {
    this.signatureClickButtonName = [buttonName, index];
  }

  addSignatures() {
    this.contentApprovel.push({
      content: 'MEMOS.SIGNATURE-REQUIRE'
    });
    this.loadsign_customer.push({
      title: this.translate.instant('MEMOS.LEVEL') + `${this.loadsign_customer.length + 1}`,
      members: [[]],
      signature_required: true,
      min_approve_count: 1,
      user_type: this.loaSignUserChoices[0].value
    });
    this.approvallistLevels[this.count_levels] = _.cloneDeep(this.approvalList);
    this.count_levels += 1;
  }

  removeSignatures() {
    this.loadsign_customer.pop();
    this.updateJobPositionList();
  }

  addMemberToLevel(levelIndex: number) {
    if (this.loadsign_customer[levelIndex].user_type === this.loaSignUserChoices[0].value) {
      this.loadsign_customer[levelIndex].members.push([]);
    } else if (this.loadsign_customer[levelIndex].user_type === this.loaSignUserChoices[1].value) {
      this.loadsign_customer[levelIndex].members.push({
        name: '',
        job_position: '',
        email: ''
      });
    }
    this.loadsign_customer[levelIndex].min_approve_count = this.loadsign_customer[levelIndex].members.length;
    this.guardRangApproveCount(levelIndex);
  }

  removeMemberFromLevel(levelIndex: number) {
    this.loadsign_customer[levelIndex].members.pop();
    this.guardRangApproveCount(levelIndex);
    this.updateJobPositionList();
  }

  onInputApproveCount(levelIndex: number) {
    this.guardRangApproveCount(levelIndex);
  }

  onInputNoneUserJobPosition() {
    this.updateJobPositionList();
  }

  guardRangApproveCount(levelIndex: number) {
    const currentCount = this.loadsign_customer[levelIndex].min_approve_count;
    const maxCount = this.loadsign_customer[levelIndex].members.length;
    if (currentCount > maxCount) {
      this.loadsign_customer[levelIndex].min_approve_count = maxCount;
    } else if (currentCount < 1) {
      this.loadsign_customer[levelIndex].min_approve_count = 1;
    }
  }

  onChangeLoaUserType(levelIndex: number) {
    this.loadsign_customer[levelIndex].members = [];
    this.addMemberToLevel(levelIndex);
    this.updateJobPositionList();
  }

  initLoaSignUser() {
    this.loaSignUserChoices[0].label = this.translate.instant('MEMOS.TYPE-USER');
    this.loaSignUserChoices[1].label = this.translate.instant('MEMOS.TYPE-NONE-USER');
    this.loaSignUserChoices = [...this.loaSignUserChoices];  // for ng-select able to detect updating
    this.loaSignNoneUserPlaceHolder.name = this.translate.instant('MEMOS.TYPE-USER-NAME');
    this.loaSignNoneUserPlaceHolder.job_position = this.translate.instant('CREATE-USER.JOB-POSITION');
    this.loaSignNoneUserPlaceHolder.email = this.translate.instant('MEMOS.TYPE-USER-EMAIL');
  }

  getCcPositionName() {
    return _.map(
      this.memoData.cc_list, pk => _.find(this.profileListAll, {value: pk}).label
    ).join(', ');
  }

  prepareDataForSave() {
    if (this.isCustomMemo) {
      const data = {
        name: this.memoData.name || '',
        subject: this.memoData.name || '',
        detail: this.memoData.detail,
        to: this.memoData.to,
        from: this.memoData.created_job_position,
        cc_list: this.memoData.cc_list,
        cc: this.getCcPositionName(),
        loa: this.loadsign_customer,
        department: this.memoData.department,
        memo_number: this.memoData.memoNumber,
        is_permission: this.isPermissionOnlyRelated.value,
        reference: this.memoData.Ref || [],
      };

      if (data['is_permission']) {
        data['announcement'] = [];
      } else {
        data['announcement'] = this.memoData.announcement_list;
      }

      if (this.loa_id != null) {
        data['loa_group'] = (this.loa_id.toString());
        data['custom_loa_group_id'] = null;
      } else {
        data['loa_group_id'] = null; // custom loa
      }

      if (this.memoData.department) {
        data['department'] = (this.memoData.department.toString());
      }

      return data;
    } else {
      const data = {
        name: this.memoData.name || '',
        subject: this.memoData.name || '',
        detail: this.memoData.detail,
        to: this.memoData.to,
        from: this.memoData.created_job_position,
        cc_list: this.memoData.cc_list,
        cc: this.getCcPositionName(),
        loa: this.loadsign_customer,
        department: this.memoData.department,
        memo_template: this.selectedTemplate.value,
        is_save_template: this.isSavedTemplate,
        is_permission: this.isPermissionOnlyRelated.value,
        reference: this.memoData.Ref || [],
      };

      if (data['is_permission']) {
        data['announcement'] = [];
      } else {
        data['announcement'] = this.memoData.announcement_list;
      }

      if (this.loa_id != null) {
        data['loa_group'] = (this.loa_id.toString());
        data['custom_loa_group_id'] = null;
      } else {
        data['loa_group_id'] = null; // custom loa
      }

      if (this.memoData.department) {
        data['department'] = (this.memoData.department.toString());
      }

      return data;
    }
  }

  save() {
    this.isSavedTemplate = true;
    this.isLoading = true;
    const data = this.prepareDataForSave();
    this.memoService.updateMemoReal(this.memoData.id, data).subscribe(
      memo => {
        this.savePreview(() => {
          this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
          this.isLoading = false;
          memo.unread_comment_count = 0;
          memo.unread_attachment_count = 0;
            this.memoService.getMemoRealDetail(memo.id).subscribe( memoData => {
              this.editSuccess.emit(memoData);
              this.templateList = [this.selectedTemplate];
            });
        });
      },
      error => {
        this.isLoading = false;
        this.swal.toastNotification({type: 'error', content: error.error});
      }
    );

    this.saveAttachments();
  }

  saveAttachments() {
    if (this.files) {
      const at = new FormData();
      at.append('memo', this.memoData.id);
      forEach(this.files, file => {
          const name = get(file, 'name', 'relativePath');
          file.fileEntry ? at.append(name, file.fileEntry) : at.append(name, file);
        }
      );
      this.memoService.uploadMemoAttachment(at).subscribe(
        res => {
          // this.isLoading = false;
        },
        error => (this.isLoading = false)
      );
    }
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  checkIfCanPublish() {
    return this.memoData.status === 'rejected';
  }

  checkIfRemovable() {
    return _.toNumber(localStorage.getItem('user_id')) === _.get(this.memoData, 'created_user', '');
  }

  sendPdf() {
    const pdf_fd = new FormData();
    pdf_fd.append('word_cloud_pdf', this.documentFile);
    this.memoService.updateMemoReal(this.memoData.id, pdf_fd).subscribe(
      memo => {
      },
      error => {
        this.isLoading = false;
        this.notification.showNotification(error.status);
      }
    );
  }

  getMemoDetail(id) {
    this.memoService.getMemoRealDetail(id).subscribe(
      memo => {
        this.modal.close();
        this.editSuccess.emit(memo);
        this.closeToggle();
      },
      error => {
        this.modal.close();
        this.notification.showNotification(error.status);
      }
    );
  }

  checkFileSize(file) {
    if (file != null && file.size / (1024 * 1024) >= 30) {
      this.notificationService.showSpecificError('กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 30 MB');
      return false;
    }

    return true;
  }

  dropped(event: UploadEvent) {
    for (const droppedFile of event.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          if (!this.checkFileSize(file)) {
            return;
          }

          this.files.push(file);
        });
      }
    }
  }

  selectFile(fileInput) {
    _.map(fileInput.target.files, (file) => {
      if (!this.checkFileSize(file)) {
        return;
      }

      this.files.push(file);
    });
  }

  removeFile(fileIndex) {
    this.files.splice(fileIndex, 1);
  }

  Preview() {
    this.isLoadingPreview = true;
    const data = this.prepareDataForSave();
    this.memoService.updateMemoReal(this.memoData.id, data).subscribe(
      memo => {
        this.openPreview(() => {
          this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
          // this.messageService.add({severity: 'success', summary: 'Save Changes'});
          this.getDataForPreview();
        });
      },
      error => {
        this.isLoadingPreview = false;
        this.swal.toastNotification({type: 'error', content: error.error});
      }
    );
  }

  getDataForPreview() {
    this.memoService.getMemoRealDetail(this.memoData.id).subscribe(
      memo => {
        if (this.isSmallScreen) {
          window.open(memo.signed_document);
        } else {
          this.documentData = memo.signed_document;
          this.showPreview = true;
        }
        this.isLoadingPreview = false;
      });
  }

  openPreview(awit) {
    const params = new HttpParams().set('memo_id', this.memoData.id);
    this.memoService.PreviewMemoReal(params).subscribe(
      preview => {

      },
      error => {
        this.preview_url = error.url;
        awit();
      }
    );
  }

  savePreview(f) {
    const params = new HttpParams().set('memo_id', this.memoData.id);
    this.memoService.PreviewMemoReal(params).subscribe(
      () => {
      },
      error => {
        this.preview_url = error.url;
        f();
        // window.open(this.preview_url);
      }
    );
  }

  closePreview(data?) {
    this.showPreview = false;
  }

  checkApproval(index) {
    const checkApproval = this.loadsign_customer[index].signature_required;

    if (checkApproval) {
      this.contentApprovel[index].content = 'MEMOS.SIGNATURE-REQUIRE';
    } else {
      this.contentApprovel[index].content = 'MEMOS.NO-SIGNATURE-REQUIRE';
    }
  }

  changeMemoNumber(e) {
    if (this.memoNumberList[0].id === e.id) {
      this.isCustomMemo = false;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[0].id;
    } else if (this.memoNumberList[1].id === e.id) {
      this.isCustomMemo = true;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[1].id;
    }
  }

  loadDepartmentList() {
    const params = new HttpParams().set('current_user_company', 'true');
    this.memoService.getDepartmentList(params).subscribe(
      (departments: any) => {
        departments.results.forEach(obj => {
          this.allDepartmentList.push({
            'label': obj.name,
            'value': obj.id
          });
        });
        this.allDepartmentList = _.orderBy(this.allDepartmentList, [department => department.label.toLowerCase()], 'asc');
      },
      error => this.notification.showNotification(error.status)
    );
  }

  initMemoTemplate() {
    this.isSavedTemplate = this.memoData.is_save_template;

    this.selectedTemplate = this.templateList.find(
      template => template.value === this.memoData.memo_template
    );

    if (this.isSavedTemplate) {
      this.templateList = [this.selectedTemplate];
    }
  }

  onSelectedTemplate(template: { value: string, showLabel: string }) {
    this.selectedTemplate = template;
    if (this.selectedTemplate.value === 'external') {
      this.greenCircleStart = 1;
    } else {
      this.greenCircleStart = 2;
    }
  }

}

