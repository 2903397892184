import {MemoService} from '../../memos/memo/memo.service';
import {Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy} from '@angular/core';
import {NotificationService} from 'src/app/shared/services/notification.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {HttpParams} from '@angular/common/http';
import {WebcamImage} from 'ngx-webcam';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CanvasWhiteboardComponent} from 'ng2-canvas-whiteboard';
import {Subscription} from 'rxjs';
import {SignatureService} from '../../../shared/services/signature.service';

@Component({
  selector: 'app-executive-detail',
  templateUrl: './executive-detail.component.html',
  styleUrls: ['./executive-detail.component.scss']
})
export class ExecutiveDetailComponent implements OnInit, OnDestroy {
  reason = '';
  modal: NgbModalRef;
  webcamImage: WebcamImage = null;
  actionType = '';
  isLoading = false;
  pdfIsloading = true;
  memoTypeText;
  userRole;
  pdfSrc;
  currentPage = 1;
  showResults: boolean;
  count: any;
  attachmentList = [];
  otp: any;
  toggleSideMenu = true;

  signImageBlob: any = null;
  blob_output: any = '';
  croppedImage: any = '';
  imageChangedEvent: any = '';
  errorMsg: any = '';
  file_input: any = '';
  signature: any;
  isSaved = false;
  hasStoredSignature: boolean = JSON.parse(localStorage.getItem('has_signature'));

  // Tooltip
  tooltipReject = '';
  tooltipTerminate = '';

  signatureColorList = [
    '#000000',
    '#4169e1',
    '#EB2B36',
    '#266D1F'
  ];
  selectedColor = this.signatureColorList[0];
  showColorPicker = false;

  subscriptions = new Subscription();

  @Input() isSelf = 'pending';
  @Input() viewType = 0;
  @Input() memo: any;

  @Output() actionChanged = new EventEmitter();

  @ViewChild('canvasWhiteboard', { static: false }) canvasWhiteboard: CanvasWhiteboardComponent;

  constructor(
    private notification: NotificationService,
    private swal: SweetAlertService,
    public modalService: NgbModal,
    private memoService: MemoService,
    private translate: TranslateService,
    private signatureService: SignatureService
  ) {
  }

  ngOnInit() {
    this.reason = '';
    this.viewType = 0;
    this.memoText();
    this.loadPurposePDF();
    this.loadMemoData();
    this.getUserRole();
    this.loadAttachmentList();

    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateContent();
    });
    this.subscriptions.add(translateSub);
    this.translateContent();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  translateContent() {
    this.tooltipReject = this.translate.instant('TOOLTIP.REJECT-BUTTON');
    this.tooltipTerminate = this.translate.instant('TOOLTIP.TERMINATED-BUTTON');
  }

  getUserRole() {
    this.userRole = localStorage.role;
  }

  memoText() {
    if (this.memo.memo_type === 'purpose') {
      this.memoTypeText = 'แบบแจ้งความประสงค์';
    }
    if (this.memo.memo_type === 'rental') {
      this.memoTypeText = 'สัญญาเช่า';
    }
    if (this.memo.memo_type === 'service') {
      this.memoTypeText = 'สัญญาบริการ';
    }
    if (this.memo.memo_type === 'amend_rental') {
      this.memoTypeText = 'บันทึกข้อตกลงสัญญาเช่า';
    }
    if (this.memo.memo_type === 'amend_service') {
      this.memoTypeText = 'บันทึกข้อตกลงสัญญาบริการ';
    }
  }

  loadMemoData() {
    this.memoService.getMemoRealDetail(this.memo.id).subscribe(
      memo => {
        this.memo = memo;
      },
      error => {
        this.swal.toastNotification({
          type: 'error',
          content: 'Unable to load memo'
        });
      }
    );
  }

  loadPurposePDF() {
    if (this.memo.signed_document) {
      this.pdfSrc = this.memo.signed_document;
    } else {
      this.pdfSrc = this.memo.word_cloud_pdf;
    }
    // this.memo.attachments.map(obj => {
    //   if (!obj.is_primary) {
    //     return;
    //   }
    //   if (obj.attachment_type) {
    //     this.pdfSrc = obj.file
    //   }
    // })
  }

  open(type, content) {
    this.actionType = type;
    this.webcamImage = null;
    this.modal = this.modalService.open(content);
  }

  openSignModal(signature) {
    this.modal = this.modalService.open(signature, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

  close() {
    this.modal.close();
  }

  approve() {
    this.isLoading = true;
    let data: any = {
      memo_id: this.memo.id,
      approve_status: true,
      reason: this.reason,
      otp: this.otp
    };
    if (this.signature) {
      const fd = new FormData();
      fd.append('data', JSON.stringify(data));
      fd.append('one_time_signature', this.signature);
      data = fd;
    }
    this.memoService.updateMemoStatus(data).subscribe(
      res => {
        this.loadMemoData();
        this.actionChanged.emit(this.toggleSideMenu);
        this.modal.close();
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        this.isLoading = false;
        this.notification.updateNotificationCount();
        this.signature = null;
      },
      error => {
        const errorMsg = error.error.message;
        this.swal.toastNotification({type: 'error', content: errorMsg});
        this.modal.close();
        this.isLoading = false;
      }
    );
  }

  reject() {
    this.isLoading = true;
    const data = {
      memo_id: this.memo.id,
      approve_status: false,
      reason: this.reason
    };
    this.memoService.updateMemoStatus(data).subscribe(
      (res: any) => {
        const params = new HttpParams().set('memo_id', this.memo.id);
        this.loadMemoData();
        this.actionChanged.emit(this.toggleSideMenu);
        this.modal.close();
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        this.isLoading = false;
        this.notification.updateNotificationCount();
      },
      error => {
        this.loadMemoData();
        this.swal.toastNotification({type: 'error', content: 'Unable to Reject Memo'});
        this.modal.close();
        this.isLoading = false;
      }
    );
  }

  terminate() {
    this.isLoading = true;
    const data = {
      status: 'terminated',
      reason: this.reason
    };
    this.memoService.updateMemoReal(this.memo.id, data).subscribe(
      (res: any) => {
        this.loadMemoData();
        this.actionChanged.emit(this.toggleSideMenu);
        this.modal.close();
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        this.isLoading = false;
        this.notification.updateNotificationCount();
      },
      error => {
        this.loadMemoData();
        this.swal.toastNotification({type: 'error', content: 'Unable to Terminate Memo'});
        this.modal.close();
        this.isLoading = false;
      }
    );
  }

  pending() {
    this.isLoading = true;
    const data = {
      approve_status: 1,
      reason: this.reason
    };
    this.memoService.updateMemoReal(this.memo.id, data).subscribe(
      (res: any) => {
        this.loadMemoData();
        this.actionChanged.emit(this.toggleSideMenu);
        this.modal.close();
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        this.isLoading = false;
        this.notification.updateNotificationCount();
      },
      error => {
        this.loadMemoData();
        this.modal.close();
        this.isLoading = false;
      }
    );
  }

  submit() {
    switch (this.actionType) {
      case 'approve':
        this.approve();
        break;
      case 'reject':
        this.reject();
        break;
      case 'terminate':
        this.terminate();
        break;
      default:
        break;
    }
  }

  changeLoading(event) {
    if (event.total) {
      this.pdfIsloading = false;
    }
  }


  onSave(event) {
    const data = new FormData();
    data.append('customer_signature', event);

    this.memoService.updateMemoReal(this.memo.id, data).subscribe(
      res => {
        const params = new HttpParams().set('memo_id', this.memo.id);
        // this.memoService.renderPDF(params).subscribe();
        setTimeout(() => {
          this.actionChanged.emit(res);
        }, 500);
      }
    );
    this.modal.close();
  }

  openFileList(fileList) {
    this.modal = this.modalService.open(fileList);
  }

  openFile(file) {
    window.open(file.file, '_blank');
  }

  closeModal() {
    this.webcamImage = null;
  }

  loadAttachmentList() {
    this.showResults = false;
    const params = new HttpParams()
      .set('page', this.currentPage.toString())
      .set('memo', this.memo.id);
    this.memoService.getMemoAttachment(params).subscribe(
      (attachments: any) => {
        this.attachmentList = attachments.results;
        this.showResults = true;
        this.count = attachments.count;
      },
      error => {
        this.notification.showNotification(error.status);
        this.showResults = true;
      }
    );
  }


  selectView(view) {
    this.viewType = view;
    this.updateMemoIsRead(view);
  }

  updateMemoIsRead(view) {
    if (view === 0) {
    } else if (view === 1) { // comment
      this.memo.unread_comment_count = 0;
    } else if (view === 2) { // attachment
      this.memo.unread_attachment_count = 0;
    }
  }

  // handleImage(webcamImage: WebcamImage) {
  // this.webcamImage = webcamImage;
  // if (this.front) {
  //   this.frontCapture = webcamImage;
  // } else {
  //   this.backCapture = webcamImage;
  // }
  // this.toggleCamera = !this.toggleCamera;
  // this.modal.close();
  // }
  onOtpChange(event) {
    if (event.length === 6) {
      this.otp = event;
    }
  }
  closeToggle() {
    const toggleSideMenu = true;
     this.actionChanged.emit(toggleSideMenu);
  }

  openSignSignature(signSignature) {
    this.modal.close();
    this.modal = this.modalService.open(signSignature, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true
    });
  }

  async saveSignature() {
    if (await this.signatureService.checkOnlyWhiteBackground(this.blob_output)) {
      this.swal.toastNotification({type: 'error', content: this.translate.instant('MEMOS.EMPTY-SIGNATURE')});
      return;
    }

    this.signature = new File([this.blob_output], 'online-sign.png', {type: this.blob_output.type});
    this.approve();
    this.modalService.dismissAll();
  }

  onClearCanvas() {
    this.canvasWhiteboard.clearCanvas();
  }

  onUndoCanvas() {
    this.canvasWhiteboard.undo();
  }

  onClickColorPicker() {
    this.showColorPicker = !this.showColorPicker;
  }

  onSaveCanvas() {
    this.canvasWhiteboard.saveLocal();
  }

  onSelectedColor(color: string) {
    this.selectedColor = color;
  }

  imageCropped(output) {
    this.croppedImage = output.base64;
    this.blob_output = output.file;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.errorMsg = 'APPROVAL.ERROR-UNABLE-UPLOAD';
    // show message
  }

  fileChangeEvent(file: any): void {
    this.errorMsg = '';
    this.imageChangedEvent = file;
    this.file_input = file.target.files[0];
  }

  clearSignature() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.file_input = '';
    this.blob_output = '';
    this.signImageBlob = null;
  }

  onSaveCanvasWhiteboard(event) {
    this.canvasWhiteboard.generateCanvasBlob((blob) => {
      this.blob_output = blob;
      this.signImageBlob = blob;
    });
  }

}
