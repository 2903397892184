import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../user/user.service';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {MemoService} from 'src/app/modules/memos/memo/memo.service';
import {ThemeService} from '../../../../shared/services/theme.service';
import {Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TitleService} from '../../../../shared/services/title-service';
import {Subscription} from 'rxjs';
import {environment} from '../../../../../environments/environment';


const ADMIN = 0;
const SUPER_ADMIN = 5;

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss']
})
export class ProfileListComponent implements OnInit, OnDestroy {
  userList = [];
  roleList = [];
  userRole: string;
  count = 0;
  currentPage = 1;
  isAdmin = false;
  showResults = false;
  toggleSideMenu = true;
  searchValue = '';
  selectedUser: any;
  isAddUser = false;
  departmentList = [];
  isToggleEdit = false;
  departmentSearchName = [];
  departmentName: string;
  jobPositionSearchName = [];
  jobPositionName: string;
  searchText: string;
  companyNameList;
  companyName: string;
  subscriptions = new Subscription();

  constructor(
    private userService: UserService,
    private memoService: MemoService,
    private swal: SweetAlertService,
    private dropdownConfig: NgbDropdownConfig,
    private themeService: ThemeService,
    private router: Router,
    private translate: TranslateService,
    private titleService: TitleService
  ) {
    dropdownConfig.placement = 'bottom-right';
    dropdownConfig.autoClose = false;
  }

  ngOnInit() {
    this.initialCompanyNameList();
    this.titleService.setTitle(this.translate.instant('CREATE-USER.CRATE-USER'));
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.titleService.setTitle(this.translate.instant('CREATE-USER.CRATE-USER'));
    });
    this.subscriptions.add(translateSub);
    this.loadRole();
    this.getUserList();
    this.loadDepartment();
    this.loadJobPosition();
    this.isAdmin = localStorage.getItem('role_name') === 'Admin';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    localStorage.removeItem('canCreateUser');
  }

  initialCompanyNameList() {
    if (environment.web_instance === 'BGC') {
      this.companyNameList = [
        {id: 1, name: 'BGC'},
        {id: 2, name: 'BGCG'},
        {id: 3, name: 'PGI'},
        {id: 4, name: 'AGI'},
        {id: 5, name: 'PTI'},
        {id: 6, name: 'RBI'},
        {id: 7, name: 'AY1'},
        {id: 8, name: 'AY2'},
        {id: 9, name: 'BVP'},
        {id: 10, name: 'PRM'},
        {id: 11, name: 'BGCP'},
      ];
    } else if (environment.web_instance === 'BG') {
      this.companyNameList = [
        {id: 1, name: 'BG'},
        {id: 2, name: 'BGA'},
        {id: 3, name: 'BGPU'},
        {id: 4, name: 'KBI'},
        {id: 6, name: 'BGE'},
      ];
    }
  }

  isMasterAdmin(): boolean {
    return localStorage.getItem('role_name') === 'Master Admin';
  }

  getUserList() {
    this.showResults = false;
    this.userList = [];
    this.count = 0;
    let params = new HttpParams()
      .set('search', this.searchValue || '')
      .set('page', this.currentPage.toString());
    // .set('none_pagination', 'True');
    if (this.departmentName) {
      params = params.set('department', this.departmentName.toString());
    }
    if (this.userRole || this.userRole === '0') {
      params = params.set('role', this.userRole.toString());
    }
    if (this.jobPositionName) {
      params = params.set('job_position', this.jobPositionName);
    }
    if (this.companyName) {
      params = params.set('company', this.companyName);
      this.loadDepartment(this.companyName);
    }

    this.userService.getUserList(params).subscribe(
      (response: any) => {

        if (!this.isMasterAdmin()) {
          this.userList = response.results.filter(
            user => !([ADMIN, SUPER_ADMIN].includes(user.role))
          );
          // this.count = this.userList.length;
          this.count = response.count;
        } else {
          this.userList = response.results.filter(
            user => !([SUPER_ADMIN].includes(user.role))
          );
          // this.count = this.userList.length;
          this.count = response.count;
        }
        this.showResults = true;
      },
      error => {
        this.showResults = true;
        this.swal.toastNotification({type: 'error', content: 'Unable to load user list'});
      }
    );
  }

  loadJobPosition() {
    const params = new HttpParams()
      .set('type', 'job_profile');
    this.memoService.getDropDown(params).subscribe((users: any) => {
        this.jobPositionSearchName = users.job_profile;
      },
      error => {
        this.showResults = true;
        this.swal.toastNotification({type: 'error', content: 'Unable to load job position'});
      }
    );
  }

  loadRole() {
    this.roleList = [
      // {
      //   id: 0,
      //   name: 'Admin'
      // },
      {
        id: 1,
        name: 'General'
      },
      {
        id: 4,
        name: 'IT'
      },
    ];
  }

  loadDepartment(company?) {
    // if (company === undefined) {
    //   company = '';
    // }
    const params = new HttpParams()
      .set('type', 'department')
      .set('is_unique', 'True');
    this.memoService.getDropDown(params).subscribe((department: any) => {
        this.departmentSearchName = department.department;
      },
      error => {
        this.swal.toastNotification({type: 'error', content: 'Unable to load department'});
      }
    );
  }

  changePage() {
    this.getUserList();
  }

  clear() {
    this.searchValue = '';
    this.userRole = '';
    this.getUserList();
  }

  search() {
    this.currentPage = 1;
    this.getUserList();
  }

  onFilterChange(searchValue) {
    this.searchValue = searchValue;
    this.currentPage = 1;
    this.getUserList();
  }

  onFilterRole() {
    this.currentPage = 1;
    this.getUserList();
  }

  onFilterDepartment(event) {
    this.departmentName = event && event['id'];
    this.currentPage = 1;
    this.getUserList();
  }


  onFilterJobPosition(role) {
    this.currentPage = 1;
    this.getUserList();
  }

  onFilterCompany() {
    this.currentPage = 1;
    this.getUserList();
  }


  clickuser(user) {
    const isSmallScreen = this.themeService.isMobile();
    if (isSmallScreen) {
      this.toggleSideMenu = false;
    }
    this.isToggleEdit = false;
    this.selectedUser = null;
    this.isAddUser = false;
    setTimeout(() => {
      this.selectedUser = user;
    }, 1);
  }

  clickAddUser() {
    const isSmallScreen = this.themeService.isMobile();
    if (isSmallScreen) {
      this.toggleSideMenu = false;
    }
    this.isToggleEdit = false;
    this.isAddUser = false;
    this.selectedUser = null;
    setTimeout(() => {
      this.isAddUser = true;
    }, 1);
  }

  checkIfSelected(user) {
    return _.get(this.selectedUser, 'id', '') === user.id;
  }

  toggleEdit(user?) {
    this.isToggleEdit = !this.isToggleEdit;
    if (user) {
      const index = this.userList.findIndex(obj => obj.id === user.id);
      this.userList[index] = user;
      this.clickuser(user);
    }
  }

  actionChange(toggleSide?, data?) {
    // const memoIndex = this.memoList.findIndex(obj => obj.id === memo.id);
    // this.memoList[memoIndex] = memo;
    // this.clickMemo(memo);
    this.toggleSideMenu = toggleSide;
    this.getUserList();
    this.selectedUser = null;
    this.isAddUser = false;
  }

  saveDelete(event) {
    this.getUserList();
  }
}
