import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { API_URL } from '../../../shared/constants/api.constant';

@Injectable()
export class LoaService {

  constructor(private http: HttpClientService) {

  }

  getLOAList(params?) {
    return this.http.get(API_URL.loa_infos, params);
  }

  createNewLOA(data) {
    return this.http.post(API_URL.loa_infos, data);
  }

  updateLOA(id, data) {
    return this.http.put(API_URL.loa_infos + id + '/', data);
  }

  removeLOA(id) {
    return this.http.delete(API_URL.loa_infos + id + '/');
  }

  getLOAData(id) {
    return this.http.get(API_URL.loa_infos + id + '/');
  }

}
