import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private setSidebar = new BehaviorSubject<boolean>(false);
  data = this.setSidebar.asObservable();
  constructor() {}
  setActiveSidebar(active: boolean) {
    this.setSidebar.next(active);
  }
}
