import {Component, OnInit, Output, EventEmitter, Input, OnDestroy} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {UserService} from '../../../permission/user/user.service';
import {LoaService} from '../loa.service';
import * as _ from 'lodash';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {MemoService} from '../../memo/memo.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {range, Subscription} from 'rxjs';
import {
  BUDGET_TYPES,
  getRangeLevelBudget,
  CompanyCode,
} from '../../../../shared/constants/helper';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-loa-create',
  templateUrl: './loa-create.component.html',
  styleUrls: ['./loa-create.component.scss']
})
export class LoaCreateComponent implements OnInit, OnDestroy {
  prepareList = [];
  prepareMGList = [];
  verifyList = [];
  approveList = [];
  acceptList = [];
  listForLOA = [];
  levels: any[] = [];
  approverList: any[] = [];
  departmentList: any[] = [];
  loaName: string = null;
  isLoading = false;
  member = [];
  memberList = [];
  listApprove = [];
  approvallistLevels = {};
  loaType = null;
  countLevel = 0;
  count_levels = 0;
  minApprove: 1;
  department: string;
  costcentre: string;
  contentSignatureRequired = [];
  isLoadmemolist = false;
  loaDepartment = [];
  currentPage = 1;
  companyList: { id: number; code_name: string }[] = [];
  wbsList: any[] = [];
  selectedCompanyId: number;
  selectedWBS: number;
  selectedLevel = 0;
  selectedBudgetType;
  costcentreList = [];
  levelList = [];
  budgetTypeList = BUDGET_TYPES;

  isCustomRange = false;
  customRange: string;

  data = {};
  officeList = [];
  groupList = [];
  selectedGroupId: string;
  selectedOfficeId: string;
  selectedYear;
  yearList = [];


  loaSignUserChoices = [
    {value: 'user', label: 'user'},
    {value: 'none user', label: 'non-user'},
  ];

  loaSignNoneUserPlaceHolder = {
    name: 'Contact Name',
    job_position: 'Job Position',
    email: 'Email Address'
  };

  subscriptions = new Subscription();

  @Output() created = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Input() type: string;
  private notification: any;

  constructor(
    private userService: UserService,
    private apiService: MemoService,
    private swal: SweetAlertService,
    private router: Router,
    private loaService: LoaService,
    private memoService: MemoService,
    public translate: TranslateService
  ) {
  }

  ngOnInit() {
    if (environment.web_instance === 'BG') {
      this.budgetTypeList = [
        ...this.budgetTypeList,
        {id: 'BGPU product sell', name: 'BGPU product sell'},
      ];
    }
    this.getCompanies();
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateSignatureRequired();
      this.initLoaSignUser();
    });
    this.subscriptions.add(translateSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  changeBudgetType() {
    const companyCode = this.getCompanyCodeNameFromId(this.selectedCompanyId);
    this.levelList = getRangeLevelBudget(this.selectedBudgetType, companyCode);
  }

  initLoaSignUser() {
    this.loaSignUserChoices[0].label = this.translate.instant('MEMOS.TYPE-USER');
    this.loaSignUserChoices[1].label = this.translate.instant('MEMOS.TYPE-NONE-USER');
    this.loaSignUserChoices = [...this.loaSignUserChoices];  // for ng-select able to detect updating
    this.loaSignNoneUserPlaceHolder.name = this.translate.instant('MEMOS.TYPE-USER-NAME');
    this.loaSignNoneUserPlaceHolder.job_position = this.translate.instant('CREATE-USER.JOB-POSITION');
    this.loaSignNoneUserPlaceHolder.email = this.translate.instant('MEMOS.TYPE-USER-EMAIL');
  }

  translateSignatureRequired() {
    for (let i = 0; i < this.contentSignatureRequired.length; i++) {
      if (this.levels[i].signature_required) {
        this.contentSignatureRequired[i].content = this.translate.instant('LOA.SIGNATURE-REQUIRE');
      } else {
        this.contentSignatureRequired[i].content = this.translate.instant('LOA.NO-SIGNATURE');
      }
    }
  }

  selectLOAType(type) {
    this.loaType = null;
    setTimeout(() => {
      this.loaType = type;
    }, 100);
  }

  getMemberList() {
    const generalRole = '1';
    const params = new HttpParams()
      .set('type', 'profile')
      .set('company', this.selectedCompanyId.toString())
      .set('role', generalRole);
    this.apiService.getDropDown(params).subscribe(data => {
      this.memberList = data.profile;
    });
    // this.userService.getProfileList(params).subscribe((members: any) => {
    //   this.memberList = members;
    // });
  }

  loadYear() {
    this.yearList = [];
    this.selectedYear = null;
    const params = new HttpParams()
      .set('company_id', (this.selectedCompanyId || '').toString());
    this.apiService.getWBSYearList(params).subscribe((year) => {
      this.yearList = year;
      this.selectedYear = this.yearList.length > 0 ? this.yearList[this.yearList.length - 1].year : null;
      if (this.selectedYear) {
        this.getWBS();
      }
    });
  }

  getWBS() {
    this.wbsList = [];
    this.costcentre = null;
    const params = new HttpParams()
      .set('company_id', (this.selectedCompanyId || '').toString())
      .set('year', this.selectedYear.toString())
      .set('none_pagination', 'True');
    this.apiService.getWBSBudgetDashboardList(params).subscribe(
      (wbs: any) => {
        this.wbsList = wbs;
      },
      error => this.notification.showNotification(error.status)
    );
  }

  getCompanies() {
    this.isLoadmemolist = false;
    this.apiService.getCompanies()
      .pipe(
        map((companies: any[]) => {
          return companies.map(company => {
            return {id: company.id, code_name: company.code_name};
          });
        })
      )
      .subscribe((companies) => {
        this.companyList = companies;
        this.isLoadmemolist = true;
        // this.selectedCompanyId = this.companyList.length > 0 ? this.companyList[1].id : undefined; // companyList[0] is default_company
        // // this.onSelected = 'by-department'; wait budget create loa button send type
      });
  }

  selectCompany() {
    this.departmentList = [];
    this.wbsList = [];
    if (this.type === 'department') {
      this.clearOffice();
      this.getOffice();
    } else if (this.type === 'budget') {
      this.changeBudgetType();
      this.loadYear();
    }
    this.getMemberList();
  }

  getCompanyCodeNameFromId(id: number) {
    const company = this.companyList.find(c => c.id === id);
    if (!company) {
      return;
    }
    return company.code_name as CompanyCode;
  }

  getOffice() {
    this.officeList = [];
    this.selectedOfficeId = null;
    const params = new HttpParams()
      .set('company_id', (this.selectedCompanyId || '').toString());
    this.memoService.getOfficeList(params).subscribe(
      (office: any) => {
        this.officeList = office.map(item => {
          return {
            name: (item.code_number as string) + ' - ' + (item.name),
            id: item.id,
          };
        });
      },
      error => this.notification.showNotification(error.status)
    );
  }

  selectOffice() {
    this.clearGroup();
    this.selectedGroupId = null;
    const params = new HttpParams()
      .set('office_id', (this.selectedOfficeId || '').toString());
    this.memoService.getGroupList(params).subscribe(
      (group: any) => {
        this.groupList = group.map(item => {
          return {
            name: (item.code_number as string) + ' - ' + (item.name),
            id: item.id,
          };
        });
      },
      error => this.notification.showNotification(error.status)
    );
  }

  getDepartmentList() {
    this.departmentList = [];
    this.department = null;
    const params = new HttpParams()
      .set('group_id', (this.selectedGroupId || '').toString());
    this.memoService.getDepartmentByGroupList(params).subscribe(
      (departments: any) => {
        this.departmentList = departments.map(item => {
          return {
            name: (item.code_number as string) + ' - ' + (item.name),
            id: item.id,
          };
        });
      },
      error => this.notification.showNotification(error.status)
    );
  }

  clear() {
    this.member = [];
  }

  checkSignature() {
    const filteredSequence = this.levels.filter(sequence => !sequence.signature);
    return filteredSequence.length !== 0;
  }

  removeParent(index) {
    this.levels.splice(index, 1);
  }

  addApprovalLevel() {
    if (!this.selectedCompanyId) {
      this.swal.toastNotification({type: 'error', content: 'Please select company'});
      return;
    }
    if (this.memberList.length === 0) {
      this.swal.toastNotification({type: 'info', content: 'Please wait loading member list'});
      return;
    }
    this.contentSignatureRequired.push({
      content: this.translate.instant('LOA.SIGNATURE-REQUIRE'),
    });
    this.countLevel += 1;
    this.minApprove = 1;
    this.levels.push({
      level: this.countLevel,
      min_approve_count: this.minApprove,
      count: 1,
      members: [{profile: null}],
      signature_required: true,
      user_type: this.loaSignUserChoices[0].value
    });
    this.approvallistLevels[this.count_levels] = _.cloneDeep(this.memberList);
    this.count_levels += 1;
  }

  addMemberToLevel(level) {
    level.count += 1;
    level.members.push({profile: null});
  }

  selectYear() {
    if (!this.selectedYear) {
      return;
    }
    this.selectedWBS = null;
    this.getWBS();
  }

  selectMember(data, res, index, i) {
    if (res.members[index].profile !== data.id) {
      this.listApprove.forEach(obj => {
        if (obj.id === res.members[index].profile) {
          this.approvallistLevels[i].push(obj);
          const remove = _.remove(this.listApprove, function (n) {
            return n.id === obj.id;
          });
        }
      });
    }
    [this.listApprove, this.approvallistLevels[i]] = _.partition(this.approvallistLevels[i], function (n) {
      return data.id === n.id;
    });
    this.approvallistLevels[i] = _.orderBy(this.approvallistLevels[i], ['full_name'], ['asc']);
    res.members[index].profile = data.id;
  }

  createLOA() {
    const msg = [];
    // if (this.loaDepartment.includes(this.loaName)) {
    //   msg.push(this.translate.instant('MEMOS.LOA-NAME-EXIST'));
    // }
    if (!this.loaName) {
      msg.push(this.translate.instant('MEMOS.LOA-NO-NAME'));
    }
    if (!this.type) {
      msg.push(this.translate.instant('MEMOS.LOA-NO-TYPE'));
    }
    if (!this.selectedBudgetType && this.type === 'budget') {
      msg.push(this.translate.instant('MEMOS.LOA-NO-BUDGET'));
    }
    if (!this.costcentre && this.type === 'budget') {
      msg.push(this.translate.instant('MEMOS.LOA-NO-COSTCENTRE'));
    }
    if (!this.department && this.type === 'department') {
      msg.push(this.translate.instant('MEMOS.LOA-NO-DEPARTMENT'));
    }
    if (this.levels.length === 0) {
      msg.push(this.translate.instant('MEMOS.LOA-NO-LEVEL'));
    }
    if (msg.length > 0) {
      this.swal.toastNotification({type: 'error', content: msg.join(', ')});
      return;
    } else {
      if (this.type === 'department') {
        this.data = {
          levels: this.levels,
          name: this.loaName,
          department: this.department
        };
      } else if (this.type === 'budget') {
        let rangeData: BudgetRange = {
            budget_min: null,
            budget_max: null,
            budget_max_unlimited: false,
            budget_equal: null,
            budget_range_text: null,
        };
        if (this.isCustomRange) {
          this.selectedLevel = null;
          try {
            rangeData = this.validateCustomRange(this.customRange);
          } catch (e) {
            this.swal.toastNotification({
              type: 'error',
              content: this.translate.instant('LOA.Custom range incorrect format'),
            });
            return;
          }
        }

        this.isLoading = true;
        this.data = {
          levels: this.levels,
          name: this.loaName,
          is_budget: true,
          budget_type: this.selectedBudgetType,
          costcentre_level: this.selectedLevel,
          costcentre: this.costcentre,
          ...rangeData,
        };
      }
      this.loaService.createNewLOA(this.data).subscribe(
        (loa) => {
          this.levels = [];
          this.loaName = null;
          this.isLoading = false;
          this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
          this.created.emit(loa);
        },
        error => {
          this.isLoading = false;
          this.swal.toastNotification({type: 'error', content: 'Unable to create LOA'});
        }
      );
    }
  }

  close() {
    this.closed.emit();
  }

  removeApprover(data, index, keep, levelIndex) {
    keep.count -= 1;
    data.splice(index, 1);
    this.guardRangeApproveCount(levelIndex);
  }

  removeLevel(data, index) {
    data.splice(index, 1);
    this.contentSignatureRequired.splice(index, 1);
  }

  checkApproval(index) {
    const checkApproval = this.levels[index].signature_required;

    if (checkApproval) {
      this.contentSignatureRequired[index].content = this.translate.instant('LOA.SIGNATURE-REQUIRE');
    } else {
      this.contentSignatureRequired[index].content = this.translate.instant('LOA.NO-SIGNATURE');
    }
  }

  guardRangeApproveCount(index: number) {
    const currentCount = this.levels[index].min_approve_count;
    const maxCount = this.levels[index].count;
    if (currentCount > maxCount) {
      this.levels[index].min_approve_count = maxCount;
    } else if (currentCount < 1) {
      this.levels[index].min_approve_count = 1;
    }
  }


  onChangeLoaUserType(level) {
    level.members = [];
    level.count = 0;
    this.addMemberToLevel(level);
  }

  onInputNoneUserJobPosition() {
    // TODO: do something on input
  }

  checkDepartment(val) {
    // this.loaDepartment = [];
    // if (val) {
    //   val.loa.forEach(obj => {
    //     this.loaDepartment.push(obj.name);
    //   });
    // }
  }

  changeWBS() {
    this.costcentreList = [];
    this.costcentre = null;
    const params = new HttpParams()
      .set('wbs_id', (this.selectedWBS || '').toString());
    this.memoService.getCostCentreList(params).subscribe(
      (costcentres: any) => {
        this.costcentreList = costcentres.results;
      },
      error => this.notification.showNotification(error.status)
    );
  }

  clearWBS() {
    this.selectedWBS = null;
    this.costcentreList = [];
    this.costcentre = null;
  }

  clearCostcentre() {
    this.costcentre = null;
  }

  clearCompany() {
    this.clearOffice();
    this.wbsList = [];
    this.departmentList = [];
    this.yearList = [];
    this.department = null;
    this.costcentre = null;
    this.selectedBudgetType = null;
    this.selectedYear = null;
    this.clearWBS();
    this.clearCostcentre();
  }

  clearOffice() {
    this.officeList = [];
    this.selectedOfficeId = null;
    this.groupList = [];
    this.selectedGroupId = null;
    this.departmentList = [];
    this.department = null;
  }

  clearGroup() {
    this.groupList = [];
    this.selectedGroupId = null;
    this.departmentList = [];
    this.department = null;
  }

  onSelectRangeLevel(val) {
    this.selectedLevel = val;
    if (this.selectedLevel) {
      this.isCustomRange = false;
      this.customRange = null;
    }
  }

  onToggleCustomRange() {
    this.isCustomRange = !this.isCustomRange;
    if (this.isCustomRange) {
      this.selectedLevel = null;
      this.customRange = null;
    }
  }

  validateCustomRange(label: string): BudgetRange {
    label = label.trim().split(',').join('');
    if (!label) {
      throw new Error();
    }

    if (label.startsWith('>')) {
      label = label.replace('>', '').trim();
      const v = Number(label);
      if (isNaN(v)) {
        throw new Error();
      }
      return {
        budget_min: v,
        budget_max: null,
        budget_max_unlimited: true,
        budget_equal: null,
        budget_range_text: `> ${this.formatBudgetNumber(v)}`,
      };
    }

    const splitLabel = label.split('-');
    if (splitLabel.length === 1) {
      const equalV = Number(splitLabel[0].trim());
      if (isNaN(equalV)) {
        throw new Error();
      }
      return {
        budget_min: null,
        budget_max: null,
        budget_max_unlimited: false,
        budget_equal: equalV,
        budget_range_text: this.formatBudgetNumber(equalV),
      };
    }

    const minV = Number(splitLabel[0].trim());
    if (isNaN(minV)) {
      throw new Error();
    }
    const maxV = Number(splitLabel[1].trim());
    if (isNaN(maxV)) {
      throw new Error();
    }
    return {
      budget_min: minV,
      budget_max: maxV,
      budget_max_unlimited: false,
      budget_equal: null,
      budget_range_text: `${this.formatBudgetNumber(minV)} - ${this.formatBudgetNumber(maxV)}`,
    };
  }

  formatBudgetNumber(number) {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(number);
  }
}

interface BudgetRange {
  budget_min: number | null;
  budget_max: number | null;
  budget_max_unlimited: boolean | null;
  budget_equal: number | null;
  budget_range_text: string | null;
}
