import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgOtpInputModule} from 'ng-otp-input';
import {CanvasWhiteboardModule} from 'ng2-canvas-whiteboard';
import {ImageCropperModule} from 'ngx-image-cropper';

import { ApprovalRoutingModule } from './approval-routing.module';
import { ApprovalDetailComponent } from './approval-detail/approval-detail.component';
import { CoreModule } from '../../core/core.module';
import { ApproveService } from './approve.service';
import {ApprovalCommentComponent, HighlightCommentApproval} from './approval-comment/approval-comment.component';
import { ApprovalAttachmentComponent } from './approval-attachment/approval-attachment.component';
import {MemoModule} from '../memos/memo/memo.module';
import {ExecutiveModule} from '../executive/executive.module';
import {ColorPickerModule, SidebarModule} from 'primeng/primeng';
import {SignatureService} from '../../shared/services/signature.service';

@NgModule({
  imports: [
    CommonModule,
    ApprovalRoutingModule,
    CoreModule,
    PdfViewerModule,
    FormsModule,
    MemoModule,
    TranslateModule,
    ExecutiveModule,
    NgOtpInputModule,
    CanvasWhiteboardModule,
    ImageCropperModule,
    SidebarModule,
    ColorPickerModule
  ],
  declarations: [
    ApprovalDetailComponent,
    ApprovalCommentComponent,
    ApprovalAttachmentComponent,
    HighlightCommentApproval
  ],
  providers: [ApproveService, SignatureService]
})
export class ApprovalModule {
}
