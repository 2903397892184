import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }
    return _.filter(items, item => {
      return item.full_name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1;
    });
  }
}
