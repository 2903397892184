import {MessageService} from 'primeng/components/common/messageservice';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {ApproveService} from '../approve.service';
import {MemoService} from '../../memos/memo/memo.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {WebcamImage} from 'ngx-webcam';
import {UserProfileService} from '../../user-profile/user-profile.service';
import {CanvasWhiteboardComponent} from 'ng2-canvas-whiteboard';
import {ProfileService} from '../../../shared/services/profile-service';
import {SweetAlertService} from '../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {ProjectService} from '../../../project.service';
import { SidebarService } from 'src/app/core/sidebar/sidebar.service';
import {SignatureService} from '../../../shared/services/signature.service';

@Component({
  selector: 'app-approval-detail',
  templateUrl: './approval-detail.component.html',
  styleUrls: ['./approval-detail.component.scss']
})
export class ApprovalDetailComponent implements OnInit, AfterViewChecked {
  document: any;
  sequenceList = [];
  confirmType: string;
  JWTKey: string;
  profile_id: number;
  profile: any;
  oldFileList = [];
  pdfFile = '';
  modal: NgbModalRef;
  confirmModal: NgbModalRef;
  commentList = [];
  newComment: string;
  isSaved = false;
  reason = '';
  loaList = [];
  isEasyReading = false;
  showMoreInformation = false;
  isLoadingPDF = false;
  pdfIsloading = true;
  webcamImage: WebcamImage = null;
  otp: any;

  viewType = 0;
  enableComment = false;
  noneUser = false;
  signatureRequire = true;

  signImageBlob: any = null;
  blob_output: any = '';
  croppedImage: any = '';
  imageChangedEvent: any = '';
  errorMsg: any = '';
  file_input: any = '';
  signature: any;

  signatureColorList = [
    '#000000',
    '#4169e1',
    '#EB2B36',
    '#266D1F'
  ];
  selectedColor = this.signatureColorList[0];
  showColorPicker = false;

  openFingerPrint = false;
  checkClickFingerPrint = false;

  userSignNow = false;

  @ViewChild('scrollMe', { static: false }) myScrollContainer: ElementRef;
  @ViewChild('canvasWhiteboard', { static: false }) canvasWhiteboard: CanvasWhiteboardComponent;
  @ViewChild('signSignature', { static: false }) signSignature: ElementRef;
  @ViewChild('checkOpenSidebar', { static: false }) checkOpenSidebar: ElementRef;
  @ViewChild('closeSidebarAtApprove', { static: false }) closeSidebarAtApprove: ElementRef;
  @ViewChild('closeSidebarAtReject', { static: false }) closeSidebarAtReject: ElementRef;
  @ViewChild('closeSidebarAtTerminate', { static: false }) closeSidebarAtTerminate: ElementRef;
  @ViewChild('closeSidebarAtApproveNoneUser', { static: false }) closeSidebarAtApproveNoneUser: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private memoService: MemoService,
    private router: Router,
    private approveService: ApproveService,
    private messageService: MessageService,
    private userProfileService: UserProfileService,
    public profileService: ProfileService,
    private swal: SweetAlertService,
    private translate: TranslateService,
    private renderer: Renderer2,
    private projectService: ProjectService,
    private sidebarService: SidebarService,
    private signatureService: SignatureService
  ) {
    this.sidebarService.setActiveSidebar(false);
     this.renderer.listen('window', 'click', (e: Event) => {
       if (this.checkOpenSidebar) {
         if (e.target === this.checkOpenSidebar.nativeElement) {
          this.checkClickFingerPrint = true;
          return ;
        }
       }
        if (this.checkClickFingerPrint) {
          if (this.closeSidebarAtApprove) {
            if (e.target !== this.closeSidebarAtApprove.nativeElement && e.target !== this.closeSidebarAtReject.nativeElement &&
              e.target !== this.closeSidebarAtTerminate.nativeElement) {
            this.openFingerPrint = false;
        }
          } else {
           if (e.target !== this.closeSidebarAtApproveNoneUser.nativeElement && e.target !== this.closeSidebarAtReject.nativeElement &&
              e.target !== this.closeSidebarAtTerminate.nativeElement) {
            this.openFingerPrint = false;
        }
          }
      }
  });
  }
  ngOnInit() {
    this.reason = '';
    this.loadMemoData();
    this.scrollToBottom();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  loadMemoData() {
    this.activatedRoute.params.subscribe(params => {
      this.JWTKey = params['id'];
      if (params.id) {
        const header = new HttpHeaders().set('Authorization', params.id);
        this.approveService.getMemoDetail(header).subscribe(
          (data: any) => {
            this.profile_id = data.profile_id;
            this.document = data;
            this.previewData(data);
            this.reformatData(data.loa);
            this.loadUserProfileData();
            this.signatureRequire = data.current_level_signature_require;
          },
          error => {
            this.router.navigate(['/expire']);
          }
        );
      }
    });
  }

  loadUserProfileData() {
    const header = new HttpHeaders().set('Authorization', this.JWTKey);
    this.userProfileService.getProfileDataById(this.profile_id, header).subscribe(
      profileRes => {
        this.profile = profileRes;
        this.enableComment = !this.profile.is_none_user;
        this.noneUser = this.profile.is_none_user;
      }
    );
  }

  reformatData(loa) {
    this.sequenceList = _.orderBy(loa, 'sequence', 'desc');
  }

  approve() {
    this.isSaved = true;
    let data: any = {
      memo_id: this.document.id,
      approve_status: true,
      reason: this.reason,
      otp: this.otp
    };
    if (this.userSignNow) {
      const fd = new FormData();
      fd.append('data', JSON.stringify(data));
      fd.append('one_time_signature', this.signature);
      data = fd;
    }
    const header = new HttpHeaders().set('Authorization', this.JWTKey);
    this.approveService.updateMemo(header, data).subscribe(
      (res: any) => {
        this.document = res;
        this.loadMemoData();
        this.checkClickFingerPrint = true;
        this.openFingerPrint = false;
        this.isSaved = false;
        this.pdfIsloading = false;
        this.isLoadingPDF = true;
        this.userSignNow = false;
        if (this.confirmModal) {
          this.confirmModal.close();
        }
        this.clearSignature();
        if (this.modal) {
          this.modal.close();
        }
        this.modalService.dismissAll();
      },
      error => {
        this.isSaved = false;
        this.confirmModal.close();
        this.loadMemoData();
        this.messageService.add({severity: 'error', summary: 'ผิดพลาด', detail: 'พบข้อผิดพลาด'});
      }
    );
  }

  terminate() {
    this.isSaved = true;
    const data = {
      memo_id: this.document.id,
      approve_status: true,
      is_terminated: true,
      reason: this.reason
    };
    const header = new HttpHeaders().set('Authorization', this.JWTKey);
    this.approveService.updateMemo(header, data).subscribe(
      (res: any) => {
        this.document = res;
        this.loadMemoData();
        this.checkClickFingerPrint = true;
        this.openFingerPrint = false;
        this.isSaved = false;
        this.confirmModal.close();
      },
      error => {
        this.isSaved = false;
        this.loadMemoData();
        this.confirmModal.close();
        this.messageService.add({ severity: 'error', summary: 'ผิดพลาด', detail: 'พบข้อผิดพลาด' });
      }
    );
  }

  reject() {
    this.isSaved = true;
    const data = {
      memo_id: this.document.id,
      approve_status: false,
      reason: this.reason
    };
    const header = new HttpHeaders().set('Authorization', this.JWTKey);
    this.approveService.updateMemo(header, data).subscribe(
      (res: any) => {
        this.document = res;
        this.loadMemoData();
        this.checkClickFingerPrint = true;
        this.openFingerPrint = false;
        this.isSaved = false;
        this.confirmModal.close();
      },
      error => {
        this.isSaved = false;
        this.loadMemoData();
        this.confirmModal.close();
        this.messageService.add({ severity: 'error', summary: 'ผิดพลาด', detail: 'พบข้อผิดพลาด' });
      }
    );
  }

  previewData(data) {
    this.pdfFile = data.signed_document;
    this.isLoadingPDF = false;
    setTimeout(() => {
      this.isLoadingPDF = true;
    }, 1);
  }

  open(content) {
    this.webcamImage = null;
    this.modal = this.modalService.open(content, { size: 'lg' });
  }

  openConfirmModal(content, type) {
    this.checkClickFingerPrint = false;
    this.confirmType = type;
    this.reason = null;
    this.confirmModal = this.modalService.open(content, {centered: true});
  }

  openSelectSignMethodModal(content, type) {
    this.checkClickFingerPrint = false;
    this.confirmType = type;
    this.reason = null;
    this.modal = this.modalService.open(content, {centered: true});
  }

  confirm() {
    switch (this.confirmType) {
      case 'approve':
        this.approve();
        break;
      case 'reject':
        this.reject();
        break;
      case 'terminate':
        this.terminate();
        break;
      default:
        break;
    }
  }

  switchMode(type) {
    this.isEasyReading = type === 'easyReading';
  }

  toggleMoreInformation() {
    this.showMoreInformation = !this.showMoreInformation;
  }

  scrollToBottom() {
    if (this.myScrollContainer) {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    }
  }

  selectView(type) {
    this.viewType = type;
    this.updateMemoIsRead(type);
  }

  updateMemoIsRead(view) {
    if (view === 0) {
    } else if (view === 1) { // comment
      this.document.unread_comment_count = 0;
    } else if (view === 2) { // attachment
      this.document.unread_attachment_count = 0;
    }
  }

  changeLoading(event) {
    if (event.total) {
      this.pdfIsloading = false;
    }
  }
  openPrint(file) {
    this.projectService.printFile(file.signed_document).subscribe(pdfFile => {
        const blob: any = new Blob([pdfFile.body], {type: 'application/pdf'});
        const link: any = document.createElement('iframe');
        const blobURl = URL.createObjectURL(blob);
        link.style.display = 'none';
        link.src = blobURl;
        document.body.appendChild(link);
        link.focus();
        link.contentWindow.print();
      },
      error => {
        this.swal.toastNotification({type: 'error', content: error.status});
      }
    );
  }
  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    // if (this.front) {
    //   this.frontCapture = webcamImage;
    // } else {
    //   this.backCapture = webcamImage;
    // }
    // this.toggleCamera = !this.toggleCamera;
    // this.modal.close();
  }
  onOtpChange(event) {
    if (event.length === 6) {
      this.otp = event;
    }
  }

  onClearCanvas() {
    this.canvasWhiteboard.clearCanvas();
  }

  onUndoCanvas() {
    this.canvasWhiteboard.undo();
  }

  onClickColorPicker() {
    this.showColorPicker = !this.showColorPicker;
  }

  onSaveCanvas() {
    this.canvasWhiteboard.saveLocal();
  }

  onSelectedColor(color: string) {
    this.selectedColor = color;
  }

  openSignModal(signature) {
    this.modal = this.modalService.open(signature, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

  onSave(event) {
    this.canvasWhiteboard.generateCanvasBlob((blob) => {
      this.blob_output = blob;
      this.signImageBlob = blob;
    });
  }

  imageCropped(output) {
    this.croppedImage = output.base64;
    this.blob_output = output.file;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.errorMsg = 'APPROVAL.ERROR-UNABLE-UPLOAD';
    // show message
  }

  fileChangeEvent(file: any): void {
    this.errorMsg = '';
    this.imageChangedEvent = file;
    this.file_input = file.target.files[0];
  }

  clearSignature() {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.file_input = '';
    this.blob_output = '';
    this.signImageBlob = null;
    this.userSignNow = false;
  }

  async saveSignature() {
    if (await this.signatureService.checkOnlyWhiteBackground(this.blob_output)) {
      this.swal.toastNotification({type: 'error', content: this.translate.instant('MEMOS.EMPTY-SIGNATURE')});
      return;
    }
    this.signature = new File([this.blob_output], 'online-sign.png', {type: this.blob_output.type});
    if (this.userSignNow) {
      this.approve();
    } else {
      this.updateSignature();
    }
    this.modalService.dismissAll();
  }

  updateSignature() {
    this.isSaved = true;
    this.pdfIsloading = true;
    this.isLoadingPDF = false;
    const fd = new FormData();
    const header = new HttpHeaders().set('Authorization', this.JWTKey);
    if (this.blob_output) {
      fd.append('signature', this.signature);
    }
    this.approveService.updateProfile(header, this.profile_id, fd).subscribe(
      () => {
        this.signature = null;
        this.approve();
      },
      error => this.swal.toastNotification({type: 'error', content: 'Unable to load user profile'})
    );
  }

  openSignSignature(signSignature, userSignNowMode: boolean = false) {
    this.userSignNow = userSignNowMode;
    this.checkClickFingerPrint = false;
    this.modal = this.modalService.open(signSignature, {backdrop: 'static', keyboard: false, size: 'lg', centered: true});
  }

  sidebarFinger(event?) {
    this.openFingerPrint = !this.openFingerPrint;
  }

  closeModal() {
    this.checkClickFingerPrint = true;
  }

  changePage() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.navigateToPage('/executive/list/', this.document.id);
    });
  }

  navigateToPage(url: string, id: number) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([url], {
        queryParams: {
          from_email_approval: true,
          jwt_token: this.JWTKey,
          memo_id: this.document.id,
        }
      });
    });
  }
}
