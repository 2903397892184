import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpClientService} from '../../shared/services/http-client.service';
import {API_URL} from '../../shared/constants/api.constant';

@Injectable()
export class UserProfileService {
  userProfileImageStorage = localStorage.getItem('photoUrl');
  userProfileNameStorage = localStorage.getItem('name');
  userProfileFullNameStorage = localStorage.getItem('full_name');
  userProfileJobPositionStorage = localStorage.getItem('job_position');

  constructor(private http: HttpClientService,
              private httpDefault: HttpClient) {
  }

  // PROJECTS
  getProfileDataById(id, header?) {
    if (header) {
      return this.httpDefault.get(API_URL.profiles + id + '/', {headers: header});
    }
    return this.http.get(API_URL.profiles + id + '/');
  }

  updateProfile(id, data) {
    return this.http.patch(API_URL.profiles + id + '/', data);
  }

  setUserProfileImage(profileImageUrl) {
    localStorage.setItem('photoUrl', profileImageUrl);
    this.refreshUserProfileImage();
  }

  refreshUserProfileImage() {
    this.userProfileImageStorage = localStorage.getItem('photoUrl');
  }

  // setUserProfileNameThai(profileFirstName, profileLastName) {
  //    localStorage.setItem('name_th', profileFirstName);
  //    localStorage.setItem('full_name_th', (profileFirstName + ' ' + profileLastName));
  //    this.refreshUserProfileName();
  //  }

  // setUserProfileNameEng(profileFirstName, profileLastName) {
  //   localStorage.setItem('name_en', profileFirstName);
  //   localStorage.setItem('full_name_en', (profileFirstName + ' ' + profileLastName));
  //   this.refreshUserProfileName();
  // }

  setUserName(name) {
    localStorage.setItem('name', name);
    this.userProfileNameStorage = localStorage.getItem('name');
  }

  setUserFullName(full_name) {
    localStorage.setItem('full_name', full_name);
    this.userProfileFullNameStorage = localStorage.getItem('full_name');
  }

  setUserJobPosition(job_position) {
    localStorage.setItem('job_position', job_position);
    this.userProfileJobPositionStorage = localStorage.getItem('job_position');
  }

  refreshUserProfileName() {
    this.userProfileNameStorage = localStorage.getItem('name');
    this.userProfileFullNameStorage = localStorage.getItem('full_name');
    this.userProfileJobPositionStorage = localStorage.getItem('job_position');
  }

  // switchUserProfileNameLang(short_code){
  //   switch (short_code) {
  //     case 'en':
  //       localStorage.setItem('name', localStorage.getItem('name_en'));
  //       localStorage.setItem('full_name', localStorage.getItem('full_name_en'));
  //       break;
  //     case 'th':
  //       localStorage.setItem('name', localStorage.getItem('name_th'));
  //       localStorage.setItem('full_name', localStorage.getItem('full_name_th'));
  //       break;
  //   }
  //   this.refreshUserProfileName();
  // }
}
