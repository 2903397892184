import {Injectable} from '@angular/core';
import {API_URL} from '../constants/api.constant';
import {HttpClientService} from './http-client.service';
import * as _ from 'lodash';

@Injectable()
export class ProfileService {
  public profile: {
    first_name?: string;
    last_name?: string;
    email?: string;
    id?: string | number;
    photo?: string;
    first_name_en?: string;
    phone_number_1?: string;
    phone_number_2?: string;
    department_name?: string;
  };

  constructor(private http: HttpClientService) {
    this.profile = {
      first_name: '',
      last_name: '',
      email: '',
      photo: null,
      first_name_en: ''
    };
    this.loadInputData();
  }

  loadInputData() {
    return this.http.get(API_URL.profiles + localStorage.getItem('profile_id') + '/');
  }

  getProfileDataById(id) {
    return this.http.get(API_URL.profiles + id + '/');
  }

  getProfileDocument(params) {
    return this.http.get(API_URL.profiles_files, params);
  }

  uploadProfileDocument(data) {
    return this.http.post(API_URL.profiles_files, data);
  }

  updateProfile(id, data) {
    return this.http.patch(API_URL.profiles + id + '/', data);
  }

  deleteProfileDocument(id) {
    return this.http.delete(API_URL.profiles_files + id + '/');
  }

  OpenOtp(data) {
    return this.http.post(API_URL.open_otp, data);
  }

  CloseOtp(data) {
    return this.http.post(API_URL.close_otp, data);
  }

  verifyOtp(data) {
    return this.http.post(API_URL.verify_otp, data);
  }

  setPassword(data) {
    return this.http.put(API_URL.me_set_password, data);
  }

  getLinkLineCodium() {
    return this.http.get(API_URL.link_line_codium);
  }

  getLinkLineUser() {
    return this.http.get(API_URL.link_line_user);
  }

  settingEmail(id, data) {
    return this.http.patch(API_URL.setting_email + id + '/', data);
  }

  deleteUser(data) {
    return this.http.post(API_URL.transfer_profile, data);
  }

  createDelegate(data) {
    return this.http.post(API_URL.delegate_permission, data);
  }

  updateDelegate(id, data) {
    return this.http.patch(API_URL.delegate_permission + id + '/', data);
  }

  deleteDelegate(id) {
    return this.http.delete(API_URL.delegate_permission + id + '/');
  }
}
