import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {LoaRoutingModule} from './loa-routing.module';
import {LoaCreateComponent} from './loa-create/loa-create.component';
import {CoreModule} from '../../../core/core.module';
import {LoaService} from './loa.service';
import {LoaUpdateComponent} from './loa-update/loa-update.component';
import {TranslateModule} from '@ngx-translate/core';
import {InputSwitchModule} from 'primeng/inputswitch';
import {CheckboxModule} from 'primeng/checkbox';

@NgModule({
    imports: [
        CommonModule,
        LoaRoutingModule,
        CoreModule,
        FormsModule,
        TranslateModule,
        InputSwitchModule,
        CheckboxModule
    ],
  declarations: [
    LoaCreateComponent,
    LoaUpdateComponent
  ],
  exports: [LoaCreateComponent, LoaUpdateComponent],
  providers: [LoaService]
})
export class LoaModule {
}
