import { Injectable } from '@angular/core';

import { HttpClientService } from '../../../shared/services/http-client.service';
import { API_URL } from '../../../shared/constants/api.constant';
import {Observable} from 'rxjs';

@Injectable()
export class UserService {

  constructor(private http: HttpClientService) {

  }

  getUserList(params?) {
    return this.http.get(API_URL.profiles, params);
  }

  getGuestList(params?) {
    return this.http.get(API_URL.profiles_guest, params);
  }

  postGuestList(data) {
    return this.http.post(API_URL.profiles_guest, data);
  }

  getUserPendingList(params?) {
    return this.http.get(API_URL.profiles, params);
  }

  createUser(data) {
    return this.http.post(API_URL.profiles, data);
  }

  getUserDetail(id) {
    return this.http.get(API_URL.profiles + id + '/');
  }

  updateUser(id, data) {
    return this.http.patch(API_URL.profiles + id + '/', data);
  }

  getDepartment(params?) {
    return this.http.get(API_URL.user_department, params);
  }

  getJobList(params?) {
    return this.http.get(API_URL.job_position, params);
  }

  deleteJob(id) {
    return this.http.delete(API_URL.job_position + id + '/');
  }

  createJob(data) {
    return this.http.post(API_URL.job_position, data);
  }

  updateJob(id, data) {
    return this.http.patch(API_URL.job_position + id + '/', data);
  }

  getProfileList(params?) {
    return this.http.get(API_URL.profiles, params);
  }

  updateUserActive(data) {
    return this.http.post(API_URL.profile_user_active, data);
  }

  getUserShortList(params?) {
    return this.http.get(API_URL.profiles_name, params);
  }

  searchEmployee(params): Observable<SearchEmployeeResponse> {
    return this.http.get(API_URL.search_employee, params);
  }

  getUserCompanyIds(): string[] {
    const company: string = localStorage.getItem('company_id');

    const permissionCompanies: string[] = (
      localStorage.getItem('permission_company') || ''
    ).split(',');

    return [company, ...permissionCompanies].filter((c) => !!c);
  }
}

export interface SearchEmployeeResponse {
  error?: string;
  result?: EmployeeProfile[];
}

export interface EmployeeProfile {
  codcomp: string;
  company_code: string;
  level: number;
  email: string;
  first_name: string;
  first_name_en: string;
  last_name: string;
  last_name_en: string;
  job_position: string;
  phone_number: string;
  status_employee: number;
}
