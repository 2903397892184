import { ApproveService } from '../approve.service';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
// import { ProjectService } from 'src/app/project/project.service';
import { MemoService } from '../../memos/memo/memo.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { get } from 'lodash';
import {ProjectService} from '../../../project.service';
import {ThemeService} from '../../../shared/services/theme.service';
import {SweetAlertService} from '../../../shared/services/sweet-alert.service';

@Component({
  selector: 'app-approval-attachment',
  templateUrl: './approval-attachment.component.html',
  styleUrls: ['./approval-attachment.component.scss']
})
export class ApprovalAttachmentComponent implements OnInit {
  attachmentList = [];
  showPreview = false;
  documentData: any;
  showResults = false;
  isLoading = false;
  count = 0;
  documentName = '';
  currentPage = 1;
  isSmallScreen: any;
  @Input() memo: any;
  @Input() memoID: any;
  @ViewChild('attachModal', { static: false }) attachModal: any;
  @Output() countAttachment = new EventEmitter();

  constructor(
    private notification: NotificationService,
    private projectService: ProjectService,
    private approveService: ApproveService,
    private memoService: MemoService,
    private themeService: ThemeService,
    private swal: SweetAlertService
  ) {}

  ngOnInit() {
    this.loadAttachmentList();
  }

  loadAttachmentList() {
    this.isSmallScreen = this.themeService.isMobile();
    this.showResults = false;
    const header = new HttpHeaders().set('Authorization', this.memoID);
    const params = new HttpParams()
      .set('page', this.currentPage.toString())
      .set('memo', this.memo.id);
    this.approveService.getAttachmentList(header, params).subscribe(
      (attachments: any) => {
        this.showResults = true;
        this.count = attachments.count;
        this.countAttachment.emit(attachments.count);
        this.attachmentList = attachments.results.map(attachment => {
          if (attachment.file_size >= 1048567) {
            attachment.file_size = (attachment.file_size / (1024 * 1024)).toFixed(1) + 'MB';
            return attachment;
          } else {
            attachment.file_size = (attachment.file_size / 1024).toFixed(1)  + 'KB';
            return attachment;
          }
        });
      },
      error => {
        this.notification.showNotification(error.status);
        this.showResults = true;
      }
    );
  }

  changePage() {
    this.loadAttachmentList();
  }

  getExtention(file) {
    return file.substr(file.lastIndexOf('.') + 1);
  }

  closePreview() {
    this.showPreview = false;
  }

  preview(file) {
    if (this.isSmallScreen) {
      window.open(file.file);
    } else {
      this.documentData = file.file;
      this.documentName = file.filename;
      this.showPreview = true;
    }
  }

  openAttachmentModal() {
    this.attachModal.openFromOutside();
  }

  checkIfRemovable() {
    return Number(localStorage.getItem('user_id')) === get(this.memo, 'created_user', '');
  }

  deleteAttachmentFile(data) {
    this.memoService
      .removeMemoAttachment(data.data.id)
      .subscribe(
        () => this.loadAttachmentList(),
        error => this.notification.showNotification(error.status)
      );
  }

  downloadDocument(attachment) {
    this.documentName = attachment.file_name;
    this.projectService.downloadFile(attachment.file).subscribe(
      res => {
        const blob: any = new Blob([res.body]);
        const link: any = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = attachment.filename;
        // console.log(link);
        link.click();
      },
      error => {
        this.notification.showNotification(error.status);
      }
    );
  }
  openPrint(attachment) {
   this.projectService.printFile(attachment.file).subscribe(
      res => {
        const blob: any = new Blob([res.body], {type: 'application/pdf'});
        const link: any = document.createElement('iframe');
        const blobURl = URL.createObjectURL(blob);
        link.style.display = 'none';
        link.src = blobURl;
        document.body.appendChild(link);
        link.focus();
        link.contentWindow.print();
      },
      error => {
        this.swal.toastNotification({type: 'error', content: error.status});
      }
    );
  }
}
