import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Sidebar} from 'primeng/primeng';
import {SidebarService} from './core/sidebar/sidebar.service';
import {Observable} from 'rxjs';
import {AuthenticationService} from './shared/services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivateChild {
  constructor(private router: Router,
              private sidebarService: SidebarService,
              private authenticationService: AuthenticationService) {

  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return new Observable<boolean>(obs => {

      const fromEmailApproval = route.queryParams['from_email_approval'] || false;
      const fromBGBGCInstance = route.queryParams['from_bgbgc_instance'] || false;

      // case redirect from email approval to executive/list/?memo={id}
      if (fromEmailApproval) {
        const jwt_token = route.queryParams['jwt_token'] || null;
        const memoId = route.queryParams['memo_id'] || null;

        this.authenticationService.loginFromEmailApproval(jwt_token).subscribe(
          (res: any) => {
            this.authenticationService.clearStorage();
            this.authenticationService.setProfileInLocalStorage(res);
            const toUrl = this.removeParamsFromUrl(state.url) + '/' + memoId;
            localStorage.setItem('toUrl', toUrl);
            return obs.next(true);
          },
          (error) => {
            return obs.next(false);
          }
        );

      } else if (fromBGBGCInstance) {
        const urlEncryptToken = route.queryParams['jwt_token'] || null;
        const data = JSON.parse(decodeURIComponent(urlEncryptToken));
        this.authenticationService.clearStorage();
        this.authenticationService.setProfileInLocalStorage(data);
        localStorage.setItem('from_bgbgc_instance', fromBGBGCInstance);
        return obs.next(true);

      } else {
        if (localStorage.getItem('currentUser') && localStorage.getItem('role_name') !== 'Guest') {
          this.sidebarService.setActiveSidebar(true);
          return obs.next(true);
        }
      }

      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      return obs.next(false);
    });

  }

  removeParamsFromUrl(url: string) {
    return url.split('?')[0];
  }
}
