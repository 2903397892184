import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SweetAlertService } from '../../../shared/services/sweet-alert.service';
import {
  Component,
  ElementRef, OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SubSink } from 'subsink';
import { FeedService } from '../feed.service';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { NotificationService } from '../../../shared/services/notification.service';
import { Subject } from 'rxjs/Subject';
import { UserService } from '../../permission/user/user.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from '../../memos/memo/memo.service';
import { TitleService } from '../../../shared/services/title-service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NewsFeedComment, NewsFeedPost } from '../feed.models';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../shared/services/authentication.service';

@Pipe({
  name: 'highlight',
})
export class HighlightSearch implements PipeTransform {
  transform(text: string, search: any) {
    // with regex u flag , \u{0E01}-\u{0E5B} stand for thai languages in unicode format , it mean ก-๙
    const regex = /(?:@)([\u{0E01}-\u{0E5B}a-zA-Z\d]+)(\s)([\u{0E01}-\u{0E5B}a-zA-Z\d]+)/gmu;
    let result = text;
    result = result.replace(regex, '<span class="text-blue">  $& </span>');
    return result;
  }
}

@Component({
  selector: 'app-feed-list',
  templateUrl: './feed-list.component.html',
  styleUrls: ['./feed-list.component.scss'],
})
export class FeedListComponent implements OnInit, OnDestroy {
  UPLOAD_MAX_FILE_SIZE_MODE = 'ALL'; // ALL: Sum of size from every files must've size less than 50 MB,
  // PER_FILE: Every files must've size less than 50 MB
  @ViewChild('attachmentInput', { static: true }) attachmentInput: ElementRef;
  @ViewChild('commentAttachment', { static: true })
  commentAttachment: ElementRef;
  @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;
  newComment = '';
  photo = localStorage.getItem('photoUrl');
  data: any;
  view = 'Public';
  selectedView = [];
  attachmentFile: File[] = null;
  commentAttachmentFile: [File[]?] = [];
  fileUpload: Array<File>;
  attachmentComment: Array<File>;
  feedPostList: Array<NewsFeedPost> = [];
  commentFileName = [];
  commentText = [];
  isLoading = false;
  showPreview = false;
  documentData = '';
  isNoMessage = false;
  selectedPost: NewsFeedPost;
  selectedComment: NewsFeedComment;
  loadNewList = false;
  newPost = '';
  items = ['Noah', 'Liam', 'Mason', 'Jacob'];
  userList = [];
  userDict = {};
  mentionedMeOnly = false;
  showResults = false;
  currentPage = 1;
  finalPage = false;
  feedListType = false;
  image = [];
  previewImage = [];
  templateList = [
    { value: 'newsFeed', showLabel: 'News Feed' },
    { value: 'announcement', showLabel: 'Announcement' },
  ];
  selectedTemplate = { value: 'newsFeed', showLabel: 'News Feed' };

  modal: NgbModalRef;
  departmentList = [];
  department = [];
  notificationDetail: any;

  alreadySearchText = [''];
  debouncer = new Subject<string>();

  private subs = new SubSink();

  showPost = true;
  showAnnouncement = false;
  pageWidth: number;
  isMentionOpen = false;
  announcementId: string = null;
  feedId: string = null;

  checkLanguage = false;

  subscriptions = new Subscription();
  isUserAdmin: boolean;

  constructor(
    private translate: TranslateService,
    private swal: SweetAlertService,
    private feedService: FeedService,
    private notification: NotificationService,
    private userService: UserService,
    private modalService: NgbModal,
    private apiService: MemoService,
    private titleService: TitleService,
    private elRef: ElementRef,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
  ) {
    this.debouncer
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe((event) => {
        if (!this.isTextHasAlreadyBeenSearched(event)) {
          // this.getSearchedUser(event);
          // this.getUserList(event);
        }
      });
  }

  ngOnInit() {
    this.checkParams();
    this.getFeedPostList();
    this.getDepartment();
    this.getUserList();
    this.translateNewsfeed();
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateNewsfeed();
    });
    this.subscriptions.add(translateSub);
    if (document.documentElement.clientWidth >= 992) {
      this.showPost = true;
      this.showAnnouncement = true;
    }
    this.isUserAdmin = this.authService.isAdmin();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  checkParams() {
    this.activatedRoute.params.subscribe(
      (params: { id: string; announcementId: string }) => {
        if (params) {
          this.announcementId = params.announcementId;
          this.feedId = params.id;
        }
      }
    );
  }

  translateNewsfeed() {
    this.titleService.setTitle(this.translate.instant('FEED.NEWS-FEED'));
    this.selectedView = [
      {
        name: this.translate.instant('FEED.PUBLIC'),
        value: 'Public',
        label: 'fa fa-globe',
      },
      {
        name: this.translate.instant('FEED.SPECIFIC-DEPARTMENT'),
        value: 'Specific Department',
        label: 'fa fa-user',
      },
    ];

    this.checkLanguage = this.translate.currentLang === 'en';
  }

  onResize(event) {
    this.pageWidth = event.target.innerWidth;
    if (this.pageWidth < 992) {
      this.onSelectedTemplate(this.templateList[0]);
    }
    if (this.pageWidth >= 992) {
      this.showPost = true;
      this.showAnnouncement = true;
    }
  }

  onSelectedTemplate(template) {
    if (template.value === 'newsFeed') {
      this.showPost = true;
      this.showAnnouncement = false;
    } else if (template.value === 'announcement') {
      this.showPost = false;
      this.showAnnouncement = true;
    }
    this.selectedTemplate = template;
  }

  isTextHasAlreadyBeenSearched(text) {
    if (this.alreadySearchText.includes(text)) {
      return true;
    }
    this.alreadySearchText.push(text);
    return false;
  }

  // getSearchedUser(word) {
  //   const a = _.filter(this.userList, function (obj) {
  //     return obj.full_name.includes(word);
  //   });
  //   console.log(this.userList);
  //   console.log(a);
  // }

  getDepartment() {
    this.departmentList = [];
    const params = new HttpParams()
      .set('type', 'department')
      .set('company', localStorage.getItem('company_id').toString());
    this.apiService.getDropDown(params).subscribe((departments: any) => {
      _.forEach(departments.department, (obj) => {
        this.departmentList.push({
          name: obj.label,
          id: obj.value,
          checkDepartment: false,
        });
      });
    });
  }

  getUserList(userSearchingText?) {
    let params = new HttpParams().set('type', 'profile');
    if (userSearchingText) {
      params = params.set('full_name', userSearchingText);
    }
    this.subs.add(
      this.apiService.getDropDown(params).subscribe(
        (users) => {
          users = users.profile;
           this.userList = _.uniqWith([...this.userList, ...users], _.isEqual);
         },
          (error) => this.notification.showNotification(error.status)
      )
    );
  }

  sortAllPost() {
    this.feedPostList = [];
    this.showResults = false;
    this.loadNewList = false;
    this.subs.add(
      this.feedService.getAllPost().subscribe((feedback) => {
        if (this.fromNormalFeedToMentionedMeOnlyFeed()) {
          this.feedPostList = [];
          this.feedListType = !this.feedListType;
        } else if (this.fromMentionedMeOnlyFeedToNormalFeed()) {
          this.feedPostList = [];
          this.feedListType = !this.feedListType;
        }
        this.feedPostList = this.feedPostList.length
          ? this.feedPostList.concat(feedback.results)
          : feedback['results'];
        this.finalPage = feedback.next == null;
        this.showResults = true;
        this.loadNewList = true;
        this.updatePostAttachment();
        this.updateCommentAttachment();
      })
    );
  }

  sortRelatedPost() {
    this.feedPostList = [];
    this.showResults = false;
    this.loadNewList = false;
    this.subs.add(
      this.feedService.getRelatedPost().subscribe((feedback) => {
        if (this.fromNormalFeedToMentionedMeOnlyFeed()) {
          this.feedPostList = [];
          this.feedListType = !this.feedListType;
        } else if (this.fromMentionedMeOnlyFeedToNormalFeed()) {
          this.feedPostList = [];
          this.feedListType = !this.feedListType;
        }
        this.feedPostList = this.feedPostList.length
          ? this.feedPostList.concat(feedback)
          : feedback;
        this.finalPage = feedback.next == null;
        this.showResults = true;
        this.loadNewList = true;
        this.updatePostAttachment();
        this.updateCommentAttachment();
      })
    );
  }

  fromNormalFeedToMentionedMeOnlyFeed() {
    return this.mentionedMeOnly && !this.feedListType;
  }

  fromMentionedMeOnlyFeedToNormalFeed() {
    return !this.mentionedMeOnly && this.feedListType;
  }

  getFeedPostList() {
    this.showResults = false;
    if (
      this.fromMentionedMeOnlyFeedToNormalFeed() ||
      this.fromNormalFeedToMentionedMeOnlyFeed()
    ) {
      this.loadNewList = false;
    }
    let params = new HttpParams().set('page', this.currentPage.toString());
    if (this.mentionedMeOnly) {
      params = params.set('mentioned_me_only', this.mentionedMeOnly.toString());
    }
    if (this.feedId) {
      params = params.set('id', this.feedId);
    }
    this.subs.add(
      this.feedService.getFeedPostList(params).subscribe(
        (feeds) => {
          if (this.fromNormalFeedToMentionedMeOnlyFeed()) {
            this.feedPostList = [];
            this.feedListType = !this.feedListType;
          } else if (this.fromMentionedMeOnlyFeedToNormalFeed()) {
            this.feedPostList = [];
            this.feedListType = !this.feedListType;
          }
          if (this.notificationDetail) {
            this.feedPostList = [];
            this.feedPostList = feeds.results;
          } else {
            this.feedPostList = this.feedPostList.length
              ? this.feedPostList.concat(feeds.results)
              : feeds['results'];
          }
          this.finalPage = feeds.next == null;
          this.showResults = true;
          this.loadNewList = true;
          this.notificationDetail = {};
          this.updatePostAttachment();
          this.updateCommentAttachment();
        },
        (error) => {
          // this.notification.showNotification(error.status);
          if (this.fromNormalFeedToMentionedMeOnlyFeed()) {
            this.mentionedMeOnly = false;
          }
          if (this.fromMentionedMeOnlyFeedToNormalFeed()) {
            this.mentionedMeOnly = true;
          }
          this.showResults = true;
          this.loadNewList = true;
        }
      )
    );
  }

  getFile(Files: any) {
    this.isLoading = true;
    this.attachmentFile = this.getArrayFromArrayFiles(
      this.attachmentFile,
      Files.target.files
    );
    if (Files.target.files && Files.target.files[0].type.includes('image')) {
      const reader = new FileReader();
      reader.readAsDataURL(Files.target.files[0]); // read file as data url
      reader.onload = (event: any) => {
        // called once readAsDataURL is completed
        this.previewImage = event.target.result;
        this.image.push({ image: this.previewImage, name: null });
      };
    } else {
      this.image.push({ image: null, name: Files.target.files[0].name });
    }
    this.checkAttachmentSize(Files);
    this.isLoading = false;
  }

  checkAttachmentSize(fileInput) {
    if (this.UPLOAD_MAX_FILE_SIZE_MODE === 'PER_FILE') {
      for (let i = 0; i < fileInput.length; i++) {
        if (fileInput[i].size / (1024 * 1024) >= 50) {
          // this.swal.showSpecificError('กรุณาอัพโหลดไฟล์ให้แต่ละไฟล์มีขนาดไม่เกิน 50 MB');
          this.attachmentComment = null;
          return;
        }
      }
    } else {
      // default: UPLOAD_MAX_FILE_SIZE_MODE == 'ALL'
      let allFileSize = 0;
      for (let i = 0; i < fileInput.length; i++) {
        allFileSize += fileInput[i].size / (1024 * 1024);
      }
      if (allFileSize >= 50) {
        // this.notification.showSpecificError('กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 50 MB');
        this.attachmentComment = null;
        return;
      }
    }
  }

  getArrayFromArrayFiles(oldArray: File[], arrayFile: Array<File>) {
    if (oldArray == null) {
      oldArray = [];
    }
    for (let i = 0; i < arrayFile.length; i++) {
      const selectedFile = arrayFile[i];
      oldArray.push(selectedFile);
    }
    return oldArray;
  }

  postFeed() {
    const specificDepartment = [];
    if (!this.newPost && !this.attachmentFile) {
      this.isNoMessage = true;
      return;
    }
    this.createUserDic();
    const returnedObject = this.checkRegexPattern(this.newPost);
    if (returnedObject.error === true) {
      this.notification.showSpecificError(
        `ต้องเว้นวรรคอย่างน้อย 1 ช่องหลังจาก Tag คนด้วย @ หรือ ${returnedObject.value} ไม่มีอยู่ในระบบ`
      );
      return;
    }
    _.forEach(this.departmentList, (data) => {
      if (data.checkDepartment === true) {
        this.department.push(data.id);
      }
    });
    const mentionedPerson = returnedObject.result;
    this.isNoMessage = false;
    this.isLoading = true;
    const fd = new FormData();
    fd.append('is_pin', 'false');
    fd.append('message', this.newPost || '');
    for (const eachElement of mentionedPerson) {
      fd.append('mentioned_person', eachElement);
    }
    if (this.department) {
      for (let i = 0; i < this.department.length; i++) {
        fd.append('department', this.department[i]);
      }
    }
    if (this.attachmentFile) {
      const files: Array<File> = this.attachmentFile;
      for (let i = 0; i < files.length; i++) {
        fd.append('news_feed_post_attachment', files[i], files[i]['name']);
      }
    }
    this.subs.add(
      this.feedService.createFeedPost(fd).subscribe(
        (feed) => {
          this.isLoading = false;
          this.feedPostList.unshift(feed);
          this.updatePostAttachment();
          this.clear();
        },
        (error) => {
          // this.notification.showNotification(error.status);
          this.isLoading = false;
        }
      )
    );
  }

  deletePostAttachment(index) {
    this.attachmentFile.splice(index, 1);
    this.image.splice(index, 1);
  }

  clear() {
    this.newPost = '';
    this.attachmentFile = null;
    this.commentAttachmentFile = [null];
    this.image = [];
    this.view = 'Public';
    this.getDepartment();
    this.textChange('');
  }

  createUserDic() {
    // ******** problem will come when two or more persons have exact same full name
    for (const eachElement of this.userList) {
      this.userDict[eachElement['name']] = eachElement['id'];
    }
  }

  checkTypeFile(file) {
    if (
      _.endsWith(file.toLowerCase(), '.jpg') ||
      _.endsWith(file.toLowerCase(), '.png') ||
      _.endsWith(file.toLowerCase(), '.gif') ||
      _.endsWith(file.toLowerCase(), '.jpeg')
    ) {
      return 'image';
    } else {
      return 'file';
    }
  }

  splitFileType(fileList) {
    // Split images and files
    const tempImagesList = [];
    const tempFileList = [];
    for (const file of fileList) {
      if (this.checkTypeFile(file.attachment_name) === 'image') {
        tempImagesList.push(file);
      }
    }
    for (const file of fileList) {
      if (this.checkTypeFile(file.attachment_name) !== 'image') {
        tempFileList.push(file);
      }
    }
    return [tempImagesList, tempFileList];
  }

  updatePostAttachment() {
    this.feedPostList.forEach((post, index) => {
      // loop to get attachments
      if (post['news_feed_post_attachment'].length) {
        const array = this.splitFileType(post['news_feed_post_attachment']);
        this.feedPostList[index]['feedPostAttachmentImages'] = array[0];
        this.feedPostList[index]['feedPostAttachmentFiles'] = array[1];
      } else {
        this.feedPostList[index]['feedPostAttachmentImages'] = [];
        this.feedPostList[index]['feedPostAttachmentFiles'] = [];
      }
    });
  }

  updateCommentAttachment() {
    this.feedPostList.forEach((post, postIndex) => {
      // loop to get comments
      post['news_feed_comment'].forEach((comment, commentIndex) => {
        // loop to get attachments in comments
        if (comment['news_feed_comment_attachment'].length) {
          const array = this.splitFileType(
            comment['news_feed_comment_attachment']
          );
          this.feedPostList[postIndex]['news_feed_comment'][commentIndex][
            'feedCommentAttachmentImages'
          ] = array[0];
          this.feedPostList[postIndex]['news_feed_comment'][commentIndex][
            'feedCommentAttachmentFiles'
          ] = array[1];
        } else {
          this.feedPostList[postIndex]['news_feed_comment'][commentIndex][
            'feedCommentAttachmentImages'
          ] = [];
          this.feedPostList[postIndex]['news_feed_comment'][commentIndex][
            'feedCommentAttachmentFiles'
          ] = [];
        }
      });
    });
  }

  checkRegexPattern(text) {
    // with regex u flag , \u{0E01}-\u{0E5B} stand for thai languages in unicode format , it mean ก-๙
    const regex = /(?:@)([\u{0E01}-\u{0E5B}a-zA-Z\d]+)(\s)([\u{0E01}-\u{0E5B}a-zA-Z\d]+)/gmu;
    let results = [];
    results = text.match(regex);
    if (results) {
      return this.findMentionedPersonID(results);
    }
    return { result: [], value: null, error: false };
  }

  findMentionedPersonID(inputArr) {
    const tempArr = { result: [], value: '', error: false };
    inputArr = inputArr && inputArr.filter(Boolean);
    for (const eachElement of inputArr) {
      if (_.isNil(this.userDict[eachElement.substring(1)])) {
        tempArr['error'] = true;
        tempArr['value'] = eachElement.substring(1);
        break;
      }
      tempArr['result'].push(this.userDict[eachElement.substring(1)]);
    }
    return tempArr;
  }

  deletePost(feed?) {
    this.subs.add(
      this.feedService.deleteFeedPost(feed.id).subscribe(
        () => {
          this.feedPostList = this.feedPostList.filter(
            (obj) => obj.id !== feed.id
          );
          this.modal.close();
        },
        (error) => {
          // this.notification.showNotification(error.status)
        }
      )
    );
  }

  getExtention(file) {
    return file.substr(file.lastIndexOf('.') + 1);
  }

  preview(attachment) {
    this.documentData = attachment;
    this.showPreview = true;
  }

  deleteComment(feed, comment) {
    this.subs.add(
      this.feedService.deleteFeedComment(comment.id).subscribe(
        () => {
          feed.news_feed_comment = feed.news_feed_comment.filter(
            (obj) => obj.id !== comment.id
          );
        },
        (error) => {
          // this.notification.showNotification(error.status)
        }
      )
    );
    this.modal.close();
  }

  postComment(feed, comment, feedIndex) {
    if (this.isMentionOpen) {
      // do nothing when the mention is open
      return;
    }
    // postComment(feed, comment) {
    if (
      comment ||
      (this.commentAttachmentFile && this.commentAttachmentFile.length)
    ) {
      this.createUserDic();

      const fd = new FormData();
      fd.append('news_feed_post', feed.id);
      fd.append('comment', comment || '');
      if (this.commentAttachmentFile[feedIndex]) {
        // const files: [Array<File>] = this.commentAttachmentFile;
        const files: Array<File> = this.commentAttachmentFile[feedIndex];
        for (let i = 0; i < files.length; i++) {
          fd.append('news_feed_comment_attachment', files[i], files[i]['name']);
        }
      }
      if (comment) {
        const returnedObject = this.checkRegexPattern(comment);
        const mentionedPerson = returnedObject.result;
        if (returnedObject.error === true) {
          this.notification.showSpecificError(
            `ต้องเว้นวรรคอย่างน้อย 1 ช่องหลังจาก Tag คนด้วย @ หรือ ${returnedObject.value} ไม่มีอยู่ในระบบ`
          );
          return;
        }
        for (const eachElement of mentionedPerson) {
          fd.append('mentioned_person', eachElement);
        }
      }
      this.subs.add(
        this.feedService.createFeedComment(fd).subscribe(
          (res) => {
            feed.news_feed_comment.push(res);
            this.commentText = [];
            this.commentFileName = [];
            this.commentAttachmentFile = [];
            this.updateCommentAttachment();
          },
          (error) => this.notification.showNotification(error.status)
        )
      );
    } else {
      alert('กรุณาพิมพ์ข้อความหรือเลือกไฟล์');
    }
  }

  triggerDebouncer(event) {
    this.debouncer.next(event);
  }

  getCommentAttachment(event, feedIndex: number) {
    const files = event.target.files;
    this.commentAttachmentFile[feedIndex] = this.getArrayFromArrayFiles(
      this.commentAttachmentFile[feedIndex],
      files
    );
    this.checkAttachmentSize(files);
    // this.commentAttachment.nativeElement.value = '';
  }

  deleteCommentAttachment(feedIndex, index) {
    this.commentAttachmentFile[feedIndex].splice(index, 1);
  }

  getKBDetail(feed) {
    this.selectedPost = null;
  }

  closePreview(e) {
    this.showPreview = false;
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  openDeleteModal(content, feed) {
    this.modal = this.modalService.open(content);
    this.selectedPost = feed;
  }

  selectedDepartment() {
    _.forEach(this.departmentList, (obj) => {
      this.departmentList.push({
        name: obj.name,
        id: obj.id,
        checkDepartment: false,
      });
    });
  }

  disableButton() {
    return !this.newPost;
  }

  checkIsAuthorOrAdmin(someObject) {
    return this.isUserAdmin || (+localStorage.getItem('user_id') === _.get(someObject, 'created_user', ''));
  }

  textChange(event) {
    if (event === '' || event[event.length - 1] === '\n') {
      this.autosize.reset();
    } else {
      this.autosize.resizeToFitContent(true);
    }
  }

  openDeleteCommentModal(
    modal: TemplateRef<any>,
    post: NewsFeedPost,
    comment: NewsFeedComment
  ) {
    this.selectedPost = post;
    this.selectedComment = comment;
    this.modal = this.modalService.open(modal);
  }

  mentionOpened() {
    this.isMentionOpen = true;
  }

  mentionClosed() {
    this.isMentionOpen = false;
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin();
  }
}
