import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewChecked, OnDestroy, Pipe, PipeTransform, TemplateRef
} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MemoService } from '../../memos/memo/memo.service';
// import { ProjectService } from 'src/app/project/project.service';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {ApproveService} from '../approve.service';
import {interval, Subscription} from 'rxjs';

@Pipe({
  name: 'highlight'
})
export class HighlightCommentApproval implements PipeTransform {
  transform(text: string, search: any) {
    // with regex u flag , \u{0E01}-\u{0E5B} stand for thai languages in unicode format , it mean ก-๙
    const regex = /(?:@)([\u{0E01}-\u{0E5B}a-zA-Z\d]+)(\s)([\u{0E01}-\u{0E5B}a-zA-Z\d]+)/gmu;
    let result = text;
    result = result.replace(regex, '<span class="text-blue">  $& </span>');
    return result;
  }
}
@Component({
  selector: 'app-approval-comment',
  templateUrl: './approval-comment.component.html',
  styleUrls: ['./approval-comment.component.scss']
})
export class ApprovalCommentComponent implements OnInit, OnDestroy {
  modal: NgbModalRef;
  newComment: string;
  commentList = [];
  showMoreInformation = false;

  owner = localStorage.full_name;
  nextPage: any;
  commentImage: File;
  documentData = undefined;
  showPreview = false;
  subscription: Subscription;
  currentComment: number = null;

  @Input() profileID = _.toNumber(localStorage.getItem('profile_id'));
  @Input() data: any;
  @Input() profile: number;
  @Input() key: string;
  @Output() submitted = new EventEmitter();

  @ViewChild('scrollMe', { static: true }) myScrollContainer: ElementRef;

  constructor(
    private notification: NotificationService,
    private memoService: MemoService,
    private approveService: ApproveService,
    private modalService: NgbModal,
    // private projectService: ProjectService
  ) {}

  ngOnInit() {
    this.getCommentList();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  startCommentListRunner() {
    const source = interval(60000);
    this.subscription = source.subscribe(val => this.getRefreshCommentList());
  }

  getCommentList() {
    const header = new HttpHeaders().set('Authorization', this.key);
    const params = new HttpParams().set('memo', this.data);
    this.approveService.getCommentList(header, params).subscribe((comments: any) => {
      this.commentList = comments.results;
      this.nextPage = comments.next;
      this.startCommentListRunner();
    });
  }
  getRefreshCommentList() {
    const header = new HttpHeaders().set('Authorization', this.key);
    const params = new HttpParams().set('memo', this.data);
    this.approveService.getCommentList(header, params).subscribe((comments: any) => {
      this.commentList = comments.results;
      this.nextPage = comments.next;
    });
  }

  // getOlderActivity() {
  //   this.projectService.getActivityByURL(this.nextPage).subscribe((stream: any) => {
  //     _.forEach(stream.results, obj => {
  //       this.commentList.unshift(obj);
  //     });
  //     this.nextPage = stream.next;
  //   });
  // }

  addComment(newComment) {
    if (newComment[0] == null || newComment[0].newComment.trim().length === 0) {
      return;
    }
    const header = new HttpHeaders().set('Authorization', this.key);
    const profileID = this.profile;
    this.newComment = newComment;
    const fd = {
      'mentioned_person': newComment[0].mentionedPerson,
      'memo': this.data,
      'message': newComment[0].newComment || '',
      'created_profile': profileID.toString()
    };
    this.approveService.createNewComment(header, fd).subscribe(() => {
      this.newComment = '';
      this.getCommentList();
    });
  }

  deleteComment(id) {
    const header = new HttpHeaders().set('Authorization', this.key);
    this.approveService
      .deleteComment(header, id)
      .subscribe(
        () => (this.commentList = this.commentList.filter(stream => stream.id !== id)),
        error => this.notification.showNotification(error.status)
      );
    this.modal.close();
  }

  isSameUser(stream) {
    return this.profile === stream.created_profile ? 'right' : 'left';
  }

  isOwner({ created_profile }) {
    return this.profile === created_profile;
  }

  uploadImageComment(event) {
    const header = new HttpHeaders().set('Authorization', this.key);
    const profileID = this.profile;
    const fd = new FormData();
    fd.append('file_upload', event.target.files[0]);
    fd.append('created_profile', profileID.toString());
    fd.append('memo', this.data);
    this.approveService.createNewComment(header, fd).subscribe(res => {
      this.getCommentList();
    });
  }

  getExtension({ file_upload }): string {
    return file_upload.substr(file_upload.lastIndexOf('.') + 1);
  }

  checkImageURL(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) !== null;
  }

  preview(file) {
    if (location.protocol === 'https:') {
      file = file.replace(/^http:/, 'https:');
    }

    this.documentData = file;
    this.showPreview = true;
  }

  closePreview() {
    this.showPreview = false;
  }

  trackByFn(index, item) {
    return item.id;
  }

  openDeleteModal(modal: TemplateRef<any>, commentId: number) {
    this.modal = this.modalService.open(modal, {centered: true});
    this.currentComment = commentId;
  }
}
