import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBGPUProductSell, FormExpensesClaim, TableExpensesClaim} from '../../memo-template';

@Component({
  selector: 'app-table-template-bgpu-product-sell',
  templateUrl: './table-template-bgpu-product-sell.component.html',
  styleUrls: ['./table-template-bgpu-product-sell.component.scss']
})
export class TableTemplateBGPUProductSellComponent implements OnInit {

  totalTax = 0;
  totalPriceAmount = 0; // totalAmount + totalTax
  taxList = [
    {name: '0 %', value: false},
    {name: '7 %', value: true}
  ];

  unitList = [
    {value: 'PC'},
    {value: 'PAC'},
    {value: 'set'},
    {value: 'unit'},
    {value: 'กระบอก'},
    {value: 'กระปุก'},
    {value: 'กล่อง'},
    {value: 'ก้อน'},
    {value: 'กิโลกรัม'},
    {value: 'ครั้ง'},
    {value: 'งาน'},
  ];

  // tslint:disable-next-line:no-input-rename
  @Input('formBGPUProductSellData') formBGPUProductSellData: FormBGPUProductSell;
  @Output() formBGPUProductSellDataChange = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    if (this.formBGPUProductSellData.bgpu_product_sell_table && this.formBGPUProductSellData.bgpu_product_sell_table.length > 0) {
      this.mapDataToLineItems(this.formBGPUProductSellData.bgpu_product_sell_table);
    }
  }

  mapDataToLineItems(line_items: any[]): TableExpensesClaim[] {
    return line_items
      .map(line_item => {
        return {
          id: line_item.id,
          description: line_item.description,
          quantity: line_item.quantity,
          unit_price: line_item.unit_price,
          amount: line_item.amount,
        } as TableExpensesClaim;
      });
  }

  onAddRowToLineItems() {
    this.formBGPUProductSellData.bgpu_product_sell_table.push({
      sequence: (this.formBGPUProductSellData.bgpu_product_sell_table || []).length + 1,
      id: null,
      description: null,
      quantity: 0,
      unit: null,
      unit_price: 0,
      amount: 0,
      remark: null,
    });
  }

  onRemoveRowFromLineItems(index) {
    this.formBGPUProductSellData.bgpu_product_sell_table.splice(index, 1);
    this.reOrderSequence();
    this.calSumAmount();
    this.updateTax();
  }

  reOrderSequence() {
    (this.formBGPUProductSellData.bgpu_product_sell_table || []).forEach((d, index) => {
      d.sequence = index + 1;
    });
  }

  calSumAmount() {
    const sumAmount = this.formBGPUProductSellData.bgpu_product_sell_table
      .reduce((sum, cur) => sum + cur.amount, 0);
    this.formBGPUProductSellData.sum_amount = this.limitTwoDecimal(sumAmount);
  }

  updateTax() {
    if (this.formBGPUProductSellData.is_vat_7) {
      this.totalTax = this.limitTwoDecimal(this.formBGPUProductSellData.sum_amount * (7 / 100));
    } else {
      this.totalTax = 0;
    }
    this.totalPriceAmount = this.limitTwoDecimal(this.totalTax + this.formBGPUProductSellData.sum_amount);
  }

  limitTwoDecimal(num: number, mode: 'round' | 'floor' = 'round'): number {
    return mode === 'round'
      ? Math.round(num * 100) / 100
      : Math.floor(num * 100) / 100;
  }

  updateTableLineItem(lineItem, key: string, val: string) {
    if (key === 'quantity') {
      lineItem[key] = Math.floor(+val);
    } else {
      lineItem[key] = this.limitTwoDecimal(+val, 'round');
    }
    lineItem.amount = this.limitTwoDecimal(lineItem.quantity * lineItem.unit_price);
    this.calSumAmount();
    this.updateTax();
  }
}
