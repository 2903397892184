// import { ProjectService } from './../../../project/project.service';
import { Component, EventEmitter, Input, OnInit, Output, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { MemoService } from '../memo.service';
import * as _ from 'lodash';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-memo-preview',
  templateUrl: './memo-preview.component.html',
  styleUrls: ['./memo-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MemoPreviewComponent implements OnInit {
  pdfFile: any;
  rendering = false;
  modal: any;

  @Input() document: any;
  @Input() fileName = 'FA_file';
  @Input() data: any;
  @Input() isHiddenDelete = true;
  @Input() isNeedToken = false;
  @Input() typeFile: 'image' | 'pdf';

  @Output() closed = new EventEmitter();

  @ViewChild('content', { static: true }) content;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closePreview();
  }


  constructor(
    // private projectService: ProjectService,
    private notification: NotificationService,
    private memoService: MemoService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    if (!this.isNeedToken) {
      _.isString(this.document) ? (this.pdfFile = this.document) : this.previewData();
    } else {
      if (this.typeFile === 'image') {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.document = event.target.result;
        };
        reader.readAsDataURL(this.document);
      } else if (this.typeFile === 'pdf') {
        this.document = URL.createObjectURL(this.document);
        _.isString(this.document) ? (this.pdfFile = this.document) : this.previewData();
      }
    }
    setTimeout(() => {
      this.modal = this.modalService.open(this.content, {size: 'lg', centered: true, windowClass: 'transparent-modal'});
    }, 100);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  pageRendering() {
    this.rendering = true;
  }

  isPDF() {
    if (this.typeFile === 'pdf') {
      return true;
    } else {
      return !_.isString(this.document) ? true : _.endsWith(this.document.toLowerCase(), '.pdf');
    }
  }

  isImage() {
    if (this.typeFile === 'image') {
      return true;
    } else {
      return !_.isString(this.document) && this.typeFile !== 'pdf'
        ? false
        : _.endsWith(this.document.toLowerCase(), '.jpg') ||
            _.endsWith(this.document.toLowerCase(), '.png') ||
            _.endsWith(this.document.toLowerCase(), '.gif') ||
            _.endsWith(this.document.toLowerCase(), '.jpeg');
    }
  }

  isBadFile() {
    if (this.isNeedToken) {
      return !(this.typeFile === 'image' || this.typeFile === 'pdf');
    } else {
      return !(this.isPDF() || this.isImage());
    }
  }

  checkIfDocumentIsString() {
    return _.isString(this.document);
  }

  previewData() {
    this.memoService.previewMemoPDF(this.document).subscribe((preview: any) => {
      const blob = new Blob([preview], { type: 'application/pdf;base64' });
      this.pdfFile = URL.createObjectURL(blob);
    });
  }

  closePreview() {
    if (this.modal) {
      this.modal.close();
    }
    this.modal = null;

    this.closed.emit({});
  }

  deleteSignedDocument(data) {
    const updatedData = {
      signed_document: null
    };
    this.memoService.updateMemoReal(this.data.id, updatedData).subscribe(() => {
      this.closed.emit({});
    });
  }

  printDocument() {
    window.open(this.document);
  }

  // downloadDocument() {
  //   this.projectService.downloadFile(this.document).subscribe(
  //     res => {
  //       const blob: any = new Blob([res.body]);
  //       const link: any = document.createElement('a');
  //       let extension = this.document.split('.').pop();
  //
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = `${this.fileName}.${extension}`;
  //       link.click();
  //     },
  //     error => {
  //       this.notification.showNotification(error.status);
  //     }
  //   );
  //  }
}
