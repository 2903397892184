import {Injectable} from '@angular/core';
import {HttpClientService} from '../../../shared/services/http-client.service';
import {API_URL, BASE_URL} from '../../../shared/constants/api.constant';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../shared/services/sweet-alert.service';

@Injectable()
export class MemoService {

  headers: HttpHeaders;
  historyText: string;
  private _params: any;

  constructor(private http: HttpClientService,
              private httpLocal: HttpClient,
              private translate: TranslateService,
              private swal: SweetAlertService) {
    this.headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + localStorage.getItem('currentUser'));
  }

  getDraftMemo(params?) {
    return this.http.get(API_URL.memo_draft, params);
  }

  getDraftMemoDetail(id) {
    return this.http.get(API_URL.memo_draft + id + '/');
  }

  updateDraftMemo(id, data) {
    return this.http.patch(API_URL.memo_draft + id + '/', data);
  }

  createDraftMemo(data) {
    return this.http.post(API_URL.memo_draft, data);
  }

  getDepartmentList(data?) {
    return this.http.get(API_URL.departments, data);
  }

  getOfficeList(data?) {
    return this.http.get(API_URL.offices, data);
  }

  getGroupList(data?) {
    return this.http.get(API_URL.groups, data);
  }

  getDepartmentByGroupList(data?) {
    return this.http.get(API_URL.department_by_group, data);
  }

  getCentralDepartment(): Observable<any> {
    return this.http.get(API_URL.central_department);
  }

  getCentralDepartmentById(id): Observable<any> {
    return this.http.get(API_URL.central_department + id + '/');
  }

  createCentralDepartment(data): Observable<any> {
    return this.http.post(API_URL.central_department, data);
  }

  updateCentralDepartment(id, data): Observable<any> {
    return this.http.patch(API_URL.central_department + id + '/', data);
  }

  deleteCentralDepartment(id) {
    return this.http.delete(API_URL.central_department + id + '/');
  }

  getCentralDepartmentChoice(): Observable<any> {
    return this.http.get(API_URL.central_department_choice);
  }

  getBudgetWBSList(data?) {
    return this.http.get(API_URL.budget_wbs, data);
  }

  getCostCentreList(data?) {
    return this.http.get(API_URL.cost_centre, data);
  }

  getWBSYearList(data?) {
    return this.http.get(API_URL.wbs_year, data);
  }

  getWBSBudgetDashboardList(data?) {
    return this.http.get(API_URL.wbs_budget_dashboard, data);
  }

  previewMemoPDF(data) {
    return this.http.pdfPost(API_URL.memo_preview, data);
  }

  openMemoDraftPDF(type, id) {
    return window.open(API_URL.memo_template + '?type=' + `${type ? type : ''}` + '&id=' + id);
  }

  deleteMemoTemplate(id) {
    return this.http.delete(API_URL.memo_draft + id + '/');
  }

  // RealMemo

  getMemoRealList(params?) {
    return this.http.get(API_URL.memo_real, params);
  }

  createRealMemo(data) {
    return this.http.post(API_URL.memo_real, data);
  }

  updateMemoReal(id, data) {
    return this.http.patch(API_URL.memo_real + id + '/', data);
  }

  PreviewMemoReal(data?) {
    return this.http.getBlob(API_URL.preview_memo, data);
  }

  getMemoRealDetail(id) {
    return this.http.get(API_URL.memo_real + id + '/');
  }

  getDetailOfMemoReal(id) {
    return this.http.get(API_URL.memo_real + id + /load-detail/);
  }

  getCommentList(params) {
    return this.http.get(API_URL.memo_comment, params);
  }

  deleteMemoReal(id) {
    return this.http.delete(API_URL.memo_real + id + '/');
  }

  createNewComment(data) {
    return this.http.post(API_URL.memo_comment, data);
  }

  updateMemoRead(data) {
    return this.http.post(API_URL.memo_read, data);
  }

  // Attachment

  getMemoAttachment(params?) {
    return this.http.get(API_URL.memo_attachment, params);
  }

  removeMemoAttachment(id) {
    return this.http.delete(API_URL.memo_attachment + id + '/');
  }

  updateMemoAttchment(id, data) {
    return this.http.patch(API_URL.memo_attachment + id + '/', data);
  }

  uploadMemoAttachment(data) {
    return this.http.post(API_URL.memo_bulk_attachment, data);
  }

  // createNewCommentWithoutAuth(data) {
  //   return this.httpLocal.post(API_URL.memo_comment, data);
  // }

  deleteComment(id) {
    return this.http.delete(API_URL.memo_comment + id + '/');
  }

  updateMemoIdToTraining(id, data) {
    return this.http.patch(API_URL.training + id + '/', data);
  }

  // Verify Duplicated Memo Number
  verifyMemoNumber(params) {
    return this.http.get(API_URL.memo_number_verify, params);
  }

  // Publish Memo
  sendEmail(data) {
    return this.http.post(API_URL.memo_send_email, data);
  }

  resendEmail(memoId: number) {
    return this.http.get(API_URL.memo_resend_email + memoId + '/');
  }

  publishMemoWithoutSendingEmail(data) {
    return this.http.post(API_URL.memo_publish, data);
  }

  // Collect data
  keepHistoryText(text) {
    this.historyText = text;
  }

  getHistoryText() {
    return this.historyText;
  }

  // Link

  getMemoLinkList(params?) {
    return this.http.get(API_URL.memo_link, params);
  }

  getMemohistory(params?) {
    return this.http.get(API_URL.memos_history, params);
  }

  createMemoLink(data) {
    return this.http.post(API_URL.memo_link, data);
  }

  getAvailableLink(params?) {
    return this.http.get(API_URL.memo_special_type, params);
  }

  deleteMemoLink(id) {
    return this.http.delete(API_URL.memo_link + id);
  }


  // memo word cloud
  createImgFromPDF(file) {
    return this.http.post(API_URL.memo_pdf2image, file);
  }

  downloadFile(url) {
    return this.http.download(url);
  }

  downloadWordCloudSignedPDF(id) {
    return this.http.download(API_URL.memo_template + '?type=' + '&id=' + id);
  }

  renderPDF(params) {
    return this.http.getBlob(API_URL.render_pdf, params);
  }

  updateMemoStatus(data) {
    return this.http.post(API_URL.approve_memo, data);
  }

  addDepartment(data) {
    return this.http.post(API_URL.departments, data);
  }

  deleteDepartment(id) {
    return this.http.delete(API_URL.departments + id + '/');
  }

  editDepartment(id, data) {
    return this.http.patch(API_URL.departments + id + '/', data);
  }

  getDashboardStorageData(params?) {
    return this.http.get(API_URL.dashboard_storage, params);
  }

  sendLogPrint(id) {
    return this.http.get(API_URL.memo_real + id + '/print/');
  }

  getMemoListCSV(data) {
    return this.http.getBlob(API_URL.memo_real + 'csv/', data);
  }

  recallMemo(id, data) {
    return this.http.patch(API_URL.memo_real + id + '/recall/', data);
  }

  getHistoryLogCSV(id) {
    return this.http.getBlob(API_URL.memo_real + id + '/history-csv/');
  }

  getCommentLogCSV(id) {
    return this.http.getBlob(API_URL.memo_real + id + '/comment-csv/');
  }

  getMentionProfiles(params) {
    return this.http.get(API_URL.memo_mentions, params);
  }

  printFile(url) {
    return this.http.printFile(url);
  }

  getListMemosNumber() {
    return this.http.get(API_URL.memo_list_members);
  }

  getMomoreference(id) {
    return this.http.get(API_URL.memo_real + id + '/reference/');
  }

  getCompanies(data?): Observable<any> {
    return this.http.get(API_URL.companies, data);
  }

  getFormTemplate(data) {
    return this.http.get(BASE_URL + '/api/' + data.api + '/' + data.id + '/');
  }

  getDropDown(data?) {
    return this.http.get(BASE_URL + '/api/dropdown/', data);
  }

  updateFormTemplate(formTemplate, data?) {
    return this.http.patch(BASE_URL + '/api/' + formTemplate.apiForm + '/' + formTemplate.id + '/', data);
  }

  createFormTemplate(data, apiFormTemplate) {
    return this.http.post(BASE_URL + '/api/' + apiFormTemplate + '/', data);
  }

  getBudgetDetail(data) {
    return this.http.get(BASE_URL + '/api/wbs-budget-dashboard/', data);
  }

  isMemoNumberExist(param: HttpParams): Observable<boolean> {
    return this.http.get(API_URL.is_memo_number_exist, param);
  }

  validateMemo(data: any): boolean {
    return this.validateLoa(data);
  }

  private validateLoa(data: any): boolean {
    if (data.loa_group) {
      // not custom LOA
      return true;
    }

    const loa: any[] = data.loa || null;

    if (loa && loa.length === 0) {
      this.swal.toastNotification({
        type: 'error',
        content: this.translate.instant('VALIDATE-ERROR.PLEASE-SELECT-LOA')
      });
      return false;
    }
    for (let i = 0; i < loa.length; i++) {
      const members: any[] = loa[i].members;
      for (let j = 0; j < members.length; j++) {
        const member: any[] | any = members[j];
        if (member.length === 0) {
          this.swal.toastNotification({
            type: 'error',
            content: this.translate.instant('VALIDATE-ERROR.PLEASE-FILL-ALL-LOA')
          });
          return false;
        }
      }
    }

    return true;
  }
}
