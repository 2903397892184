import {Component, OnDestroy, OnInit} from '@angular/core';
import {MemoService} from '../../../memos/memo/memo.service';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ThemeService} from '../../../../shared/services/theme.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TitleService} from '../../../../shared/services/title-service';
import {tap, map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

// import {ApiService} from '../../../../services/api.service';

@Component({
  selector: 'app-department-list',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.scss']
})
export class DepartmentListComponent implements OnInit, OnDestroy {
  modal: NgbModalRef;
  departmentList = [];
  budgetList = [];
  onSelected = '';
  checkIsSmall = null;
  companyList: { id: number; code_name: string }[] = [];
  selectedCompanyId: number;
  departmentsModeList: { label: string; value: string }[] = [
    {label: 'By Department', value: 'by-department'},
    {label: 'By Budget', value: 'by-budget'}
  ];
  selectedDepartmentsMode: string = this.departmentsModeList[0].value;
  isLoading = true;
  yearList = [];
  selectedYear;
  count = 0;
  currentPage = 1;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 40];

  subscriptions = new Subscription();

  deletedCentralDepartmentId: number = null;
  deletedCentralDepartmentName: string = null;

  constructor(
    private apiService: MemoService,
    private swal: SweetAlertService,
    private modalService: NgbModal,
    private theme: ThemeService,
    private translate: TranslateService,
    private titleService: TitleService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle(this.translate.instant('DEPARTMENT.SETTING-DEPARTMENT'));
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.titleService.setTitle(this.translate.instant('DEPARTMENT.SETTING-DEPARTMENT'));
    });
    this.subscriptions.add(translateSub);
    this.loadCompaniesDropdown();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadCompaniesDropdown() {
    this.apiService.getCompanies()
      .pipe(
        map((companies: any[]) => {
          return companies.map(company => {
            return {id: company.id, code_name: company.code_name};
          });
        })
      )
      .subscribe((companies) => {
        this.companyList = companies;
        this.selectedCompanyId = this.companyList.length > 0 ? this.companyList[0].id : undefined; // companyList[0] is default_company
        this.onSelected = 'by-department';
        this.loadDepartments();
      });
  }

  loadDepartments() {
    this.isLoading = true;
    this.budgetList = [];
    this.departmentList = [];
    this.selectedYear = null;
    this.onSelected = 'by-department';
    const params = new HttpParams()
      .set('company_id', (this.selectedCompanyId || '').toString())
      .set('page', this.currentPage.toString())
      .set('page_size', this.pageSize.toString());
    this.apiService.getDepartmentList(params).subscribe(
      (departments: any) => {
        this.departmentList = departments.results;
        this.count = departments.count;
        this.isLoading = false;
      }
    );
  }

  loadBudgetsWBS() {
    this.isLoading = true;
    this.departmentList = [];
    this.budgetList = [];
    const params = new HttpParams()
      .set('company_id', (this.selectedCompanyId || '').toString())
      .set('year', this.selectedYear.toString())
      .set('page', this.currentPage.toString())
      .set('page_size', this.pageSize.toString());
    this.apiService.getWBSBudgetDashboardList(params).subscribe(
      (wbs: any) => {
        this.count = wbs.count;
        this.budgetList = wbs.results;
        this.isLoading = false;
      }
    );
  }

  filterCompany(event) {
    if (this.onSelected === 'by-department') {
      this.loadDepartments();
    } else if (this.onSelected === 'by-budget') {
      this.loadYear();
    }
  }

  onSelectMode(event) {
    this.currentPage = 1;
    if (event.value === 'by-department') {
      this.loadDepartments();
    } else if (event.value === 'by-budget') {
      this.loadYear();
    }
  }

  loadYear() {
    this.onSelected = 'by-budget';
    this.yearList = [];
    this.selectedYear = null;
    this.departmentList = [];
    this.budgetList = [];
    const params = new HttpParams()
      .set('company_id', (this.selectedCompanyId || '').toString());
    this.apiService.getWBSYearList(params).subscribe((year) => {
      this.yearList = year;
      this.selectedYear = this.yearList.length > 0 ? this.yearList[this.yearList.length - 1].year : null;
      if (this.selectedYear) {
        this.loadBudgetsWBS();
      }
    });
  }

  onSelectYear() {
    if (!this.selectedYear) {
      return;
    }
    this.loadBudgetsWBS();
  }

  changePage() {
    if (this.onSelected === 'by-department') {
      this.loadDepartments();
    } else if (this.onSelected === 'by-budget') {
      this.loadBudgetsWBS();
    }
  }

  changePageSize() {
    this.currentPage = 1;
    if (this.onSelected === 'by-department') {
      this.loadDepartments();
    } else if (this.onSelected === 'by-budget') {
      this.loadYear();
    }
  }

  onClickCreateCentralDepartment() {
    // this.router.navigate(['/setting/department/edit-central', 99]);
    this.router.navigate(['/setting/department/create-central']);
  }

  onClickEditCentralDepartment(id: number) {
    const redirectUrl = '/setting/department/edit-central/' + id;
    this.router.navigate([redirectUrl]);
  }

  onClickDeleteCentralDepartment(content, id: number, name: string) {
    this.deletedCentralDepartmentId = id;
    this.deletedCentralDepartmentName = name;
    this.modal = this.modalService.open(content);
  }

  closeModal() {
    this.deletedCentralDepartmentId = null;
    this.deletedCentralDepartmentName = null;
    this.modal.close();
  }

  onConfirmDeleteCentralDepartment() {
    this.isLoading = true;
    this.apiService.deleteCentralDepartment(this.deletedCentralDepartmentId).subscribe(
      res => {
        this.deletedCentralDepartmentId = null;
        this.deletedCentralDepartmentName = null;
        this.isLoading = false;
        this.modal.close();
        this.loadDepartments();
      }, error => {
        this.swal.toastNotification({
          type: 'error',
          content: 'Can\'t delete'
        });
        this.isLoading = false;
      }
    );
  }
}
