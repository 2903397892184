import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserProfileComponent } from './user-profile.component';
import { UserProfileEditComponent } from './user-profile-edit/user-profile-edit.component';

export const userProfileRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'detail',
        component: UserProfileComponent
      },
      {
        path: 'edit',
        component: UserProfileEditComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(userProfileRoutes)],
  exports: [RouterModule]
})
export class UserProfileRoutingModule {}
