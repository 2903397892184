import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateListService {

  formTemplateData = null;

  constructor() {
  }

  checkTemplate(memoData, validate) {
    if (memoData.is_custom_memo_number) {
      return this.isCustomNumberMemo(memoData, validate);
    } else {
      return this.isNormalnumberMemo(memoData, validate);
    }
  }

  isCustomNumberMemo(memoData, validate) {
    const data = {
      name: memoData.name || '',
      subject: memoData.name || '',
      to: memoData.to,
      multi_to: memoData.multi_to || null,
      cc_list: memoData.cc_list,
      loa: memoData.loa,
      department: memoData.department,
      memo_number: memoData.memo_number,
      is_permission: validate.isPermissionOnlyRelated,
      reference: memoData.reference || [],
      memo_template: validate.selectedTemplate,
      announcement: memoData.announcement,
      detail: memoData.detail,
      is_custom_memo_number: memoData.is_custom_memo_number,
      company_by_selection: memoData.company_by_selection || null,
      to_none_user: memoData.to_none_user ? memoData.to_none_user : null,
      custom_header: memoData.custom_header || null,
      custom_footer: memoData.custom_footer || null,
    };
    this.validateMemo(data, memoData, validate);

    return data;
  }

  isNormalnumberMemo(memoData, validate) {
    const data = {
      name: memoData.name || '',
      subject: memoData.name || '',
      to: memoData.to,
      multi_to: memoData.multi_to || null,
      cc_list: memoData.cc_list,
      loa: memoData.loa,
      department: memoData.department,
      is_permission: validate.isPermissionOnlyRelated,
      reference: memoData.reference || [],
      memo_template: validate.selectedTemplate,
      announcement: memoData.announcement,
      detail: memoData.detail,
      is_custom_memo_number: memoData.is_custom_memo_number,
      company_by_selection: memoData.company_by_selection || null,
      memo_number: memoData.memo_number ? memoData.memo_number : null,
      to_none_user: memoData.to_none_user ? memoData.to_none_user : null,
      custom_header: memoData.custom_header || null,
      custom_footer: memoData.custom_footer || null,
    };
    this.validateMemo(data, memoData, validate);
    return data;
  }

  validateMemo(data, memoData, validate) {
    if (data['is_permission']) {
      data['announcement'] = [];
    } else {
      data['announcement'] = memoData.announcement;
    }

    if (memoData.loa_group != null) {
      data['loa_group'] = memoData.loa_group.toString();
      data['custom_loa_group_id'] = null;
    } else if (memoData['loa'] != null) {
      if (memoData['loa'].lenght > 0 && !memoData['loa_group']) {
        data['loa_group_id'] = null; // custom loa
      }
    }
  }

}
