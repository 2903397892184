import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'cutWord',
  pure: false
})
export class CutWordPipe implements PipeTransform {
  transform(items: string): any {
    const lengthOfText = 60;
    return (_.size(items) < lengthOfText) ? items : items.substring(0, lengthOfText) + '...';
  }
}
