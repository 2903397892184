import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {HttpParams} from '@angular/common/http';
import {UserService} from '../../permission/user/user.service';
import {SweetAlertService} from '../../../shared/services/sweet-alert.service';
import {ProfileService} from '../../../shared/services/profile-service';
import {NgbThaiDateParserFormatter} from '../../../core/NgDateParser';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-setting-delegate',
  templateUrl: './setting-delegate.component.html',
  styleUrls: ['./setting-delegate.component.scss']
})
export class SettingDelegateComponent implements OnInit, OnDestroy {

  modal: NgbModalRef;

  userList = [];
  showResultUser = true;
  isLoading = false;
  selectedDelegate;
  startDate;
  endDate;
  toProfile = null;
  headerSetting = null;
  headerReset = null;
  warningError = null;
  warningErrorDate = null;
  typeDelete = 'tag';
  textError: any = '';
  subscriptions = new Subscription();

  @Input() profile: any;
  @Output() submitted = new EventEmitter();

  constructor(
    private  modalService: NgbModal,
    private userService: UserService,
    private swal: SweetAlertService,
    public profileService: ProfileService,
    private dateFormat: NgbThaiDateParserFormatter,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    if (this.profile && this.profile.delegate[0]) {
      this.selectedDelegate = this.profile.delegate[0];
    }
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateContent();
    });
    this.subscriptions.add(translateSub);
    this.translateContent();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  translateContent() {
    this.headerSetting = this.translate.instant('CREATE-USER.SETTING-DELEGATE-PERMISSION');
    this.headerReset = this.translate.instant('CREATE-USER.RESET-DELEGATE-PERMISSION');
    this.warningError = this.translate.instant('CREATE-USER.SELECT-USER-TO-BE-DELEGATE-NO');
    this.warningErrorDate = this.translate.instant('MEMOS.SELECTED-PERIOD');
  }


  openSettingDelegateModal(event) {
    if (this.selectedDelegate) {
      this.toProfile = this.selectedDelegate.to_profile;
      this.endDate = this.dateFormat.parse(this.selectedDelegate.end_date);
      this.startDate = this.dateFormat.parse(this.selectedDelegate.start_date);
    }
    this.modal = this.modalService.open(event, {backdrop: 'static', keyboard: false, size: 'lg'});
    this.getUserList();
  }

  closeSettingDelegateModal() {
    this.modal.close();
  }

  confirmDelegate() {
    this.isLoading = true;
    this.selectedDelegate ? this.updateDelegate() : this.createDelegate();
  }

  clear() {
    this.toProfile = null;
    this.startDate = null;
    this.endDate = null;
  }

  getData() {
    const data = {
      from_profile: this.profile.id,
      to_profile: this.toProfile,
      start_date: this.dateFormat.format(this.startDate, true),
      end_date: this.dateFormat.format(this.endDate, true)
    };
    return data;
  }

  getTextError(error) {
    if (error.error[0]) {
      this.textError = error.error[0];
    } else if (error.error.non_field_errors[0]) {
      this.textError = error.error.non_field_errors[0];
    } else {
      this.textError = error;
    }
    return this.textError;
  }

  createDelegate() {
    if (!this.toProfile) {
      this.swal.toastNotification({type: 'warning', content: this.warningError});
    } else if (!this.endDate || !this.startDate) {
      this.swal.toastNotification({type: 'warning', content: this.warningErrorDate});
    } else {
      this.profileService.createDelegate(this.getData()).subscribe(
        delegate => {
          this.isLoading = false;
          this.modal.close();
          this.submitted.emit(delegate);
          this.selectedDelegate = delegate;
        },
        error => {
          this.isLoading = false;
          this.getTextError(error);
          this.swal.toastNotification({type: 'error', content: this.textError});
        }
      );
    }
  }

  updateDelegate() {
    if (!this.toProfile) {
      this.swal.toastNotification({type: 'warning', content: this.warningError});
    } else if (!this.endDate || !this.startDate) {
      this.swal.toastNotification({type: 'warning', content: this.warningErrorDate});
    } else {
      this.profileService.updateDelegate(this.selectedDelegate.id, this.getData()).subscribe(
        delegate => {
          this.isLoading = false;
          this.modal.close();
          this.submitted.emit(delegate);
          this.selectedDelegate = delegate;
        },
        error => {
          this.isLoading = false;
          this.getTextError(error);
          this.swal.toastNotification({type: 'error', content: this.textError});
        }
      );
    }
  }

  deleteDelegate(event) {
    this.profileService.deleteDelegate(this.selectedDelegate.id).subscribe(
      () => {
        // this.swal.toastNotification({type: 'success', content: 'Delete Success'});
        this.selectedDelegate = null;
        this.clear();
      },
      error => {
        this.swal.toastNotification({type: 'error', content: error});
      }
    );
  }

  getUserList() {
    this.userList = [];
    this.showResultUser = false;
    const generalRole = '1';
    const params = new HttpParams().set('none_pagination', 'True')
      .set('role', generalRole);
    this.userService.getUserList(params).subscribe(
      (users) => {
        this.userList = users.filter(user => user.id !== this.profile.id).map(user => {
          return {name: user.full_name, id: user.id};
        });
        this.showResultUser = true;
      },
      error => {
        // this.showResults = true;
        this.swal.toastNotification({type: 'error', content: 'Unable to load user list'});
      }
    );
  }

}
