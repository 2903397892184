import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {API_URL, BASE_URL} from '../../shared/constants/api.constant';
import {HttpClientService} from '../../shared/services/http-client.service';
import {MasterGLDropdown, MasterItem, MasterItemDropdown, MasterItemList, Pagination} from './master-item.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class MasterItemService {

  constructor(private httpLocal: HttpClient, private http: HttpClientService) {
  }

  getMasterItemList(params?): Observable<Pagination<MasterItemList>> {
    return this.http.get(API_URL.master_item, params);
  }

  getMasterItem(id): Observable<MasterItem> {
    return this.http.get(API_URL.master_item + id + '/');
  }

  createMasterItem(data: MasterItem): Observable<MasterItem> {
    return this.http.post(API_URL.master_item, data);
  }

  editMasterItem(id, data: MasterItem): Observable<MasterItem> {
    return this.http.patch(API_URL.master_item + id + '/', data);
  }

  deleteMasterItem(id) {
    return this.http.delete(API_URL.master_item + id + '/');
  }

  getBlobMasterItemExcel() {
    return this.http.getBlob(API_URL.master_item + 'export-excel/');
  }

  getBlobMasterGLExcel() {
    return this.http.getBlob(API_URL.master_gl + 'export-excel/');
  }

  getGLDropdown(params?): Observable<MasterGLDropdown[]> {
    return this.http.get(BASE_URL + '/api/dropdown', {
      type: 'gl_setting',
      ...params,
    }).pipe(map((res: any) => res.gl_setting));
  }

  getItemDropdown(query= ''): Observable<MasterItemDropdown[]> {
    return this.http.get(BASE_URL + '/api/dropdown', {
      type: 'item_detail',
      query,
    }).pipe(map((res: any) => res.item_detail));
  }
}
