import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthenticationService} from '../../shared/services/authentication.service';
import {ThemeService} from '../../shared/services/theme.service';
import {environment} from '../../../environments/environment';

interface Token {
  token: string;
  role: string;
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  // isSidebarOpen = false;
  currentUser = localStorage.currentUser;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    public themeService: ThemeService
  ) {
  }

  ngOnInit() {
    // set toUrl be "/executive/list", when redirect from email approval to website
    const toUrl = localStorage.getItem('toUrl');
    localStorage.removeItem('toUrl');

    console.log('instance : ', environment.web_instance);

    if (this.currentUser) {
      this.refreshToken(toUrl);
    } else {
      this.router.navigate(['/login']);
    }
  }

  refreshToken(navigateTo?: string) {
    this.authService.refreshToken({token: this.currentUser}).subscribe(
      (data: Token) => {
        if (navigateTo) {
          this.router.navigate([navigateTo]);
        } else {
          if (this.router.url === '/') {
            this.authService.setProfileInLocalStorage(data);
          }
        }
        localStorage.setItem('currentUser', data.token);
      },
      (error) => {
        this.router.navigate(['/login']);
      }
    );
  }

  onClickHamburger() {
    this.themeService.isSidebarOpen = !this.themeService.isSidebarOpen;
  }

  handleClickMain() {
    if (this.themeService.isMobile()) {
      this.themeService.isSidebarOpen = false;
      // this.isResized = true;
    }
  }
}
