import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThemeService} from '../../../../shared/services/theme.service';
import {NotificationService} from '../../../../shared/services/notification.service';
import {Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-theme-add',
  templateUrl: './theme-add.component.html',
  styleUrls: ['./theme-add.component.scss']
})
export class ThemeAddComponent implements OnInit {
  themeList = {
    'name': 'Hello',
    'display_email': null,
    'display_login': null,
    'display_sidebar': null,
    'display_pdf': null,
    'bg_login': null,
    'bg_color_primary': '',
    'bg_color_secondary': '',
    'active': false
  };
  formGroup: FormGroup;
  colorBgLogin: string;

  signatureColorList = [
    '#f00000',
    '#f07800',
    '#ee9600',
    '#f0b400',
    '#f0f000',
    '#b4f000',
    '#78f000',
    '#3cf000',
    '#00f078',
    '#00f0f0',
    '#00b4f0',
    '#003cf0',
    '#3c00f0',
    '#7800f0',
    '#b400f0',
    '#f000b4',
  ];
  showColorPicker = {
    'bgLogin': false,
    'bgColorBoxlogin': false,
    'bgColorHome': false,
    'textBoxlogInColor': false,
    'textColor': false,
    'hoveredTextColor': false,
    'textLoginColor': false,
    'bgColorHomeTwo': false,
    'headerTableColor': false,
    'textHeaderTableColor': false,
    'bgLoginTwo': false,
  };
  themePreview = {
    'bgLogin': '#102a44',
    'bgLoginTwo': '#2663a1',
    'bgColorBoxlogin': '#ffffff',
    'bgColorHome': '#102a44',
    'bgColorHomeTwo': '#2663a1',
    'textColor': '#ffffff',
    'hoveredTextColor': '#ADADAD',
    'textBoxlogInColor': '#102a44',
    'textLoginColor': '#ffffff',
    'headerTableColor': '#102a44',
    'textHeaderTableColor': '#ffffff',
    'display_sidebar': '',
    'display_login': '',
    'display_pdf': '',
    'display_email': ''
  };
  selectPage = 'email';
  modal: NgbModalRef;
  active = true;

  notificationError = [];

  get f(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  @Output() themeSetup = new EventEmitter();

  constructor(public themeService: ThemeService,
              private notificationService: NotificationService,
              private router: Router,
              private modalService: NgbModal,
              private builder: FormBuilder,
              private swal: SweetAlertService,
              private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.formGroup = this.builder.group({
      name: ['', Validators.required],
      display_email: ['', Validators.required],
      display_login: ['', Validators.required],
      display_sidebar: ['', Validators.required],
      display_pdf: ['', Validators.required],
    });
  }

  onSubmit() {
    const fd = new FormData();
    fd.append('name', this.formGroup.value.name);
    fd.append('display_email', this.formGroup.value.display_email);
    fd.append('display_pdf', this.formGroup.value.display_pdf);
    fd.append('display_login', this.formGroup.value.display_login);
    fd.append('bg_login_color', this.themePreview.bgLogin.toString());
    fd.append('bg_login_second_color', this.themePreview.bgLoginTwo.toString());
    fd.append('box_login_color', this.themePreview.bgColorBoxlogin.toString());
    fd.append('text_login_color', this.themePreview.textLoginColor.toString());
    fd.append('text_box_login_color', this.themePreview.textBoxlogInColor.toString());
    fd.append('display_sidebar', this.formGroup.value.display_sidebar);
    fd.append('bg_color_primary', this.themePreview.bgColorHome.toString());
    fd.append('bg_color_secondary', this.themePreview.bgColorHomeTwo.toString());
    fd.append('text_color', this.themePreview.textColor.toString());
    fd.append('hovered_text_color', this.themePreview.hoveredTextColor.toString());
    fd.append('header_table_color', this.themePreview.headerTableColor.toString());
    fd.append('text_header_table_color', this.themePreview.textHeaderTableColor.toString());
    fd.append('active', this.active.toString());
    this.themeService.createTheme(fd).subscribe(theme => {
      this.modal.close();
      this.themeSetup.emit(theme);
      if (theme.active) {
        this.getTheme();
      }
      this.swal.toastNotification({type: 'success'});
    }, error => {
      this.modal.close();
      this.swal.toastNotification({type: 'error'});
    });
  }

  getTheme() {
    this.themeService.getTheme().subscribe(theme => {
      this.themeService.setThemeList(theme);
    });
  }

  onSelectedColor(color, typeSetup) {
    this.themePreview[typeSetup] = color;
  }

  onClickColorPicker(typeSetup) {
    this.showColorPicker[typeSetup] = !this.showColorPicker[typeSetup];
  }

  closeColorPicker(content) {
    this.showColorPicker[content] = false;
  }

  openPreviewLogo(File, page) {
    const reader = new FileReader();
    reader.readAsDataURL(File); // read file as data url
    reader.onload = (event: any) => { // called once readAsDataURL is completed
      const previewImage = event.target.result;
      this.themePreview[page] = previewImage;
    };
  }

  selectedPage(typePage) {
    this.selectPage = typePage;
  }

  openModalSave(content) {
    this.notificationError = [];
    Object.keys(this.formGroup.controls).forEach(key => {
      if (this.formGroup.controls[key].invalid) {
        this.notificationError.push('VALIDATORS.' + key.toUpperCase());
      }
    });
    this.modal = this.modalService.open(content);
  }

  cancelCreateTheme() {
    this.themeSetup.emit();
  }

}
