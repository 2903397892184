import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: string): string {
    const number = parseFloat(value);
    if (isNaN(number)) {
      return value; // return the original value if it's not a number
    }
    return number.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
  }
}
