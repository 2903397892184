import { LoaModule } from '../../memos/loa/loa.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { GeneralRoutingModule } from './general-routing.module';
import { GeneralListComponent } from './general-list/general-list.component';
import { GeneralService } from './general.service';
import { CoreModule } from '../../../core/core.module';

// Migration from LOA Settings
import { GeneralLoaModalComponent } from './loa-modal/general-loa-modal.component';
import { LoaService } from './loa.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {TableModule} from 'primeng/table';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../../shared/shared.module';
import { LoaListComponent } from './loa-list/loa-list.component';
import {
  PartialSearchPipe,
  PartialSearchCodeNamePipe,
  WbsBudgetSearchPipe
} from '../../../shared/pipes/partial-search.pipe';

@NgModule({
  imports: [
    CommonModule,
    GeneralRoutingModule,
    CoreModule,
    FormsModule,
    LoaModule,
    SweetAlert2Module.forRoot(),
    TableModule,
    TranslateModule,
    SharedModule
  ],
  declarations: [
    GeneralListComponent,
    GeneralLoaModalComponent,
    LoaListComponent,
    PartialSearchPipe,
    WbsBudgetSearchPipe,
    PartialSearchCodeNamePipe
  ],
  exports: [
    GeneralListComponent,
    GeneralLoaModalComponent
  ],
  providers: [GeneralService, LoaService]
})
export class GeneralModule {
}
