import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { LaddaModule } from 'angular2-ladda';
import { GrowlModule } from 'primeng/growl';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ClickOutsideModule } from 'ng-click-outside';
import { FileDropModule } from 'ngx-file-drop';
import { ElasticModule } from 'ng-elastic';
import { FormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NavbarComponent } from '../core/navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { DeleteConfirmationModalComponent } from './delete-confirmation-modal/delete-confirmation-modal.component';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageExpireComponent } from './page-expire/page-expire.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { MemoPlaneTicketComponent } from './memo-plane-ticket/memo-plane-ticket.component';
// import { TranslatePipe } from '../translate/translate.pipe';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ClosePopoverOnOutsideClickDirective } from './directive/close-popover-directive';
import { ImageUploadPreviewComponent } from './image-upload-preview/image-upload-preview.component';

import { NgbThaiDateParserFormatter } from './NgDateParser';
import { CommentComponent } from './comment/comment.component';
import { AttachmentModalComponent } from './attachment-modal/attachment-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MentionModule } from 'angular-mentions';
import { TooltipModule, CalendarModule } from 'primeng/primeng';
import { HumanDatetimePipe } from '../shared/pipes/human-datetime.pipe';
import { THDatetimePipe } from '../shared/pipes/th-datetime.pipe';
import { SafeHtmlPipe } from '../shared/pipes/safehtml.pipe';
import { PipeArray } from '../shared/pipes/keys.pipe';
import { GroupbyPipe } from '../shared/pipes/groupby.pipe';
import { FilterPipe } from '../shared/pipes/filter.pipe';
import { FilterMemoProject } from '../shared/pipes/filter-memo-project';
import { CutWordPipe } from '../shared/pipes/cutword.pipe';
import {AdfsCallbackComponent} from './adfs-callback/adfs-callback.component';
import {InputCurrencyFormatDirective} from '../shared/directive/input-currency-format.directive';
import {CustomCurrencyFormatPipe} from '../shared/pipes/custom-currency-format.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    PerfectScrollbarModule,
    CommonModule,
    RouterModule,
    NgSelectModule,
    LaddaModule,
    NgbModule,
    TranslateModule,
    GrowlModule,
    FormsModule,
    PdfViewerModule,
    DropdownModule,
    FormsModule,
    ClickOutsideModule,
    FileDropModule,
    AngularSvgIconModule,
    ElasticModule,
    CheckboxModule,
    MentionModule,
    TooltipModule,
    CalendarModule,
  ],
  declarations: [
    NavbarComponent,
    SidebarComponent,
    LoadingSpinnerComponent,
    DeleteConfirmationModalComponent,
    HumanDatetimePipe,
    THDatetimePipe,
    SafeHtmlPipe,
    PipeArray,
    PageNotFoundComponent,
    PageExpireComponent,
    SearchbarComponent,
    MemoPlaneTicketComponent,
    // TranslatePipe,
    GroupbyPipe,
    ClosePopoverOnOutsideClickDirective,
    FilterPipe,
    ImageUploadPreviewComponent,
    FilterMemoProject,
    CutWordPipe,
    CommentComponent,
    AttachmentModalComponent,
    AdfsCallbackComponent,
    CustomCurrencyFormatPipe,
    InputCurrencyFormatDirective,
  ],
  exports: [
    NavbarComponent,
    SidebarComponent,
    NgSelectModule,
    LaddaModule,
    NgbModule,
    LoadingSpinnerComponent,
    DeleteConfirmationModalComponent,
    GrowlModule,
    HumanDatetimePipe,
    THDatetimePipe,
    SafeHtmlPipe,
    GroupbyPipe,
    SearchbarComponent,
    MemoPlaneTicketComponent,
    ClickOutsideModule,
    ElasticModule,
    AngularSvgIconModule,
    PipeArray,
    ClosePopoverOnOutsideClickDirective,
    FilterPipe,
    PerfectScrollbarModule,
    ImageUploadPreviewComponent,
    CommentComponent,
    AttachmentModalComponent,
    CalendarModule,
    AdfsCallbackComponent,
    CustomCurrencyFormatPipe,
    InputCurrencyFormatDirective,
  ],
  providers: [
    NgbThaiDateParserFormatter,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbThaiDateParserFormatter,
    },
  ],
})
export class CoreModule {}
