import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { UserProfileService } from '../../modules/user-profile/user-profile.service';
import { TitleService } from '../../shared/services/title-service';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SweetAlertService } from '../../shared/services/sweet-alert.service';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';
import { Notification, Result } from './navbar.models';
import { MemoService } from '../../../app/modules/memos/memo/memo.service';
import {ThemeList, ThemeService} from '../../shared/services/theme.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  photo: string;
  name: string;
  showUser = false;
  showLogo = false;
  fullWidth: number;
  flags: SelectItem[];
  selectedFlags: string;
  profile: any;
  titleSubject: Subscription;
  title: string;
  isAdmin = false;
  notificationsUnread: Array<Notification>;
  notificationsRead: Array<Notification>;
  notificationResult: Result;
  notificationHeader: string;
  showNotificationToggle = false;
  earlier: string;
  loadMore: string;

  page = 1;
  isLoading = false;
  typeNotification = {
    tag_comment_on_memo: 0,
    comment_reply_on_memo: 1,
    new_announcement: 2,
    new_post_department: 3,
    new_post_public: 4,
    new_post_and_mention: 5,
    new_comment: 6,
    new_comment_mention: 7,
    new_comment_tag_comment: 8,
    memo_new_req_for_approval: 9,
    memo_update_status: 10,
  };

  checkLanguage = false;
  themeList: ThemeList;
  hoverList = {
    'notification': false
  };

  subscriptions = new Subscription();

  constructor(
    private userProfileService: UserProfileService,
    private titleService: TitleService,
    private location: Location,
    private router: Router,
    private apiService: MemoService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private swal: SweetAlertService,
    private themeService: ThemeService
  ) {
    this.titleSubject = this.titleService.getTitle().subscribe((title) => {
      this.title = title.title;
    });
    this.themeService.data.subscribe(theme => {
      this.themeList = theme;
    });
  }

  ngOnInit() {
    this.photo = localStorage.getItem('photoUrl');
    this.name = localStorage.getItem('name');
    // const id = localStorage.getItem('profile_id');
    this.isAdmin = localStorage.getItem('role_name') === 'Admin';
    this.showUser = true;
    this.fullWidth = document.documentElement.clientWidth;
    this.showLogo = this.fullWidth < 992;
    this.flags = [
      { label: 'English', value: 'en' },
      { label: 'Thai', value: 'th' },
    ];
    this.selectedFlags = localStorage.getItem('lang');
    // this.selectLang('en');
    this.getAllNotification();
    this.translateNotification();
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateNotification();
    });
    this.subscriptions.add(translateSub);
  }

  translateNotification() {
    this.notificationHeader = this.translate.instant('NAV-BAR.NOTIFICATION');
    this.earlier = this.translate.instant('NAV-BAR.EARLIER');
    this.loadMore = this.translate.instant('NAV-BAR.LOAD-MORE');
    if (this.translate.currentLang === 'en') {
      this.checkLanguage = true;
    } else {
      this.checkLanguage = false;
    }
  }

  getAllNotification() {
    this.notificationService.getAllNotification().subscribe(
      (notification) => {
        this.notificationResult = notification;
        [this.notificationsUnread, this.notificationsRead] = _.partition(
          this.notificationResult.results,
          ['read', false]
        ); //
        this.notificationsUnread.forEach((obj) => {
          this.notificationService.createThaiDescription(obj);
        });
        this.notificationsRead.forEach((obj) => {
          this.notificationService.createThaiDescription(obj);
        });
      },
      (err) => {
        this.swal.toastNotification({ type: 'error', content: err.status });
      }
    );
  }

  canCreateUser() {
    return this.router.url === '/permission/profile/list';
  }

  ngOnDestroy(): void {
    this.titleSubject.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  back() {
    this.location.back();
  }

  openNotification() {
    // if (this.notificationResult.results.length === 0) {
    //   return;
    // }
    this.showNotificationToggle = !this.showNotificationToggle;
  }

  closeNotification() {
    this.showNotificationToggle = false;
  }

  // Notification
  clickNotification(notification: Notification) {
    this.notificationService.updateIsRead(notification.id).subscribe(
      (res) => {
        _.forEach(this.notificationResult.results, (obj) => {
          if (res.id === obj.id && obj.read === false) {
            obj.read = res.read;
            this.notificationResult.unread_count =
              this.notificationResult.unread_count - 1;
          }
        });
        [this.notificationsUnread, this.notificationsRead] = _.partition(
          this.notificationResult.results,
          ['read', false]
        );
      },
      (err) => {
        this.swal.toastNotification({ type: 'error', content: err.status });
      }
    );
    this.notificationRouter(notification);
  }

  notificationRouter(notification: Notification) {
    this.showNotificationToggle = !this.showNotificationToggle;
    switch (notification.action) {
      case this.typeNotification.tag_comment_on_memo:
      case this.typeNotification.memo_update_status:
      case this.typeNotification.comment_reply_on_memo:
        return this.navigateToPage('/memo/memo/real/list/', notification.memo);
      case this.typeNotification.memo_new_req_for_approval:
        return this.navigateToPage('/executive/list/', notification.memo);
      case this.typeNotification.new_announcement:
        return this.navigateToPage(
          '/feed/list/announcement/',
          notification.announcement
        );
      default:
        return this.navigateToPage('/feed/list/', notification.news_feed_post);
    }
  }

  navigateToPage(url: string, id: number) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url + id]);
    });
  }

  loadMoreNotification() {
    if (
      this.notificationResult.results.length < this.notificationResult.count
    ) {
      this.isLoading = true;
      this.page += 1;
      const params = new HttpParams().set('page', this.page.toString());
      this.notificationService.getAllNotification(params).subscribe(
        (res) => {
          _.forEach(res.results, (moreNotification) => {
            this.notificationService.createThaiDescription(moreNotification);
            this.notificationResult.results.push(moreNotification);
          });
          [this.notificationsUnread, this.notificationsRead] = _.partition(
            this.notificationResult.results,
            ['read', false]
          );
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.swal.toastNotification({ type: 'error', content: err.status });
        }
      );
    }
  }
  hoverText(type, value) {
    this.hoverList[type] = value;
  }
}
