import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../../shared/constants/api.constant';

@Injectable()
export class ApproveService {

  constructor(private http: HttpClient) {

  }

  getMemoDetail(header) {
    return this.http.get(API_URL.guest_approve_memo, {headers: header});
  }

  updateMemo(header, data) {
    return this.http.post(API_URL.guest_approve_memo, data, {headers: header});
  }

  getAttachmentList(header, params) {
    return this.http.get(API_URL.memo_attachment, {params: params, headers: header});
  }

  getCommentList(header, params) {
    return this.http.get(API_URL.memo_comment, {params: params, headers: header});
  }

  createNewComment(header, data) {
    return this.http.post(API_URL.memo_comment, data, {headers: header});
  }

  deleteComment(header, id) {
    return this.http.delete(API_URL.memo_comment + id + '/', {headers: header});
  }

  updateProfile(header, id: number, data) {
    return this.http.patch(API_URL.profiles + id + '/', data, {headers: header});
  }
}
