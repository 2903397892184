import { ProfileService } from '../../../../../shared/services/profile-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, EventEmitter, Injectable, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { MemoService } from '../../memo.service';
import { HttpParams } from '@angular/common/http';
import {
  NgbCalendar,
  NgbDate, NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbDropdownConfig,
  NgbModal,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SweetAlertService } from '../../../../../shared/services/sweet-alert.service';
import { TitleService } from '../../../../../shared/services/title-service';
import { HttpClientService } from '../../../../../shared/services/http-client.service';
import { NgbThaiDateParserFormatter } from '../../../../../core/NgDateParser';
import { CALENDAR_LOCALE } from 'src/app/shared/services/constant';
import { LocaleSettings } from 'primeng/calendar';
import { ThemeList, ThemeService } from '../../../../../shared/services/theme.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../../../shared/services/authentication.service';
import { map } from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';


@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private tranlate: TranslateService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    const en = CALENDAR_LOCALE.EN;
    const th = CALENDAR_LOCALE.TH;
    const dataLanguage = this.tranlate.currentLang === 'en' ? en : th;
    return dataLanguage.dayNamesShort[weekday - 1];
  }

  getMonthShortName(month: number): string {
    const en = CALENDAR_LOCALE.EN;
    const th = CALENDAR_LOCALE.TH;
    const dataLanguage = this.tranlate.currentLang === 'en' ? en : th;
    return dataLanguage.monthNamesShort[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-memo-real-list',
  templateUrl: './memo-real-list.component.html',
  styleUrls: ['./memo-real-list.component.scss'],
  providers: [NgbDropdownConfig, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}],
})
export class MemoRealListComponent implements OnInit, OnDestroy {
  count_all = 0;
  count_draft = 0;
  count_pending = 0;
  count_rejected = 0;
  count_approved = 0;
  count_terminated = 0;
  memoList = [];
  memoName: string;
  remark: string;
  departmentList = [];
  department: string;
  fileDepartment;
  count = 0;
  currentPage = 1;
  showResults = false;
  pendingLoading = false;
  documentData: any;
  data: any;
  isEdit = false;
  isCreate = false;
  isTemplate = false;
  isSetting = false;
  showDetail = false;
  showPreview = false;
  ordering = '-created_at';
  sort1 = 'fas fa-sort-down';
  sort2 = 'fas fa-sort-down';
  sort3 = 'fas fa-sort';
  searchValue = '';
  searchCreatorName = '';
  type: string;
  createdUser = '';
  isMobile = false;
  parentViewType = 0;
  fade = 'out';
  viewType = {
    name: 'All Memos',
    value: 1
  };
  fullWidth: number;
  selectedMemo: any;
  isLoading = false;
  isLoadingCSV = false;

  status = '';
  statusHolder = '';
  statusType: string;
  documentStatus: any;
  paginationDropdown = ['10', '20', '30', '40', '50'];
  visibility = 'All Memos';
  visibilityStatus = [
    {
      name: 'All Memos',
      id: 'All Memos'
    },
    {
      name: 'Related To Me',
      id: 'Related To Me'
    },
    {
      name: 'Created By Me',
      id: 'Created By Me'
    }
  ];
  wordCloudType = 'word_cloud';
  uploadType = 'upload';
  itemsPerPage = 10;
  createdName = '';
  attachmentCurrentPage = 1;
  showAttachmentPreview = false;

  modal: NgbModalRef;
  memoForDelete: any;
  pdfFile: string;
  isAdmin = false;
  memoID: number;
  attachmentList = [];
  attachmentCount = 0;
  profile;
  name: any;
  viewAll: any;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  rangeDate = '';


  maxSizePage: number;

  // createdAtBefore: string = moment().subtract(60, 'days').format('YYYY-MM-DD');
  // createdAtAfter: string = moment().format('YYYY-MM-DD');
  createdAtBefore: string;
  createdAtAfter: string;
  // notiSelectedMemoSubject: Subscription;

  calendarLocale: LocaleSettings = null;
  startDate: Date;
  rangeDates: Date[] = [new Date(this.createdAtBefore), new Date(this.createdAtAfter)];
  themeList: ThemeList;
  memoType = [
    {value: 0, label: 'memo'},
    {value: 1, label: 'budget'},
  ];
  templateMemo = [
    {name: 'Asset - แบบฟอร์มการขอจำหน่าย/จ่าย/โอน/ทิ้ง ทรัพย์สิน', value: 'asset'},
    {name: 'Internal - แบบฟอร์มขออนุมัติเรื่องทั่วไป', value: 'internal'},
    {name: 'Expense Claim - แบบฟอร์มการขอเบิกจ่าย/เคลียร์ค่าใช้จ่ายต่างๆ', value: 'expenses claim'},
    {name: 'Budget Request - แบบฟอร์มขอเพิ่มงบประมาณ', value: 'budget request'},
    {name: 'Budget Transfer - แบบฟอร์มขอโอนงบประมาณ', value: 'budget transfer'}];
  wbsList = [];
  selectedWBS = [];
  selectedTemplateMemo;
  yearList = [];
  selectedYear;
  yearFromBudgetDashboard;
  isLoadingYear = false;
  isLoadingWBS = false;
  isLoadingCompany = false;
  companyList = [];
  selectedCompany;
  company_id;
  companyFromDashboard = null;
  subscriptions = new Subscription();
  profileList = null;
  isLoadingProfile = false;
  selectedProfile = null;
  selectedMemoIdForResendEmail: number = null;

  @ViewChild('attachmentModal', {static: false}) attachmentModal: ElementRef;
  @ViewChild('noPermissionModal', {static: true}) noPermissionModal: ElementRef;
  @ViewChild('dateFilter', undefined) datesRangeFilter: { hideOverlay: () => void; };
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSuccess = new EventEmitter();

  constructor(
    private memoService: MemoService,
    private notification: NotificationService,
    private modalService: NgbModal,
    private dropdownConfig: NgbDropdownConfig,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private messageService: MessageService,
    private translate: TranslateService,
    private swal: SweetAlertService,
    private titleService: TitleService,
    private router: Router,
    private notificationService: NotificationService,
    private httpClientService: HttpClientService,
    private dateFormat: NgbThaiDateParserFormatter,
    private themeService: ThemeService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private apiService: MemoService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
  ) {
    dropdownConfig.placement = 'bottom-right';
    dropdownConfig.autoClose = false;
    this.themeService.data.subscribe(theme => {
      this.themeList = theme;
    });
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    if (environment.web_instance === 'BG') {
      this.templateMemo = this.templateMemo.concat([
        {name: 'แบบฟอร์มซื้อผลิตภัณฑ์ของ BGPU', value: 'BGPU product sell'}
      ]);
    }
  }

  ngOnInit() {
    this.isAdmin = this.authenticationService.isAdmin();
    const queryParamSub = this.route.queryParamMap.subscribe((params) => {
      if (params['params'].wbs_id) {
        this.selectedWBS.push(+params['params'].wbs_id);
      }
      if (params['params'].status) {
        // this.status = params['params'].status;
        this.statusHolder = params['params'].status;
        // this.selectedTemplateMemo = 'expenses claim';
      }
      if (params['params'].company_from_dashboard) {
        this.companyFromDashboard = params['params'].company_from_dashboard;
      }
      if (params['params'].year) {
        this.yearFromBudgetDashboard = params['params'].year;
      }
    });
    this.subscriptions.add(queryParamSub);
    this.selectedCompany = this.getCompany();
    this.handleCalendarLocale();
    this.titleService.setTitle(this.translate.instant('MEMOS.MEMOS'));
    this.translateVisibilityStatus();
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateVisibilityStatus();
      this.handleCalendarLocale();
    });
    this.subscriptions.add(translateSub);
    this.loadCompanyList();
    this.getUserProfile();
    this.checkParams();
    this.loadYear();
    this.loadProfile();
    this.isMobile = this.themeService.isMobileSmallScreen();
    // window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadYear() {
    this.isLoadingYear = true;
    let params = new HttpParams()
      .set('type', 'wbs_year')
      .set('is_admin', this.authenticationService.isAdmin().toString());
    if (!this.isAdmin) {
      params = params.set('company', this.selectedCompany || '');
    }
    this.memoService.getDropDown(params).subscribe((data) => {
      this.yearList = data.wbs_year;
      if (this.yearFromBudgetDashboard) {
        this.selectedYear = this.yearFromBudgetDashboard;
      }
      this.isLoadingYear = false;
      this.loadWBS();
    });
  }

  loadCompanyList() {
    this.isLoadingCompany = true;
    const params = new HttpParams().set('type', 'company');
    this.memoService.getDropDown(params)
      .pipe(map(res => res['company'].map(c => Object({
        label: c['context']['code_name'],
        value: c['value']
      }))))
      .subscribe(companyList => {
        this.companyList = [...this.companyList, ...companyList];
        this.selectedCompany = +this.companyFromDashboard || null;
        this.isLoadingCompany = false;
      });
  }

  loadWBS() {
    this.isLoadingWBS = true;
    this.wbsList = [];
    let params = new HttpParams()
      .set('type', 'wbs')
      .set('year', this.selectedYear || '');
    if (!this.isAdmin) {
      params = params.set('company', this.selectedCompany || '');
    }
    this.apiService.getDropDown(params).subscribe((wbs) => {
        this.wbsList = wbs.wbs;
        this.isLoadingWBS = false;
        this.loadMemoReal(true);
      },
      error => this.notification.showNotification(error.status)
    );
  }

  loadProfile() {
    this.isLoadingProfile = true;
    this.profileList = [];
    const params = new HttpParams()
      .set('type', 'profile');
    this.apiService.getDropDown(params).subscribe((res) => {
      this.profileList = res.profile;
      this.isLoadingProfile = false;
    });
  }

  filterProfile() {
    this.currentPage = 1;
    this.statusHolder = this.status;
    this.status = '';
    this.loadMemoReal(true);
  }


  filterWBS() {
    this.currentPage = 1;
    this.statusHolder = this.status;
    this.status = '';
    this.loadMemoReal(true);
  }

  filterMemoType() {
    this.currentPage = 1;
    this.statusHolder = this.status;
    this.status = '';
    this.loadMemoReal(true);
  }

  checkParams() {
    this.activatedRoute.params.subscribe(params => { // handle routing by id
      this.currentPage = params.page || 1;
      this.memoID = params.memo;
      this.checkScreenSize();
      // this.loadDepartmentList();
      // this.loadMemoReal(true);

      if (this.memoID) {
        this.memoService.getMemoRealDetail(this.memoID).subscribe(
          (memo: any) => {
            this.clickMemo(memo);
            this.toggleChat(memo);
          },
          err => {
            this.swal.toastNotification({type: 'error', content: err.status});
          }
        );
      }
    });
  }

  // ngOnDestroy() {
  //   localStorage.removeItem('notify-memo');
  // }

  translateVisibilityStatus() {
    this.titleService.setTitle(this.translate.instant('MEMOS.MEMOS'));
    this.visibilityStatus = [
      {
        name: this.translate.instant('MEMOS.ALL-MEMOS'),
        id: 'All Memos'
      },
      {
        name: this.translate.instant('MEMOS.RELATED-TO-ME'),
        id: 'Related To Me'
      },
      {
        name: this.translate.instant('MEMOS.CREATED-BY-ME'),
        id: 'Created By Me'
      }
    ];
  }

  handleCalendarLocale() {
    if (this.translate.currentLang === 'en') {
      this.calendarLocale = CALENDAR_LOCALE.EN;
    } else {
      this.calendarLocale = CALENDAR_LOCALE.TH;
    }
  }

  getUserProfile() {
    this.documentStatus = [
      {
        name: this.translate.instant('MEMOS.DRAFT'),
        value: 'draft'
      },
      {
        name: this.translate.instant('MEMOS.PENDING'),
        value: 'pending'
      },
      {
        name: this.translate.instant('MEMOS.APPROVED'),
        value: 'approved'
      },
      {
        name: this.translate.instant('MEMOS.REJECT'),
        value: 'rejected'
      },
      {
        name: this.translate.instant('MEMOS.NOT-APPROVED'),
        value: 'terminated'
      }
    ];

    this.profileService.loadInputData().subscribe((profile: any) => {
      this.profile = profile;
      this.viewAll = profile.is_view_all_memo;
      this.loadDepartmentList();
    });
  }

  loadDepartmentList() {
    if (this.isAdmin || this.profile.level >= 7) {
      const params = new HttpParams()
        .set('type', 'department')
        .set('company', (this.getCompany() || '').toString());
      this.memoService.getDropDown(params).subscribe(
        (departments: any) => {
          this.departmentList = departments.department;
        },
        error => {
          this.swal.toastNotification({type: 'error', content: error.status});
        }
      );
    } else {
      this.departmentList = [];
      const departmentName = this.profile.department_name.split(',');
      for (let i = 0; i < departmentName.length; i++) {
        this.departmentList.push({value: this.profile.department[i], label: departmentName[i]});
      }
    }
  }

  getParameter() {
    let params = new HttpParams()
      .set('page_size', this.itemsPerPage.toString())
      .set('visibility', this.visibility || 'All Memos')
      .set('page', this.currentPage.toString())
      .set('department', this.department || '')
      .set('search', this.memoName || '')
      .set('upload_type', this.type || '')
      .set('status', this.status || '')
      .set('created_user', this.createdUser || '')
      .set('created_name', this.searchCreatorName || '')
      .set('created_at_before', this.createdAtBefore || '')
      .set('created_at_after', this.createdAtAfter || '')
      .set('wbs', this.selectedWBS.toString() || '')
      .set('memo_template', this.selectedTemplateMemo || '')
      .set('ordering', this.ordering)
      .set('year_wbs', this.selectedYear || '');

    if (this.authenticationService.isAdmin() && this.selectedCompany) {
      params = params.set('admin_select_company', this.selectedCompany);
    }
    if (this.authenticationService.isAdmin() && this.selectedProfile) {
      params = params.set('admin_select_profile', this.selectedProfile);
    }
    return params;
  }


  loadMemoReal(updateCounter = true) {
    this.showResults = false;
    const params: any = this.getParameter();
    this.memoService.getMemoRealList(params).subscribe(
      (memos: any) => {
        this.count = memos.count;
        this.memoList = memos.results;
        if (updateCounter) {
          this.count_all = memos.count;
          this.count_draft = memos.draft_count;
          this.count_pending = memos.pending_count;
          this.count_rejected = memos.rejected_count;
          this.count_approved = memos.approved_count;
          this.count_terminated = memos.terminated_count;
          this.status = this.statusHolder;
          if (this.status !== '') {
            this.loadMemoReal(false);
          }
        }
        this.showResults = true;
      },
      error => {
        this.swal.toastNotification({type: 'error', content: error.status});
      }
    );
  }

  onFilterChange(searchValue) {
    this.currentPage = 1;
    this.memoName = searchValue;
    this.statusHolder = this.status;
    this.status = '';
    this.loadMemoReal(true);
  }

  filterDateAndDepartment() {
    this.currentPage = 1;
    this.statusHolder = this.status;
    this.status = '';
    this.loadMemoReal(true);
  }

  filterByVisibility(e) {
    this.currentPage = 1;
    this.visibility = e.id.trim().toLowerCase();
    this.statusHolder = this.status;
    this.status = '';
    this.loadMemoReal(true);
  }

  setStatus(e) {
    this.currentPage = 1;
    this.status = e.trim().toLowerCase();
    this.loadMemoReal(false);
  }

  // filterByStatus(e) {
  //   this.currentPage = 1;
  //   if (e) {
  //     if (e.value === 'draft') {
  //       this.status = 'draft';
  //     } else if (e.value === 'pending') {
  //       this.status = e.value;
  //     } else {
  //       this.status = e.value;
  //     }
  //   } else {
  //     this.type = '';
  //     this.status = '';
  //   }
  //   this.loadMemoReal(false);
  // }

  changePageSize(e) {
    this.currentPage = 1;
    this.itemsPerPage = e;
    this.loadMemoReal(false);
  }

  changePage() {
    this.loadMemoReal(false);
  }

  clear() {
    this.department = undefined;
    this.memoName = '';
    this.remark = '';
    this.type = undefined;
    this.status = undefined;
    this.createdName = '';
    this.createdAtBefore = null;
    this.createdAtAfter = null;
    this.loadMemoReal(false);
  }

  search() {
    this.currentPage = 1;
    this.loadMemoReal(false);
  }

  preview(memo) {
    memo['preview'] = true;
    this.data = memo;
    if (memo.signed_document) {
      this.documentData = memo.signed_document;
    } else {
      this.documentData = memo;
    }
    this.showPreview = !this.showPreview;
  }

  closePreview(e) {
    this.showPreview = !this.showPreview;
    this.loadMemoReal(false);
  }

  refreshMemoList() {
    this.loadMemoReal(false);
  }

  checkScreenSize() {
    this.fullWidth = document.documentElement.clientWidth;
    if (this.fullWidth < 992) {
      this.isMobile = true;
    }
  }

  selectView(type) {
    this.isEdit = false;
    this.viewType = type;
    this.selectedMemo = null;
    if (type.value === 0) {
      this.createdUser = localStorage.getItem('user_id');
    } else {
      this.createdUser = '';
    }
    this.currentPage = 1;
    this.loadMemoReal(false);
  }

  open(content, memo) {
    this.memoForDelete = memo;
    this.selectedMemo = memo;
    this.modal = this.modalService.open(content);
    this.getAttachmentList(memo);
  }

  getAttachmentList(memo) {
    const params = new HttpParams()
      .set('page', this.attachmentCurrentPage.toString())
      .set('memo', memo.id);
    this.memoService.getMemoAttachment(params).subscribe(
      attachments => {
        this.attachmentList = attachments['results'];
        this.attachmentCount = attachments['count'];
      },
      error => {
        this.notification.showNotification(error.status);
      }
    );
  }

  openUploadModal(content) {
    this.modal = this.modalService.open(content);
  }

  confirmDelete(memo) {
    this.memoService.deleteMemoReal(memo.id).subscribe(
      () => {
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        this.showDetail = false;
        this.memoList = this.memoList.filter(obj => obj.id !== memo.id);
        this.selectedMemo = null;
        this.statusHolder = this.status;
        this.status = '';
        this.loadMemoReal(true);
      },
      error => {
        this.swal.toastNotification({type: 'error', content: error.status});
      }
    );
  }

  closeToggle() {
    this.statusHolder = this.status;
    this.status = null;
    this.loadMemoReal(true);
    this.resetVariableSentToChild();
    this.showDetail = false;
    this.fade = 'out';
  }

  clickMemo(memo, gotoEdit = false, index?) {
    if (!this.checkIfRelated(memo)) {
      this.modalService.open(this.noPermissionModal);
      return;
    }

    this.selectedMemo = null;
    this.showDetail = true;
    setTimeout(() => {
      this.fade = 'in';
    }, 100);
    setTimeout(() => {
      this.selectedMemo = memo;
      this.loadPreviewData(memo);
      this.isEdit = gotoEdit;
      this.isCreate = false;
      this.isTemplate = false;
      this.isSetting = false;
      this.parentViewType = 0;
    }, 1);
  }

  toggleChat(memo) {
    if (!this.checkIfRelated(memo)) {
      this.modalService.open(this.noPermissionModal);
      return;
    }
    this.selectedMemo = null;
    this.showDetail = true;
    this.parentViewType = 1;
    setTimeout(() => {
      this.fade = 'in';
    }, 100);
    setTimeout(() => {
      this.parentViewType = 1;
      this.selectedMemo = memo;
      this.loadPreviewData(memo);
      this.isEdit = false;
      this.isCreate = false;
      this.isTemplate = false;
      this.isSetting = false;
    }, 1);
  }

  toggleAttachment(memo, index?) {

    if (!this.checkIfRelated(memo)) {
      this.modalService.open(this.noPermissionModal);
      return;
    }
    this.selectedMemo = null;
    this.showDetail = true;
    this.parentViewType = 2;
    setTimeout(() => {
      this.fade = 'in';
    }, 100);
    setTimeout(() => {
      this.parentViewType = 2;
      this.selectedMemo = memo;
      this.loadPreviewData(memo);
      this.isEdit = false;
      this.isCreate = false;
      this.isTemplate = false;
      this.isSetting = false;
    }, 1);
  }

  loadPreviewData(data) {
    this.pdfFile = data.signed_document;
    this.pdfFile = null;
    this.memoService.getMemoRealDetail(data.id).subscribe(
      (preview: any) => {
        // const blob = new Blob([preview.attachments[0].file], {type: 'application/pdf;base64'});
        // this.pdfFile = URL.createObjectURL(blob);
        if (preview.signature_position) {
          this.selectedMemo.signature_position = preview.signature_position;
        }
        if (preview.signed_document) {
          this.pdfFile = preview.status !== 'draft' ? preview.signed_document : preview.word_cloud_pdf;
        } else {
          this.pdfFile = preview.word_cloud_pdf;
        }

      }
    );
  }

  order(type) {
    if (type === 1) {
      if (this.ordering === 'memo_number') {
        this.ordering = '-memo_number';
        this.sort1 = 'fas fa-sort-up';
      } else {
        this.ordering = 'memo_number';
        this.sort1 = 'fas fa-sort-down';
      }
      this.sort2 = 'fas fa-sort';
      this.sort3 = 'fas fa-sort';
    } else if (type === 2) {
      if (this.ordering === 'created_at') {
        this.ordering = '-created_at';
        this.sort2 = 'fas fa-sort-down';
      } else {
        this.ordering = 'created_at';
        this.sort2 = 'fas fa-sort-up';
      }
      this.sort1 = 'fas fa-sort';
      this.sort3 = 'fas fa-sort';
    } else {
      if (this.ordering === 'name') {
        this.ordering = '-name';
        this.sort3 = 'fas fa-sort-up';
      } else {
        this.ordering = 'name';
        this.sort3 = 'fas fa-sort-down';
      }
      this.sort1 = 'fas fa-sort';
      this.sort2 = 'fas fa-sort';
    }
    this.loadMemoReal(false);
  }

  toggleEdit(memo?) {
    if (memo) {
      this.selectedMemo = memo;
    }
    this.isCreate = false;
    this.isTemplate = false;
    this.isSetting = false;
    this.isEdit = true;
  }

  editSuccess(memo) {
    this.resetVariableSentToChild();
    this.isEdit = false;
    if (memo.ready) { // check if the memo is ready (click saving button) see issue #216
      const index = this.memoList.findIndex(obj => obj.id === memo.id);
      this.memoList[index] = memo;
      this.clickMemo(memo);
    } else {
      this.closeToggle();
    }
  }

  updatePareantMemoListFromChildOutput(memo) {
    const index = this.memoList.findIndex(obj => obj.id === memo.id);
    this.memoList[index] = memo;
  }

  clickCreate() {
    this.toggleDetail();
    this.isTemplate = false;
    this.isEdit = false;
    this.isCreate = false;
    this.isSetting = false;
    this.createUploadMemo();
  }

  clickTemplate() {
    // this.notification.showDisabledWarning()
    // this.toggleDetail();
    // this.isTemplate = true;
    // this.isEdit = false;
    // this.isCreate = false;
    // this.isSetting = false;
  }

  clickSetting() {
    this.toggleDetail();
    this.isTemplate = false;
    this.isEdit = false;
    this.isCreate = false;
    this.isSetting = true;
  }

  clickWordCloud() {
    this.toggleDetail();
    this.isTemplate = false;
    this.isEdit = false;
    this.isCreate = false;
    this.isSetting = false;
    this.createWordCloudMemo();
  }

  afterCreate(memo) {
    this.pendingLoading = false;

    // go to edit page.
    this.clickMemo(memo, true);
    this.memoList.unshift(memo);
    // refresh list in main page.
    // this.loadMemoReal();
  }

  cancelCreate() {
    this.isCreate = false;
  }

  checkIfSelected(memo) {
    return _.get(this.selectedMemo, 'id', '') === memo.id;
  }

  createOfflineMemo() {
    if (!_.isNil(this.fileDepartment.special_type)) {
      this.createSecretMemo();
    } else {
      this.isLoading = true;
      const data = {
        upload_type: 'offline',
        department: this.fileDepartment.id
      };
      this.memoService.createRealMemo(data).subscribe(
        res => {
          this.isLoading = false;
          if (this.modal) {
            this.modal.close();
          }
          this.afterCreate(res);
        },
        error => {
          this.isLoading = false;
          this.swal.toastNotification({type: 'error', content: error.status});
          if (this.modal) {
            this.modal.close();
          }
        }
      );
    }
  }

  createSecretMemo() {
    this.isLoading = true;
    const data = {
      upload_type: 'offline',
      department: null,
      special_type: this.fileDepartment.special_type
    };
    this.memoService.createRealMemo(data).subscribe(
      res => {
        this.isLoading = false;
        if (this.modal) {
          this.modal.close();
        }
        this.afterCreate(res);
      },
      error => {
        this.isLoading = false;
        this.swal.toastNotification({type: 'error', content: error.status});
        if (this.modal) {
          this.modal.close();
        }
      }
    );
  }

  createWordCloudMemo() {
    this.isLoading = true;
    const data = {
      upload_type: 'word_cloud_draft',
      department: null,
      special_type: null,
      is_permission: true
    };
    this.memoService.createRealMemo(data).subscribe(
      (res) => {
        this.isLoading = false;
        this.afterCreate(res);
      },
      error => {
        this.isLoading = false;
        this.swal.toastNotification({type: 'error', content: error.status});
      }
    );
  }

  createUploadMemo() {
    this.isLoading = true;
    const data = {
      memo_type: this.uploadType,
      department: null,
      special_type: null
    };
    this.memoService.createRealMemo(data).subscribe(
      (res) => {
        this.isLoading = false;
        this.afterCreate(res);
      },
      error => {
        this.isLoading = false;
        this.swal.toastNotification({type: 'error', content: error.status});
      }
    );
  }

  setLoadingSpinner() {
    this.pendingLoading = true;
  }

  toggleDetail() {
    this.showDetail = !this.showDetail;
    setTimeout(() => {
      this.fade = this.fade === 'in' ? 'out' : 'in';
    }, 100);
  }

  updateMemo(memo, color) {
    const data = {
      color_code: color || memo.color_code
    };
    this.memoService.updateMemoReal(memo.id, data).subscribe(
      (res: any) => {
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
        const memoIndex = this.memoList.findIndex(obj => obj.id === res.id);
        this.memoList[memoIndex] = res;
      },
      error => {
        this.swal.toastNotification({type: 'error', content: error.status});
      }
    );
  }

  previewAttachment(attachment) {
    this.modal.close();
    this.documentData = attachment.file;
    this.showAttachmentPreview = true;
  }

  closeAttachmentPreview() {
    this.showAttachmentPreview = false;
    if (this.attachmentModal) {
      this.open(this.attachmentModal, this.selectedMemo);
    }
  }

  getExtention({filename}) {
    return filename.substr(filename.lastIndexOf('.') + 1);
  }

  changeAttachmentPage() {
    this.getAttachmentList(this.selectedMemo);
  }

  checkIfRelated(memo) {

    // if (localStorage.role_name && localStorage.role_name === 'Admin') {
    return true;
    // }
    //
    // if (memo.department_name == 'ประกาศ' || memo.department_name == 'คำสั่ง')
    //   return true;
    //
    // const isOwner = _.toNumber(localStorage.user_id) === memo.created_user;
    //
    // let isApprover = false;
    // if (memo.approver && memo.approver[0].approver) {
    //   isApprover = _.toNumber(localStorage.user_id) === memo.approver[0].approver.user;
    // }
    //
    // let isLOA = false;
    // if (memo.loa) {
    //   const loaList = memo.loa.map(obj => obj.profile);
    //   isLOA = loaList.includes(_.toNumber(localStorage.profile_id));
    // }
    //
    // return isOwner || isApprover || isLOA ;
  }

  resetVariableSentToChild() {
    this.selectedMemo = null;
    this.parentViewType = 0;
  }

  checkStatus(value) {
    return this.status === value;
  }

  openModal(content) {
    this.modal = this.modalService.open(content, {size: 'md'});
  }

  generateReportExcel() {
    const msg = [];
    if (!this.createdAtAfter || !this.createdAtBefore) {
      msg.push(this.translate.instant('MEMOS.PLEASE-SELECTED-PERIOD'));
    }
    if (msg.length > 0) {
      this.swal.toastNotification({type: 'error', content: msg.join(', ')});
      return;
    } else {
      this.isLoadingCSV = true;
      const params: any = this.getParameter()
        .set('none_pagination', 'True');
      this.memoService.getMemoListCSV(params).subscribe(
        res => {
          const filename = 'Memo List.csv';
          this.httpClientService.createDownloadFile(res, filename);
          this.isLoadingCSV = false;
          this.modal.close();
        },
        err => {
          this.isLoadingCSV = false;
          const errorMsg = this.translate.instant('MEMOS.MORE-THAN-3-MONTHS');
          this.swal.toastNotification({type: 'error', content: errorMsg});
        }
      );
    }
  }

  onSelectRange(selectedValue: Date) {
    if (this.rangeDates[0]) {
      this.createdAtBefore = moment(this.rangeDates[0]).format('YYYY-MM-DD');
    }
    if (this.rangeDates[1]) {
      this.createdAtAfter = moment(this.rangeDates[1]).format('YYYY-MM-DD');
      this.filterDateAndDepartment();
    }
    if (this.rangeDates[1]) {
      this.datesRangeFilter.hideOverlay();
    }
  }

  onResize(event) {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 576;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.createdAtBefore = this.setFormatdate(this.fromDate, '-');
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.createdAtAfter = this.setFormatdate(this.toDate, '-');
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.createdAtBefore = this.setFormatdate(this.fromDate, '-');
    }
    if (this.toDate) {
      this.rangeDate = this.setFormatdate(this.fromDate, '/') + '  -  ' + this.setFormatdate(this.toDate, '/');
      this.filterDateAndDepartment();
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  setFormatdate(date, str: string) {
    return date.year + str + date.month + str + date.day;
  }

  getCompany() {
    if (!this.authenticationService.isAdmin()) {
      return localStorage.getItem('company_id');  // not admin, return user's company
    }
    if (this.selectedCompany) {
      return this.selectedCompany;  // admin select company from budget-dashboard page
    }
    return null; // admin see all company
  }

  openResend(event, memo) {
    this.selectedMemoIdForResendEmail = memo.id;
    this.modal = this.modalService.open(event);
  }

  submitResendMail() {
    this.memoService.resendEmail(this.selectedMemoIdForResendEmail).subscribe(res => {
        this.modal.close();
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.RESEND-EMAIL-SUCCESS')});
      }, error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      }
    );
  }


}
