import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { userRoutes } from './user/user-routing.module';
import { profileRoutes } from './profile/profile-routing.module';

export const permissionRoutes: Routes = [
  {
    path: '',
    children: [
      ...userRoutes,
      ...profileRoutes
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(permissionRoutes)],
  exports: [RouterModule]
})
export class PermissionRoutingModule {
}
