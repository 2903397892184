import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-memo-plane-ticket',
  templateUrl: './memo-plane-ticket.component.html',
  styleUrls: ['./memo-plane-ticket.component.scss']
})
export class MemoPlaneTicketComponent implements OnInit, OnChanges {

  tooltipCreatorName = '';
  tooltipRequestorName = '';
  max_level_arr: any ;
  loa = [];
  // current_level: any;
  @Input() data: any;  // memo.loa
  @Input() memo: any;

  @Output() submitted = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.max_level_arr = Array(this.memo.max_level).fill(0).map((x, i) => i);
    if (this.memo.status === 'terminated') {
      this.memo.current_level += 1;
    }
    // this.max_level_arr = Array(this.memo.max_level).fill(0).map((x, i) => i);
    if (this.data) {
      this.data = _.orderBy(this.data, 'sequence', 'desc');
    }
    _.forEach(this.memo.tooltip_data, (obj, index) => {
      this.loa.push(obj);
    });
    let publishedAt = '';
    if (this.memo['publish_at']) {
      publishedAt = `\n ${moment(this.memo['publish_at']).format('DD/MM/YYYY')}`;
    }

    // this.tooltipCreatorName = `${this.memo.created_name} ${publishedAt}`;

    if (this.memo.approver && this.memo.approver[0] && this.memo.approver[0].approver) {
      const approveAt = 'approve_at' in this.memo.approver[0] ? `\n ${moment(this.memo.approver[0].approve_at).format('DD/MM/YYYY')}` : '';
      this.tooltipRequestorName = `${this.memo.approver[0].approver.full_name} ${approveAt}`;
    }
  }

  tooltipForLoa(loa): string {
    if (loa) {
      const approveAt = 'approved_at' in loa ? `\n ${moment(loa.approved_at).format('DD/MM/YYYY')}` : '' ;
      return `${loa.profile_name} ${approveAt}`;
    }
    return '';
  }

  checkTooltip(index) {
    return this.loa[index];
  }
  ngOnChanges() {
    if (!_.has(this.memo, 'send_status')) {
      this.memo['send_status'] = true;
    }
    if (this.data) {
      this.data = _.orderBy(this.data, 'sequence', 'desc');
    }
  }
}
