import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'thDate' })
export class THDatetimePipe implements PipeTransform {
  transform(value: any): any {
    /** value example => Wed Jan 30 2019 00:00:00 GMT+0700 (Indochina Time) **/
    /**         index => 0   1   2  3    4        5        6**/
    try {
      let result = '';
      const split = value.toString().split(' ');
      result = result.concat(split[2].startsWith('0') ? split[2].substr(1, 1) : split[2]);
      switch (split[1]) {
        case 'Jan': result = result.concat(' มกราคม'); break;
        case 'Feb': result = result.concat(' กุมภาพันธ์'); break;
        case 'Mar': result = result.concat(' มีนาคม'); break;
        case 'Apr': result = result.concat(' เมษายน'); break;
        case 'May': result = result.concat(' พฤษภาคม'); break;
        case 'Jun': result = result.concat(' มิถุนายน'); break;
        case 'Jul': result = result.concat(' กรกฎาคม'); break;
        case 'Aug': result = result.concat(' สิงหาคม'); break;
        case 'Sep': result = result.concat(' กันยายน'); break;
        case 'Oct': result = result.concat(' ตุลาคม'); break;
        case 'Nov': result = result.concat(' พฤศจิกายน'); break;
        case 'Dec': result = result.concat(' ธันวาคม'); break;
      }
      result = result.concat(` ${split[3]}`);
      return result;
    } catch (e) {
      return '-';
    }
  }

}
