import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';

import { MemoService } from '../../memo.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { UserService } from '../../../../permission/user/user.service';
import { GeneralService } from '../../../../setting/general/general.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-memo-real-choice',
  templateUrl: './memo-real-choice.component.html',
  styleUrls: ['./memo-real-choice.component.scss']
})
export class MemoRealChoiceComponent implements OnInit {
  memoList = [];
  count = 0;
  currentPage = 1;
  memberName = '';
  memberRemark = '';
  departmentList = [];
  department: string;
  logoList = [];
  showPreview = false;
  documentData: any;
  createdPerson = '';
  isLoading = false;
  modal: NgbModalRef;

  @Output() closed = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSuccess = new EventEmitter();

  constructor(
    private memoService: MemoService,
    private messageService: MessageService,
    private generalService: GeneralService,
    private modalService: NgbModal,
    private userService: UserService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.getMemoTemplateList();
    this.loadDepartmentList();
    this.loadLogoList();
  }

  loadDepartmentList() {
    this.memoService
      .getDepartmentList()
      .subscribe(
        (departments: any) => (this.departmentList = departments.results),
        error => this.notification.showNotification(error.status)
      );
  }

  getMemoTemplateList() {
    const params = new HttpParams()
      .set('page', this.currentPage.toString() || '')
      .set('name', this.memberName || '')
      .set('created_name', this.createdPerson || '')
      .set('department', this.department || '');
    this.memoService.getDraftMemo(params).subscribe(
      (memos: any) => {
        this.count = memos.count;
        this.memoList = memos.results;
      },
      error => this.notification.showNotification(error.status)
    );
  }

  loadLogoList() {
    this.generalService.getLogo().subscribe(logos => (this.logoList = logos['results']));
  }

  changePage() {
    this.getMemoTemplateList();
  }

  search() {
    this.getMemoTemplateList();
  }

  preview(memo) {
    if (!memo.created_date) {
      memo['created_date'] = moment().format('YYYY-MM-DD');
    }
    memo['preview'] = true;
    this.documentData = memo;
    this.showPreview = true;
  }

  closePreview(e) {
    this.showPreview = false;
  }

  clear() {
    this.memberName = '';
    this.createdPerson = '';
    this.department = undefined;
    this.getMemoTemplateList();
  }

  createMemoFromTemplate(memo) {
    const data = {
      logo: memo.logo_id,
      logo_id: memo.logo_id,
      subject: memo.subject,
      to: memo.to,
      detail: memo.detail,
      department: memo.department,
      approver: memo.approver,
      mailing_address_th: memo.mailing_address_th,
      mailing_address_en: memo.mailing_address_en,
      attachments: [],
      type: memo.type,
      name: memo.name,
      remark: memo.remark,
      loa: null,
      due_date: null,
      attachment_text: '',
      loa_type: 'oblique',
      loa_title: 'เรียน นายกสมาคมฯ',
      loa_purpose: 'approve',
      created_date: moment().format('YYYY-MM-DD')
    };
    this.memoService.createRealMemo(data).subscribe(
      res => {
        this.messageService.add({
          severity: 'success',
          summary: 'สร้างการตั้งเรื่องใหม่',
          detail: 'ระบบทำการสร้างการตั้งเรื่องฉบับร่าง'
        });
        this.onSuccess.emit(res);
      },
      error => console.log('err', error)
    );
  }

  createFromScratch() {
    this.isLoading = true;
    const data = {
      subject: '',
      to: '',
      detail: [
        {
          text:
            '<p>เรื่อง &nbsp; ชื่อการตั้งเรื่อง</p>' +
            '<p>เรียน &nbsp; ชื่อผู้รับ</p><p>สิ่งที่ส่งมาด้วย &nbsp; ' +
            '1. เอกสารแนบฉบับที่หนึ่ง</p><p>&nbsp; &nbsp; ' +
            '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' +
            '&nbsp; &nbsp; &nbsp;' +
            '2. เอกสารแนบฉบับที่สอง</p><p><br></p><p>' +
            '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ' +
            '&nbsp; &nbsp; Lorem ipsum dolor sit amet, ' +
            'consectetur adipiscing elit. Aliquam semper ' +
            'mattis porta. Etiam laoreet, libero eget ' +
            'ultricies rutrum, urna tortor consequat diam, ' +
            'ac placerat est augue at orci. Donec mollis nulla est, ' +
            'vitae pharetra mauris rhoncus a. Nullam porta leo a ' +
            'nibh rhoncus facilisis. Sed vel rutrum lacus. ' +
            'Suspendisse ac luctus diam. Sed est magna, ' +
            'sollicitudin euismod ultrices vel, ' +
            'ornare pellentesque nunc.</p>'
        }
      ],
      department: null,
      approver: null,
      mailing_address_th: null,
      mailing_address_en: null,
      attachments: [],
      type: 1,
      name: '',
      remark: '',
      loa: null,
      due_date: null,
      attachment_text: '',
      loa_type: 'oblique',
      loa_title: 'เรียน นายกสมาคมฯ',
      loa_purpose: 'approve',
      created_date: moment().format('YYYY-MM-DD')
    };
    this.memoService.createRealMemo(data).subscribe(
      res => {
        this.messageService.add({
          severity: 'success',
          summary: 'สร้างการตั้งเรื่องใหม่',
          detail: 'ระบบทำการสร้างการตั้งเรื่องฉบับร่าง'
        });
        this.onSuccess.emit(res);
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.notification.showNotification(error.status);
      }
    );
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  closeToggle() {
    this.closed.emit();
  }
}
