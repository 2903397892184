import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  ViewChild,
  ElementRef,
  EventEmitter,
  Renderer2, OnChanges, SimpleChanges
} from '@angular/core';
import {
  PDFProgressData,
  PDFDocumentProxy,
  PDFSource
} from 'ng2-pdf-viewer';
import {SignaturePosition, SingleSignaturePosition} from './upload-template-cuxtomize.models';
import {uuid} from 'uuidv4';
import * as _ from 'lodash';
import * as moment from 'moment';
import {ResizedEvent} from 'angular-resize-event';
import {CdkDragEnd} from '@angular/cdk/drag-drop';
import {SelectItem, SelectItemGroup} from 'primeng/api';
import {DateFormatService} from '../../../../../../shared/services/date-format.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-upload-template-customize',
  templateUrl: './upload-template-customize.component.html',
  styleUrls: ['./upload-template-customize.component.scss']
})
export class UploadTemplateCustomizeComponent implements OnInit, OnDestroy, OnChanges {

  readonly DRAGDROP_DEFAULT_WIDTH = 24.5098;
  readonly DRAGDROP_DEFAULT_HEIGHT = 5.6818182;
  readonly DRAGDROP_DEFAULT_FONTSIZE = 1.8796992481203008;

  currentPage = 1;
  totalPage: number;
  pdf: PDFDocumentProxy;
  pdfError: any = null;
  isPageListSelection = false;
  loadingPage = true;
  pdfStyleWidth: number;
  pdfStyleHeight: number;
  showLoaMemberList = false;
  currentSignaturePosition: SignaturePosition;

  fontList = [
    {name: 'THSarabunNew', value: 'THSarabunNew'},
    {name: 'THSarabunNewBold', value: 'THSarabunNewBold'},
    {name: 'THSarabunNewItalic', value: 'THSarabunNewItalic'},
    {name: 'THSarabunNewBoldItalic', value: 'THSarabunNewBoldItalic'},
  ];
  selectedFont: { name: string; value: string } = this.fontList[0];

  canvas: HTMLCanvasElement;
  contextCanvas2d: CanvasRenderingContext2D;

  signatureColorList = [
    '#000000',
    '#4169e1',
    '#EB2B36',
    '#266D1F'
  ];
  showDragDropSetting = false;
  selectedPosition: SingleSignaturePosition;
  selectedDragDropType: string;

  dateFormatList: SelectItem[];
  selectedDateFormat: string;

  @Input() allowEdit = true;
  @Input() pdfPath: PDFSource;
  @Input() memoNumber: number;
  @Input() signaturePositions: SignaturePosition[];
  @Output() signaturePositionsChange: EventEmitter<SignaturePosition[]> = new EventEmitter<SignaturePosition[]>();

  @ViewChild('pdfPageListBtn', {static: false}) pdfPageListBtn: ElementRef;
  @ViewChild('pdfPageList', {static: false}) pdfPageList: ElementRef;
  @ViewChild('loaMemberListBtn', {static: false}) loaMemberListBtn: ElementRef;
  @ViewChild('loaMemberList', {static: false}) loaMemberList: ElementRef;

  constructor(private renderer: Renderer2,
              private dateFormatService: DateFormatService,
              public translate: TranslateService) {

    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.pdfPageList && this.pdfPageListBtn) {
        if (e.target !== this.pdfPageListBtn.nativeElement && e.target !== this.pdfPageList.nativeElement) {
          this.isPageListSelection = false;
        }
      }
      if (this.loaMemberList && this.loaMemberListBtn) {
        if (e.target !== this.loaMemberList.nativeElement && e.target !== this.loaMemberListBtn.nativeElement) {
          this.showLoaMemberList = false;
        }
      }
    });

  }

  ngOnInit(): void {
    if (this.hasLoaMember()) {
      this.currentSignaturePosition = this.signaturePositions[2];
    }
    this.canvas = document.createElement('canvas');
    this.contextCanvas2d = this.canvas.getContext('2d');

    this.dateFormatList = this.dateFormatService.dateFormatDropDownList;
    this.selectedDateFormat = this.dateFormatService.defaultFormat;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.signaturePositions) {
      if (this.hasLoaMember()) {
        this.currentSignaturePosition = this.signaturePositions[2];
      } else {
        this.currentSignaturePosition = null;
      }
    }
  }

  onProgress(progressData: PDFProgressData) {
    // console.log(`${progressData.loaded} / ${progressData.total}`);
  }

  onLoadComplete(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.currentPage = 1;
    this.totalPage = this.pdf.numPages;
    this.loadingPage = false;
  }

  onPdfViewResized(event: ResizedEvent) {
    if (event.newWidth !== event.oldWidth) {
      this.pdfStyleWidth = event.newWidth;
    }
    if (event.newHeight !== event.oldHeight) {
      this.pdfStyleHeight = event.newHeight;
    }
  }

  pageChangeDelay() {
    this.loadingPage = true;
    setTimeout(() => {
      this.loadingPage = false;
    }, 200);
  }

  prevPage() {
    this.pageChangeDelay();
    this.currentPage--;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }
  }

  nextPage() {
    this.pageChangeDelay();
    this.currentPage++;
    if (this.currentPage > this.totalPage) {
      this.currentPage = this.totalPage;
    }
  }

  showPageList() {
    this.isPageListSelection = !this.isPageListSelection;
  }

  onSelectedPage(pageNumber: number) {
    this.pageChangeDelay();
    this.currentPage = pageNumber;
    setTimeout(() => this.isPageListSelection = false, 400);
  }

  onPdfError(error: any) {
    this.pdfError = error;
  }

  onSelectedLoaMember(selected: SignaturePosition) {
    this.currentSignaturePosition = selected;
    setTimeout(() => this.showLoaMemberList = false, 400);
  }

  onClickSignature() {
    const position: SingleSignaturePosition = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      fontStyle: this.fontList[0],
      initialPos: {x: 0, y: 0}
    };
    const idx = _.findIndex(this.signaturePositions, this.currentSignaturePosition);
    if (this.signaturePositions[idx].signature) {
      position.width = 100;
      position.height = 100;
    }
    this.addPosition(position, idx);
  }

  onClickDate() {
    const position: SingleSignaturePosition = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      W: this.DRAGDROP_DEFAULT_WIDTH,
      H: this.DRAGDROP_DEFAULT_HEIGHT,
      dateFormat: this.dateFormatService.defaultFormat,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: {x: 0, y: 0},
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(this.signatureColorList[0])
    };
    this.addPosition(position, 0);
  }

  onClickMemoNumber() {
    const position: SingleSignaturePosition = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      W: this.DRAGDROP_DEFAULT_WIDTH,
      H: this.DRAGDROP_DEFAULT_HEIGHT,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: {x: 0, y: 0},
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(this.signatureColorList[0])
    };
    this.addPosition(position, 1);
  }

  onClickCustomWrite() {
    const position: SingleSignaturePosition = {
      page: this.currentPage,
      X: 0,
      Y: 0,
      W: this.DRAGDROP_DEFAULT_WIDTH,
      H: this.DRAGDROP_DEFAULT_HEIGHT,
      string: null,
      fontSize: this.DRAGDROP_DEFAULT_FONTSIZE,
      fontStyle: this.fontList[0],
      initialPos: {x: 0, y: 0},
      color: this.signatureColorList[0],
      colorForRender: this.convertColorToRenderFormat(this.signatureColorList[0])
    };
    this.addPosition(position);
  }

  onClickClear() {
    // this.signaturePositions.map(item => item.positions = null);
    this.clearSignaturePositionsData();
  }

  clearSignaturePositionsData() {
    if (!this.signaturePositions) {
      return;
    }
    this.signaturePositions = this.signaturePositions
      .filter(item => item.sequence !== -1)
      .map(item => {
        return {
          ..._.cloneDeep(item),
          positions: null
        } as SignaturePosition;
      });
    this.signaturePositionsChange.emit(this.signaturePositions);
  }

  getDragDropClass(dragdrop: SignaturePosition): string {
    let dragdropClass = null;
    switch (dragdrop.type) {
      case 'date':
        dragdropClass = 'dragdrop-date';
        break;
      case 'memo_number':
        dragdropClass = 'dragdrop-memonumber';
        break;
      case 'custom':
        dragdropClass = 'dragdrop-custom';
        break;
      case 'signature':
        dragdropClass = 'dragdrop-signature';
        break;
      default:
        dragdropClass = 'dragdrop-signature';
        break;
    }
    return dragdropClass;
  }

  hasLoaMember(): boolean {
    if (!this.signaturePositions) {
      return false;
    }
    if (this.signaturePositions.length < 3) {
      // signature position start at index 2
      return false;
    }
    // no word undefined in name
    return this.signaturePositions[2].name.indexOf('undefined') === -1;
  }

  filterHavePositions(signaturePosition?: SignaturePosition): SingleSignaturePosition[] {
    if (signaturePosition.positions === null || signaturePosition.positions === undefined) {
      return [];
    }
    return signaturePosition.positions.filter(position => position.page === this.currentPage);
  }

  filterOnlySignatures(): SignaturePosition[] {
    return this.signaturePositions.filter(item => {
      return item.type !== 'date' && item.type !== 'memo_number' && item.type !== 'custom';
    });
  }

  addPosition(position: SingleSignaturePosition, index?: number) {
    if (index === null || index === undefined) {  // for custom text
      this.signaturePositions.push({
        sequence: -1,
        name: 'custom',
        positions: [{...position, id: uuid()}],
        type: 'custom'
      });
      return;
    }

    if (this.signaturePositions[index].positions) {
      this.signaturePositions[index].positions.push({
        ...position,
        id: uuid()
      });
    } else {
      this.signaturePositions[index].positions = [{...position, id: uuid()}];
    }
  }

  removeDragDrop(type: string, targetPosition: SingleSignaturePosition) {
    if (type === 'date') {
      this.signaturePositions[0].positions = this.signaturePositions[0].positions
        .filter(position => position !== targetPosition);

    } else if (type === 'memo_number') {
      this.signaturePositions[1].positions = this.signaturePositions[1].positions
        .filter(position => position !== targetPosition);

    } else if (type === 'custom') {
      this.signaturePositions = this.signaturePositions.filter(signaturePosition => {
        if (!signaturePosition.positions) {
          return true;
        }
        return !signaturePosition.positions.find(position => position === targetPosition);
      });

    } else { // for signature type
      for (let i = 2; i < this.signaturePositions.length; i++) {
        if (!this.signaturePositions[i].positions) {
          continue;
        }
        this.signaturePositions[i].positions = this.signaturePositions[i].positions
          .filter(position => position !== targetPosition);
      }
    }
    this.signaturePositionsChange.emit(this.signaturePositions);
  }

  onDragEnded(event: CdkDragEnd, position: SingleSignaturePosition) {
    const transform = event.source.getFreeDragPosition();
    position.initialPos = transform;
    const pos = this.convertTransformToPos(transform.x, transform.y);
    position.X = pos.x;
    position.Y = pos.y;
  }

  convertTransformToPos(translateX: number, translateY: number): { x: number; y: number } {
    return {
      x: (translateX / this.pdfStyleWidth) * 100,
      y: (translateY / this.pdfStyleHeight) * 100
    };
  }

  getDragDropInfo(dragdrop: SignaturePosition, dragdropItem?: SingleSignaturePosition): string {
    let info = '';
    switch (dragdrop.type) {
      case 'date':
        info = this.dateFormatService.getDateLabel(dragdropItem.dateFormat);
        break;
      case 'memo_number':
        if (this.memoNumber) {
          info = this.memoNumber.toString();
        } else {
          info = dragdrop.name;
        }
        break;
      case 'custom':
        break;
      case 'signature':
        break;
      default:
        info = dragdrop.name;
        break;
    }
    return info;
  }

  getFontSizePx(size: number) {
    return Math.round(size / 100 * this.pdfStyleHeight);
  }

  getLineHeightPx(fontSize: number) {
    return this.getFontSizePx(fontSize) * 1.2;
  }

  getFontStyle(style: { name: string; value: string }) {
    if (!style) {
      return this.fontList[0].value;
    }
    return style.value;
  }

  getTextNoUndefined(text: string) {
    if (!text) {
      return '';
    }
    return text;
  }

  updateTextareaWidthPx(dragdrop: SingleSignaturePosition) {
    const DEFAULT = 80;

    if (!dragdrop.fontSize || !dragdrop.fontStyle || !dragdrop.string) {
      return DEFAULT;
    }

    const fontSizePx = this.getFontSizePx(dragdrop.fontSize);
    const fontStyle = dragdrop.fontStyle.value;
    const textSplitNewLine = dragdrop.string.split('\n');
    const textMaxLength = textSplitNewLine.reduce((text, max) => {
      return text.length > max.length ? text : max;
    });

    this.contextCanvas2d.font = `${fontSizePx}px ${fontStyle}`;
    const metrics = this.contextCanvas2d.measureText(textMaxLength);
    return Math.max(metrics.width + fontSizePx, DEFAULT);
  }

  updateTextareaHeightPx(dragdrop: SingleSignaturePosition) {
    if (!dragdrop.string) {
      return this.getLineHeightPx(dragdrop.fontSize);
    }

    const textSplitNewLine = dragdrop.string.split('\n');
    return textSplitNewLine.length * this.getLineHeightPx(dragdrop.fontSize);
  }

  dragdropGroupTrackByFunc(index, item: SignaturePosition) {
    return index;
  }

  dragdropTrackByFunc(index, item: SingleSignaturePosition) {
    return item.id;
  }

  onSelectedColor(color: string) {
    this.selectedPosition.color = color;
    this.selectedPosition.colorForRender = this.convertColorToRenderFormat(color);
  }

  onClickDragDropSetting(targetPosition: SingleSignaturePosition, type: string) {
    this.showDragDropSetting = !this.showDragDropSetting;
    this.selectedPosition = targetPosition;
    this.selectedDragDropType = type;
  }

  confirmSetting() {
    this.showDragDropSetting = false;
  }

  convertColorToRenderFormat(color: string): {r: number; g: number; b: number} {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
      .exec(color);
    return result ? {
      r: parseInt(result[1], 16) / 255,
      g: parseInt(result[2], 16) / 255,
      b: parseInt(result[3], 16) / 255,
    } : null;
  }

  updateFontSize(sizePx: number) {
    this.selectedPosition.fontSize = sizePx / this.pdfStyleHeight * 100;
  }

  onSelectedDateFormat() {
    this.selectedPosition.dateFormat = this.selectedDateFormat;
  }

  ngOnDestroy() {
    this.currentPage = 1;
    if (this.allowEdit) {
      this.clearSignaturePositionsData();
    }
  }

  convertSizeSignature(position, item) {
    const signaturePositionArray = [];
    signaturePositionArray.push(this.signaturePositions[item].positions);
    this.signaturePositions[item].positions.map((data) => {
        this.signaturePositions[item].positions = this.signaturePositions[item].positions.map((isData) => {
          data.width = position.width;
          data.height = position.height;
          return isData;
        });
    });
  }
}
