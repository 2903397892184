import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CanvasWhiteboardModule} from 'ng2-canvas-whiteboard';

import { DndModule } from 'ngx-drag-drop';
import { UserProfileRoutingModule } from './user-profile-routing.module';
import { UserProfileComponent } from './user-profile.component';
import { UserProfileService } from './user-profile.service';
import { UserProfileEditComponent } from './user-profile-edit/user-profile-edit.component';
import {TranslateModule} from '@ngx-translate/core';
import {PasswordStrengthMeterModule} from 'angular-password-strength-meter';
import {InputSwitchModule} from 'primeng/inputswitch';
import {QRCodeModule} from 'angularx-qrcode';
import {NgOtpInputModule} from 'ng-otp-input';
import {MemoModule} from '../memos/memo/memo.module';
import { SettingEmailModalComponent } from './setting-email-modal/setting-email-modal.component';
import {ColorPickerModule} from 'primeng/colorpicker';
import { SettingDelegateComponent } from './setting-delegate/setting-delegate.component';
import {SignatureService} from '../../shared/services/signature.service';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        UserProfileRoutingModule,
        NgbModule,
        DndModule,
        ReactiveFormsModule,
        ImageCropperModule,
        TranslateModule,
        PasswordStrengthMeterModule,
        InputSwitchModule,
        QRCodeModule,
        NgOtpInputModule,
        MemoModule,
        CanvasWhiteboardModule,
        ColorPickerModule
    ],
  declarations: [UserProfileComponent, UserProfileEditComponent, SettingEmailModalComponent, SettingDelegateComponent],
  providers: [SignatureService]
})
export class UserProfileModule {}
