import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {ProfileService} from '../../../shared/services/profile-service';
import {SweetAlertService} from '../../../shared/services/sweet-alert.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-setting-email-modal',
  templateUrl: './setting-email-modal.component.html',
  styleUrls: ['./setting-email-modal.component.scss']
})
export class SettingEmailModalComponent implements OnInit, OnDestroy {

  modal: NgbModalRef;
  translateEmailCreate = [];
  translateEmailLoa = [];
  typeEmailList = [
    {value: 'create', showLabel: 'Create a Memo'},
    {value: 'loa', showLabel: 'Line of Approval'},
  ];
  selectedTypeEmail = {value: 'create', showLabel: 'Create a Memo'};

  typeEmailCreate = {
    approve_self_memo: '',
    publish_memo: '',
    reject_self_memo: '',
    terminate_self_memo: ''
  };
  typeEmailLoa = {
    notify_revise: '',
    approve_memo: '',
    notify_level: '',
    recall_memo: '',
    reject_memo: '',
    reminder_memo: '',
    revise_memo: '',
    hard_reminder_memo: '',
    terminate_memo: '',
  };

  subscriptions = new Subscription();

  @Input() data: any;
  @Output() submitted = new EventEmitter();
  constructor( private  modalService: NgbModal,
               private  swal: SweetAlertService,
               private  translate: TranslateService,
               private profileService: ProfileService

  ) {}

  ngOnInit() {
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateTilte();
    });
    this.subscriptions.add(translateSub);
    this.translateTilte();
    this.typeOfEmail();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  translateTilte() {
    this.typeEmailList = [
    {value: 'create', showLabel: this.translate.instant('PROFILE.CREATING-MEMO')},
    {value: 'loa', showLabel: this.translate.instant('PROFILE.LOA')},
  ];
    this.translateEmailCreate = [];
    this.translateEmailLoa = [];
    this.translateEmailCreate.push(
      this.translate.instant('PROFILE.APPROVE-SELF-MEMO'),
      this.translate.instant('PROFILE.PUBLISH-MEMO'),
      this.translate.instant('PROFILE.REJECT-SELF-MEMO'),
      this.translate.instant('PROFILE.TERMINATE-SELF-MEMO'),
    );
    this.translateEmailLoa.push(
      this.translate.instant('PROFILE.APPROVE-MEMO'),
      this.translate.instant('PROFILE.HARD-REMINDER-MEMO'),
      this.translate.instant('PROFILE.NOTIFY-LEVEL'),
      this.translate.instant('PROFILE.NOTIFY-REVISE'),
      this.translate.instant('PROFILE.RECALL-MEMO'),
      this.translate.instant('PROFILE.REJECT-MEMO'),
      this.translate.instant('PROFILE.REMINDER-MEMO'),
      this.translate.instant('PROFILE.REVISE-MEMO'),
      this.translate.instant('PROFILE.TERMINATE-MEMO'),
    );
  }

  typeOfEmail() {
    this.typeEmailCreate = _.pick(this.data, Object.keys(this.typeEmailCreate));
    this.typeEmailLoa = _.pick(this.data, Object.keys(this.typeEmailLoa));

  }
  trackByFn(index, item) {
    return item.key;
  }

  openSettingEmailModal(event) {
    this.modal = this.modalService.open(event, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

  confirmUpdateEmail() {
    let typeOfAllEmail = {};
    typeOfAllEmail = _.merge(typeOfAllEmail, this.typeEmailCreate, this.typeEmailLoa);
    const emilAll = Object.assign(typeOfAllEmail, {id: this.data.id});
    this.profileService.settingEmail(this.data.id, emilAll).subscribe(
      update => {
        this.modal.close();
        this.submitted.emit(update);
        this.swal.toastNotification({type: 'success', content: this.translate.instant('MEMOS.SAVE-CHANGES')});
      }, error => {
        this.swal.toastNotification({type: 'error', content: error});
      }
    );

  }

  closeSettingEmailModal() {
    this.typeOfEmail();
    this.modal.close();
  }

  onSelectedTemplate(template: { value: string, showLabel: string }) {
    this.selectedTypeEmail = template;
  }
  updateStatusEmailByCreate(keyTypeEmail: string, event) {
    this.typeEmailCreate[keyTypeEmail] = event.checked;
  }
  updateStatusEmailByLoa(keyTypeEmail: string, event) {
    if (keyTypeEmail === 'notify_level') {
      this.typeEmailLoa[keyTypeEmail] = event.checked;
      this.typeEmailLoa['revise_memo'] = event.checked;
    } if (keyTypeEmail === 'notify_revise') {
      this.typeEmailLoa[keyTypeEmail] = event.checked;
      this.typeEmailLoa['reject_memo'] = event.checked;
    } else {
      this.typeEmailLoa[keyTypeEmail] = event.checked;
    }
  }

}
