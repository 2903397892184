import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from '../../../setting/general/general.service';

@Component({
  selector: 'app-memo-logo-modal',
  templateUrl: './memo-logo-modal.component.html',
  styleUrls: ['./memo-logo-modal.component.scss']
})
export class MemoLogoModalComponent implements OnInit {

  modal: NgbModalRef;
  logoList = [];
  logoID: number;

  @Input() data: any;
  @Output() submitted = new EventEmitter();

  constructor(private modalService: NgbModal, private generalService: GeneralService) {
  }

  ngOnInit() {
    this.loadLogo();
  }

  loadLogo() {
    this.generalService.getLogo().subscribe(
      (logos: any) => this.logoList = logos.results
    );
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  submit() {
    this.submitted.emit({ logo: this.logoID });
    this.modal.close();
  }

  selectLogo(logo) {
    this.data = logo.image;
    this.logoID = logo;
  }

}
