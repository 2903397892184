import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormAsset, TableAsset} from '../../memo-template';

@Component({
selector: 'app-table-template-asset',
  templateUrl: './table-template-asset.component.html',
  styleUrls: ['./table-template-asset.component.scss']
})
export class TableTemplateAssetComponent implements OnInit {

  lineItems: TableAsset[] = [];
  totalAmount = 0;
  totalPrice = 0;
  totalDepreciationPrice = 0;
  totalCostPrice = 0;

  // tslint:disable-next-line:no-input-rename
  @Input('formAssetData') formAssetData: FormAsset;
  @Output() formAssetDataChange = new EventEmitter();
  // tslint:disable-next-line:no-input-rename
  @Output() getTotalPriceForm = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    if (this.formAssetData.asset_table && this.formAssetData.asset_table.length > 0) {
      this.mapDataToLineItems(this.formAssetData.asset_table);
    } else {
      this.onAddRowToLineItems();
    }
  }

  mapDataToLineItems(line_items: any[]): TableAsset[] {
    return line_items
      .map(line_item => {
        return {
          id: line_item.id,
          code: line_item.code,
          description: line_item.description,
          quantity: line_item.quantity,
          amount: line_item.amount,
          depreciation: line_item.depreciation,
          residual_value: line_item.residual_value,
          get_date: line_item.get_date,
          remark: line_item.remark,
        } as TableAsset;
      });
  }

  onAddRowToLineItems() {
    this.formAssetData.asset_table.push({
      sequence: (this.formAssetData.asset_table || []).length + 1,
      id: null,
      code: null,
      description: null,
      quantity: 0,
      amount: 0,
      depreciation: 0,
      residual_value: 0,
      get_date: null,
      remark: null,
    });
  }

  onRemoveRowFromLineItems(index) {
    this.formAssetData.asset_table.splice(index, 1);
    this.reOrderSequence();
    this.sumTable();
  }

  reOrderSequence() {
    (this.formAssetData.asset_table || []).forEach((d, index) => {
      d.sequence = index + 1;
    });
  }


  limitTwoDecimal(num: number, mode: 'round' | 'floor' = 'round'): number {
    return mode === 'round'
      ? Math.round(num * 100) / 100
      : Math.floor(num * 100) / 100;
  }

  costPricecal(listItems) {
    const amount = listItems.amount || 0;
    const depreciation = listItems.depreciation || 0;
    const residual_value = amount - depreciation;
    listItems.residual_value = this.limitTwoDecimal(residual_value);
  }

  sumTable() {
    if (!this.formAssetData.asset_table) {
      return;
    }
    let sumAmount = 0;
    let sumPrice = 0;
    let sumDepreciation = 0;
    let sumCostPrice = 0;
    this.formAssetData.asset_table.forEach(row => {
      sumAmount += row.quantity;
      sumPrice += row.amount;
      sumDepreciation += row.depreciation;
      sumCostPrice += row.residual_value;
    });
    this.totalAmount = this.limitTwoDecimal(sumAmount);
    this.totalPrice = this.limitTwoDecimal(sumPrice);
    this.totalDepreciationPrice = this.limitTwoDecimal(sumDepreciation);
    this.totalCostPrice = this.limitTwoDecimal(sumCostPrice);
    this.getTotalPriceForm.emit(this.totalCostPrice);
  }

  calculateAll(listItems, index, key: string, val: string) {
    if (key === 'quantity') {
      listItems[key] = Math.floor(+val);
    } else {
      listItems[key] = this.limitTwoDecimal(+val, 'floor');
    }
    this.costPricecal(listItems);
    this.sumTable();
  }

}
