export const LANG_TH_NAME = 'th';

export const LANG_TH_TRANS = {
  // Navbar
  'Clubs': 'asdfasfqew',

  // Login
  'Sign in' : 'ลงชื่อเข้าใช้',


};
