import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../user.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  users = [];
  roleList = [];
  showResults = false;
  count = 0;
  currentPage = 1;
  searchValue = '';

  @ViewChild('confirmation', { static: false }) confirmation: any;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.loadData();
    this.loadRole();
  }

  loadData() {
    this.showResults = false;
    const params = new HttpParams()
      .set('search', this.searchValue || '')
      .set('page', this.currentPage.toString());
    this.userService.getUserPendingList(params).subscribe(
      (users: any) => {
        this.count = users.count;
        this.users = users.results;
        this.showResults = true;
      }
    );
  }

  loadRole() {
    this.roleList = [
      {
        id: 0,
        name: 'Admin'
      },
      {
        id: 1,
        name: 'General'
      },
      // {
      //   id: 2,
      //   name: 'Guest'
      // },
      // {
      //   id: 3,
      //   name: 'Accounting'
      // },
    ];
  }

  updateRole(user) {
    this.userService.updateUser(user.id, { role: user.role }).subscribe(
      () => this.loadData()
    );
  }

  changePage() {
    this.loadData();
  }

  onFilterChange(searchValue) {
    this.searchValue = searchValue;
    this.currentPage = 1;
    this.loadData();
  }
}
