import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClientService} from '../../shared/services/http-client.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import * as moment from 'moment';
import {SweetAlertService} from 'src/app/shared/services/sweet-alert.service';
import {TranslateService} from '@ngx-translate/core';
import {SidebarService} from '../sidebar/sidebar.service';
import {versionInfo} from '../../../version-info';
import {ThemeList, ThemeService} from '../../shared/services/theme.service';
import { ADFS_URL } from '../../shared/constants/api.constant';
import {environment} from '../../../environments/environment';

// import {AuthService, GoogleLoginProvider} from 'angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  isLoading = false;
  currentTime: any;
  currentDate = moment().format('DD MMMM YYYY');
  loginMode = true;
  checkResetemail: boolean;
  textList: string;
  forgotPasswordMode = false;
  resetPasswordSuccessMode = false;
  email: string;
  passwordResetText = 'Please enter your email address.';
  language = '';
  version = versionInfo.hash;
  themeList: ThemeList;
  isNormalLogin = false;
  HOST_URL = environment.host_url;

  defaultLogo = '/assets/images/LOGO_template_white.png';

  constructor(
    private router: Router,
    private swal: SweetAlertService,
    private http: HttpClientService,
    private tokenService: AuthenticationService,
    private translate: TranslateService,
    private sidebar: SidebarService,
    private themeService: ThemeService
  ) {
    this.language = translate.currentLang;
    this.themeService.data.subscribe(theme => {
      this.themeList = theme;
    });
  }

  ngOnInit() {
    this.checkCurrentTime();
    this.sidebar.setActiveSidebar(false);
    this.language = this.translate.currentLang;
  }

  checkCurrentTime() {
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }

  login() {
    if (!this.username) {
      return;
    }
    this.isLoading = true;
    this.tokenService
      .login({
        username: this.username.toLocaleLowerCase().trim(),
        password: this.password,
      })
      .subscribe(
        (user: any) => {
          this.tokenService.clearStorage();
          this.tokenService.setProfileInLocalStorage(user);
        },
        (error) => {
          this.isLoading = false;
          this.errorHandler(error);
        }
      );
  }

  forgotPassword() {
    this.isLoading = true;
    this.checkResetemail = true;
    this.tokenService
      .forgotPassword({
        email: this.email,
      })
      .subscribe(
        (user: any) => {
          this.passwordResetText =
            'Please check the email address connected to your account for a password reset email.';
          this.isLoading = false;
          this.checkLogin();
          this.forgotPasswordMode = false;
          this.resetPasswordSuccessMode = true;
        },
        (error) => {
          console.log(error.error);
          this.isLoading = false;
          if (error.error.en && error.error.th) {
            this.swal.toastNotification({
              type: 'error',
              content:  this.translate.currentLang === 'th' ? error.error.th : error.error.en,
            });
          } else {
            this.swal.toastNotification({
              type: 'error',
              content: 'the email address is not in the system.',
            });
          }
        }
      );
  }

  checkLogin() {
    if (!this.checkResetemail) {
      this.textList = 'LOGIN.PASS-WORK-RESET-TEXT';
    } else {
      this.textList = 'LOGIN.EMAIL-RESET-TEXT';
    }
  }

  gotoLogin() {
    this.loginMode = true;
    this.forgotPasswordMode = false;
    this.resetPasswordSuccessMode = false;
    this.passwordResetText = 'Please enter your email address.';
    this.email = '';
  }

  errorHandler(error) {
    this.isLoading = false;
    const statusCode = error.status;
    switch (statusCode) {

      case 400:
        if (error.error.en && error.error.th) {
          this.swal.toastNotification({
            type: 'error',
            content: this.translate.currentLang === 'th' ? error.error.th : error.error.en
          });
        } else {
          this.swal.toastNotification({
            type: 'error',
            content: this.translate.instant('LOGIN.INVALID-USERNAME-OR-PASSWORD'),
          });
        }
        break;

      case 401:
        this.swal.toastNotification({
          type: 'error',
          content: 'invalid email or password',
        });
        break;

      default:
        this.swal.toastNotification({
          type: 'error',
          content: 'System error, Please contact technical support.',
        });
        break;
    }
  }

  changeLanguage(lang: string) {
    this.language = lang;
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    }
    if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  adfsLogin() {
    window.location.href = ADFS_URL.login;
  }

  downloadUserManual() {
    window.open(this.HOST_URL + '/media/user_manual/BGC-User-Manual.pdf', '_blank');
  }
}
