import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../../../../shared/services/notification.service';

@Component({
  selector: 'app-memo-sign-modal',
  templateUrl: './memo-sign-modal.component.html',
  styleUrls: ['./memo-sign-modal.component.scss']
})
export class MemoSignModalComponent {

  modal: NgbModalRef;
  file: File;

  @Input() memo: any;
  @Output() submitted = new EventEmitter();

  constructor(private modalService: NgbModal,
              private notificationService: NotificationService) {
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }

  submit() {
    this.submitted.emit(this.file);
    this.modal.close();
  }

  uploadFile(fileInput) {
    if (!fileInput) {
      return;
    }
    if (fileInput.size / (1024 * 1024) >= 30) {
      this.notificationService.showSpecificError('กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 30 MB');
      return;
    }
    this.file = fileInput;
  }

}
