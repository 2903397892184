import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProfileListComponent } from './profile-list/profile-list.component';
import { ProfileCreateComponent } from './profile-create/profile-create.component';
import { ProfileUpdateComponent } from './profile-update/profile-update.component';

export const profileRoutes: Routes = [
  {
    path: 'profile',
    children: [
      { path: 'list', component: ProfileListComponent },
      { path: 'create', component: ProfileCreateComponent },
      { path: ':id', component: ProfileUpdateComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(profileRoutes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
