import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss']
})
export class DeleteConfirmationModalComponent implements OnInit, OnDestroy {

  modal: NgbModalRef;
  cancel: '';
  delete: '';
  subscriptions = new Subscription();
  @Input() type = 'icon';
  @Input() header = 'Confirm Delete';
  @Input() deleteMessage = 'Delete';
  @Input() text = 'Do you confirm to delete?';

  @Input() data: any;
  @Output() submitted = new EventEmitter();

  constructor(private modalService: NgbModal,
              private translate: TranslateService) {
  }

  ngOnInit() {
    const translateSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateContent();
    });
    this.subscriptions.add(translateSub);
    this.translateContent();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  open(content) {
    this.modal = this.modalService.open(content);
  }
  translateContent() {
    this.text = this.translate.instant('MEMOS.TEXT');
    this.header = this.translate.instant('MEMOS.CONFIRM-DELETE');
    this.cancel = this.translate.instant('MEMOS.CANCEL');
    this.delete = this.translate.instant('MEMOS.CONFIRM-DELETE');
  }

  submit() {
    this.submitted.emit(
      { data: this.data }
    );
    this.modal.close();
  }


}
