import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MemoRealListComponent } from './real/memo-real-list/memo-real-list.component';
import {MemoGuard} from '../../../shared/guard/memo.guard';

export const childMemoRoutes: Routes = [
  {
    path: 'memo',
    canActivateChild: [MemoGuard],
    children: [
      {
        path: 'real/list/:memo',
        component: MemoRealListComponent
      },
      {
        path: 'real/list',
        component: MemoRealListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(childMemoRoutes)],
  exports: [RouterModule]
})
export class MemoRoutingModule {}
