import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MemoService} from '../../../memo.service';
import {TemplateListService} from '../template-list.service';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertService} from '../../../../../../shared/services/sweet-alert.service';
import {UserService} from '../../../../../permission/user/user.service';
import {LoaService} from '../../../../loa/loa.service';
import {FormAsset} from '../memo-template';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {
  BUDGET_ASSET,
  getCompanyCodeNameFromId,
  getLevelBudget
} from '../../../../../../shared/constants/helper';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'app-template-asset',
  templateUrl: './template-asset.component.html',
  styleUrls: ['./template-asset.component.scss']
})
export class TemplateAssetComponent implements OnInit, OnDestroy {

  isLoading = false;
  isPermissionOnlyRelated = {value: false, label: 'Only related'};
  documentData: any;
  isSavedTemplate: boolean;
  isSmallScreen: boolean;
  profileListAll = [];
  profileList = [];
  profile_id = null;

  approvalDocumentsList = [
    {label: 'จำหน่ายทรัพย์สิน/เศษซาก', value: 'Deal Asset'},
    {label: 'จ่ายทรัพย์สิน/เศษซาก', value: 'Pay Asset'},
    {label: 'โอนทรัพย์สิน/เศษซาก', value: 'Transfer Asset'},
    {label: 'ทิ้งทรัพย์สิน/เศษซาก', value: 'Throw away Asset'},
  ];
  memoNumberList = [
    {
      name: 'Auto Generated',
      name_th: 'สร้างเลขเอกสารแบบอัตโนมัติ',
      id: 'Auto Generated'
    },
    {
      name: 'Custom Memo Number',
      name_th: 'สร้างเลขเอกสารด้วยตนเอง',
      id: 'Custom Memo Number'
    }
  ];
  plantList;
  total_price = 0;
  permission_company;
  selectedCompany;
  selectedYear;
  selectedWBS;
  companyName;
  company_id;
  department;
  wbsList = [];
  costCentreList = [];
  companyList = [];
  departmentList = [];
  noAsset = '';
  level = 13;

  memoNumber = 'Auto Generated';
  purposeCheck = {
    sell: false,
    store: false,
    other: false,
  };
  yearList = [];

  isLoadingList = {
    department: false,
    company: false,
    year: false,
    wbs: false,
    costcentre: false,
    to: false,
    cc: false,
    ref: false,
    announcement: false,
  };

  memoReference = null;
  loadTo = false;

  userKeyMemoNumber$ = new Subject<string>();
  userKeyMemoNumber: string;
  memoNumberAlreadyExist = false;
  subscriptions = new Subscription();

  @Input() selectedTemplate;
  // tslint:disable-next-line:no-input-rename
  @Input('memoData') memoData;
  // tslint:disable-next-line:no-input-rename
  @Input('attachment') file;
  // tslint:disable-next-line:no-input-rename
  @Input('formAssetData') formAssetData: FormAsset;
  @Output() memoDataChange = new EventEmitter();
  @Output() attachmentChange = new EventEmitter();
  @Output() formAssetDataChange = new EventEmitter();

  constructor(
    private builder: FormBuilder,
    private memoService: MemoService,
    private templateListService: TemplateListService,
    private translate: TranslateService,
    private swal: SweetAlertService,
    private userService: UserService,
    private loaService: LoaService
  ) {
  }

  ngOnInit() {
    this.initialPlantList();
    if (this.memoData.is_permission) {
      this.isPermissionOnlyRelated.value = this.memoData.is_permission;
    }
    this.memoData.customMemoNumberDropDown = this.memoData.is_custom_memo_number
      ? this.memoNumberList[1].id
      : this.memoNumberList[0].id;
    this.company_id = localStorage.getItem('company_id');
    this.permission_company = localStorage.getItem('permission_company');
    this.profile_id = localStorage.getItem('profile_id');
    this.memoData.company_by_selection = this.company_id;
    if (this.permission_company) {
      this.loadCompanyPermission();
    } else {
      this.loadAllGeneralProfile();
      this.loadCompanyAndDepartment();
    }
    this.checkPurpose();
    this.getRefMemos();
    // this.loadAllGeneralProfile();
    this.initialCheckMemoNumberExist();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initialPlantList() {
    if (environment.web_instance === 'BGC') {
      this.plantList = [
        {name: 'BGC'},
        {name: 'PTI'},
        {name: 'AGI'},
        {name: 'PGI'},
        {name: 'BGCG'},
        {name: 'RBI'},
        {name: 'AY1'},
        {name: 'AY2'},
        {name: 'BVP'},
        {name: 'PRM'},
        {name: 'BGCP'},
        {name: 'Other'},
      ];
    } else if (environment.web_instance === 'BG') {
      this.plantList = [
        {name: 'BG'},
        {name: 'BGA'},
        {name: 'BGPU'},
        {name: 'KBI'},
        {name: 'BGE'},
      ];
    }
  }

  getTotalPriceForm(event) {
    this.total_price = event;
  }

  sendLevel() {
    const companyCode = getCompanyCodeNameFromId(this.selectedCompany, this.companyList);
    if (companyCode) {
      this.level = getLevelBudget(BUDGET_ASSET, this.total_price, companyCode);
    }
    return this.level;
  }

  loadCompanyPermission() {
    this.isLoadingList.company = true;
    if (this.memoData.department) {
      const params_company = new HttpParams()
        .set('type', 'company')
        .set('profile', this.profile_id);
      this.memoService.getDropDown(params_company).subscribe((data) => {
        this.selectedCompany = data.company[0].value;
        this.companyList = data.company.map((item) => {
          if (item.context && item.context.code_name) {
            item.label = `${item.context.code_name} - ${item.label}`;
          }
          return item;
        });
        this.isLoadingList.company = false;
        this.loadDepartmentForPermissionCompany();
      });
    } else {
      const company = this.company_id + ',' + this.permission_company;
      const params = new HttpParams()
        .set('type', 'company')
        .set('company', company);
      this.memoService.getDropDown(params).subscribe((data) => {
        this.selectedCompany = data.company[0].value;
        this.companyList = data.company;
        this.isLoadingList.company = false;
      });
    }
  }

  loadCompanyAndDepartment() {
    this.isLoadingList.department = true;
    this.isLoadingList.year = true;
    this.isLoadingList.ref = true;
    const params = new HttpParams()
      .set('type', 'memo_number,department,company,wbs_year')
      .set('company', this.company_id)
      .set('department', this.department);
    this.memoService.getDropDown(params).subscribe((data) => {
      this.companyName = data.company[0].label;
      this.selectedCompany = data.company[0].value;
      this.companyList = data.company;
      this.memoReference = data.memo_number;
      this.departmentList = _.orderBy(data.department, [department => department.label], 'asc');
      this.departmentList = this.departmentList.map(item => {
        return {
          label: (item.context.code_number as string) + ' - ' + (item.label),
          value: item.value,
        };
      });
      this.memoData.department = this.initialSelectedDepartment(this.departmentList);
      this.yearList = data.wbs_year;
      this.selectedYear = this.yearList.length > 0 ? this.yearList[this.yearList.length - 1].year : null;
      this.isLoadingList.department = false;
      this.isLoadingList.year = false;
      this.isLoadingList.ref = false;
      if (this.memoData.department && this.selectedYear) {
        this.loadWBS();
      }
    });
  }

  loadDepartmentForPermissionCompany() {
    this.memoData.company_by_selection = this.selectedCompany;
    this.isLoadingList.department = true;
    this.isLoadingList.year = true;
    this.isLoadingList.ref = true;
    const params = new HttpParams()
      .set('type', 'memo_number,department,wbs_year')
      .set('company', this.selectedCompany)
      .set('department', +this.selectedCompany === +this.company_id ? this.department : null);
    this.memoService.getDropDown(params).subscribe((data) => {
      this.memoReference = data.memo_number;
      this.departmentList = _.orderBy(data.department, [department => department.label], 'asc');
      this.departmentList = this.departmentList.map(item => {
        return {
          label: (item.context.code_number as string) + ' - ' + (item.label),
          value: item.value,
        };
      });
      this.memoData.department = this.initialSelectedDepartment(this.departmentList);
      this.yearList = data.wbs_year;
      this.selectedYear = this.yearList.length > 0 ? this.yearList[this.yearList.length - 1].year : '';
      this.isLoadingList.department = false;
      this.isLoadingList.year = false;
      this.isLoadingList.ref = false;
      if (this.memoData.department && this.selectedYear) {
        this.loadWBS();
      }
    });
    this.loadAllGeneralProfile();
  }

  loadWBS() {
    this.isLoadingList.wbs = true;
    this.wbsList = [];
    this.selectedWBS = null;
    const params = new HttpParams()
      .set('type', 'wbs')
      .set('year', this.selectedYear)
      .set('department', +this.selectedCompany === +this.company_id ? this.memoData.department.toString() : '')
      .set('company', +this.selectedCompany === +this.company_id ? '' : this.selectedCompany);
    this.memoService.getDropDown(params).subscribe((wbs) => {
        this.wbsList = wbs.wbs;
        if (+this.selectedCompany !== +this.company_id) {
          this.wbsList = this.wbsList.map(item => {
            return {code: item.label, id: item.value};
          });
        }
        this.isLoadingList.wbs = false;
        if (this.formAssetData.wbs) {
          this.changeWBS();
        }
      },
      error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      });
  }

  changeWBS() {
    this.isLoadingList.costcentre = true;
    this.costCentreList = [];
    this.formAssetData.cost_centre = null;
    const params = new HttpParams()
      .set('type', 'costcentre')
      .set('wbs', this.formAssetData.wbs.toString())
      .set('company', this.selectedCompany ? this.selectedCompany : localStorage.getItem('company_id').toString());
    this.memoService.getDropDown(params).subscribe((costcentre) => {
        this.costCentreList = costcentre.costcentre;
        this.isLoadingList.costcentre = false;
      },
      error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      });
  }

  selectedTo(event) {
    if (event.label === '') {
      this.memoData.to_none_user = event.name;
    }
  }

  changeMemoNumber(e) {
    if (this.memoNumberList[0].id === e.id) {
      this.memoData.is_custom_memo_number = false;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[0].id;
    } else if (this.memoNumberList[1].id === e.id) {
      this.memoData.is_custom_memo_number = true;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[1].id;
    }
  }

  filterUser(event: { isCustom: boolean; isClear: boolean; data: { name: string; label: number }[] }) {
    this.loadTo = true;
    this.profileList = event.data;
    setTimeout(() => {
      this.loadTo = false;
    }, 10);
  }

  updateNormalLoa(event) {
    this.loadTo = true;
    this.profileList = [{
      name: localStorage.getItem('full_name'),
      label: +localStorage.getItem('profile_id')
    }];
    event.data.forEach(level => {
      if (level.members) {
        level.members.forEach(member => {
          if (member.profile && member.profile_name) {
            this.profileList.push({name: member.profile_name, label: member.profile});
          }
        });
      }
    });
    setTimeout(() => {
      this.loadTo = false;
    }, 10);
  }

  checkPurpose() {
    if (this.formAssetData.type_purpose === 'Sell') {
      this.purposeCheck.sell = true;
    }
    if (this.formAssetData.type_purpose === 'Store') {
      this.purposeCheck.store = true;
    }
    if (this.formAssetData.type_purpose === 'Other') {
      this.purposeCheck.other = true;
    }
  }

  checkBoxPurpose(event, data) {
    if (event) {
      this.formAssetData.type_purpose = data;
    } else {
      this.formAssetData.type_purpose = null;
      this.formAssetData.purpose_detail = null;
    }
  }

  getRefMemos() {
    this.isLoadingList.ref = true;
    const params = new HttpParams()
      .set('type', 'memo_number,department')
      .set('company', localStorage.getItem('company_id'));
    this.memoService.getDropDown(params).subscribe(dropDown => {
      this.memoReference = dropDown.memo_number;
      let departmentL = dropDown.department;
      departmentL = departmentL.map(item => {
        return {
          label: (item.context.code_number as string) + ' - ' + (item.label as string),
          value: item.value,
        };
      });
      this.departmentList = _.orderBy(departmentL, [department => department.label], 'asc');
      this.isLoadingList.ref = false;
    });
  }

  loadAllGeneralProfile() {
    this.isLoadingList.cc = true;
    this.isLoadingList.to = true;
    const generalRole = '1';
    const params = new HttpParams()
      .set('type', 'profile')
      // .set('company', localStorage.getItem('company_id'))
      .set('role', generalRole);
    this.memoService.getDropDown(params).subscribe(dropDown => {
      const profiles = dropDown.profile;
      this.profileListAll = _.orderBy(profiles, [profile => profile.name.toLowerCase()], 'asc');
      this.isLoadingList.cc = false;
      this.isLoadingList.to = false;
    });
  }

  initialCheckMemoNumberExist() {
    this.userKeyMemoNumber = this.memoData.memo_number;
    const sub = this.userKeyMemoNumber$
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        map(memoNumber => memoNumber.trim()),
        filter(memoNumber => memoNumber !== ''),
        switchMap((memoNumber: string) => {
          const params = new HttpParams().set('memo_number', memoNumber);
          return this.memoService.isMemoNumberExist(params);
        }))
      .subscribe((isExist: boolean) => {
        this.memoNumberAlreadyExist = isExist;
        if (!this.memoNumberAlreadyExist) {
          this.memoData.memo_number = this.userKeyMemoNumber.trim();
        } else {
          this.memoData.memo_number = null;
        }
      });

    this.subscriptions.add(sub);
  }

  addTagNonMemoRef(name: string) {
    return {label: name, value: name, tag: true};
  }

  get isEnLanguage() {
    return this.translate.currentLang === 'en';
  }

  initialSelectedDepartment(departmentList: any[]) {
    let initialDepartment = departmentList.length > 0 ? departmentList[0].value : null;
    const ownDepartmentIds = localStorage.getItem('department_id');
    if (!ownDepartmentIds) {
      return;
    }
    const ownDepartmentId = ownDepartmentIds.split(',')[0];
    (departmentList || []).forEach(d => {
      if (d.value === Number(ownDepartmentId)) {
        initialDepartment = d.value;
        return;
      }
    });
    return initialDepartment;
  }
}

