import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../shared/services/authentication.service';

@Component({
  selector: 'app-adfs-callback',
  templateUrl: './adfs-callback.component.html',
  styleUrls: ['./adfs-callback.component.scss']
})
export class AdfsCallbackComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private tokenService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const jwtStrEncoded = params['jwt'];
      const jwtStr = window.atob(jwtStrEncoded);
      const jwtData = JSON.parse(jwtStr);
      this.tokenService.clearStorage();
      this.tokenService.setProfileInLocalStorage(jwtData);
      localStorage.setItem('adfs', 'true');
      this.router.navigate(['/memo/memo/real/list']);
    });
  }

}
