import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeedListComponent } from './feed-list/feed-list.component';
import { FeedAnnouncementComponent } from './feed-announcement/feed-announcement.component';

export const feedRoutes: Routes = [
  {
    path: 'list',
    children: [
      { path: '', component: FeedListComponent },
      {
        path: ':id',
        component: FeedListComponent,
      },
      {
        path: 'announcement/:announcementId',
        component: FeedListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(feedRoutes)],
  exports: [RouterModule],
})
export class FeedRoutingModule {}
