import {NotificationService} from 'src/app/shared/services/notification.service';
import {MemoService} from '../../memo.service';
import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {forEach, get , includes , remove , uniqWith , isEqual} from 'lodash';
// import {ProjectService} from 'src/app/project/project.service';
import {HttpClientService} from '../../../../../shared/services/http-client.service';
// import {el} from "@angular/platform-browser/testing/src/browser_util";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {ProjectService} from '../../../../../project.service';
import {ThemeService} from '../../../../../shared/services/theme.service';

@Component({
  selector: 'app-memo-real-attachment',
  templateUrl: './memo-real-attachment.component.html',
  styleUrls: ['./memo-real-attachment.component.scss']
})
export class MemoRealAttachmentComponent implements OnInit {
  attachmentList = [];
  showPreview = false;
  userList = [];
  documentData: any;
  showResults = false;
  isLoading = false;
  count = 0;
  documentName = '';
  currentPage = 1;
  userListCurrentPage = 1;
  attachInfomation;
  searchUser = '';

  isMobile: boolean;

  debouncer = new Subject<string>();
  @Input() memo: any;
  @ViewChild('attachModal', { static: true }) attachModal: any;
  @Output() countAttachment = new EventEmitter();

  constructor(
    private notification: NotificationService,
    private projectService: ProjectService,
    private memoService: MemoService,
    private httpClientService: HttpClientService,
    private modalService: NgbModal,
    private themeService: ThemeService
  ) {
    this.debouncer.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe((event) => {
      this.userListCurrentPage = 1;
      this.getUserList(event);
    });
  }

  ngOnInit() {
    this.loadAttachmentList();
    this.isMobile = this.themeService.isMobile();
  }

  loadAttachmentList() {
    this.showResults = false;
    const params = new HttpParams()
      .set('page', this.currentPage.toString())
      .set('memo', this.memo.id);
    this.memoService.getMemoAttachment(params).subscribe(
      (attachments: any) => {
        this.attachmentList = attachments.results;
        this.showResults = true;
        this.count = attachments.count;
        this.countAttachment.emit(attachments.count);
      },
      error => {
        this.notification.showNotification(error.status);
        this.showResults = true;
      }
    );
  }

  getUserList(filterUsername?) {
    let params = new HttpParams()
      .set('page' , this.userListCurrentPage.toString());
    if (filterUsername) {
      params = params.set('full_name' , filterUsername);
    }
    // this.projectService.getProfileList(params).subscribe(
    //   (users: any) => {
    //     this.userList = users.results;
    //     // this.userList = uniqWith([...this.userList, ...users.results], isEqual);
    //     // this.isCommentNotCollapsed = true;
    //   },
    //   error => {
    //     this.notification.showNotification(error.status);
    //   }
    // );
  }

  changePage() {
    this.loadAttachmentList();
  }

  getExtention(attachment) {
    if ( attachment.file) {
      return attachment.file.substr(attachment.file.lastIndexOf('.') + 1);
    }
    return attachment.file_extension;
  }

  closePreview() {
    this.showPreview = false;
  }

  preview(file) {
    // if(!file.accessible){
    //   this.notification.showSpecificError('ไม่ได้รับสิทธิ์ในการเข้าถึงเอกสารฉบับนี้');
    //   return;
    // }
    if (this.isMobile) {
        this.showPreview = false;
        window.open(file.file);
    } else {
        this.documentData = file.file;
        this.documentName = file.filename;
        this.showPreview = true;
      }
  }

  onResize(event) {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
  }

  uploadFile(files) {
    this.isLoading = true;
    const fd = new FormData();
    fd.append('memo', this.memo.id);
    forEach(files, file => {
      const name = get(file, 'name', 'relativePath');
      file.fileEntry ? fd.append(name, file.fileEntry) : fd.append(name, file);
    });
    this.memoService.uploadMemoAttachment(fd).subscribe(
      res => {
        this.isLoading = false;
        this.loadAttachmentList();
      },
      error => (this.isLoading = false)
    );
  }

  openAttachmentModal() {
    this.attachModal.openFromOutside();
  }

  checkIfRemovable() {
    return Number(localStorage.getItem('user_id')) === get(this.memo, 'created_user', '');
  }

  deleteAttachmentFile(data) {
    this.memoService
      .removeMemoAttachment(data.data.id)
      .subscribe(
        () => this.loadAttachmentList(),
        error => this.notification.showNotification(error.status)
      );
  }

  downloadDocument(attachment) {
    // if(!attachment.accessible){
    //   this.notification.showSpecificError('ไม่ได้รับสิทธิ์ในการเข้าถึงเอกสารฉบับนี้');
    //   return;
    // }
    this.documentName = attachment.file_name;

    this.projectService.downloadFile(attachment.file).subscribe(
      res => {
        const blob: any = new Blob([res.body]);
        const link: any = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = attachment.filename;
        link.click();
      },
      error => {
        this.notification.showNotification(error.status);
      }
    );
  }

  callAttachmentModal(attachment, attachmentModal) {
    if (!attachment.accessible) {
      this.notification.showSpecificError('ไม่ได้รับสิทธิ์ในการเข้าถึงเอกสารฉบับนี้');
      return;
    }
    this.attachInfomation = attachment;
    this.attachModal = this.modalService.open(attachmentModal);
    this.attachModal.result.then(
      () => {
      },
      () => {
        this.notification.showSuccess();
      }
    );
  }

  selectUserForVisibility(userID) {
    if (includes(this.attachInfomation.profiles, userID)) {
      remove(this.attachInfomation.profiles, id => {
        return id === userID;
      });
    } else {
      this.attachInfomation.profiles.push(userID);
    }
  }

  isIncludes(collection: Array<any>, value) {
    return includes(collection, value);
  }

  submitAttachment() {
    this.isLoading = true;
    const currentUser = parseInt(localStorage.getItem('profile_id'), 10);
    const data = {
      profiles: this.attachInfomation.profiles.length ?
        [...this.attachInfomation.profiles, currentUser] : this.attachInfomation.profiles
    };
    this.memoService.updateMemoAttchment(this.attachInfomation.id, data).subscribe(
      () => {
        this.isLoading = false;
        this.notification.showSuccess();
        this.attachModal.close();
      },
      error => {
        this.isLoading = false;
        this.notification.showNotification(error.status);
      }
    );
  }

  triggerDebouncer(event) {
    this.debouncer.next(event);
  }

}
