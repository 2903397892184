import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OperationLogComponent } from './operation-log.component';


export const OperationLogRoutes: Routes = [
  {
    path: '',
    component: OperationLogComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(OperationLogRoutes)],
  exports: [RouterModule]
})
export class OperationLogRoutingModule {
}
