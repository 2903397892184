import { Component, OnInit } from '@angular/core';
import { ThemeService } from './shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './shared/services/authentication.service';
import { NavigationEnd, Router } from '@angular/router';
import { SidebarService } from './core/sidebar/sidebar.service';
import { Subscription } from 'rxjs';

interface Token {
  token: string;
  role: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  defaultLang = 'en';
  currentUser = localStorage.currentUser;
  public loading = true;
  sidebarActive = false;
  sidebarSubj: Subscription;
  checkTheme = false;

  constructor(
    private themeService: ThemeService,
    private translateService: TranslateService,
    private sidebarService: SidebarService
  ) {
    this.sidebarSubj = this.sidebarService.data.subscribe((res: boolean) => {
      this.sidebarActive = res;
    });
    this.defaultLang = localStorage.getItem('lang');
    if (!this.defaultLang || this.defaultLang === 'null') {
      localStorage.setItem('lang', 'en');
      this.translateService.setDefaultLang('en');
    }
    this.defaultLang = localStorage.getItem('lang');
    this.translateService.use(this.defaultLang);
  }

  ngOnInit(): void {
    this.getTheme();
  }

  onClickHamburger() {
    this.themeService.isSidebarOpen = !this.themeService.isSidebarOpen;
  }

  handleClickMain() {
    if (this.themeService.isMobile()) {
      this.themeService.isSidebarOpen = false;
    }
  }
  getTheme() {
    this.themeService.getTheme().subscribe(theme => {
      this.themeService.setThemeList(theme);
    });
    this.loading = false;
  }
}
