import {Injectable} from '@angular/core';
import {HttpClientService} from './shared/services/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private http: HttpClientService) {
  }

  downloadFile(url) {
    return this.http.download(url);
  }

  printFile(url) {
    return this.http.printFile(url);
  }


}
