import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {UserService} from '../../../../../../permission/user/user.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {LoaService} from '../../../../../loa/loa.service';
import {SweetAlertService} from '../../../../../../../shared/services/sweet-alert.service';
import {MemoService} from '../../../../memo.service';
import { Subscription } from 'rxjs/internal/Subscription';
import {Subject} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';

@Component({
  selector: 'app-memo-loa-select',
  templateUrl: './memo-loa-select.component.html',
  styleUrls: ['./memo-loa-select.component.scss']
})
export class MemoLoaSelectComponent implements OnChanges, OnInit, OnDestroy {

  loa: any;
  memberList = [];
  loadsign = [];
  loaListAll = [];
  loaList = [];
  contentApprovel = [];
  count_sign = 0;
  count_levels = 0;
  approvalList = [];
  approvalListLevel = [];
  profileListAll = [];
  signatures = {'approver': []};
  countNumber: any;
  checkCustomLoa = false;
  profileUserList = [];
  isLoadingLoa = false;

  loaSignUserChoices = [
    {value: 'user', label: 'user'},
    {value: 'none user', label: 'non-user'},
  ];
  loaSignNoneUserPlaceHolder = {
    name: 'Contact Name',
    job_position: 'Job Position',
    email: 'Email Address'
  };
  countMember = 0;
  countCheckbox = 0;
  changeLog = [];
  delay = false;
  refreshSelect = false;
  loadLoaTrigger$ = new Subject();

  // tslint:disable-next-line:no-input-rename
  @Input('isBudget') isBudget;
  // tslint:disable-next-line:no-input-rename
  @Input('budgetType') budgetType;
  // tslint:disable-next-line:no-input-rename
  @Input('wbs') wbs;
  // tslint:disable-next-line:no-input-rename
  @Input('level') level;
  @Input() budgetAmount;
  // tslint:disable-next-line:no-input-rename
  @Input('memoData') memoData;
  @Input() companyId;
  @Output() memoDataChange = new EventEmitter();
  @Input() numberLabel: string;
  @Input() costcentreId: number;
  @Input() limitSignature = 1000000;

  // @Input() profileList: any;
  @Output() profileList = new EventEmitter();
  @Output() normalLOA = new EventEmitter();

  subscriptions = new Subscription();
  constructor(
    private userService: UserService,
    private translate: TranslateService,
    private loaService: LoaService,
    private swal: SweetAlertService,
    private memoService: MemoService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadLoaTrigger$.next();
  }

  ngOnInit() {
    if (this.isBudget) {
      this.getApprovalList();
    }
    if (this.memoData.loa) {
      this.checkApproveListUpdate();
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translatePage();
    });

    this.subscriptions.add(
      this.loadLoaTrigger$.pipe(
        tap(() => {
          this.isLoadingLoa = true;
        }),
        debounceTime(1000)
      ).subscribe(() => {
        this.loadLOAList();
      })
    );

    // load LOA first time for memo upload
    if (!this.isBudget) {
      this.loadLoaTrigger$.next();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  translatePage() {
    this.refreshSelect = true;
    this.loaListAll = this.loaListAll.map(item => {
      if (item.id === 'cus') {
        item.name = this.translate.instant('MEMOS.CUSTOM-LOA');
      }
      return item;
    });
    if (this.memoData.is_custom_loa) {
      this.loa = this.translate.instant('MEMOS.CUSTOM-LOA');
    }
    setTimeout(() => {
      this.refreshSelect = false;
    }, 100);
  }

  checkApproveListUpdate() {
    this.count_levels = this.memoData.loa.length - 1;
    this.memoData.loa_level_delegate.forEach(item => {
      this.approvalListLevel.push([]);
    });
  }

  loadLOAList() {
    this.isLoadingLoa = true;
    let params = new HttpParams()
      .set('is_budget', this.isBudget ? 'True' : 'False')
      .set('budget_type', this.budgetType ? this.budgetType : '')
      .set('company', this.companyId ? this.companyId.toString() : localStorage.getItem('company_id').toString());

    if (this.budgetAmount !== undefined && this.budgetAmount !== null) {
      params = params.set('budget_amount', this.budgetAmount.toString());
    }

    params = this.wbs !== undefined && this.wbs !== null ? params.set('wbs', this.wbs.toString()) : params;
    // tslint:disable-next-line:max-line-length
    params = this.costcentreId !== undefined && this.costcentreId !== null ? params.set('costcentre', this.costcentreId.toString()) : params;

    // .set('min_approve_count', '3');
    this.loaService.getLOAList(params).subscribe(data => {
      this.loaListAll = data.results;
      this.loaListAll.push({
        name: this.translate.instant('MEMOS.CUSTOM-LOA'),
        id: 'cus',
        department: null
      });
      this.loaList = _.cloneDeep(this.loaListAll);
      this.isLoadingLoa = false;
      const department = localStorage.getItem('department_id').split(',').map(x => +x);
      this.loaList = _.filter(this.loaListAll, loa => department.includes(loa.department) || loa.department === null);
      this.loaList.forEach(obj => {
        if (this.memoData.is_custom_loa) {
          this.loa = this.translate.instant('MEMOS.CUSTOM-LOA');
        }
        if (this.memoData.loa_group === obj.id) {
          this.loa = obj.name;
        }
      });
      this.showLOAData();
    });
  }

  showLOAData() {
    if (this.memoData.is_custom_loa) { // custom group
      this.getApprovalList('');
      // this.memoData.loa_group = null;
      this.loadsign = [];
      this.checkCustomLoa = true;
      this.countNumber += 1;
      _.forEach(this.memoData.loa, obj => {
        this.contentApprovel.push({
          content: obj.signature_required ? 'MEMOS.SIGNATURE-REQUIRE' : 'MEMOS.NO-SIGNATURE-REQUIRE'
        });
      });
      this.updateJobPositionList();
    } else { // normal group
      // this.loaId = this.memoData.loa_group;
      this.loaList.forEach(obj => {
        if (this.memoData.loa_group === obj.id) {
          this.loadsign = obj.levels;
          this.normalLOA.emit({isCustom: false, isClear: false, data: this.loadsign});
        }
      });
      this.updateJobPositionList();
      this.memoData.loa = [];
      this.checkCustomLoa = false;
    }
    // }
  }

  selectLOA(data) {
    // this.memberList = [{
    //   name: this.memoData.created_name,
    //   label: this.memoData.created_name
    // }];
    if (data === undefined) { // clear
      this.clearLoa();
    } else {
      if (data.id === 'cus') {  // custom loa
        this.customLoa();
      } else { // normal loa
        this.normalLoa(data);
      }
    }
  }

  clearLoa() {
    this.getApprovalList('');
    this.memoData.loa_group = null;
    this.signatures = {'approver': []};
    this.loadsign = [];
    if (this.checkCustomLoa) {
      this.profileList.emit({isCustom: true, isClear: true, data: []});
    } else {
      this.normalLOA.emit({isCustom: false, isClear: true, data: []});
    }
    this.checkCustomLoa = null;
    this.memoData.loa = [];
  }

  customLoa() {
    this.getApprovalList('', true);
    this.memoData.loa_group = null;
    this.loadsign = [];
    this.memoData.loa = [];
    this.checkCustomLoa = true;
    this.countNumber += 1;
    this.contentApprovel.push({
      content: 'MEMOS.SIGNATURE-REQUIRE'
    });
    this.addLevelLoa();
  }

  normalLoa(data) {
    this.getApprovalList(data.id);
    this.memoData.loa_group = data.id;
    this.loadsign = data.levels;
    this.normalLOA.emit({isCustom: false, isClear: false, data: this.loadsign});
    this.memoData.loa = [];
    this.checkCustomLoa = null;
    data.levels.forEach(obj => {
      obj.members.forEach(job => {
        this.memberList.push({
          name: job.profile_name,
          label: job.profile_name
        });
      });
    });
    this.memberList = _.cloneDeep(this.memberList);
  }

  getApprovalList(loa = '', custom?: boolean) {
    const generalRole = '1';
    let params = new HttpParams()
      .set('type', 'profile')
      // .set('company', localStorage.getItem('company_id'))
      .set('role', generalRole);
    if (loa !== '') {
      params = params.set('loa', loa);
    }
    this.memoService.getDropDown(params).subscribe(dropDown => {
      const profiles = (dropDown.profile || []).map(item => {
        return {full_name: item.name, id: item.id, signature: item.signature};
      });
      this.count_levels = custom ? 0 : this.count_levels;
      this.approvalList = _.orderBy(profiles, [profile => profile.full_name.toLowerCase()], 'asc');
      this.approvalListLevel[this.count_levels] = this.approvalList;
      this.checkApproveListNull();
    });
  }

  checkApproveListNull() {
    this.approvalListLevel.forEach((item, index) => {
      this.approvalListLevel[index] = item.length === 0 ? this.approvalList : item;
    });
  }

  addLevelLoa() {
    this.contentApprovel.push({
      content: 'MEMOS.SIGNATURE-REQUIRE'
    });
    this.memoData.loa.push({
      title: this.translate.instant('MEMOS.LEVEL') + `${this.memoData.loa.length + 1}`,
      members: [[]],
      signature_required: true,
      min_approve_count: 1,
      user_type: this.loaSignUserChoices[0].value
    });
    this.count_levels += 1;
    this.approvalListLevel[this.count_levels] = _.cloneDeep(this.approvalList);
    this.checkSignatureRequired();
  }

  removeLevelLoa() {
    this.memoData.loa.pop();
    _.pickBy(this.approvalListLevel, this.count_levels);
    this.count_levels -= 1;
    this.updateJobPositionList();
    this.checkSignatureRequired();
  }

  onChangeLoaUserType(levelIndex: number) {
    this.memoData.loa[levelIndex].members = [];
    this.addMemberToLevel(levelIndex);
    this.updateJobPositionList();
  }

  updateJobPositionList() {
    this.profileUserList = [{
      name: localStorage.getItem('full_name'),
      label: Number(localStorage.getItem('profile_id'))
    }];
    const loa = this.checkCustomLoa ? this.memoData.loa : this.loadsign;
    loa.forEach(level => {
      level.members.forEach(memberLoa => {
        const hasMemberData = memberLoa.full_name && memberLoa.id;
        if (hasMemberData && this.profileUserList.some(merber => merber.name !== memberLoa.full_name)) {
          this.profileUserList.push({
            name: memberLoa.full_name,
            label: memberLoa.id
          });
        }
      });
    });
    this.profileList.emit({isCustom: this.checkCustomLoa, isClear: false, data: this.profileUserList});
    this.profileUserList = _.cloneDeep(this.profileUserList);
  }

  addMemberToLevel(levelIndex: number) {
    if (this.memoData.loa[levelIndex].user_type === this.loaSignUserChoices[0].value) {
      this.memoData.loa[levelIndex].members.push([]);
    } else if (this.memoData.loa[levelIndex].user_type === this.loaSignUserChoices[1].value) {
      this.memoData.loa[levelIndex].members.push({
        name: '',
        job_position: '',
        email: ''
      });
    }
    this.memoData.loa[levelIndex].min_approve_count = this.memoData.loa[levelIndex].members.length;
    this.guardRangApproveCount(levelIndex);
  }

  removeMemberFromLevel(levelIndex: number, memberIndex: number) {
    // this.approvalListLevel[levelIndex].push(this.memoData.loa[levelIndex]['members'][memberIndex]);
    this.memoData.loa[levelIndex].members.pop();
    this.guardRangApproveCount(levelIndex);
    this.updateJobPositionList();
    this.checkSignatureRequired();
  }

  guardRangApproveCount(levelIndex: number) {
    const currentCount = this.memoData.loa[levelIndex].min_approve_count;
    const maxCount = this.memoData.loa[levelIndex].members.length;
    if (currentCount > maxCount) {
      this.memoData.loa[levelIndex].min_approve_count = maxCount;
    } else if (currentCount < 1) {
      this.memoData.loa[levelIndex].min_approve_count = 1;
    }
    this.checkSignatureRequiredMember(levelIndex);
  }

  onInputNoneUserJobPosition() {
    this.updateJobPositionList();
  }

  onInputApproveCount(levelIndex: number) {
    this.guardRangApproveCount(levelIndex);
  }

  selectedApprover(event?, selectedApprover?, index?, index2?, levelMembers?) {
    if (this.memoData.loa[index]['members'][index2] !== selectedApprover
      && this.approvalList.length !== this.approvalListLevel[index].length) {
      if (this.memoData.loa[index]['members'][index2].length !== 0) {
        this.approvalListLevel[index].push(this.memoData.loa[index]['members'][index2]);
      }
    }
    let listApprove = [];
    [listApprove, this.approvalListLevel[index]] = _.partition(this.approvalListLevel[index], function (n) {
      return selectedApprover.id === n.id;
    });
    this.approvalListLevel[index] = _.orderBy(this.approvalListLevel[index], ['full_name'], ['asc']);
    this.memoData.loa[index]['members'][index2] = event;
    this.count_sign += 1;
    this.updateJobPositionList();
  }

  checkApproval(index, data?) {
    const checkApproval = this.memoData.loa[index].signature_required;
    if (checkApproval) {
      this.contentApprovel[index].content = 'MEMOS.SIGNATURE-REQUIRE';
    } else {
      this.contentApprovel[index].content = 'MEMOS.NO-SIGNATURE-REQUIRE';
    }
    this.checkSignatureRequiredMember(index, data);
  }

  // check limit Signature Required max 3
  checkSignatureRequired(index?) {
    this.countMember = 0;
    this.countCheckbox = 0;
    this.memoData.loa.forEach((loa, i) => {
      if (loa.signature_required === true) {
        this.countMember += loa.min_approve_count;
        if (this.countMember > this.limitSignature) {
          loa.signature_required = false;
          this.memoData.loa[i].signature_required = false;
          this.contentApprovel[i].content = 'MEMOS.NO-SIGNATURE-REQUIRE';
          this.swal.toastNotification({type: 'error', content: this.translate.instant('MEMOS.ERROR-SIGNATURE')});
        }
      }
    });
  }

  checkSignatureRequiredMember(index, data?) {
    this.delay = true;
    let count = 0;
    this.memoData.loa.forEach(checkMember => {
      if (checkMember.signature_required === true) {
        count += checkMember.min_approve_count;
        if (count > this.limitSignature) {
          this.memoData.loa[index].signature_required = false;
          this.swal.toastNotification({type: 'error', content: this.translate.instant('MEMOS.ERROR-SIGNATURE')});
        }
      }
    });
    setTimeout(() => {
      this.delay = false;
    }, 1);
  }

  sendNoneUserName(name) {
    const profile = [];
    this.memoData.loa.forEach(level => {
      level.members.forEach(item => {
        if (item.name !== '') {
          profile.push({
            name: item.name,
            label: '',
          });
        }
      });
    });
    this.profileList.emit({isCustom: this.checkCustomLoa, isClear: false, data: profile});
  }
}
