import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'filterMemo',
  pure: false
})
export class FilterMemoProject implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }
    return _.filter(items, (item) =>
      item.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1
    );
  }
}
