import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LaddaModule } from 'angular2-ladda';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GrowlModule } from 'primeng/growl';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { AppComponent } from './app.component';
import { LoginComponent } from './core/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { MainModule } from './modules/main/main.module';
import { AuthGuard } from './auth-guard.service';
import { HttpClientService } from './shared/services/http-client.service';
import { CoreModule } from './core/core.module';
import { PendingRequestComponent } from './core/pending-request/pending-request.component';
import { MessageService } from 'primeng/components/common/messageservice';
import { NotificationService } from './shared/services/notification.service';
import { DndModule } from 'ngx-drag-drop';
import { ClickOutsideModule } from 'ng-click-outside';
import { ProfileService } from './shared/services/profile-service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ExecutiveModule } from './modules/executive/executive.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { DropdownModule } from 'primeng/dropdown';
import { UserProfileService } from './modules/user-profile/user-profile.service';
import {NgSelectModule} from '@ng-select/ng-select';

// const config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(
//       '21884014313-r636n16ta4f2c63f03jmaguqopds8bqo.apps.googleusercontent.com'
//     )
//   }
// ]);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
};

// export function provideConfig() {
//   return config;
// }

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PendingRequestComponent,
  ],
  imports: [
    PerfectScrollbarModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    MainModule,
    HttpClientModule,
    CoreModule,
    LaddaModule,
    FormsModule,
    GrowlModule,
    ReactiveFormsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgbModule,
    DndModule,
    ClickOutsideModule,
    ExecutiveModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttploaderFactory,
        deps: [HttpClient],
      },
    }),
    PasswordStrengthMeterModule,
    DropdownModule,
    NgSelectModule
  ],
  providers: [
    AuthGuard,
    HttpClientService,
    MessageService,
    NotificationService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ProfileService,
    UserProfileService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttploaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
