import {environment} from '../../../environments/environment';

export type CompanyCode =
  'BGC' | 'PTI' | 'AGI' | 'PGI' | 'BGCG' | 'RBI' | 'AY1' | 'AY2' | 'BVP' | 'BGCP' | 'PRM' |
  'BG' | 'BGA' | 'BGPU' | 'KBI' | 'BGE';

export const EXPENSES_CLAIM = 'expenses claim';
export const BUDGET_REQUEST = 'budget request';
export const BUDGET_TRANSFER = 'budget transfer';
export const BUDGET_ASSET = 'budget asset';
export const PR = 'PR';

// BGP expenses claim range เงืนไม่เหมือนบริษัทอื่น
export const BGP_EXPENSES_CLAIM = 'BGP expenses claim';

// BG Group ใช้ range เงินไม่เหมือน BGC group
export const BG_EXPENSES_CLAIM = 'BG expenses claim';
export const BG_BUDGET_REQUEST = 'BG budget request';
export const BG_BUDGET_TRANSFER = 'BG budget transfer';
export const BG_BUDGET_ASSET = 'BG budget asset';

// BGPU ใช้ range เงืนเหมือนกันทุก form
export const BG_BGPU_BUDGET_COMMON = 'BG BGPU budget common';

// BGE ใช้ range เงืนเหมือนกันทุก form
export const BG_BGE_BUDGET_COMMON = 'BG BGE budget common';

// แยกตามประเภทเอกสาร
export type FormBudgetType =
  typeof EXPENSES_CLAIM
  | typeof BUDGET_REQUEST
  | typeof BUDGET_TRANSFER
  | typeof BUDGET_ASSET
  | typeof PR;

// แยกตาม range เงินที่ไม่เหมือนกัน
export type FormBudgetTypeAll =
  typeof EXPENSES_CLAIM
  | typeof BUDGET_REQUEST
  | typeof BUDGET_TRANSFER
  | typeof BUDGET_ASSET
  | typeof PR
  | typeof BGP_EXPENSES_CLAIM
  | typeof BG_EXPENSES_CLAIM
  | typeof BG_BUDGET_REQUEST
  | typeof BG_BUDGET_TRANSFER
  | typeof BG_BUDGET_ASSET
  | typeof BG_BGPU_BUDGET_COMMON
  | typeof BG_BGE_BUDGET_COMMON;

export const BUDGET_TYPES = [
  {id: EXPENSES_CLAIM, name: 'Expenses claim'},
  {id: BUDGET_REQUEST, name: 'Budget request'},
  {id: BUDGET_TRANSFER, name: 'Budget transfer'},
  {id: BUDGET_ASSET, name: 'Budget asset'},
  {id: PR, name: 'Purchase Requisition'},
];

function determineFormBudgetType(shortType: FormBudgetType, companyCode: CompanyCode): FormBudgetTypeAll {
  if (shortType === EXPENSES_CLAIM) {
    if (companyCode === 'AY1' || companyCode === 'AY2') {
      return BGP_EXPENSES_CLAIM;
    }
  }

  if (environment.web_instance === 'BG') {
    if (companyCode === 'BGE') {
      return BG_BGE_BUDGET_COMMON;
    }
    if (companyCode === 'BGPU') {
      return BG_BGPU_BUDGET_COMMON;
    }
    const toBGType = {
      [EXPENSES_CLAIM]: BG_EXPENSES_CLAIM,
      [BUDGET_REQUEST]: BG_BUDGET_REQUEST,
      [BUDGET_TRANSFER]: BG_BUDGET_TRANSFER,
      [BUDGET_ASSET]: BG_BUDGET_ASSET,
    };
    return toBGType[shortType] as FormBudgetTypeAll;
  }

  return shortType;
}

export const formBudgetRangeLevelBudget = {
  [EXPENSES_CLAIM]: [
    {id: 0, name: '0-3000'},
    {id: 1, name: '3000-10000'},
    {id: 2, name: '10000-100000'},
    {id: 3, name: '100000-200000'},
    {id: 4, name: '>200000'},
  ],
  [BUDGET_REQUEST]: [
    {id: 5, name: '0-3000000'},
    {id: 6, name: '3000000-5000000'},
    {id: 7, name: '5000000-10000000'},
  ],
  [BUDGET_TRANSFER]: [
    {id: 8, name: '0-50000'},
    {id: 9, name: '50000-100000'},
    {id: 10, name: '100000-500000'},
    {id: 11, name: '500000-1000000'},
    {id: 12, name: '>1000000'},
  ],
  [BUDGET_ASSET]: [
    {id: 13, name: '0-500000'},
    {id: 14, name: '500000-5000000'},
    {id: 15, name: '>5000000'},
  ],

  [BGP_EXPENSES_CLAIM]: [
    {id: 16, name: '0-5000'},
    {id: 17, name: '5000-10000'},
    {id: 18, name: '10000-50000'},
    {id: 19, name: '>50000'},
  ],

  [BG_EXPENSES_CLAIM]: [
    {id: 35, name: '0-3000'},
    {id: 36, name: '3000-10000'},
    {id: 37, name: '10000-50000'},
    {id: 38, name: '50000-100000'},
    {id: 39, name: '>100000'},
  ],
  [BG_BUDGET_REQUEST]: [
    {id: 25, name: '0-50000'},
    {id: 26, name: '50000-100000'},
    {id: 27, name: '100000-500000'},
    {id: 28, name: '500000-1000000'},
    {id: 29, name: '>1000000'},
  ],
  [BG_BUDGET_TRANSFER]: [
    {id: 20, name: '0-50000'},
    {id: 21, name: '50000-100000'},
    {id: 22, name: '100000-500000'},
    {id: 23, name: '500000-1000000'},
    {id: 24, name: '>1000000'},
  ],
  [BG_BUDGET_ASSET]: [
    {id: 30, name: '0'},
    {id: 31, name: '0-500000'},
    {id: 32, name: '500000-1000000'},
    {id: 33, name: '1000000-5000000'},
    {id: 34, name: '>5000000'},
  ],

  [BG_BGPU_BUDGET_COMMON]: [
    {id: 40, name: '0-10000'},
    {id: 41, name: '10000-30000'},
    {id: 42, name: '30000-100000'},
    {id: 43, name: '100000-1000000'},
    {id: 44, name: '1000000-2000000'},
    {id: 45, name: '>2000000'},
  ],

  [BG_BGE_BUDGET_COMMON]: [
    {id: 46, name: '0-50000'},
    {id: 47, name: '50000-100000'},
    {id: 48, name: '100000-1000000'},
    {id: 49, name: '1000000-2000000'},
    {id: 50, name: '>2000000'},
  ],

  [PR]: [
    {id: 51, name: '0-50000'},
    {id: 52, name: '50000-100000'},
    {id: 53, name: '100000-500000'},
    {id: 54, name: '500000-1000000'},
    {id: 55, name: '>1000000'},
  ]
};

type BudgetLevelRangeCase = 'specify' | 'min' | 'range' | 'not defined';

export interface BudgetLevelRange {
  case: BudgetLevelRangeCase;
  min: number | null;
  max: number | null;
  specify: number | null;
}

export function getRangeLevelBudget(shortType: FormBudgetType, companyCode: CompanyCode) {
  const type = determineFormBudgetType(shortType, companyCode);
  return formBudgetRangeLevelBudget[type];
}

function getRangeNumberFromBudgetLevelName(budgetRangeName: string): BudgetLevelRange {
  let min = null;
  let max = null;
  let specify = null;
  let budgetCase: BudgetLevelRangeCase = 'not defined';

  if (budgetRangeName.includes('-')) {
    // case format like "0-1000"
    budgetCase = 'range';
    const split = budgetRangeName.split('-');
    min = Number(split[0]);
    max = Number(split[1]);

  } else if (budgetRangeName.includes('>')) {
    // case format like ">1000"
    budgetCase = 'min';
    min = Number(budgetRangeName.replace('>', ''));

  } else {
    // case format like "0"
    budgetCase = 'specify';
    specify = Number(budgetRangeName);
  }
  return {min, max, specify, case: budgetCase};
}

function inBudgetLevelRange(total: number, range: BudgetLevelRange): boolean {
  if (range.case === 'specify') {
    return total === range.specify;
  }
  if (range.case === 'min') {
    return total > range.min;
  }
  if (range.case === 'range') {
    return total > range.min && total <= range.max;
  }
  return false;
}

export function getLevelBudget(shortType: FormBudgetType, total: number, companyCode: CompanyCode) {
  const type = determineFormBudgetType(shortType, companyCode);
  const budgetLevels = formBudgetRangeLevelBudget[type] || [];

  const level = budgetLevels.find(budgetLevel => {
    const range = getRangeNumberFromBudgetLevelName(budgetLevel.name);
    return inBudgetLevelRange(total, range);
  });

  if (!level) {
    return;
  }
  return level.id;
}

export function getCompanyCodeNameFromId(id: number, companyDropdown) {
  const company = (companyDropdown || []).find(c => c.value === id);
  if (!company) {
    return;
  }
  return company.context.code_name;
}
