import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CoreModule} from '../../core/core.module';
import {FormsModule} from '@angular/forms';
import {FileUploadComponent} from './file-upload.component';
import {FileDropModule} from 'ngx-file-drop';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FileDropModule
  ],
  declarations: [FileUploadComponent],
  exports: [
    FileUploadComponent
  ]
})
export class FileUploadModule { }
