import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'partialSearch'})
export class PartialSearchPipe implements PipeTransform {
  transform(arr, name: string, search: string) {
    if (!search || !name) {
      return arr;
    }
    return arr.filter(item => (item[name] || '').toUpperCase().includes(search.toUpperCase()));
  }
}

@Pipe({name: 'partialSearchCodeName'})
export class PartialSearchCodeNamePipe implements PipeTransform {
  transform(arr, search: string) {
    if (!search) {
      return arr;
    }
    return arr.filter(item => `${item['code']}-${item['name']}`.toUpperCase().includes(search.toUpperCase()));
  }
}

@Pipe({name: 'wbsBudgetSearch'})
export class WbsBudgetSearchPipe implements PipeTransform {
  transform(arr: any[], wbs: string, costcentre: string) {
    let results = arr;

    if (wbs) {
      results = results.filter((item) => {
        const itemCodeUpper = (item['code'] || '').toUpperCase();
        const wbsUpper = wbs.toUpperCase();
        return itemCodeUpper.includes(wbsUpper);
      });
    }

    if (costcentre) {
      results = results.filter((item) => {
        if (!('costcentre' in item)) {
          return false;
        }
        const costcentreList: any[] = item['costcentre'] || [];

        return costcentreList.some((c) => {
          const costcentreUpper = costcentre.toUpperCase();
          let isContain = false;
          if  ('code' in c && c['code']) {
            isContain = isContain || c['code'].toUpperCase().includes(costcentreUpper);
          }
          if ('name' in c && c['name']) {
            isContain = isContain || c['name'].toUpperCase().includes(costcentreUpper);
          }
          return isContain;
        });
      });
    }

    return results;
  }
}
