import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  toastNoti = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000
  });

  constructor() { }

  toastNotification(objectKey = {}) {
    if ('errorDetail' in objectKey) {
      this.toastNoti.fire({
        type: objectKey['type'],
        html: `${objectKey['content']} <br /> <span class="text-red"> ${objectKey['errorDetail']} </span>`,
        timer: objectKey['timer'] || 4000
      });
    } else {
      this.toastNoti.fire({
        type: objectKey['type'],
        title: objectKey['content'] || 'SAVE CHANGE',
        timer: objectKey['timer'] || 4000
      }).then((result) => {
        return result;
      });
    }
  }
}
