import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SweetAlertService} from '../../../../shared/services/sweet-alert.service';
import {MemoService} from '../../../memos/memo/memo.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-department-create',
  templateUrl: './department-create.component.html',
  styleUrls: ['./department-create.component.scss']
})
export class DepartmentCreateComponent implements OnInit {

  modal: NgbModalRef;
  name = '';
  code = '';
  role_level = [{
    name: 'Corporate',
    id: '1'
  }, {
    name: 'Property',
    id: '2'
  }
  ];
  level = null;

  @Output() submitted = new EventEmitter();

  constructor(private modalService: NgbModal,
              private swal: SweetAlertService,
              private apiService: MemoService,
              private translate: TranslateService
  ) {
  }

  ngOnInit() {
  }

  open(content) {
    this.level = null;
    this.name = '';
    this.code = '';
    this.modal = this.modalService.open(content);
  }

  submitModal() {
    const msg = [];
    const dataToBeSent = {
      name: this.name,
      code: this.code,
    };
    if (!this.name) {
      msg.push(this.translate.instant('DEPARTMENT.DEPARTMENT-NAME'));
    }
    if (this.code.length < 3) {
      msg.push(this.translate.instant('DEPARTMENT.DOCUMENT-NO'));
    }
    if (msg != null && msg.length > 0) {
      this.swal.toastNotification({type: 'error', content: (this.translate.instant('DEPARTMENT.PLEASE-ENTER') + '\t') + msg.join(', ')});
      return;
    } else {
      this.apiService.addDepartment(dataToBeSent).subscribe(
        () => {
          // this.loadAnnouncement(),
          this.submitted.emit(dataToBeSent);
          this.modal.close();
        }, error => {
          this.swal.toastNotification({
            type: 'error',
            content: this.translate.instant('DEPARTMENT.ERROR-ALREADY-SYSTEM')
            // content: 'This department is already in the system. or this document number is already in the system.'
          });
        }
      );

    }
    // if (this.name.length === 0) {
    //   this.error_name = 'Please enter department name';
    // } else if (this.code.length === 0) {
    //   this.error_code = 'Please enter document no.';
    // }

  }

  // addNewDepartment(data) {
  //   let datas = {
  //     name: data.name,
  //     code: data.code,
  //     company: data.company
  //   }
  //   this.apiService.addDepartment(datas).subscribe(
  //     () => {
  //       this.loadAnnouncement(),
  //       this.modal.close();
  //     }
  //     ,
  //     error => {
  //         this.swal.toastNotification({type: 'error', content: 'This department is already in the system.'});
  //     }
  //   );
  // }

}
