import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-preview-email-template',
  templateUrl: './preview-email-template.component.html',
  styleUrls: ['./preview-email-template.component.scss']
})
export class PreviewEmailTemplateComponent implements OnInit {

  // mainLogo = '/assets/images/logo-cloudhm.png';
  mainLogo = '/assets/images/LOGO_template.png';
  @Input() image: any;
  constructor() { }

  ngOnInit() {
  }

}
