import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'loaNameThai'
})
export class LoaNameThPipe implements PipeTransform {
  transform(value: string, disable: boolean = false): string {
    if (disable) {
      return value;
    }
    return value.replace('level', 'ลำดับที่');
  }
}
