import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {SelectItem} from 'primeng/api';

@Injectable()
export class DateFormatService {
  today: Moment;
  dateFormatListEN: Array<{ value: string; label: string; }>;
  dateFormatListTH: Array<{ value: string; label: string; }>;

  readonly monthTH = [
    {full: 'มกราคม', short: 'ม.ค.'},
    {full: 'กุมภาพันธ์', short: 'ก.พ.'},
    {full: 'มีนาคม', short: 'มี.ค.'},
    {full: 'เมษายน', short: 'เม.ย.'},
    {full: 'พฤษภาคม', short: 'พ.ค.'},
    {full: 'มิถุนายน', short: 'มิ.ย.'},
    {full: 'กรกฎาคม', short: 'ก.ค.'},
    {full: 'สิงหาคม', short: 'ส.ค.'},
    {full: 'กันยายน', short: 'ก.ย.'},
    {full: 'ตุลาคม', short: 'ต.ค.'},
    {full: 'พฤศจิกายน', short: 'พ.ย.'},
    {full: 'ธันวาคม', short: 'ธ.ค.'},
  ];

  constructor() {
    this.today = moment();
    this.dateFormatListEN = [
      {value: 'EN:%-d/%-m/%Y', label: this.today.format('D/M/YYYY')},
      {value: 'EN:%-d-%-m-%Y', label: this.today.format('D-M-YYYY')},
      {value: 'EN:%-d %b %Y', label: this.today.format('D MMM YYYY')},
      {value: 'EN:%-d %B %Y', label: this.today.format('D MMMM YYYY')},
      {value: 'EN:%b %-d, %Y', label: this.today.format('ll')},
      {value: 'EN:%B %-d, %Y', label: this.today.format('LL')},
    ];
    this.dateFormatListTH = [
      {value: 'TH:d/m/yyyy', label: this.getDateLabelTH('TH:d/m/yyyy')},
      {value: 'TH:d mmm yyyy', label: this.getDateLabelTH('TH:d mmm yyyy')},
      {value: 'TH:d mmmm yyyy', label: this.getDateLabelTH('TH:d mmmm yyyy')},
      {value: 'TH:d mmmm พ.ศ.yyyy', label: this.getDateLabelTH('TH:d mmmm พ.ศ.yyyy')},
    ];
  }

  get dateFormatDropDownGroups() {
    return [
      {
        label: 'EN',
        items: this.dateFormatListEN
      },
      {
        label: 'TH',
        items: this.dateFormatListTH
      },
    ];
  }

  get dateFormatDropDownList() {
    return [
      ...this.dateFormatListEN,
      ...this.dateFormatListTH
    ];
  }

  get defaultFormat(): string {
    return this.dateFormatListEN[5].value;
  }

  getDateLabel(value: string): string | null {
    const type = value.slice(0, 2);
    if (type === 'EN') {
      const findObj = this.dateFormatListEN.find(obj => obj.value === value);
      if (!findObj) {
        return null;
      }
      return findObj.label;

    } else if (type === 'TH') {
      const findObj = this.dateFormatListTH.find(obj => obj.value === value);
      if (!findObj) {
        return null;
      }
      return findObj.label;

    } else {
      return null;
    }
  }

  getDateLabelTH(format: string): string {
    const thaiDate = moment(this.today).add(543, 'years');
    const month = thaiDate.month();
    let thaiDateLabel: string;

    switch (format) {
      case 'TH:d/m/yyyy':
        thaiDateLabel = thaiDate.format('D/M/YYYY');
        break;

      case 'TH:d mmm yyyy':
        thaiDateLabel = thaiDate.format(`D ${this.monthTH[month].short} YYYY`);
        break;

      case 'TH:d mmmm yyyy':
        thaiDateLabel = thaiDate.format(`D ${this.monthTH[month].full} YYYY`);
        break;

      case 'TH:d mmmm พ.ศ.yyyy':
        thaiDateLabel = thaiDate.format(`D ${this.monthTH[month].full} พ.ศ.YYYY`);
        break;

      default:
        thaiDateLabel = thaiDate.format('D/M/YYYY');
    }

    return thaiDateLabel;
  }
}
