import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoaCreateComponent } from './loa-create/loa-create.component';

export const loaRoutes: Routes = [
  {
    path: 'loa',
    children: [
      {
        path: 'create',
        component: LoaCreateComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(loaRoutes)],
  exports: [RouterModule]
})
export class LoaRoutingModule { }
