import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpParams} from '@angular/common/http';
import {MemoService} from '../../../memo.service';
import * as _ from 'lodash';
import {FormBudgetRequest, FormBudgetTransfer} from '../memo-template';
import {SweetAlertService} from '../../../../../../shared/services/sweet-alert.service';
import {UserService} from '../../../../../permission/user/user.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {
  BUDGET_TRANSFER,
  getCompanyCodeNameFromId,
  getLevelBudget
} from '../../../../../../shared/constants/helper';

@Component({
  selector: 'app-template-budget-transfer',
  templateUrl: './template-budget-transfer.component.html',
  styleUrls: ['./template-budget-transfer.component.scss']
})
export class TemplateBudgetTransferComponent implements OnInit, OnDestroy {
  isLoading = false;
  isLoadingPreview = false;
  showPreview = false;
  isSmallScreen: boolean;
  documentData: any;

  memoNumber = 'Auto Generated';
  memoNumberList = [
    {
      name: 'Auto Generated',
      name_th: 'สร้างเลขเอกสารแบบอัตโนมัติ',
      id: 'Auto Generated'
    },
    {
      name: 'Custom Memo Number',
      name_th: 'สร้างเลขเอกสารด้วยตนเอง',
      id: 'Custom Memo Number'
    }
  ];

  loadingData = false;
  wbsYearList: [];
  wbsList = [];
  costCentreList = [];
  loadCostcentre = false;
  toWbsList = [];
  formWbsList = [];
  companyList = [];
  companyId = null;
  selectedCompany$ = new Subject<number>();
  profileListAll = [];
  profileList = [];
  departmentList = [];
  toCostCentreList = [];
  formCostCentreList = [];
  wbsCodeName = {
    from_wbs: null,
    to_wbs: null
  };
  isLoadingList = {
    company: false,
    year: false,
    wbs_1: false,
    costcentre_1: false,
    wbs_2: false,
    costcentre_2: false,
    cc: false,
    announcement: false,
  };
  profile_id;
  level = 8;

  subscriptions = new Subscription();

  userKeyMemoNumber$ = new Subject<string>();
  userKeyMemoNumber: string;
  memoNumberAlreadyExist = false;

  autoSelectedCompanyByInitial = true;
  autoSelectedWbsByInitial = true;
  loadCompanyFirstTime = true;

  // tslint:disable-next-line:no-input-rename
  @Input('memoData') memoData;
  // tslint:disable-next-line:no-input-rename
  @Input('attachment') file;
  // tslint:disable-next-line:no-input-rename
  @Input('formBudgetTransfer') formBudgetTransfer: FormBudgetTransfer;
  @Input() selectedTemplate;
  @Output() memoDataChange = new EventEmitter();
  @Output() attachmentChange = new EventEmitter();
  @Output() formBudgetTransferChange = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private memoService: MemoService,
    private swal: SweetAlertService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.memoData.customMemoNumberDropDown = this.memoData.is_custom_memo_number
      ? this.memoNumberList[1].id
      : this.memoNumberList[0].id;
    this.profile_id = localStorage.getItem('profile_id');

    if (this.formBudgetTransfer.from_wbs) {
      this.loadWBS('form_wbs');
    }
    if (this.formBudgetTransfer.to_wbs) {
      this.loadWBS('to_wbs');
    }

    this.loadCompany();
    const sub = this.selectedCompany$.pipe(distinctUntilChanged()).subscribe(() => {
      if (!this.autoSelectedCompanyByInitial) {
        this.resetFormInputData();
      }
      // if (!this.isUserSelectOwnMajorCompany()) {
      //   this.memoData.company_by_selection = this.companyId;
      // } else {
      //   this.memoData.company_by_selection = null;
      // }
      this.memoData.company_by_selection = this.companyId;
      this.getDropDownMemos();
      this.loadAllGeneralProfile();
      this.autoSelectedCompanyByInitial = false;
    });
    this.subscriptions.add(sub);
    const total = this.formBudgetTransfer.transfer_budget || 0;
    const companyCode = getCompanyCodeNameFromId(this.companyId, this.companyList);
    if (companyCode) {
      this.level = getLevelBudget(BUDGET_TRANSFER, total, companyCode);
    }
    this.initialCheckMemoNumberExist();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  checkLevel() {
    const total = this.formBudgetTransfer.transfer_budget || 0;
    const companyCode = getCompanyCodeNameFromId(this.companyId, this.companyList);
    if (companyCode) {
      this.level = getLevelBudget(BUDGET_TRANSFER, total, companyCode);
    }
  }

  updateTransferBudget(value: string) {
    this.formBudgetTransfer.transfer_budget = this.limitTwoDecimal(+value, 'floor');
    this.checkLevel();
  }

  loadCompany() {
    this.isLoadingList.company = true;
    const params = new HttpParams()
      .set('type', 'company')
      .set('profile', this.profile_id);
    this.memoService.getDropDown(params).subscribe((dropdown) => {
      this.companyList = dropdown.company.map((item) => {
        if (item.context && item.context.code_name) {
          item.label = `${item.context.code_name} - ${item.label}`;
        }
        return item;
      });
      this.companyId = this.memoData.company_id;
      this.selectedCompany$.next(this.companyId);
      this.isLoadingList.company = false;
    });
  }

  loadAllGeneralProfile() {
    const generalRole = '1';
    this.isLoadingList.cc = true;
    const params = new HttpParams()
      .set('type', 'profile')
      // .set('company', this.companyId)
      .set('role', generalRole);
    this.memoService.getDropDown(params).subscribe(profiles => {
      this.profileListAll = profiles.profile;
      this.isLoadingList.cc = false;
    });
  }

  changeMemoNumber(e) {
    if (this.memoNumberList[0].id === e.id) {
      this.memoData.is_custom_memo_number = false;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[0].id;
    } else if (this.memoNumberList[1].id === e.id) {
      this.memoData.is_custom_memo_number = true;
      this.memoData.customMemoNumberDropDown = this.memoNumberList[1].id;
    }
  }

  getDropDownMemos() {
    this.loadingData = true;
    this.isLoadingList.announcement = true;
    this.isLoadingList.year = true;
    const params = new HttpParams()
      .set('type', 'memo_number,department,wbs_year')
      .set('company', this.companyId);
    this.memoService.getDropDown(params).subscribe(dropDown => {
      let departmentL = dropDown.department;
      departmentL = departmentL.map(item => {
        return {
          label: (item.context.code_number as string) + ' - ' + (item.label as string),
          value: item.value,
        };
      });
      this.departmentList = _.orderBy(departmentL, [department => department.label], 'asc');
      this.wbsYearList = dropDown.wbs_year;
      this.loadingData = false;
      this.isLoadingList.announcement = false;
      this.isLoadingList.year = false;
    });
  }

  loadWBS(event?) {
    this.wbsList = [];
    let data = null;
    if (event === 'form_wbs') {
      this.isLoadingList.wbs_1 = true;
      data = this.formBudgetTransfer.year_form_wbs;
    }
    if (event === 'to_wbs') {
      this.isLoadingList.wbs_2 = true;
      data = this.formBudgetTransfer.year_to_wbs;
    }
    let params = new HttpParams()
      .set('type', 'wbs')
      .set('year', data);
      // .set('department', this.memoData.department);
    const isUserSelectOwnMajorCompany = this.isUserSelectOwnMajorCompany();
    if (isUserSelectOwnMajorCompany) {
      params = params.set('department', localStorage.getItem('department_id'));
    } else {
      params = params.set('company', this.companyId);
    }
    this.memoService.getDropDown(params).subscribe(
      dropdown => {
        if (event === 'form_wbs') {
          this.formWbsList = isUserSelectOwnMajorCompany
            ? _.cloneDeep(dropdown.wbs)
            : dropdown.wbs.map(i => Object({code: i.label, id: i.value}));
          this.isLoadingList.wbs_1 = false;
        }
        if (event === 'to_wbs') {
          this.toWbsList = isUserSelectOwnMajorCompany
            ? _.cloneDeep(dropdown.wbs)
            : dropdown.wbs.map(i => Object({code: i.label, id: i.value}));
          this.isLoadingList.wbs_2 = false;
        }
        if (this.formBudgetTransfer.from_wbs && event === 'form_wbs') {
          this.isLoadingList.costcentre_1 = true;
          this.changeWBS(event);
        }
        if (this.formBudgetTransfer.to_wbs && event === 'to_wbs') {
          this.isLoadingList.costcentre_2 = true;
          this.changeWBS(event);
        }
      },
      error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      });
  }

  changeWBS(event) {
    this.loadCostcentre = true;
    let data = null;
    if (event === 'form_wbs') {
      data = this.formBudgetTransfer.from_wbs;
    } else if (event === 'to_wbs') {
      data = this.formBudgetTransfer.to_wbs;
    }
    const params = new HttpParams()
      .set('type', 'costcentre')
      .set('wbs', data)
      .set('company', this.companyId);
    this.memoService.getDropDown(params).subscribe(costCentre => {
        if (event === 'form_wbs') {
          this.formCostCentreList = costCentre.costcentre;
          this.isLoadingList.costcentre_1 = false;
        } else {
          this.toCostCentreList = costCentre.costcentre;
          this.isLoadingList.costcentre_2 = false;
        }
        this.loadCostcentre = false;
        this.inputDetailWbs(event);
      },
      error => {
        if (error.error && error.error.en) {
          this.swal.toastNotification({type: 'error', content: error.error[this.translate.currentLang].join('\n')});
        } else {
          this.swal.toastNotification({type: 'error'});
        }
      });
  }

  inputDetailWbs(event?) {
    let data = null;
    let yearDate = null;
    if (event === 'form_wbs') {
      data = this.formBudgetTransfer.from_wbs;
      yearDate = this.formBudgetTransfer.year_form_wbs;
    }
    if (event === 'to_wbs') {
      data = this.formBudgetTransfer.to_wbs;
      yearDate = this.formBudgetTransfer.year_to_wbs;
    }
    const params = new HttpParams()
      .set('company_id', this.companyId)
      .set('year', yearDate)
      .set('wbs_id', data);
    this.memoService.getBudgetDetail(params).subscribe(res => {
      res.results.forEach(budgetRequest => {
        if (event === 'form_wbs') {
          this.wbsCodeName.from_wbs = budgetRequest.code;
          this.formBudgetTransfer.from_budget = budgetRequest.budget;
          this.formBudgetTransfer.from_remaining = budgetRequest.available;
        }
        if (event === 'to_wbs') {
          this.wbsCodeName.to_wbs = budgetRequest.code;
          this.formBudgetTransfer.to_budget = budgetRequest.budget;
          this.formBudgetTransfer.to_remaining = budgetRequest.available;
        }
      });
    });
  }

  initialCheckMemoNumberExist() {
    this.userKeyMemoNumber = this.memoData.memo_number;
    const sub = this.userKeyMemoNumber$
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        map(memoNumber => memoNumber.trim()),
        filter(memoNumber => memoNumber !== ''),
        switchMap((memoNumber: string) => {
          const params = new HttpParams().set('memo_number', memoNumber);
          return this.memoService.isMemoNumberExist(params);
        }))
      .subscribe((isExist: boolean) => {
        this.memoNumberAlreadyExist = isExist;
        if (!this.memoNumberAlreadyExist) {
          this.memoData.memo_number = this.userKeyMemoNumber.trim();
        } else {
          this.memoData.memo_number = null;
        }
      });

    this.subscriptions.add(sub);
  }

  isUserSelectOwnMajorCompany(): boolean {
    const userSelectedCompanyId = this.companyId;
    const majorCompanyId = +localStorage.getItem('company_id');
    return userSelectedCompanyId === majorCompanyId;
  }

  resetFormInputData() {
    this.formBudgetTransfer.from_budget = 0;
    this.formBudgetTransfer.to_budget = 0;
    this.formBudgetTransfer.from_remaining = 0;
    this.formBudgetTransfer.to_remaining = 0;
    this.formBudgetTransfer.transfer_budget = 0;
    this.formBudgetTransfer.from_wbs = null;
    this.formBudgetTransfer.to_wbs = null;
    this.formBudgetTransfer.from_cost_centre = 0;
    this.formBudgetTransfer.to_cost_centre = 0;
    this.formBudgetTransfer.year_form_wbs = null;
    this.formBudgetTransfer.year_to_wbs = null;
  }

  limitTwoDecimal(num: number, mode: 'round' | 'floor' = 'round'): number {
    return mode === 'round'
      ? Math.round(num * 100) / 100
      : Math.floor(num * 100) / 100;
  }

  get isEnLanguage() {
    return this.translate.currentLang === 'en';
  }
}
