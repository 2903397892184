import {SweetAlertService} from '../../../shared/services/sweet-alert.service';
import {Component, Input, OnInit} from '@angular/core';
import {FeedService} from '../feed.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpParams} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MemoService} from '../../memos/memo/memo.service';
import {SubSink} from 'subsink';
import * as _ from 'lodash';
import {ThemeService} from '../../../shared/services/theme.service';

interface Announcement {
  id: number;
  created_by: string;
  created_department: string;
  memo_from: string;
  memo_name: string;
  memo_number: string;
  created_at: Date;
  updated_at: Date;
  created_user: number;
  updated_user: number;
  memo: number;
}


@Component({
  selector: 'app-feed-announcement',
  templateUrl: './feed-announcement.component.html',
  styleUrls: ['./feed-announcement.component.scss']
})
export class FeedAnnouncementComponent implements OnInit {

  announcements: Array<Announcement> = [];
  isLoading = false;
  pdfFile: any;
  showPreview: any;
  documentData: any;
  checkNotiAnnouncement = false;
  isMobile: boolean;

  @Input() announcementId: string;

  private subs = new SubSink();

  constructor(
    private swal: SweetAlertService,
    private feedService: FeedService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private memoService: MemoService,
    private themeService: ThemeService
  ) {
  }

  ngOnInit() {
    this.loadAnnouncements();
    this.isMobile = this.themeService.isMobile();
  }

  onResize(event) {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
  }

  hiddenAnnouncement(announcement: Announcement) {
    const data = {
      announcement: announcement.id
    };
    this.subs.add(
      this.feedService.hiddenAnnouncement(data).subscribe(
        () => {
          this.swal.toastNotification({type: 'success', content: 'Hidden Success'});
          this.loadAnnouncements();
        },
        error => {
          this.swal.toastNotification({type: 'error', content: error});
        }
      )
    );
  }

  checkIsAuthor(announcement) {
    return _.toNumber(localStorage.getItem('user_id')) === _.get(announcement, 'created_user', '');
  }

  deleteAnnouncement(announcement: Announcement) {
    this.subs.add(
      this.feedService.deleteAnnouncementList(announcement.id).subscribe(
        () => {
          this.swal.toastNotification({type: 'success', content: 'Delete Success'});
          this.loadAnnouncements();
        },
        error => {
          this.swal.toastNotification({type: 'error', content: error});
        }
      )
    );
  }

  loadAnnouncements() {
    this.isLoading = true;
    let params = new HttpParams();
    if (this.announcementId) {
      params = params.set('id', this.announcementId);
    }
    this.feedService.getAnnouncementList(params).subscribe(
      feeds => {
        this.announcements = feeds;
        this.isLoading = false;
      }, error => {
        this.swal.toastNotification({type: 'error', content: error});
        this.isLoading = false;
      }
    );
  }

  clickAnnouncement(announcement) {
      if (this.isMobile) {
        this.showPreview = false;
        window.open(announcement.pdf_file);
      } else {
      this.documentData = announcement.pdf_file;
      this.showPreview = true;
      }
  }

  closePreview(data?) {
    this.showPreview = false;
  }

  allAnnouncement() {
    this.checkNotiAnnouncement = false;
    this.loadAnnouncements();
  }
}
