import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { SweetAlertService } from '../../shared/services/sweet-alert.service';
import { HttpClientService } from '../../shared/services/http-client.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import {ThemeList, ThemeService} from '../../shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { SidebarService } from 'src/app/core/sidebar/sidebar.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  password1: string;
  password2: string;
  isLoading = false;
  currentTime: any;
  currentDate = moment().format('DD MMMM YYYY');
  token: string;
  strength = 0;
  checkLowerCase = false;
  checkUpperCase = false;
  checkNumber = false;
  checkSymbols = false;
  checkLength = false;
  language = '';
  themeList: ThemeList;

  constructor(
    private router: Router,
    private swal: SweetAlertService,
    private http: HttpClientService,
    private tokenService: AuthenticationService,
    private themeService: ThemeService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private sidebarService: SidebarService
  ) {
    this.language = translate.currentLang;
    this.themeService.data.subscribe(theme => {
      this.themeList = theme;
    });
  }

  ngOnInit() {
    this.sidebarService.setActiveSidebar(false);
    this.checkCurrentTime();
    this.checkToken();
    this.language = this.translate.currentLang;
  }

  strengthChange(strength: number): void {
    this.strength = strength;
  }

  checkToken() {
    this.activatedRoute.params.subscribe((params) => {
      this.token = params['id'];
      if (params.id) {
        this.tokenService
          .validateResetPasswordToken({ token: this.token })
          .subscribe(
            (data) => {},
            (err) => {
              this.swal.toastNotification({
                type: 'error',
                content: 'This link is no longer valid',
              });
              setTimeout(() => this.gotoLogin(), 3000);
            }
          );
      }
    });
  }

  gotoLogin() {
    this.router.navigate(['/login']);
  }

  checkCurrentTime() {
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }

  changePassword() {
    const msg = [];
    if (
      !this.checkLowerCase ||
      !this.checkUpperCase ||
      !this.checkNumber ||
      !this.checkSymbols ||
      !this.checkLength
    ) {
      msg.push(this.translate.instant('RESET-PASS.PASSWORD-NOT-SECURE'));
    }
    if (!this.validatePassword()) {
      msg.push(this.translate.instant('RESET-PASS.PASSWORD-NOT-SECURE'));
    }
    if (msg.length > 0) {
      this.swal.toastNotification({ type: 'error', content: msg.join(', ') });
      return;
    }

    this.isLoading = true;
    this.tokenService
      .resetPassword({
        password: this.password1,
        token: this.token,
      })
      .subscribe(
        (user: any) => {
          this.swal.toastNotification({
            type: 'success',
            content: this.translate.instant(
              'RESET-PASS.SUCCESS-CHANGE-PASSWORD'
            ),
          });
          setTimeout(() => this.gotoLogin(), 1000);
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 400) {
            this.swal.toastNotification({
              type: 'error',
              content: error.error.password.join(),
            });
          } else {
            this.errorHandler(error.status);
          }
        }
      );
  }

  validatePassword() {
    return this.password1 === this.password2;
  }

  errorHandler(statusCode) {
    this.isLoading = false;
    switch (statusCode) {
      case 400:
        this.swal.toastNotification({
          type: 'error',
          content: 'invalid email or password',
        });
        break;
      case 401:
        this.swal.toastNotification({
          type: 'error',
          content: 'invalid email or password',
        });
        break;
      default:
        this.swal.toastNotification({
          type: 'error',
          content: 'System error, Please contact technical support.',
        });
        break;
    }
  }

  validationLanguage() {
    this.checkLowerCase = false;
    this.checkUpperCase = false;
    this.checkNumber = false;
    this.checkSymbols = false;
    this.checkLength = false;
    if (this.password1) {
      this.checkLowerCase = !!this.password1.match(/[a-z]/g);
      this.checkUpperCase = !!this.password1.match(/[A-Z]/g);
      this.checkNumber = !!this.password1.match(/[0-9]/g);
      this.checkSymbols = !!this.password1.match(
        /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g
      );
      this.checkLength = this.password1.length >= 8;
    }
  }

  changeLanguage(lang: string) {
    this.language = lang;
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    }
    if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }
}
