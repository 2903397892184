import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PermissionRoutingModule } from './permission-routing.module';
import { UserModule } from './user/user.module';
import { ProfileModule } from './profile/profile.module';

@NgModule({
  imports: [
    CommonModule,
    PermissionRoutingModule,
    UserModule,
    ProfileModule
  ],
  declarations: [

  ]
})
export class PermissionModule { }
